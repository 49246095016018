import React, { useEffect, useState } from 'react';
import { Button, Group, Loader, Select, Stack, Text, TextInput } from '@mantine/core';
import { CareTeamMemberRole, CareTeamMemberRoleDisplay } from 'const-utils/codeSystems/ImaginePediatrics';
import { useMedplum } from '@medplum/react';
import { useForm } from '@mantine/form';
import { selectExtension } from 'imagine-dsl/utils/extensions';
import { PractitionerType, PractitionerTypeTag, System } from 'const-utils';
import useManageExternalCareTeamMember from './useManageExternalCareTeamMember';
import { Practitioner } from '@medplum/fhirtypes';
import isEmail from 'validator/lib/isEmail';
import { logError } from '@/errors';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery } from 'medplum-gql';

interface SaveExternalCareTeamMemberProps {
  cancel: () => void;
  careTeamId?: string;
  previousMemberId?: string;
  practitionerId?: string;
  patientId: string;
  role?: CareTeamMemberRole;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

function usePractitioner(practitionerId: string | undefined): [Practitioner | undefined, boolean] {
  const medplum = useMedplum();
  const [practitioner, setPractitioner] = useState<Practitioner | undefined>(undefined);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (practitionerId && !practitioner) {
      setLoading(true);
      medplum
        .readResource('Practitioner', practitionerId)
        .then((p) => setPractitioner(p))
        .catch((e) => logError(e))
        .finally(() => setLoading(false));
    }
  }, [medplum, practitioner, practitionerId]);

  return [practitioner, loading];
}

export default function SaveExternalCareTeamMember({
  cancel,
  careTeamId,
  previousMemberId,
  practitionerId,
  patientId,
  role: defaultRole,
  refetch,
}: SaveExternalCareTeamMemberProps): JSX.Element {
  const [practitioner, loading] = usePractitioner(practitionerId);

  const form = useForm({
    initialValues: {
      role: defaultRole,
      name: practitioner?.name?.[0].text,
      association: selectExtension(practitioner?.extension, System.ExternalPractitionerAssociation)?.valueString,
      workPhoneNumber: practitioner?.telecom?.find((t) => t.system === 'phone')?.value,
      faxNumber: practitioner?.telecom?.find((t) => t.system === 'fax')?.value,
      email: practitioner?.telecom?.find((t) => t.system === 'email')?.value,
    },
    validateInputOnBlur: true,
    validate: {
      name: (value) => (value ? undefined : 'Name is required'),
      role: (value) => (value ? undefined : 'Role is required'),
      email: (value) => {
        if (!value) {
          return undefined;
        }
        return isEmail(value) ? null : 'Invalid email';
      },
    },
  });

  useEffect(() => {
    form.setValues({
      role: defaultRole,
      name: practitioner?.name?.[0].text,
      association: selectExtension(practitioner?.extension, System.ExternalPractitionerAssociation)?.valueString,
      workPhoneNumber: practitioner?.telecom?.find((t) => t.system === 'phone')?.value,
      faxNumber: practitioner?.telecom?.find((t) => t.system === 'fax')?.value,
      email: practitioner?.telecom?.find((t) => t.system === 'email')?.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practitioner, defaultRole]);

  const [manageCareTeamMember, submitLoading] = useManageExternalCareTeamMember(
    careTeamId,
    {
      id: practitionerId,
      resourceType: 'Practitioner',
      name: [
        {
          text: form.values.name,
        },
      ],
      telecom: [
        {
          system: 'phone',
          value: form.values.workPhoneNumber,
        },
        {
          system: 'fax',
          value: form.values.faxNumber,
        },
        {
          system: 'email',
          value: form.values.email,
        },
      ],
      meta: {
        tag: [PractitionerTypeTag(PractitionerType.External)],
      },
      extension: form.values.association
        ? [
            {
              url: System.ExternalPractitionerAssociation,
              valueString: form.values.association,
            },
          ]
        : [],
    },
    form.values.role,
    previousMemberId,
    refetch,
    patientId,
    cancel,
  );

  const roleDisplay = form.values.role && CareTeamMemberRoleDisplay[form.values.role];

  return (
    <Stack
      style={{
        width: '100%',
        height: '100%',
        minHeight: '150px',
      }}
      justify="space-between"
    >
      <form>
        <Stack gap={14}>
          <Select
            label="Role"
            placeholder="Select a practitioner role"
            data={Object.values(CareTeamMemberRole).map((r) => ({
              value: r,
              label: CareTeamMemberRoleDisplay[r],
            }))}
            withAsterisk
            {...form.getInputProps('role')}
          />
          {roleDisplay && (
            <Text>
              You are saving a <b>{roleDisplay}</b> on the care team.
            </Text>
          )}
          <TextInput disabled={loading} withAsterisk label="Name" {...form.getInputProps('name')}></TextInput>
          <TextInput disabled={loading} label="Association" {...form.getInputProps('association')}></TextInput>
          <Group grow>
            <TextInput disabled={loading} label="Work Phone Number" {...form.getInputProps('workPhoneNumber')} />
            <TextInput disabled={loading} label="Fax Number" {...form.getInputProps('faxNumber')} />
          </Group>
          <TextInput disabled={loading} label="Email" {...form.getInputProps('email')} />
        </Stack>
      </form>
      <Group justify="right">
        {(loading || submitLoading) && <Loader />}
        <Button variant="outline" color="imagine-green" onClick={cancel}>
          Cancel
        </Button>
        <Button color="imagine-green" disabled={!form.isValid()} onClick={manageCareTeamMember}>
          Save
        </Button>
      </Group>
    </Stack>
  );
}
