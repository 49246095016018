import { Button, ButtonProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';
import { SaveTouchpointModal } from './SaveTouchpointModal';
import { IconArrowsShuffle } from '@tabler/icons-react';
import { Reference } from '@medplum/fhirtypes';

interface AddTouchpointButtonProps extends Omit<ButtonProps, 'onClick'> {
  patientId: string;
  basedOn?: Reference[];
}
export const AddTouchpointButton = ({ patientId, basedOn, ...buttonProps }: AddTouchpointButtonProps): JSX.Element => {
  const [open, handle] = useDisclosure();

  const close = async (): Promise<void> => {
    handle.close();
  };

  return (
    <>
      <Button variant="outline" color="imagine-green" {...buttonProps} onClick={handle.open}>
        <IconArrowsShuffle size={14} />
        <span style={{ paddingLeft: '6px' }}>Add Touchpoint</span>
      </Button>
      {open && (
        <SaveTouchpointModal
          patientId={patientId}
          basedOn={basedOn}
          submitLabel="Add Touchpoint"
          opened={open}
          closeModal={close}
        />
      )}
    </>
  );
};
