import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

interface UsePaginationProps {
  initialPage?: number;
  perPage?: number;
  changePage?: (page: number) => void;
}

interface UsePagination {
  getActivePage: (searchParams: URLSearchParams) => number;
  currentPage: number;
  pageBounds: number[];
  getTotalPages: (total: number, perPage: number) => number;
  changePage: (page: number) => void;
}

export const usePagination = (props: UsePaginationProps): UsePagination => {
  const { initialPage, perPage } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const getActivePage = (searchParams: URLSearchParams): number => {
    const page = searchParams.get('page');
    return page ? Number(page) : 1;
  };

  const currentPage = useMemo(() => {
    return initialPage || getActivePage(searchParams);
  }, [initialPage, searchParams]);

  const pageBounds: number[] = [];

  if (perPage) {
    const pageLowerBound = currentPage * perPage - perPage + 1;
    const pageUpperBound = currentPage * perPage;
    pageBounds.push(pageLowerBound, pageUpperBound);
  }

  const getTotalPages = (total: number, perPage: number): number => {
    return total ? Math.ceil(total / perPage) : total;
  };

  const changePage = (page: number): void => {
    searchParams.set('page', page.toString());
    setSearchParams(searchParams);
    window.scrollTo(0, 0);
  };

  return {
    getActivePage,
    currentPage,
    pageBounds,
    getTotalPages,
    changePage: props.changePage || changePage,
  };
};
