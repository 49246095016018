import { getErrorMessage, logError } from '@/errors';
import { ApolloQueryResult } from '@apollo/client';
import { notifications } from '@mantine/notifications';
import { useMedplum } from '@medplum/react';
import { CareTeamType, System } from 'const-utils';
import { GetPatientQuery } from 'medplum-gql';
import { useState } from 'react';

const useManagePodAssignment = (
  careTeamId: string | undefined,
  newPodId: string | undefined,
  previousPodId: string | undefined,
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>,
  patientId: string,
  close: () => void,
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const medplum = useMedplum();

  const addOrReplacePod = async (): Promise<void> => {
    if (!newPodId) {
      return;
    }

    try {
      setLoading(true);
      const existing = careTeamId && (await medplum.readResource('CareTeam', careTeamId));

      const newParticipant = {
        member: {
          reference: `CareTeam/${newPodId}`,
        },
      };

      if (existing) {
        const participantsFiltered =
          existing.participant?.filter((m) => m.member?.reference !== `CareTeam/${previousPodId}`) || [];
        await medplum.updateResource({
          ...existing,
          participant: [...participantsFiltered, newParticipant],
        });
      } else {
        await medplum.createResource({
          resourceType: 'CareTeam',
          name: 'Internal Care Team',
          subject: {
            reference: `Patient/${patientId}`,
          },
          participant: [newParticipant],
          meta: {
            tag: [
              {
                system: System.CareTeam,
                code: CareTeamType.Internal,
              },
            ],
          },
        });
      }
      await refetch();
      close();
    } catch (e) {
      notifications.show({
        message: getErrorMessage(e),
        color: 'status-error',
      });
      logError(e);
    } finally {
      setLoading(false);
    }
  };
  return { mutate: addOrReplacePod, loading };
};

export default useManagePodAssignment;
