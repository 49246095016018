import { Badge, Box, Group, Text, ThemeIcon } from '@mantine/core';
import { IconHeartExclamation } from '@tabler/icons-react';

import React from 'react';

interface AcuteCareBadgeProps {
  condensedDisplay?: boolean;
  iconOnly?: boolean;
}

export const AcuteCareBadge = ({ condensedDisplay, iconOnly }: AcuteCareBadgeProps): JSX.Element => {
  const acuteOptions = {
    display: 'Acute Care Only',
    icon: (
      <ThemeIcon c="brand-secondary.8" size={16}>
        <IconHeartExclamation size={16} />
      </ThemeIcon>
    ),
    background: 'imagine-green.0',
    color: 'brand-secondary.8',
  };

  const Icon = acuteOptions.icon;

  return (
    <Group gap="xs">
      <Badge
        leftSection={iconOnly ? undefined : Icon}
        c={acuteOptions.color}
        bg={acuteOptions.background}
        variant="light"
        p={condensedDisplay ? '0' : undefined}
        circle={!!iconOnly}
      >
        {iconOnly && <Box mt={6}>{Icon}</Box>}
        {!condensedDisplay && <Group gap="xs">{acuteOptions.display}</Group>}
      </Badge>
      {condensedDisplay && <Text c="dimmed">{acuteOptions.display}</Text>}
    </Group>
  );
};
