import { Container, Paper, Title } from '@mantine/core';
import React, { PropsWithChildren } from 'react';

type Variant = 'index' | 'default';

interface PageSkeletonProps {
  title?: string;
  withPaper?: boolean;
  variant?: Variant;
  topSection?: React.ReactNode;
}
export const PageSkeleton = ({
  children,
  title,
  withPaper = true,
  variant = 'default',
  topSection,
}: PropsWithChildren<PageSkeletonProps>): JSX.Element => {
  if (variant === 'index') {
    return (
      <Container size="xl" mt={20} style={{ width: '100%' }}>
        {topSection}
        <Paper radius="lg" shadow="xs" my={20}>
          {children}
        </Paper>
      </Container>
    );
  }

  return (
    <Container size="xl" mt={20} style={{ width: '100%' }}>
      {withPaper && (
        <Paper radius="lg" shadow="xs" p="xl" mb={20}>
          {title && <Title>{title}</Title>}
          {children}
        </Paper>
      )}
      {!withPaper && (
        <>
          {title && <Title>{title}</Title>}
          {children}
        </>
      )}
    </Container>
  );
};
