import React from 'react';
import { TextInput, TextInputProps } from '@mantine/core';
import { PatientPrimaryInfoFields } from '../../helpers/EditFormHandler';

interface PcpFieldProps extends Omit<TextInputProps, 'label'> {}

const Pcp = (props: PcpFieldProps): JSX.Element => {
  return <TextInput {...props} label={`Patient's ${PatientPrimaryInfoFields.PCP}`} data-autofocus mb="lg" />;
};

export default Pcp;
