import { useEffect, useState } from 'react';
import { Participant, TrackPublication } from 'twilio-video';

export default function usePublications(participant: Participant): TrackPublication[] {
  const [publications, setPublications] = useState<TrackPublication[]>([]);

  useEffect(() => {
    setPublications(Array.from(participant.tracks.values()) as TrackPublication[]);

    const publicationAdded = (publication: TrackPublication): void =>
      setPublications((prevPublications) => [...prevPublications, publication]);

    const publicationRemoved = (publication: TrackPublication): void =>
      setPublications((prevPublications) => prevPublications.filter((p) => p !== publication));

    participant.on('trackPublished', publicationAdded);
    participant.on('trackUnpublished', publicationRemoved);
    return () => {
      participant.off('trackPublished', publicationAdded);
      participant.off('trackUnpublished', publicationRemoved);
    };
  }, [participant]);

  return publications;
}
