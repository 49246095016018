import React, { PropsWithChildren, useMemo } from 'react';
import { usePinnedPatients } from '@/hooks/usePatientList';
import { IconPinned, IconPinnedFilled } from '@tabler/icons-react';
import { ActionIcon, Tooltip } from '@mantine/core';

interface PatientPinButtonProps {
  patientId: string;
}
export const PatientPinButton = (props: PatientPinButtonProps): JSX.Element => {
  const { patientIds, add, remove, isFull, loading } = usePinnedPatients();
  const isPinned = useMemo(() => patientIds.some((p) => p === props.patientId), [patientIds, props.patientId]);

  const FullListMessage = (props: PropsWithChildren) => {
    return isFull ? (
      <Tooltip label="Maximum patients pinned. Please unpin some patients if you’d like to add more.">
        {props.children}
      </Tooltip>
    ) : (
      <>{props.children}</>
    );
  };

  if (loading) {
    return <></>;
  }

  return isPinned ? (
    <ActionIcon
      variant="transparent"
      onClick={() => {
        remove(props.patientId);
      }}
    >
      <IconPinnedFilled size={20} />
    </ActionIcon>
  ) : (
    <FullListMessage>
      <ActionIcon
        variant="transparent"
        onClick={(e) => {
          e.stopPropagation();
          add(props.patientId);
        }}
        disabled={isFull}
      >
        <IconPinned size={20} />
      </ActionIcon>
    </FullListMessage>
  );
};
