import { useUserSession } from '@/components/shared/UserSessionContext';
import { useMedplumProfile } from '@medplum/react';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import { FeatureFlag } from 'const-utils';
import { compact } from 'lodash';
import { useMemo } from 'react';

type Flags = { -readonly [K in keyof typeof FeatureFlag]: boolean };

export const useFeatureFlags = (): Flags => {
  const profile = useMedplumProfile();
  const { markets } = useUserSession();
  const marketIdentifiers = compact(markets.map((m) => m.identifier?.at(0)?.value));

  // We can use attributes to target flags to groups of providers,
  // e.g. role, market. For now, we're just using email.
  const splitAttributes = {
    market_id: marketIdentifiers,
  };
  const email = profile?.telecom?.find((t) => t.system === 'email')?.value;

  const { treatments } = useSplitTreatments({
    names: Object.values(FeatureFlag),
    attributes: splitAttributes,
    splitKey: email,
    updateOnSdkUpdate: true,
  });

  return useMemo(() => {
    return Object.entries(FeatureFlag).reduce((acc, [key, name]) => {
      const treatment = treatments[name]?.treatment;
      acc[key as keyof typeof FeatureFlag] = treatment === 'on';

      return acc;
    }, {} as Flags); // eslint-disable-line @typescript-eslint/prefer-reduce-type-parameter
  }, [treatments]);
};
