import React, { useEffect, useMemo, useState } from 'react';
import { usePinnedPatients } from '@/hooks/usePatientList';
import { ActionIcon, Divider, Group, ScrollArea, Stack, Text, ThemeIcon } from '@mantine/core';
import { useDisclosure, useHover } from '@mantine/hooks';
import { IconChevronDown, IconChevronRight, IconInfoCircle, IconPinnedOff } from '@tabler/icons-react';
import { Patient } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { logError } from '@/errors';
import { formatHumanName } from '@medplum/core';
import { formatAgeAndDateOfBirth } from 'imagine-dsl/utils/strings';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Link } from 'react-router-dom';
import { is21OrOlder } from 'imagine-dsl/utils/patient';
import { Over21Badge } from '../Over21Badge';

interface PatientItemProps {
  patient: Patient;
  unpin: (patientId: string) => void;
  idx: number;
}
const PatientItem = (props: PatientItemProps): JSX.Element => {
  const { patient, unpin } = props;
  const { hovered: cardHovered, ref: cardRef } = useHover();
  const { hovered: nameHovered, ref: nameRef } = useHover<HTMLAnchorElement>();

  return (
    <Draggable index={props.idx} draggableId={patient.id!}>
      {(provided) => (
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Group
            bg={cardHovered ? 'primary-50.1' : 'none'}
            grow
            wrap="nowrap"
            ref={cardRef}
            justify="space-between"
            gap={0}
            style={{ borderRadius: '8px' }}
            py="md"
            pl="lg"
          >
            <Stack gap={4} miw="82%">
              <Text
                td={nameHovered ? 'underline' : 'none'}
                component={Link}
                ref={nameRef}
                to={`/Patient/${patient.id}`}
                c="imagine-green"
              >
                {patient.name?.[0] && formatHumanName(patient.name?.[0])}
              </Text>
              <Text size="xs" c="gray">
                {formatAgeAndDateOfBirth(patient.birthDate)}
                {is21OrOlder(patient.birthDate) && <Over21Badge />}
              </Text>
            </Stack>
            {cardHovered && (
              <ActionIcon
                onClick={() => patient.id && unpin(patient.id)}
                size={30}
                title="Unpin"
                aria-label="Unpin"
                pr={20}
                variant="transparent"
              >
                <IconPinnedOff />
              </ActionIcon>
            )}
          </Group>
        </div>
      )}
    </Draggable>
  );
};

export const PinnedPatientList = (): JSX.Element => {
  const { patientIds: pinnedPatientIds, remove: unpinPatientId, move, isFull, loading } = usePinnedPatients();
  const [opened, handle] = useDisclosure(true);

  const [patients, setPatients] = useState<Patient[]>([]);
  const medplum = useMedplum();

  const searchKey = useMemo(() => {
    return pinnedPatientIds.toSorted().join(',');
  }, [pinnedPatientIds]);

  useEffect(() => {
    if (!searchKey) {
      setPatients([]);
      return;
    }
    medplum
      .searchResources(
        'Patient',
        {
          _id: searchKey,
        },
        {
          cache: 'no-cache',
        },
      )
      .then((result) => {
        setPatients(result);
      })
      .catch((err) => {
        logError(err);
      });
  }, [searchKey, medplum]);

  const height = '100%';

  const patientLookupTable = useMemo(() => {
    return patients.reduce<Record<string, Patient>>((acc, patient) => {
      if (patient.id) {
        acc[patient.id] = patient;
      }
      return acc;
    }, {});
  }, [patients]);

  if (loading) {
    return <></>;
  }

  return (
    <>
      <Divider w="85%" mt="xl" />
      <Stack m="lg" w="90%" mah={height}>
        <Group>
          <ActionIcon onClick={handle.toggle} title="Pinned Patients" aria-label="Pinned Patients">
            {opened ? <IconChevronDown /> : <IconChevronRight />}
          </ActionIcon>
          <Text size="md">Pinned Patients ({pinnedPatientIds.length})</Text>
        </Group>
        {opened && (
          <ScrollArea w="100%" h="100%" scrollbarSize={8}>
            {isFull && (
              <Group wrap="nowrap">
                <ThemeIcon size="xs" color="status-info" variant="transparent">
                  <IconInfoCircle />
                </ThemeIcon>
                <Text c="gray" size="xs">
                  Maximum patients pinned. Please unpin some patients if you'd like to add more.
                </Text>
              </Group>
            )}
            <DragDropContext
              onDragEnd={({ destination, source }) => {
                if (destination?.index !== undefined) {
                  move(source.index, destination.index);
                }
              }}
            >
              <Droppable droppableId="dnd-list" direction="vertical">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {pinnedPatientIds.map((patientId, idx) => {
                      const patient = patientLookupTable[patientId];
                      if (!patient) {
                        return null;
                      }
                      return <PatientItem key={patientId} patient={patient} unpin={unpinPatientId} idx={idx} />;
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </ScrollArea>
        )}
      </Stack>
    </>
  );
};
