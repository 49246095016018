import { useEffect, useState } from 'react';
import { LocalTrackPublication, RemoteTrackPublication } from 'twilio-video';

export default function useTrackEnabled(publication?: LocalTrackPublication | RemoteTrackPublication): boolean {
  const [enabled, setEnabled] = useState(publication?.isTrackEnabled);

  useEffect(() => {
    // Reset the track when the 'publication' variable changes.
    setEnabled(publication?.isTrackEnabled);

    if (publication) {
      const setEnabledIfTrack = (): void => setEnabled(publication.isTrackEnabled);

      publication.on('trackEnabled', setEnabledIfTrack);
      publication.on('trackDisabled', setEnabledIfTrack);

      return () => {
        publication.off('trackEnabled', setEnabledIfTrack);
        publication.off('trackDisabled', setEnabledIfTrack);
      };
    }

    return () => {};
  }, [publication]);

  return !!enabled;
}
