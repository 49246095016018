import { Box, Group, Text, ThemeIcon } from '@mantine/core';
import React, { RefObject, forwardRef, useMemo } from 'react';
import { AlertTitle } from './AlertTitle';
import { TimelineItem } from '../utils/timeline';
import { TimelineItemComponent } from './TimelineItem';
import { Communication, HumanName, Practitioner } from '@medplum/fhirtypes';
import { Maybe } from 'medplum-gql';
import { IconUserCheck } from '@tabler/icons-react';
import { HumanNameDisplay } from '@medplum/react';
import { formatWithYesterdayAndToday } from 'imagine-dsl/utils/dates';

interface TimelineGroupProps {
  encounterId: string;
  items: TimelineItem[];
  previousGroupTime: string;
  currentTaskEncounterId?: string;
  priority: 'routine' | 'urgent' | 'asap' | 'stat';
  assigneeForTask: Maybe<Practitioner>;
  itemRefs: Record<string, RefObject<HTMLDivElement>>;
}

const DotBorderComponent = ({
  children,
  accentColor,
  hasAssignee,
}: {
  children: JSX.Element;
  accentColor: string;
  hasAssignee: boolean;
}) => {
  return (
    <Box
      style={{
        position: 'relative',
        border: '2px solid transparent', // Set the border width and make it transparent
        padding: '0 0px', // Adjust padding to make space for the dots
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '8px', // Set width of the dot
          height: '8px', // Set height of the dot
          backgroundColor: accentColor, // Set color of the dot
          borderRadius: '50%', // Make it a circle
          left: -3,
          top: 0,
        }}
      />
      <div
        style={{
          position: 'absolute',
          width: '8px', // Set width of the dot
          height: '8px', // Set height of the dot
          backgroundColor: accentColor, // Set color of the dot
          borderRadius: '50%', // Make it a circle
          bottom: hasAssignee ? -5 : 5,
          left: -3,
        }}
      />
      {children}
    </Box>
  );
};

export const TimelineGroup = forwardRef<HTMLDivElement, TimelineGroupProps>(
  (props: TimelineGroupProps, _ref): JSX.Element => {
    const { encounterId, previousGroupTime, currentTaskEncounterId, items, priority, assigneeForTask, itemRefs } =
      props;

    const accentColor = useMemo((): string => {
      if (currentTaskEncounterId === encounterId) {
        return priority === 'urgent' ? 'var(--mantine-color-status-error-6)' : 'var(--mantine-color-imagine-green-6)';
      } else {
        return 'var(--mantine-color-brand-gray-4';
      }
    }, [currentTaskEncounterId, encounterId, priority]);

    const sameDay = (item: TimelineItem, index: number): boolean => {
      const currentItemDate = new Date(item.relevantTime).getDate();
      if (index === 0) {
        const previousItemDate = previousGroupTime ? new Date(previousGroupTime).getDate() : '';
        return currentItemDate === previousItemDate;
      }

      const previousItemDate = new Date(items[index - 1].relevantTime).getDate();
      return currentItemDate === previousItemDate;
    };

    const sameSender = (item: TimelineItem, index: number): boolean => {
      if (item.itemType !== 'Communication' || index === 0 || items[index - 1].itemType !== 'Communication') {
        return false;
      }
      const currentSender = (item.resource as Communication).sender?.reference;
      const previousSender = (items[index - 1].resource as Communication).sender?.reference;
      return currentSender === previousSender;
    };

    return (
      <DotBorderComponent hasAssignee={!!assigneeForTask} accentColor={accentColor}>
        <Box
          pl={24}
          style={{
            borderLeft: `1px solid ${accentColor}`,
          }}
          key={encounterId}
        >
          {currentTaskEncounterId && (
            <AlertTitle encounterId={encounterId} currentTaskEncounterId={currentTaskEncounterId} priority={priority} />
          )}
          {items.map((item, index) => {
            const isSameDay = sameDay(item, index);
            return (
              <React.Fragment key={item.resource?.id}>
                {!isSameDay && (
                  <Text ta="center" fw="bold">
                    {formatWithYesterdayAndToday(item.relevantTime, 'EEE, MMMM do, yyyy')}
                  </Text>
                )}
                <TimelineItemComponent
                  ref={item.resource?.id ? itemRefs[item.resource?.id] : null}
                  item={item}
                  sameGroup={isSameDay}
                  sameSender={sameSender(item, index)}
                />
              </React.Fragment>
            );
          })}
          {assigneeForTask && (
            <Group style={{ gap: 4 }}>
              <ThemeIcon>
                <IconUserCheck height={24} width={24} />
              </ThemeIcon>
              <Text>Alert was assigned to</Text>
              <Text fw="bold">
                <HumanNameDisplay value={assigneeForTask?.name?.[0] as HumanName} />
              </Text>
            </Group>
          )}
        </Box>
      </DotBorderComponent>
    );
  },
);
