import React from 'react';
import { SelectInput, SelectInputProps } from '../../helpers/SelectInput';
import { PatientPrimaryInfoFields } from '../../helpers/EditFormHandler';

interface LivingSituationFieldProps extends Omit<SelectInputProps, 'label'> {}

const LivingSituation = (props: LivingSituationFieldProps): JSX.Element => {
  return <SelectInput {...props} label={PatientPrimaryInfoFields.LIVING_SITUATION} />;
};

export default LivingSituation;
