import React, { useEffect, useRef } from 'react';
import { getConfig } from '@/config';
import { Anchor, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import type { VersionFile } from 'vite.config';
import { logError } from '@/errors';

// useNewVersionNotification is expected to be mounted only once
export const useNewVersionNotification = (): void => {
  const alreadyNotified = useRef(false);

  useEffect(() => {
    const config = getConfig();
    if (config.appEnv === 'local') {
      return () => {};
    }

    // Please see vite.config.ts generate-version.json-file plugins note about `buildHash` regarding why we
    // are not initializing this to a config-defined value and are instead relying on the initial poll of version.json
    //
    // There is an understanding that a deploy between the first mount of this hook and the initial retrieval of `buildHash`
    // would result in an invalid state where the version is incorrectly reported resulting in a missed 'new version notification'.
    // Given the corner-case nature of this scenario, this seemed like an acceptable risk relative to the simplified build and config setup.
    let initial = '';
    const checkAndNotify = async (): Promise<void> => {
      if (alreadyNotified.current) {
        return;
      }

      const version = await getVersion().catch((e) => logError(e));
      if (!version) {
        return;
      }

      if (!initial) {
        initial = version.buildHash;
        return;
      }

      if (initial !== version.buildHash && !alreadyNotified.current) {
        alreadyNotified.current = true;

        showNotification({
          color: 'status-info',
          title: 'New Version Available',
          message: (
            <Text>
              A new version of Care Hub is available. Please reload the page or{' '}
              <Anchor
                onClick={(e) => {
                  e.preventDefault();
                  window.location.reload();
                }}
              >
                click here
              </Anchor>{' '}
              to get the latest changes.
            </Text>
          ),
          autoClose: false,
        });
      }
    };

    const interval = setInterval(checkAndNotify, 15_000);
    // execute a leading edge tick of the interval to initialize the buildHash from version.json ASAP
    checkAndNotify().catch((e) => logError(e));

    return () => {
      clearInterval(interval);
    };
  }, []);
};

const getVersion = async (): Promise<VersionFile> => {
  const res = await fetch('/version.json');
  if (!res.ok) {
    throw new Error(`unexpected non-2xx version.json response ${res.status}`);
  }

  return (await res.json()) as VersionFile;
};
