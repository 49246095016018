import React, { useEffect } from 'react';
import {
  Anchor,
  Box,
  Breadcrumbs,
  Container,
  Group,
  LoadingOverlay,
  Modal,
  Stepper,
  Text,
  Stack,
  Button,
} from '@mantine/core';
import { ContactCaregiver } from './steps/ContactCaregiver';
import { useOutreach } from './Context';
import ReviewContactInfo from './steps/ReviewContactInfo';
import Enroll from './steps/Enroll';
import { SaveTouchpointModal } from '@/components/outreach/SaveTouchpointModal';
import { useNavigate, useBlocker } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { EnrollStep } from './types';

export const OutreachFlow = (): JSX.Element => {
  const {
    flowStepIndex,
    enrollStep,
    loading,
    loggingCall,
    patient,
    closeLogCallModal,
    handleClickLogCallAndExit,
    contactCaregiverForm,
  } = useOutreach();
  const [isConfirmingExit, { open: openConfirmExit, close: closeConfirmExit }] = useDisclosure();

  const navigate = useNavigate();

  const loader = (
    <LoadingOverlay
      overlayProps={{ opacity: 0.5, blur: 0 }}
      transitionProps={{ duration: 1000 }}
      visible={loading}
      zIndex={1000}
    />
  );

  const navigateToProfile = (): void => {
    navigate(`/Patient/${patient.id}`);
  };

  const close = async ({ canceled }: { canceled: boolean }): Promise<void> => {
    closeLogCallModal();
    if (!canceled) {
      navigateToProfile();
    }
  };

  const onBackToProfile = (): void => {
    if (flowStepIndex > 0) {
      openConfirmExit();
      return;
    }

    navigateToProfile();
  };

  const blocker = useBlocker(
    !isConfirmingExit && !loggingCall && flowStepIndex > 0 && enrollStep !== EnrollStep.ScheduleOnboarding,
  );
  useEffect(() => {
    if (flowStepIndex === 0) {
      return () => {};
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#browser_compatibility
    const listener = (event: BeforeUnloadEvent): void => {
      event.preventDefault();
    };
    window.addEventListener('beforeunload', listener);
    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [flowStepIndex]);

  return (
    <Group gap={0} h="100%">
      <Container p="lg" w="100%" h="100%">
        <Breadcrumbs mb="xl">
          <Anchor onClick={onBackToProfile}>‹ Back to patient profile</Anchor>
        </Breadcrumbs>
        <Stepper size="sm" active={flowStepIndex}>
          <Stepper.Step label="Contact caregiver" loading={flowStepIndex === 0 && loading}>
            <Box pos="relative">
              {loader}
              <ContactCaregiver />
            </Box>
          </Stepper.Step>
          <Stepper.Step label="Review contact info" loading={flowStepIndex === 1 && loading}>
            <Box pos="relative">
              {loader}
              <ReviewContactInfo />
            </Box>
          </Stepper.Step>
          <Stepper.Step label="Enroll" loading={flowStepIndex === 2 && loading}>
            <Box pos="relative">
              {loader}
              <Enroll />
            </Box>
          </Stepper.Step>
        </Stepper>
        {loggingCall && (
          <SaveTouchpointModal
            patientId={patient.id!}
            opened={loggingCall}
            submitLabel="Log call and exit"
            closeModal={close}
            details={{
              ...contactCaregiverForm.values,
              contactName:
                `${contactCaregiverForm.values.contactFirstName} ${contactCaregiverForm.values.contactLastName}`.trim(),
            }}
          />
        )}
        <Modal
          opened={isConfirmingExit || blocker.state === 'blocked'}
          onClose={() => {
            blocker.reset?.();
            closeConfirmExit();
          }}
          title={
            <Text size="sm" fw={700} c="imagine-green">
              Are you sure you want to exit?
            </Text>
          }
          centered
        >
          <Text>
            Any unsaved changes will be lost. You may want to 'Log call & exit' instead to record a touchpoint.
          </Text>
          <Stack align="center" mt="xl" gap="xs">
            <Button
              onClick={() => {
                blocker.reset?.();
                closeConfirmExit();
                handleClickLogCallAndExit();
              }}
            >
              Log call & exit
            </Button>
            <Anchor
              onClick={() => {
                if (blocker.state === 'blocked') {
                  blocker.proceed?.();
                } else {
                  navigateToProfile();
                }

                closeConfirmExit();
              }}
              c="dimmed"
            >
              Exit
            </Anchor>
          </Stack>
        </Modal>
      </Container>
    </Group>
  );
};

export default OutreachFlow;
