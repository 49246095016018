import { LanguageTranslation } from 'const-utils';
import { PreferredLanguage } from 'const-utils/codeSystems/ImaginePediatrics';

export const getTranslatedValue = (translations: LanguageTranslation[], language: string): string => {
  const translation = translations.find((t) => t.languageCodes.includes(language));
  return (
    translation?.value ||
    translations.find((t) => t.languageCodes.includes(PreferredLanguage.En))?.value ||
    translations[0].value
  );
};
