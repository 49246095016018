import React, { useMemo } from 'react';
import { Button, Group, Modal, Select, Stack, TextInput, Textarea, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useSaveTouchpoint } from './useSaveTouchpoint';
import { isCallbackRequested } from 'imagine-dsl/utils/outreach';
import { GetPatientActivityDocument, GetPatientOutreachActivityDocument } from 'medplum-gql';
import { useApolloClient } from '@apollo/client';
import { logError } from '@/errors';
import { PhoneInput } from '../shared/PhoneInput';
import {
  OutreachDisposition,
  OutreachModality,
  outreachDispositionMap,
  outreachModalityMap,
  outreachModalityOptions,
} from 'const-utils/codeSystems/ImaginePediatrics';
import { DateTimePicker } from '@mantine/dates';
import { endOfToday, format, isAfter } from 'date-fns';
import { Reference } from '@medplum/fhirtypes';

export interface SaveTouchpointFormValues {
  notes: string;
  outcome?: OutreachDisposition;
  callbackDateTimeStart?: Date;
  callbackDateTimeEnd?: Date;
  callbackTimezone?: string;
  details?: TouchpointDetails;
}

interface TouchpointDetails {
  date?: Date;
  timezone: string;
  modality?: OutreachModality;
  phoneNumber: string;
  contactName: string;
}

const validateCallbackTimeEnd = (
  callbackDateTimeEnd: Date | undefined,
  values: SaveTouchpointFormValues,
): React.ReactNode => {
  if (!isCallbackRequested(values)) {
    return null;
  }

  if (
    values.callbackDateTimeStart &&
    callbackDateTimeEnd &&
    format(values.callbackDateTimeStart, 'yyyyMMddHHmm') > format(callbackDateTimeEnd, 'yyyyMMddHHmm')
  ) {
    return 'End must be on or after start';
  }

  return !callbackDateTimeEnd ? 'Time is required' : null;
};
const validateCallbackTimeStart = (
  callbackDateTimeStart: Date | undefined,
  values: SaveTouchpointFormValues,
): React.ReactNode => {
  if (!isCallbackRequested(values)) {
    return null;
  }
  const touchpointDate = values.details?.date;

  if (touchpointDate && callbackDateTimeStart && isAfter(touchpointDate, callbackDateTimeStart)) {
    return 'Start must be on or after touchpoint date';
  }

  return !callbackDateTimeStart ? 'Time is required' : null;
};

const timezoneOptions = [
  { value: 'America/Los_Angeles', label: 'Pacific' },
  { value: 'America/Denver', label: 'Mountain' },
  { value: 'America/Chicago', label: 'Central' },
  { value: 'America/New_York', label: 'Eastern' },
];

export interface SaveTouchpointModalProps {
  patientId: string;
  submitLabel: string;
  opened: boolean;
  details?: TouchpointDetails;
  formValues?: SaveTouchpointFormValues;
  touchpointId?: string;
  closeModal: (opts: { canceled: boolean }) => Promise<void>;
  basedOn?: Reference[];
}

export const SaveTouchpointModal = (props: SaveTouchpointModalProps): JSX.Element => {
  const apolloClient = useApolloClient();
  const form = useForm<SaveTouchpointFormValues>({
    initialValues: {
      details: props.details ?? {
        date: props.formValues?.details?.date ?? new Date(),
        timezone: props.formValues?.details?.timezone ?? '',
        modality: props.formValues?.details?.modality ?? undefined,
        phoneNumber: props.formValues?.details?.phoneNumber ?? '',
        contactName: props.formValues?.details?.contactName ?? '',
      },
      notes: props.formValues?.notes ?? '',
      outcome: props.formValues?.outcome,
      callbackDateTimeStart: props.formValues?.callbackDateTimeStart,
      callbackDateTimeEnd: props.formValues?.callbackDateTimeEnd,
      callbackTimezone: props.formValues?.callbackTimezone,
    },
    validate: {
      outcome: (value) => !value && 'Outcome is required',
      callbackDateTimeStart: validateCallbackTimeStart,
      callbackDateTimeEnd: validateCallbackTimeEnd,
      callbackTimezone: (value, values) => (isCallbackRequested(values) && !value ? 'Timezone is required' : null),
      details: {
        date: (value) => (value ? null : 'Date is required'),
        modality: (value) => (value ? null : 'Modality is required'),
      },
    },
    validateInputOnBlur: true,
  });

  const close = async ({ canceled }: { canceled: boolean }): Promise<void> => {
    await props.closeModal({ canceled });
  };

  const callback = isCallbackRequested(form.values);

  const callbackTimeStart = form.values.callbackDateTimeStart;

  const [saveTouchpoint] = useSaveTouchpoint(props.patientId, form.values, props.touchpointId, props.basedOn);
  const [submitting, setSubmitting] = React.useState(false);
  const submit = async (): Promise<void> => {
    setSubmitting(true);
    form.validate();
    if (!form.isValid()) {
      setSubmitting(false);
      return;
    }

    await saveTouchpoint();
    await apolloClient
      .refetchQueries({
        include: [GetPatientActivityDocument, GetPatientOutreachActivityDocument],
      })
      .catch((e) => logError(e));
    await close({ canceled: false });
    setSubmitting(false);
  };

  const modality = form.values.details?.modality;
  const outcome = form.values.outcome;
  const outreachDispositionOptions = useMemo(() => {
    if (!modality) {
      return [];
    }

    return outreachModalityMap[modality].dispositions.map((disposition) => ({
      value: disposition,
      label: outreachDispositionMap[disposition].display,
    }));
  }, [modality]);

  return (
    <Modal.Root size="lg" padding="xl" radius="xl" opened={props.opened} onClose={() => close({ canceled: true })}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title c="imagine-green" style={{ fontSize: '20px' }}>
            Save Touchpoint
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Stack gap="md">
            <Group grow align="start">
              <Select
                size="sm"
                data={outreachModalityOptions}
                required
                label="Modality"
                {...form.getInputProps('details.modality')}
                onChange={(m) => {
                  // Modality informs allowed outcomes so if a modality is selected that doesn't allow the current outcome, clear it
                  if (m && outcome && !outreachModalityMap[m as OutreachModality].dispositions.includes(outcome)) {
                    form.setFieldValue('outcome', undefined);
                  }
                  form.getInputProps('details.modality').onChange(m);
                }}
              />
            </Group>
            <PhoneInput style={{ flex: 1 }} {...form.getInputProps('details.phoneNumber')} />
            <Group grow align="start">
              <TextInput label="Contact name" {...form.getInputProps('details.contactName')} />
            </Group>
            <Group grow>
              <DateTimePicker
                required
                maxDate={endOfToday()}
                label="Date"
                valueFormat="YYYY-MM-DD hh:mm a"
                {...form.getInputProps('details.date')}
              />
            </Group>
            <br />
          </Stack>
          <Stack gap="md">
            <Tooltip disabled={!!modality} label="Select modality first">
              <Select
                key={modality} // force re-render when modality changes to update options and value
                label="Outcome"
                placeholder="Please select"
                required
                disabled={!modality}
                data={outreachDispositionOptions}
                {...form.getInputProps('outcome')}
              />
            </Tooltip>
            {callback && (
              <>
                <Group grow align="start">
                  <DateTimePicker
                    required
                    valueFormat="YYYY-MM-DD hh:mm a"
                    label="Callback start"
                    minDate={form.values.details?.date}
                    {...form.getInputProps('callbackDateTimeStart')}
                    onBlur={(e: React.FocusEvent) => {
                      form.getInputProps('callbackDateTimeStart').onBlur(e);
                      if (!form.values.callbackDateTimeEnd && form.values.callbackDateTimeStart) {
                        form.setFieldValue('callbackDateTimeEnd', form.values.callbackDateTimeStart);
                      }
                    }}
                  />
                  <DateTimePicker
                    required
                    valueFormat="YYYY-MM-DD hh:mm a"
                    label="Callback end"
                    disabled={!callbackTimeStart}
                    minDate={form.values.callbackDateTimeStart}
                    {...form.getInputProps('callbackDateTimeEnd')}
                  />
                </Group>
                <Group grow align="start">
                  <Select
                    size="sm"
                    label="Timezone"
                    placeholder="Please select"
                    data={timezoneOptions}
                    required
                    disabled={!callbackTimeStart}
                    miw={'45%'}
                    {...form.getInputProps('callbackTimezone')}
                  />
                </Group>
              </>
            )}
            <Textarea label="Notes" {...form.getInputProps('notes')} />
            <Group justify="right">
              <Button variant="subtle" onClick={() => close({ canceled: true })}>
                Cancel
              </Button>
              <Button
                variant="filled"
                type="submit"
                onClick={submit}
                loading={submitting}
                disabled={!modality || !outcome}
              >
                {props.submitLabel}
              </Button>
            </Group>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
