import React from 'react';
import { Group, Stack, Text } from '@mantine/core';
import { HumanName, Organization } from '@medplum/fhirtypes';
import { HumanNameDisplay } from '@medplum/react';
import { Patient } from 'medplum-gql';
import { formatAge } from 'imagine-dsl/utils/strings';

import classes from './TaskTable.module.css';
import { useGetCarePathway } from '@/hooks/useGetCarePathway';
import { ClusterBadge } from '@/components/ClusterBadge';
import { is21OrOlder } from 'imagine-dsl/utils/patient';
import { AcuteCareBadge } from '@/components/AcuteCareBadge';

import { acuteCareOnly } from 'imagine-dsl/utils/healthplan';
import { Over21Badge } from '@/components/Over21Badge';

interface PatientColumnProps {
  patient: Patient;
  market?: Organization;
}

export function PatientColumn({ patient, market }: PatientColumnProps): JSX.Element {
  const age = formatAge(patient.birthDate);
  const abbreviatedAge = age.replace('years old', 'y.o.');
  const isOver21 = is21OrOlder(patient.birthDate);

  const { carePathway, cluster } = useGetCarePathway({
    clinicalImpressions: patient?.ClinicalImpressionList,
  });

  const isAcuteCareOnly = acuteCareOnly(patient?.CoverageList);

  return (
    <Stack className={classes.patientCol} gap="xs">
      <HumanNameDisplay value={patient.name?.[0] as HumanName} />
      <Group align="center" gap={5} className={classes.patientDetails}>
        <Text c="dimmed">{market?.name}</Text>
        <Text c="dimmed">•</Text>
        <Text c="dimmed">{abbreviatedAge}</Text>
        {isOver21 && <Over21Badge />}
        {isAcuteCareOnly && (
          <>
            <Text c="dimmed">•</Text> <AcuteCareBadge iconOnly condensedDisplay />
          </>
        )}
        {carePathway && !isAcuteCareOnly && (
          <>
            <Text c="dimmed">•</Text>
            <ClusterBadge
              cluster={cluster || 'Unknown'}
              carePathway={carePathway}
              iconOnly
              hideSubtext
              condensedDisplay
            />
          </>
        )}
      </Group>
    </Stack>
  );
}
