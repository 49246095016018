import { localStorageKeys } from '@/localAndSessionStorage';
import { useEffect } from 'react';
import { throttle } from 'lodash';

export const setActive = (userId?: string): void =>
  localStorage.setItem(localStorageKeys.lastActive(userId), `${new Date().getTime()}`);

const INACTIVE_TIMEOUT_THRESHOLD_MS = 1000 * 60 * 60;

export const useActivityTracking = ({
  userId,
  inactiveTimeoutThresholdMs = INACTIVE_TIMEOUT_THRESHOLD_MS,
  onInactive,
}: {
  userId?: string;
  inactiveTimeoutThresholdMs?: number;
  onInactive?: () => unknown;
}): void => {
  useEffect(() => {
    if (!userId) {
      return () => {};
    }

    const inactiveCheckInterval = setInterval(() => {
      const lastActive = localStorage.getItem(localStorageKeys.lastActive(userId));
      if (!lastActive) {
        return;
      }

      const now = new Date().getTime();
      const lastActiveTime = parseInt(lastActive, 10);
      if (now - lastActiveTime > inactiveTimeoutThresholdMs) {
        onInactive?.();
        clearInterval(inactiveCheckInterval);
      }
    }, 1000);

    const setActiveThrottled = throttle(() => {
      setActive(userId);
    }, 500);

    window.addEventListener('mousemove', setActiveThrottled);
    window.addEventListener('keydown', setActiveThrottled);
    window.addEventListener('scroll', setActiveThrottled, true);
    window.addEventListener('focus', setActiveThrottled);
    window.addEventListener('@ipch:active', setActiveThrottled);
    window.addEventListener('storage', (_event) => {
      //no op to help refresh storage across tabs
    });

    return () => {
      window.removeEventListener('mousemove', setActiveThrottled);
      window.removeEventListener('keydown', setActiveThrottled);
      window.removeEventListener('scroll', setActiveThrottled, true);
      window.removeEventListener('focus', setActiveThrottled);
      window.removeEventListener('@ipch:active', setActiveThrottled);
      window.removeEventListener('storage', (_event) => {
        //no op to help refresh storage across tabs
      });
      clearInterval(inactiveCheckInterval);
    };
  }, [userId, onInactive, inactiveTimeoutThresholdMs]);
};

export const useAlwaysActive = ({ userId, when }: { userId?: string; when: boolean }): void => {
  useEffect(() => {
    if (!when) {
      return () => {};
    }

    const interval = setInterval(() => {
      window.dispatchEvent(new Event('@ipch:active'));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [userId, when]);
};
