import React, { useMemo, useState } from 'react';
import { OutreachTask } from 'imagine-dsl/models/tasks/outreachTask';
import { TaskDrawerContentRenderer } from '..';
import {
  ActionIcon,
  Button,
  Card,
  Divider,
  Drawer,
  Group,
  Loader,
  ScrollArea,
  Stack,
  Table,
  Text,
} from '@mantine/core';
import { TaskDrawerHeader } from '../TaskDrawerHeader';
import {
  Maybe,
  SearchPractitionersQueryVariables,
  useGetIncompleteOutreachTaskQuery,
  useGetPatientOutreachAttemptsQuery,
} from 'medplum-gql';
import { NoOutreachAttempts } from '@/components/outreach/OutreachSidebar';
import { mapOutreachQueryResult } from 'imagine-dsl/utils/outreach';
import { TouchpointActivityItem } from '@/components/patientProfile/patientActivity/TouchpointActivityItem';
import { EditTaskButton } from '../../EditOutreachTaskButton';
import { TaskFormValues } from '@/components/shared/UpsertTaskModal';
import { ResolveTaskButton } from '../../ResolveTaskButton';
import { format, isAfter } from 'date-fns';
import { capitalize } from 'lodash';
import { formatHumanName } from '@medplum/core';
import { HumanName } from '@medplum/fhirtypes';
import { PriorityBadge } from '../../PriorityBadge';
import { IconAlertTriangle, IconEditCircle } from '@tabler/icons-react';
import { useHandleConductOutreach } from '@/components/shared/patient/PatientHeader/hooks';
import { useUserSession } from '@/components/shared/UserSessionContext';
import { useDisclosure } from '@mantine/hooks';
import { useSaveTask } from '@/components/shared/useSaveTask';
import { AssignToTeamMemberModal } from '@/components/shared/AssignToTeamMemberModal';
import { ProgramStatus, System } from 'const-utils';
import { AddTouchpointButton } from '@/components/outreach/AddTouchpointButton';

export const OutreachDrawerContent: TaskDrawerContentRenderer = ({
  task: baseTask,
  patient,
  withViewPatientProfile,
  closeDrawer,
  refetchTasks,
}) => {
  const outreachAttempts = useGetPatientOutreachAttemptsQuery({
    variables: {
      id: patient?.id ?? '',
    },
    skip: !patient?.id,
  });

  const conductOutreach = useHandleConductOutreach(patient);
  const { profile } = useUserSession();
  const [searchParams, setSearchParams] = useState<Maybe<SearchPractitionersQueryVariables>>();
  const [opened, { close, open }] = useDisclosure();
  const outreachTaskQuery = useGetIncompleteOutreachTaskQuery({ variables: { taskId: baseTask?.id ?? '' } });
  const task = useMemo(
    () => outreachTaskQuery.data?.Task && new OutreachTask(outreachTaskQuery.data.Task),
    [outreachTaskQuery.data?.Task],
  );

  const taskFormValues = useMemo((): TaskFormValues => {
    return {
      taskType: task?.taskType?.code ?? '',
      assignedTo: task?.owner?.id ?? '',
      assignedToName: task?.owner ? formatHumanName(task.owner?.name?.[0] as HumanName) : '',
      priority: task?.priority ?? '',
      dueTime: task?.dueDate ? format(task.dueDate, 'HH:mm') : '23:59',
      dueDate: task?.dueDate ? format(task.dueDate, 'yyyy-MM-dd') : undefined,
      id: task?.id ?? '',
      notes: task?.notes ?? '',
      requestor: task?.requestor?.id ?? profile.id,
      createdDate: task?.createdDate ?? new Date().toISOString(),
    };
  }, [task, profile]);

  const [saveTask] = useSaveTask(patient?.id ?? '', taskFormValues);

  const needsOutreach = useMemo(() => {
    const programStatus = patient?.meta?.tag?.find((tag) => tag.system === System.ProgramStatus)?.code;
    if (!programStatus) {
      return true;
    }

    return ![ProgramStatus.Onboarded.toString(), ProgramStatus.Enrolled.toString()].includes(programStatus);
  }, [patient]);

  if (!patient?.id || !task?.id) {
    return (
      <Drawer.Content>
        <Drawer.Body>
          <Loader />
        </Drawer.Body>
      </Drawer.Content>
    );
  }

  const count = outreachAttempts.data?.Patient?.outreachAttempts?.length || 0;

  const EMPTY_PLACEHOLDER = '--';

  const onAssignToTeamMember = async (practitionerId: string): Promise<void> => {
    taskFormValues.assignedTo = practitionerId;
    await saveTask();
    close();
  };

  const onSearch = (name: Maybe<string>): void => {
    if (!name) {
      setSearchParams({});
    } else {
      setSearchParams({ name });
    }
  };

  const closeModal = (): void => {
    setSearchParams(null);
    close();
  };

  const onCloseDrawerHandler = async () => {
    await refetchTasks?.();
    closeDrawer();
  };

  return (
    <Drawer.Content>
      <Drawer.Header h={3}>
        <Group ml="auto">
          <Drawer.CloseButton />
        </Group>
      </Drawer.Header>
      <Drawer.Body style={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 60px)' }}>
        <TaskDrawerHeader patientId={patient.id} withViewPatientProfile={withViewPatientProfile} />

        <ScrollArea style={{ padding: '2em' }}>
          <Text c="imagine-green">Task Details</Text>
          <Card withBorder style={{ borderRadius: '10px' }} my="sm">
            <Group gap="xs" justify="space-between">
              <Text size="md" fw={'bold'}>
                {task.taskType.display}
              </Text>
              <EditTaskButton patientId={patient.id} task={taskFormValues} />
            </Group>
            <Stack gap={'xs'} mt={'xs'}>
              <Group>
                <Text miw={75} size="xs">
                  Created by:
                </Text>
                <Text ml={'xl'} size="xs">{`${formatHumanName(task.requestor?.name?.[0] as HumanName)} - ${format(
                  task.createdDate ?? '',
                  'MMM dd, yyyy hh:mm aa',
                )}`}</Text>
              </Group>
              {task.hasBeenEdited && (
                <Group>
                  <Text miw={75} size="xs">
                    Edited by:
                  </Text>
                  <Text ml={'xl'} size="xs">{`${task.editor} - ${format(
                    task.updatedDate ?? '',
                    'MMM dd, yyyy hh:mm aa',
                  )}`}</Text>
                </Group>
              )}
              <Group>
                <Text miw={75} size="xs">
                  Assigned to:
                </Text>
                <Text ml={'xl'} size="xs">{`${formatHumanName(task.owner?.name?.[0] as HumanName)}`}</Text>
              </Group>
              <Group>
                <Text miw={75} size="xs">
                  Due date:
                </Text>
                <Text ml={'xl'} size="xs">
                  {format(task.dueDate ?? '', 'MMM dd, yyyy hh:mm aa')}
                </Text>
                {isAfter(new Date(), task.dueDate ?? '') && (
                  <Group>
                    <IconAlertTriangle color="orange" />
                    <Text fw={'bold'} size="xs">
                      Overdue
                    </Text>
                  </Group>
                )}
              </Group>
              <Group>
                <Text miw={75} size="xs">
                  Priority:
                </Text>
                <Text ml={'xl'} size="xs">
                  <PriorityBadge priority={task.priority} />
                </Text>
                <Text size="xs">{capitalize(task.priority)}</Text>
              </Group>
              <Group>
                <Text miw={75} size="xs">
                  Notes:
                </Text>
                <Text ml={'xl'} size="xs">
                  {task.notes || EMPTY_PLACEHOLDER}
                </Text>
              </Group>
            </Stack>
          </Card>

          <Divider my="lg" />
          <Text c="imagine-green">Outreach Attempts ({count})</Text>
          <br />
          {count === 0 ? (
            <NoOutreachAttempts />
          ) : (
            <Table verticalSpacing="sm" mih="100%">
              <Table.Tbody>
                {mapOutreachQueryResult(outreachAttempts.data!).map((att) => (
                  <TouchpointActivityItem key={att.id} touchpoint={att} patientId={patient.id!} hideEditButton />
                ))}
              </Table.Tbody>
            </Table>
          )}
        </ScrollArea>
        {!baseTask?.isResolved && (
          <Group justify="space-between" mt="md">
            <Stack gap={'xs'}>
              <Text size="xs" c="dimmed">
                Assigned to:
              </Text>
              <Group>
                <Text>{formatHumanName(task.owner?.name?.[0] as HumanName)}</Text>
                <ActionIcon onClick={() => open()}>
                  <IconEditCircle size={20} radius={'lg'} />
                </ActionIcon>
              </Group>
            </Stack>
            <Group>
              {needsOutreach ? (
                <Button onClick={() => conductOutreach()}>Conduct Outreach</Button>
              ) : (
                <AddTouchpointButton
                  basedOn={[{ reference: `Task/${task.id}`, display: task.taskType?.display }]}
                  patientId={patient.id}
                  variant="filled"
                />
              )}
              <ResolveTaskButton taskId={task.id} callback={onCloseDrawerHandler} />
            </Group>
          </Group>
        )}
        {opened && (
          <AssignToTeamMemberModal
            searchParams={searchParams}
            search={onSearch}
            assign={onAssignToTeamMember}
            opened={opened}
            onClose={closeModal}
          />
        )}
      </Drawer.Body>
    </Drawer.Content>
  );
};
