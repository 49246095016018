import { Group } from '@mantine/core';
import React from 'react';

const getInitials = (name: string): string => {
  const nameSplit = name.split(' ');
  const nameLength = nameSplit.length;
  if (nameLength === 1) {
    return `${name.charAt(0)}`.toUpperCase();
  } else if (nameLength > 1) {
    return `${nameSplit[0].charAt(0)}${nameSplit[nameLength - 1].charAt(0)}`.toUpperCase();
  } else {
    return '';
  }
};

export const createImageFromName = (size: number, name: string): string => {
  const prettyName = name.split(' session-id:')[0];
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const initials = getInitials(prettyName);
  const canvasWidth = size;
  const canvasHeight = size;
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  if (context) {
    context.fillStyle = '#DEEDED';
    context.fillRect(0, 0, canvasWidth, canvasHeight);
    context.font = `${canvasWidth / 2}px Arial`;
    context.textAlign = 'center';
    context.fillStyle = '#345A62';
    context.fillText(initials, canvasWidth / 2, canvasHeight / 1.5);
  }
  return canvas.toDataURL();
};

interface PlaceholderProps {
  name: string;
  size?: number;
}

function Placeholder({ name, size }: PlaceholderProps): JSX.Element {
  return (
    <Group style={{ height: '100%', width: '100%', padding: '6rem', borderRadius: 5 }} justify="center">
      <img style={{ borderRadius: 100 }} id="preview" src={createImageFromName(size || 185, name)} alt="profile-pic" />
    </Group>
  );
}

export default Placeholder;
