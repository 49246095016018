import { Client, createError } from '@/hooks/useApiClient';
import { UseFormReturnType } from '@mantine/form';
import { MedplumClient } from '@medplum/core';
import { Extension } from '@medplum/fhirtypes';
import { System } from 'const-utils';
import { AppDownloadFormValues } from '../types';
import { pleaseFixErrorsAboveMessage } from '@/errors';

export const sendAppDownloadLink = async (
  client: Client,
  phoneNumber: string,
  caregiverId: string,
): Promise<Response> => {
  const response = await client.fetch('/api/enrollment/downloadSMS', {
    method: 'POST',
    body: JSON.stringify({
      targetNumber: phoneNumber,
      caregiverId,
    }),
  });
  if (!response.ok) {
    throw await createError(response, 'Unable to send SMS');
  }

  return response;
};

export const validateAppDownload = async (appDownloadForm: UseFormReturnType<AppDownloadFormValues>): Promise<void> => {
  appDownloadForm.validate();

  if (!appDownloadForm.isValid()) {
    throw new Error(pleaseFixErrorsAboveMessage);
  }
};

export const updateCaregiverSmsConsent = async (
  medplum: MedplumClient,
  caregiverId: string,
  consent: boolean,
): Promise<void> => {
  // This should never happen, but just in case
  if (!caregiverId) {
    throw new Error('No caregiver found.');
  }

  const caregiver = await medplum.readResource('Patient', caregiverId, { cache: 'no-cache' });
  const extension: Extension[] = [
    ...(caregiver.extension?.filter((e) => e.url !== System.CaregiverSMSConsent.toString()) || []),
    {
      url: System.CaregiverSMSConsent.toString(),
      valueBoolean: consent,
    },
  ];
  await medplum.updateResource({
    ...caregiver,
    extension,
  });
};
