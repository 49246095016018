import React, { useMemo } from 'react';
import { TaskDrawerContentRenderer } from '..';
import { QuestionnaireResponseProvider } from './QuestionnaireResponseProvider';
import { DefaultTaskDrawer } from './DefaultTaskDrawer';
import { ExpandedTaskDrawerContent } from './ExpandedTaskDrawer';
import { SurveyReviewTask } from 'imagine-dsl/models/tasks/surveyReviewTask';
import { Drawer, Loader } from '@mantine/core';
import { useGetReviewTaskQuery } from 'medplum-gql';

export const SurveyReviewDrawerContent: TaskDrawerContentRenderer = ({
  patientId,
  drawerExpanded,
  setDrawerExpanded,
  closeDrawer,
  withViewPatientProfile,
  task: baseTask,
  refetchTasks,
}) => {
  const outreachTaskQuery = useGetReviewTaskQuery({ variables: { taskId: baseTask?.id ?? '' } });
  const task = useMemo(
    () => outreachTaskQuery.data?.Task && new SurveyReviewTask(outreachTaskQuery.data.Task),
    [outreachTaskQuery.data?.Task],
  );

  if (!patientId || !task) {
    return (
      <Drawer.Content>
        <Drawer.Body>
          <Loader />
        </Drawer.Body>
      </Drawer.Content>
    );
  }

  return (
    <QuestionnaireResponseProvider surveyTask={task} patientId={patientId}>
      {drawerExpanded ? (
        <ExpandedTaskDrawerContent
          patientId={patientId}
          onClose={closeDrawer}
          setDrawerExpanded={setDrawerExpanded}
          drawerExpanded={drawerExpanded}
        />
      ) : (
        <DefaultTaskDrawer
          patientId={patientId}
          setDrawerExpanded={setDrawerExpanded}
          drawerExpanded={drawerExpanded}
          withViewPatientProfile={withViewPatientProfile}
          closeDrawer={closeDrawer}
          refetchTasks={refetchTasks}
        />
      )}
    </QuestionnaireResponseProvider>
  );
};
