import { createReference, MedplumClient, parseReference, PatchOperation } from '@medplum/core';
import { Notification } from '../models/communications/notification';
import { Communication, Patient, Practitioner, Reference, Task } from '@medplum/fhirtypes';
import { CommunicationMedium, System } from 'const-utils';
import { getCarePathwayReviewers } from './carePathwayReferralService';
import { NotificationTopic } from 'const-utils/codeSystems/ImaginePediatrics';

export const toggleReadStatus = async (medplum: MedplumClient, notification: Notification): Promise<void> => {
  const read = !notification.received;
  const readFunc = read ? markAsRead : markAsUnread;

  await readFunc(medplum, notification.id!);
};

export const markAsRead = async (medplum: MedplumClient, notificationId: string): Promise<void> => {
  const patchOp: PatchOperation = {
    op: 'add',
    path: '/received',
    value: new Date().toISOString(),
  };

  await medplum.patchResource('Communication', notificationId, [patchOp]);
};

const markAsUnread = async (medplum: MedplumClient, notificationId: string): Promise<void> => {
  const patchOp: PatchOperation = {
    op: 'remove',
    path: '/received',
  };

  await medplum.patchResource('Communication', notificationId, [patchOp]);
};

type recipient = 'approver' | 'requester';

/**
 * Map of task status to the intended recipient of the notification.
 * If the status is requested we should send a notification to any approvers.
 * If the status is accepted or rejected we should send a notification to the requester.
 */
const TaskToRecipientMap: Record<Task['status'], recipient | undefined> = {
  requested: 'approver',
  accepted: 'requester',
  rejected: 'requester',
  draft: undefined,
  received: undefined,
  ready: undefined,
  cancelled: undefined,
  'in-progress': undefined,
  'on-hold': undefined,
  failed: undefined,
  completed: undefined,
  'entered-in-error': undefined,
};

export const buildNotificationCommunication = async (medplum: MedplumClient, task: Task): Promise<Communication> => {
  const senderReference =
    parseReference(task.meta?.author)[0] === 'Practitioner' ? task.meta?.author?.reference : undefined;
  const recipientReferences = await getRecipient(medplum, task);

  const { payload, note } = getPayload(task);

  const subject = task.for as Reference<Patient> | undefined;
  const topicCode = ['accepted', 'rejected'].includes(task.status)
    ? NotificationTopic.TaskCompleted
    : NotificationTopic.TaskCreated;

  return {
    resourceType: 'Communication',
    status: 'completed',
    sent: new Date().toISOString(),
    basedOn: [{ reference: `Task/${task.id}` }],
    subject,
    sender: {
      reference: senderReference,
      resource: task.meta?.author?.resource as Practitioner,
    },
    recipient: recipientReferences,
    payload,
    medium: [{ coding: [{ system: System.CommunicationMedium, code: CommunicationMedium.Notification }] }],
    note: note ? [{ text: note }] : [],
    topic: {
      coding: [{ system: System.NotificationTopic, code: topicCode }],
    },
  };
};

const getRecipient = async (medplum: MedplumClient, task: Task): Promise<Reference<Practitioner>[]> => {
  const intendedRecipient = TaskToRecipientMap[task.status];
  if (!intendedRecipient) {
    return [];
  }

  if (intendedRecipient === 'approver') {
    const practitioners = await getCarePathwayReviewers(medplum, task);
    return practitioners.map((practitioner) => createReference(practitioner));
  }

  if (intendedRecipient === 'requester') {
    if (!task.requester) {
      return [];
    }

    return task.requester.reference?.startsWith('Practitioner') ? [task.requester as Reference<Practitioner>] : [];
  }

  return [];
};

const getPayload = (task: Task): { payload: Communication['payload']; note: string } => {
  if (['rejected', 'accepted'].includes(task.status)) {
    const reason = task.statusReason?.text;

    const taskNote = task.extension?.find((ext) => ext.url === System.CarePathwayReferralReviewNote.toString())
      ?.valueString;

    const taskNoteAndReason = taskNote && reason ? `${taskNote} - ${reason}` : taskNote || reason;

    const payload = taskNoteAndReason ? [{ contentString: taskNoteAndReason }] : undefined;
    const note = `Care pathway change request for ${task.for?.display} has been ${
      task.status === 'rejected' ? 'denied' : 'approved'
    }`;

    return { payload, note };
  }

  if (task.status === 'requested') {
    return {
      payload: [
        {
          contentString: `New care pathway change request for ${task.for?.display} created by the Care Hub System.`,
        },
      ],
      note: '',
    };
  }

  return { payload: undefined, note: '' };
};
