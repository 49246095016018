import { Table, Flex, Text, ActionIcon } from '@mantine/core';
import React, { useMemo } from 'react';
import { TableHead, TableHeaderProps } from '@shared/search/TableHead';
import { isEmpty } from 'lodash';
import { HumanNameDisplay } from '@medplum/react';
import { ClinicalImpression, EpisodeOfCare, HumanName } from '@medplum/fhirtypes';
import { GetPatientQuery, Maybe, SearchPatientQuery } from 'medplum-gql';
import { Link, useNavigate } from 'react-router-dom';
import { Header } from '@shared/search/types';
import { SortDirection, buildFieldSort } from 'imagine-dsl/utils/search';
import { formatAgeAndDateOfBirth, formatPhoneString } from 'imagine-dsl/utils/strings';
import { episodeOfCareStatus, is21OrOlder, phoneValue } from 'imagine-dsl/utils/patient';
import { Attribution, ProgramStatus } from 'const-utils';
import { IconCopy } from '@tabler/icons-react';
import { CaregiverColumn } from './CaregiverColumn';
import { PatientEligibilityBadge } from '../shared/patient/PatientEligibilityBadge';
import { PatientPinButton } from '../PatientPinButton';
import { upperFirst } from '@mantine/hooks';
import { ClusterBadge } from '../ClusterBadge';
import { clusterFromClinicalImpressions } from 'imagine-dsl/utils/clinicalImpression';
import { useGetCarePathway } from '@/hooks/useGetCarePathway';
import { copyToClipboardHandler } from '../shared/patient/clipboard';
import { EMPTY_DATA_PLACEHOLDER } from '@/constants';
import { isDefined } from 'imagine-dsl/utils/lists';
import { Over21Badge } from '../Over21Badge';

const styles = {
  link: {
    color: 'var(--mantine-color-imagine-green-6)',
    fontWeight: 'bold',
    fontSize: 'var(--mantine-font-size-md)',
  },
  dataRow: {
    minWidth: '138px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

interface ResultTableProps {
  sort: (field?: string, sortString?: string) => void;
  filter: (field: string, value?: string) => void;
  data: SearchPatientQuery | undefined;
  loading: boolean;
}

function ClusterRow({ patient }: { patient: GetPatientQuery['Patient'] }): JSX.Element {
  const cluster = clusterFromClinicalImpressions(patient?.ClinicalImpressionList as Maybe<ClinicalImpression[]>);
  const { carePathway } = useGetCarePathway({ patientId: patient?.id || '' });

  return <ClusterBadge cluster={cluster || 'Unknown'} carePathway={carePathway} hideSubtext />;
}

export function ResultTable({ sort, data, loading, filter }: ResultTableProps): JSX.Element {
  const onSortClick = (header: Header, direction?: SortDirection): void => {
    if (direction) {
      sort(header.field, buildFieldSort(header.field, direction));
    } else {
      sort();
    }
  };

  const onFilter = (field: string, value?: string): void => {
    filter(field, value);
  };

  const tableHeaderProps: TableHeaderProps = {
    headers: [
      {
        field: '',
        display: '',
        style: { width: '10px' },
      },
      {
        field: 'name',
        display: 'Name',
        sortable: true,
      },
      {
        field: 'cluster',
        display: 'Care Pathway',
      },
      {
        field: 'contact',
        display: 'Primary contact',
      },
      {
        field: 'attribution',
        display: 'Attribution',
        filterOptions: [
          {
            display: 'Not attributed',
            value: Attribution.NotAttributed,
            field: 'attribution',
          },
          { display: 'Attributed', value: Attribution.Attributed, field: 'attribution' },
        ],
      },
      {
        field: 'status',
        display: 'Status',
        filterOptions: [
          { display: 'Not enrolled', value: ProgramStatus.NotEnrolled, field: 'status' },
          { display: 'Enrolled', value: ProgramStatus.Enrolled, field: 'status' },
          { display: 'Onboarded', value: ProgramStatus.Onboarded, field: 'status' },
          { display: 'Disenrolled', value: ProgramStatus.Disenrolled, field: 'status' },
        ],
      },
    ],
    onSort: onSortClick,
    onFilter,
    resultsFound: !isEmpty(data?.PatientConnection?.edges),
    filtersReset: isEmpty(data) && !loading,
  };

  const patients = useMemo(() => {
    return data?.PatientConnection?.edges?.map((edge) => edge?.resource).filter(isDefined) || [];
  }, [data]);

  const navigate = useNavigate();

  return (
    <Table highlightOnHover horizontalSpacing="xs" verticalSpacing="sm">
      <TableHead {...tableHeaderProps} />
      {data?.PatientConnection?.edges && (
        <Table.Tbody>
          {patients.map((patient) => (
            <Table.Tr
              key={patient?.id}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate(`/Patient/${patient.id}`);
              }}
            >
              <Table.Td w={10}>
                <PatientPinButton patientId={patient.id!} />
              </Table.Td>
              <Table.Td style={styles.dataRow}>
                <Link to={`/Patient/${patient.id}`} style={{ color: 'black' }}>
                  <HumanNameDisplay value={patient.name?.[0] as HumanName} />
                </Link>
                <Text c="gray" size="xs">
                  {upperFirst(patient.gender || '')} {formatAgeAndDateOfBirth(patient.birthDate)}
                  {is21OrOlder(patient.birthDate) && <Over21Badge />}
                </Text>
              </Table.Td>
              <Table.Td style={styles.dataRow}>
                <ClusterRow patient={patient} />
              </Table.Td>
              <Table.Td style={styles.dataRow}>
                <CaregiverColumn patient={patient} />
                {formatPhoneString(phoneValue(patient)) ? (
                  <Flex align="center">
                    <span>{formatPhoneString(phoneValue(patient))}</span>
                    <ActionIcon
                      ml={10}
                      color="imagine-green"
                      onClick={async (e) => {
                        e.stopPropagation();
                        await copyToClipboardHandler(phoneValue(patient));
                      }}
                    >
                      <IconCopy size={20} />
                    </ActionIcon>
                  </Flex>
                ) : (
                  EMPTY_DATA_PLACEHOLDER
                )}
              </Table.Td>
              <Table.Td style={styles.dataRow}>
                <PatientEligibilityBadge size="md" patientTags={patient.meta?.tag || []} style={{ fontSize: '11px' }} />
              </Table.Td>
              <Table.Td style={styles.dataRow}>
                {episodeOfCareStatus(patient.EpisodeOfCareList as EpisodeOfCare[]) || EMPTY_DATA_PLACEHOLDER}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      )}
    </Table>
  );
}
