import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useMedplum } from '@medplum/react';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { useUserSession } from '@/components/shared/UserSessionContext';
import { TaskType } from 'const-utils';
import { useQuery } from '@tanstack/react-query';

interface TaskCountProviderProps {}

interface TaskCountContext {
  tasksAssignedToMeCount: number;
  hasOverdueTasks: boolean;
  refetch: () => Promise<void>;
  selectedSurveyId?: string;
  setSelectedSurveyId: (id: string | undefined) => void;
}

const TaskCountContextValue = createContext<TaskCountContext | undefined>(undefined);

export const useTaskCountContext = (): TaskCountContext => {
  const context = useContext(TaskCountContextValue);
  if (!context) {
    throw new Error('useTaskCountContext must be used within a TaskCountProvider');
  }
  return context;
};

export function TaskCountProvider({ children }: React.PropsWithChildren<TaskCountProviderProps>): JSX.Element {
  const flags = useFeatureFlags();
  const { profile } = useUserSession();
  const owner = `Practitioner/${profile.id}`;
  const medplum = useMedplum();

  const [selectedSurveyId, setSelectedSurveyId] = useState<string | undefined>();

  const taskTags = useMemo(() => {
    const tags = [
      TaskType.CarePathwayReferralReview.toString(),
      TaskType.BHTOC.toString(),
      TaskType.ScreenerReview.toString(),
      TaskType.ReviewBHSurvey.toString(),
    ];
    if (flags.MergeTaskViews) {
      tags.push('outreach');
    }
    return tags;
  }, [flags]);
  const tags = taskTags.join(',');

  const { refetch: refetchTasksAssignedToMeCount, data: tasksAssignedToMeCountData } = useQuery(
    ['task-assigned-to-me-count', owner, tags],
    async () => {
      if (!profile.id) {
        return 0;
      }

      const result = await medplum.search('Task', {
        owner: owner,
        _tag: tags,
        _count: 0,
        _total: 'accurate',
        'status:not': 'completed,cancelled,rejected,accepted',
      });
      return result.total ?? 0;
    },
  );
  const { refetch: refetchHasOverdueTasks, data: hasOverdueTasksData } = useQuery(
    ['has-overdue-tasks', owner, tags],
    async () => {
      if (!profile.id) {
        return false;
      }

      const result = await medplum.search('Task', {
        owner: owner,
        'due-date': `lt${new Date().toISOString()}`,
        _tag: tags,
        _count: 0,
        _total: 'accurate',
      });
      return (result.total ?? 0) > 0;
    },
  );

  const refetch = useCallback(async () => {
    await Promise.all([refetchHasOverdueTasks(), refetchTasksAssignedToMeCount()]);
  }, [refetchHasOverdueTasks, refetchTasksAssignedToMeCount]);

  return (
    <TaskCountContextValue.Provider
      value={{
        tasksAssignedToMeCount: tasksAssignedToMeCountData ?? 0,
        hasOverdueTasks: !!hasOverdueTasksData,
        refetch: refetch,
        selectedSurveyId,
        setSelectedSurveyId,
      }}
    >
      {children}
    </TaskCountContextValue.Provider>
  );
}
