import { Box, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';

const useVideoDuration = (start: Date): string => {
  const startDuration = new Date().getTime() - start.getTime();
  const [duration, setDuration] = useState(startDuration);
  useEffect(() => {
    const interval = setInterval(() => {
      setDuration(new Date().getTime() - start.getTime());
    }, 1000);
    return () => clearInterval(interval);
  }, [start]);

  return new Date(duration).toISOString().substr(14, 5);
};

export default function DurationDisplay({ start }: { start: Date }): JSX.Element {
  const duration = useVideoDuration(start);

  return (
    <Box>
      <Text
        size="xs"
        style={{ backgroundColor: 'rgba(0,0,0,.30)', padding: '.125rem .25rem', borderRadius: '.25rem' }}
        data-testid="chat-duration"
      >
        {duration}
      </Text>
    </Box>
  );
}
