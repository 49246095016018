import React from 'react';
import { Group, Paper, Text, Tabs } from '@mantine/core';
import { GetPatientQuery } from 'medplum-gql';
import { EngagementTab } from './EngagementTab';
import { ClinicalTab } from './ClinicalTab';
import { engagementRoles, useHasRole } from '@/hooks/useHasRole';
import { ApolloQueryResult } from '@apollo/client';

type PatientCareTeamComponent = JSX.Element | null;

interface PatientCareTeamProps {
  patient: GetPatientQuery | undefined;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

export default function PatientCareTeam({ patient, refetch }: PatientCareTeamProps): PatientCareTeamComponent {
  const isEngagementAssociate = useHasRole(engagementRoles);

  return (
    <Paper shadow="xs" radius="lg" style={{ padding: '20px', marginBottom: '20px' }}>
      <Group style={{ width: '100%', marginBottom: '10px' }} justify="flex-start" align="center" gap="xs">
        <Text size="xl" c="imagine-green">
          Care Teams
        </Text>
      </Group>
      <Tabs defaultValue={isEngagementAssociate ? 'engagement' : 'clinical'} style={{ flexGrow: 1 }}>
        <Tabs.List>
          <Tabs.Tab value="engagement">Engagement</Tabs.Tab>
          <Tabs.Tab value="clinical">Clinical</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="engagement">
          <EngagementTab patient={patient} />
        </Tabs.Panel>
        <Tabs.Panel value="clinical">
          <ClinicalTab patient={patient} refetch={refetch} />
        </Tabs.Panel>
      </Tabs>
    </Paper>
  );
}
