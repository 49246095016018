import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Coding, Patient } from '@medplum/fhirtypes';
import { uniq } from 'lodash';
import { cleanPhoneNumber } from 'imagine-dsl/utils/strings';
import { searchCaregiversWithProjectMembership, useApiClient } from '@/hooks/useApiClient';
import { logError } from '@/errors';
import { Contact } from '../shared/ContactReviewForm';
import { HL7ValueSet } from 'const-utils';

interface IMaybeExistingContactContext {
  setContact: (contact: Contact | undefined) => void;
  candidates?: Patient[];
  loading: boolean;
  // undefined indicates no selection has been made while null indicates no candidate was valid
  selected: Patient | undefined | null;
  setSelected: (patient: Patient | null) => void;
  relationship: Coding | undefined;
  setRelationship: (relationship: Coding | undefined) => void;
  contactType: Coding | undefined;
  setContactType: (relationship: Coding | undefined) => void;
  inputRequired: boolean;
  key: string;
}
const defaultKey = 'MaybeExistingContactContext';
const MaybeExistingContactContext = createContext<IMaybeExistingContactContext>({
  setContact: () => {},
  candidates: undefined,
  loading: false,
  selected: undefined,
  setSelected: () => {},
  relationship: {
    code: 'caregiver',
    display: 'Caregiver',
    system: HL7ValueSet.RelatedPersonRelationshipType,
  },
  setRelationship: () => {},
  contactType: undefined,
  setContactType: () => {},
  inputRequired: false,
  key: defaultKey,
});

export const useMaybeExistingContacts = (): IMaybeExistingContactContext => {
  return useContext(MaybeExistingContactContext);
};

export const MaybeExistingContactProvider = ({ children }: { children: React.ReactNode }) => {
  const [contact, setContact] = useState<Contact | undefined>(undefined);
  const [candidates, setCandidates] = useState<Patient[] | undefined>(undefined);
  const [selected, setSelected] = useState<Patient | undefined | null>(undefined);
  const [relationship, setRelationship] = useState<Coding | undefined>({
    code: 'caregiver',
    display: 'Caregiver',
    system: HL7ValueSet.RelatedPersonRelationshipType,
  });
  const [contactType, setContactType] = useState<Coding | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const apiClient = useApiClient();

  const isNewContact = !contact?.contactId;
  const phoneNumbersSearch = useMemo(() => {
    if (!contact?.phoneNumbers) {
      return undefined;
    }

    // A phone number stored in Medplum as (123) 456-7890
    // will not be returned from a query for 1234567890 and vice versa
    // so we should search for both to accommodate inconsistencies in phone number formatting that exist
    const phoneNumbers = uniq([
      ...contact.phoneNumbers.map((phone) => cleanPhoneNumber(phone?.number ?? '')),
      ...contact.phoneNumbers.map((phone) => phone.number),
    ]);

    return phoneNumbers.filter(Boolean).sort().join(',');
  }, [contact?.phoneNumbers]);

  useEffect(() => {
    if (!isNewContact || !phoneNumbersSearch) {
      setCandidates(undefined);
      return undefined;
    }
    let ignore = false;

    setLoading(true);
    setSelected(undefined);
    searchCaregiversWithProjectMembership(apiClient, `profile:Patient.telecom=${phoneNumbersSearch}`)
      .then((patients) => {
        if (ignore) {
          return;
        }

        if (!patients?.length) {
          setCandidates(undefined);
          return;
        }

        setCandidates(patients);
      })
      .catch((e) => {
        if (ignore) {
          return;
        }

        logError(e);
      })
      .finally(() => {
        if (ignore) {
          return;
        }

        setLoading(false);
      });

    return () => {
      ignore = true;
    };
  }, [phoneNumbersSearch, isNewContact, apiClient]);

  const value = useMemo(
    () => ({
      candidates,
      setContact,
      loading,
      selected,
      setSelected,
      relationship,
      setRelationship,
      contactType,
      setContactType,
      inputRequired:
        (candidates?.length ?? 0) > 0 && (selected === undefined || (selected !== null && relationship === undefined)),
      key: phoneNumbersSearch ?? defaultKey,
    }),
    [
      candidates,
      setContact,
      loading,
      selected,
      setSelected,
      contactType,
      setContactType,
      relationship,
      setRelationship,
      phoneNumbersSearch,
    ],
  );

  return <MaybeExistingContactContext.Provider value={value}>{children}</MaybeExistingContactContext.Provider>;
};
