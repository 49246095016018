import React, { createContext, useCallback, useContext } from 'react';
import { VERTICAL_NAV_WIDTH, VERTICAL_NAV_WIDTH_COLLAPSED } from '../shared/constants';
import { useProfilePreferences } from '@/context/ProfilePreferencesContext';
import { useIsMobile } from '@/hooks/useIsMobile';

type VerticalNavContextType = {
  width: number;
  opened: boolean;
  open: () => void;
  close: () => void;
};

const VerticalNavContext = createContext<VerticalNavContextType>({
  width: 0,
  opened: true,
  open: () => {},
  close: () => {},
});

export const useVerticalNav = (): VerticalNavContextType => {
  return useContext(VerticalNavContext);
};

export const VerticalNavProvider = ({ children }: { children: React.ReactNode }) => {
  const [profilePreferences, setProfilePreferences] = useProfilePreferences();
  const isMobile = useIsMobile();
  const opened = !isMobile && !profilePreferences.verticalNavCollapsed;
  const open = useCallback(() => {
    setProfilePreferences((prev) => ({ ...prev, verticalNavCollapsed: false }));
  }, [setProfilePreferences]);
  const close = useCallback(() => {
    setProfilePreferences((prev) => ({ ...prev, verticalNavCollapsed: true }));
  }, [setProfilePreferences]);

  const width = opened ? VERTICAL_NAV_WIDTH : VERTICAL_NAV_WIDTH_COLLAPSED;

  return <VerticalNavContext.Provider value={{ width, opened, open, close }}>{children}</VerticalNavContext.Provider>;
};
