import { Loader, Modal, Text } from '@mantine/core';
import React, { useMemo, useState } from 'react';
import {
  GetPatientQuery,
  Maybe,
  Patient,
  SearchPatientByAddressQueryVariables,
  SearchPatientQueryVariables,
  useSearchPatientByAddressQuery,
} from 'medplum-gql';

import { SearchPatientsModalBody } from './SearchPatientsModalBody';
import { RecommendedLinksModalBody } from './RecommendedLinksModalBody';
import { ApolloQueryResult } from '@apollo/client';
import { caregiversFromPatient } from 'imagine-dsl/utils/patient';
import { isEmpty } from 'lodash';
import { formatAddress } from 'imagine-dsl/utils/strings';

const getAddressSearch = (patient: Patient): Maybe<SearchPatientByAddressQueryVariables> => {
  if (!patient.address || isEmpty(patient?.address)) {
    return null;
  }

  const address = patient.address[0];

  return {
    address: formatAddress(address),
    addressCity: address.city,
    addressPostalCode: address.postalCode,
    addressState: address.state,
  };
};

interface LinkedPatientModalProps {
  opened: boolean;
  close: () => void;
  patient: Patient;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

export function LinkedPatientModal({ refetch, patient, opened, close }: LinkedPatientModalProps): JSX.Element {
  const [searchParams, setSearchParams] = useState<Maybe<SearchPatientQueryVariables>>();
  const [searchForPatients, setSearchForPatients] = useState(false);

  const addressSearchParams = useMemo(() => getAddressSearch(patient), [patient]);
  const { data, loading } = useSearchPatientByAddressQuery({
    skip: !addressSearchParams,
    variables: addressSearchParams || {},
  });

  const primaryPatientCaregivers = useMemo(() => caregiversFromPatient(patient), [patient]);

  const recommendedLinks = useMemo(() => {
    return (data?.PatientConnection?.edges || [])
      ?.map((edge) => edge?.resource)
      .filter(
        (link) => link?.id !== patient.id && !primaryPatientCaregivers.some((c) => c.id === link?.id),
      ) as Patient[];
  }, [data?.PatientConnection, patient, primaryPatientCaregivers]);

  const closeModal = (): void => {
    setSearchParams(undefined);
    setSearchForPatients(false);
    close();
  };

  const onSearch = (query: SearchPatientQueryVariables): void => {
    setSearchParams({ ...query, tag: 'patient' });
  };

  const onSearchClick = (): void => {
    setSearchForPatients(!searchForPatients);
  };

  let body = <RecommendedLinksModalBody onSearchClick={onSearchClick} patient={patient} refetchPatient={refetch} />;
  if (searchForPatients || !recommendedLinks.length) {
    body = (
      <SearchPatientsModalBody
        refetchPatient={refetch}
        searchParams={searchParams}
        onSearch={onSearch}
        patient={patient}
      />
    );
  }
  if (loading) {
    body = <Loader />;
  }

  return (
    <Modal.Root size="xl" opened={opened} onClose={() => closeModal()} centered radius="md">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <Text c="imagine-green">Link patients</Text>
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}
