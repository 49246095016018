import {
  Group,
  UnstyledButton,
  Text,
  Collapse,
  Box,
  Loader,
  Paper,
  useMantineTheme,
  Modal,
  Button,
  Textarea,
  ScrollArea,
  ThemeIcon,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMedplum } from '@medplum/react';
import { IconChevronDown, IconChevronRight, IconInfoCircle, IconNotes } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { NotesModal } from './NotesModal';
import { MedplumClient } from '@medplum/core';
import { isDefined } from 'imagine-dsl/utils/lists';
import { useMutation, useQuery } from '@tanstack/react-query';
import { CareHubNote } from 'imagine-dsl/utils/note';
import { Row } from './Row';
import { showNotification } from '@mantine/notifications';
import { getErrorMessage, logError } from '@/errors';
import { useForm } from '@mantine/form';
import { createPatientNote, editPatientNote } from 'imagine-dsl/services/notesService';
import { useSha256 } from '@/hooks/useSha256';
import { useUserSession } from '../../UserSessionContext';

interface NotesProps {
  patientId: string;
}

const queryFn = (medplum: MedplumClient, patientId?: string) => async () => {
  const bundle = patientId
    ? await medplum.search(
        'Composition',
        { _sort: '-_lastUpdated', patient: `Patient/${patientId}`, type: 'patient-note' },
        { cache: 'no-cache' },
      )
    : undefined;
  const resources = bundle?.entry?.map((e) => e.resource).filter(isDefined) || [];
  return resources.map((note) => new CareHubNote(note));
};

export function Notes(props: NotesProps): JSX.Element {
  const theme = useMantineTheme();
  const [opened, { open, close }] = useDisclosure();
  const medplum = useMedplum();
  const { refetch, data } = useQuery(['notes', props.patientId], queryFn(medplum, props.patientId));
  const notes = data || [];

  const onClose = async () => {
    await refetch();
    close();
  };

  const notesExistStyles = {
    backgroundColor: theme.colors['highlight-yellow'][0],
    borderRadius: '16px',
  };

  return (
    <>
      <NotesModal fetchNotes={refetch} notes={notes} patientId={props.patientId} opened={opened} close={onClose} />
      <Group p={4} style={notes.length > 0 ? notesExistStyles : {}} gap={4}>
        <IconNotes size={14} />
        <Text fw="bold">Notes ({notes.length})</Text>
        <UnstyledButton c="imagine-green" style={{ fontWeight: 'bold', textDecoration: 'underline' }} onClick={open}>
          {notes.length === 0 ? 'Add' : 'View'}
        </UnstyledButton>
      </Group>
    </>
  );
}

export function NotesFullWidth({ patientId }: NotesProps) {
  const medplum = useMedplum();
  const { profile } = useUserSession();

  const {
    data: notes = [],
    isLoading: isLoadingNotes,
    refetch: refetchNotes,
  } = useQuery(['notes', patientId], queryFn(medplum, patientId));
  const notesHash = useSha256(notes);
  const [noteIdToDelete, setNoteIdToDelete] = useState<string>();
  const draftNote = useForm({
    initialValues: {
      drafting: false,
      noteId: '',
      note: '',
    },
  });

  const stopDrafting = () => {
    draftNote.setFieldValue('drafting', false);
    draftNote.reset();
  };

  const [opened, { toggle, open }] = useDisclosure(false);
  useEffect(() => {
    if (notesHash && notes.length > 0) {
      open();
    }
  }, [notesHash, notes.length, open]);

  const theme = useMantineTheme();
  const saveNote = useMutation(
    async () => {
      if (draftNote.values.noteId) {
        return editPatientNote(medplum, {
          noteId: draftNote.values.noteId,
          text: draftNote.values.note,
          author: profile,
        });
      } else {
        return createPatientNote(medplum, {
          patientId,
          note: draftNote.values.note,
          author: profile,
        });
      }
    },
    {
      onError: (err) => {
        showNotification({
          message: getErrorMessage(err),
          color: 'status-error',
        });
      },
      onSuccess: async () => {
        showNotification({
          message: 'Note saved',
          color: 'status-success',
        });
        stopDrafting();
        refetchNotes().catch((err) => logError(err));
      },
    },
  );
  const deleteNote = useMutation(
    async () => {
      if (!noteIdToDelete) {
        return;
      }

      await medplum.deleteResource('Composition', noteIdToDelete);
    },
    {
      onError: (err) => {
        showNotification({
          message: getErrorMessage(err),
          color: 'status-error',
        });
      },
      onSuccess: () => {
        showNotification({
          message: 'Note deleted',
          color: 'status-success',
        });
        setNoteIdToDelete(undefined);
        refetchNotes().catch((err) => logError(err));
      },
    },
  );

  return (
    <Paper radius="lg" shadow="xs" mb="md">
      <Box>
        <Group
          onClick={toggle}
          style={
            notes.length > 0
              ? {
                  backgroundColor: theme.colors['highlight-yellow'][0],
                  borderBottomLeftRadius: opened ? 0 : theme.radius.lg,
                  borderBottomRightRadius: opened ? 0 : theme.radius.lg,
                  borderTopRightRadius: theme.radius.lg,
                  borderTopLeftRadius: theme.radius.lg,
                }
              : undefined
          }
          p="sm"
        >
          {opened ? (
            <ThemeIcon color="imagine-green" variant="transparent" mr={5}>
              <IconChevronDown size={15} />
            </ThemeIcon>
          ) : (
            <ThemeIcon color="imagine-green" variant="transparent" mr={5}>
              <IconChevronRight size={15} />
            </ThemeIcon>
          )}
          <Text fw="bold">Notes {isLoadingNotes ? <Loader size="xs" /> : `(${notes.length})`}</Text>
          <UnstyledButton
            fw="bold"
            color="imagine-green"
            td="underline"
            onClick={(e) => {
              e.stopPropagation();
              draftNote.setFieldValue('drafting', true);
            }}
          >
            Add
          </UnstyledButton>
        </Group>
        <Collapse in={opened} p="sm">
          {notes.length > 0 ? (
            <ScrollArea.Autosize type="hover" mah={250}>
              {notes.map((note, noteIndex) => (
                <Row
                  undoDelete={() => {}}
                  deleteNote={setNoteIdToDelete}
                  key={note.id}
                  editNote={(n) => {
                    draftNote.setValues({
                      drafting: true,
                      noteId: n.id!,
                      note: n.latestNoteSection!.noteText,
                    });
                  }}
                  note={note}
                  noteIndex={noteIndex}
                  notesLength={notes.length}
                />
              ))}
            </ScrollArea.Autosize>
          ) : (
            <Group>
              <ThemeIcon color="status-info" variant="transparent">
                <IconInfoCircle size={16} />
              </ThemeIcon>
              <Text c="dimmed">No notes have been added for this patient</Text>
            </Group>
          )}
        </Collapse>
      </Box>
      <Modal centered opened={!!noteIdToDelete} onClose={() => setNoteIdToDelete(undefined)} title="Delete note">
        <Text>Are you sure you want to delete this note?</Text>
        <Group mt="lg" justify="flex-end">
          <Button variant="transparent" onClick={() => setNoteIdToDelete(undefined)}>
            Cancel
          </Button>
          <Button
            loading={deleteNote.isLoading}
            onClick={() => {
              deleteNote.mutate();
            }}
          >
            Delete
          </Button>
        </Group>
      </Modal>
      <Modal
        centered
        opened={draftNote.values.drafting}
        onClose={stopDrafting}
        title={draftNote.values.noteId ? 'Edit note' : 'Add note'}
      >
        <form
          onSubmit={draftNote.onSubmit(() => {
            saveNote.mutate();
          })}
        >
          <Textarea name="note" label="Note" autosize {...draftNote.getInputProps('note')} />
          <Group mt="lg" justify="flex-end">
            <Button variant="transparent" onClick={stopDrafting}>
              Cancel
            </Button>
            <Button
              type="submit"
              loading={saveNote.isLoading}
              disabled={!draftNote.values.note || !draftNote.isDirty('note')}
            >
              Save
            </Button>
          </Group>
        </form>
      </Modal>
    </Paper>
  );
}
