import { Group, Stack, Text, ThemeIcon } from '@mantine/core';
import { BundleEntry, Practitioner, Task } from '@medplum/fhirtypes';
import { HumanNameDisplay, useMedplum } from '@medplum/react';
import { IconUserCheck, IconUserX } from '@tabler/icons-react';
import { Maybe } from 'medplum-gql';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { TimelineItem } from '../utils/timeline';
import { formatWithYesterdayAndToday } from 'imagine-dsl/utils/dates';

interface AssignmentHistoryProps {
  timelineItem: TimelineItem;
}

interface HistoryDisplayProps {
  assignmentHistoryItem: BundleEntry<Task>;
  author?: Maybe<Practitioner>;
}

function AssignmentAuthor({ author }: { author?: Maybe<Practitioner> }): JSX.Element {
  if (!author) {
    return <></>;
  }

  return (
    <Text>
      by <HumanNameDisplay value={author.name?.[0]} />
    </Text>
  );
}

function TaskAssigned({ assignmentHistoryItem, author }: HistoryDisplayProps): JSX.Element {
  const medplum = useMedplum();
  const [owner, setOwner] = useState<Maybe<Practitioner>>();

  useEffect(() => {
    if (!assignmentHistoryItem.resource?.owner) {
      setOwner(null);
      return;
    }
    medplum
      .readReference(assignmentHistoryItem.resource.owner)
      .then((practitioner) => {
        setOwner(practitioner as Practitioner);
      })
      .catch(() => {
        setOwner(null);
      });
  }, [assignmentHistoryItem, setOwner, medplum]);

  if (!owner) {
    return <></>;
  }

  return (
    <Group style={{ gap: 4 }}>
      <ThemeIcon>
        <IconUserCheck height={24} width={24} />
      </ThemeIcon>
      <Text>Alert has been assigned to</Text>
      <Text fw="bold">
        <HumanNameDisplay value={owner.name?.[0]} />
      </Text>
      <AssignmentAuthor author={author} />
    </Group>
  );
}

function TaskUnassigned({ author }: HistoryDisplayProps): JSX.Element {
  return (
    <Group style={{ gap: 4 }}>
      <ThemeIcon c="status-error">
        <IconUserX height={24} width={24} />
      </ThemeIcon>
      <Text>Alert was unassigned</Text>
      <AssignmentAuthor author={author} />
    </Group>
  );
}

export function AssignmentHistory(props: AssignmentHistoryProps): JSX.Element {
  const medplum = useMedplum();
  const assignmentHistoryItem = props.timelineItem.resource as BundleEntry<Task>;
  const [author, setAuthor] = useState<Maybe<Practitioner>>();
  const assignmentHistoryAssigned = useMemo((): boolean => {
    return !isEmpty(assignmentHistoryItem?.resource?.owner);
  }, [assignmentHistoryItem]);

  useEffect(() => {
    if (!assignmentHistoryItem.resource?.meta?.author) {
      setAuthor(null);
      return;
    }
    medplum
      .readReference(assignmentHistoryItem.resource.meta.author)
      .then((practitioner) => {
        setAuthor(practitioner as Practitioner);
      })
      .catch(() => {
        setAuthor(null);
      });
  }, [setAuthor, medplum, assignmentHistoryItem]);

  return (
    <Stack style={{ gap: 0 }}>
      {assignmentHistoryAssigned && <TaskAssigned author={author} assignmentHistoryItem={assignmentHistoryItem} />}
      {!assignmentHistoryAssigned && <TaskUnassigned author={author} assignmentHistoryItem={assignmentHistoryItem} />}
      <Text ml={28} c="imagine-gray.7" size="xs">
        {formatWithYesterdayAndToday(props.timelineItem.relevantTime, 'EEE, MMMM do, yyyy hh:mm aaa', true)}
      </Text>
    </Stack>
  );
}
