export enum FeatureFlag {
  MergeTaskViews = 'merge-task-views',
  TestFlag = 'test_flag',
  ChatGQLQuery = 'chat-gql-query',
  ProgramStatusDateSelection = 'program-status-date-selection',
  PathwayReviewOwners = 'pathway-review-owners',
  Fuh = 'fuh',
  ConsentUpload = 'consent_upload',
  DeescalateCarePathway = 'deescalate_care_pathway',
}
