import React, { useMemo } from 'react';
import { Paper, Grid, Text, Flex } from '@mantine/core';
import { System } from 'const-utils';
import { CarePathwayReferralTask } from 'imagine-dsl/models/tasks/carePathwayReferralTask';
import { format } from 'date-fns';
import { CarePathway, carePathwayDisplay, CarePathwayReferralType } from 'const-utils/codeSystems/ImaginePediatrics';
import { useGetPatientQuery } from 'medplum-gql';
import { extractPod } from 'imagine-dsl/utils/careTeam';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';

interface ManualReferralProps {
  task: CarePathwayReferralTask;
}

const ManualReferral: React.FC<ManualReferralProps> = ({ task }) => {
  const result = useGetPatientQuery({
    variables: {
      id: task?.for?.id ?? '',
    },
  });

  const patient = useMemo(() => result.data, [result]);

  const pod = useMemo(() => patient && extractPod(patient), [patient]);
  const referralType = task?.referralType;
  const originalCarePathway = task?.originalPathway;
  const flags = useFeatureFlags();

  return (
    <Paper my="md" withBorder p="md">
      <Text fw="bold" size="lg">
        {referralType === CarePathwayReferralType.Data ? 'Care Hub System' : task.task?.requester?.display}
      </Text>
      <Text c="dimmed">{task.authoredOn && format(task.authoredOn, 'Pp')}</Text>
      <Grid mt="xl">
        <Grid.Col span={4}>
          <Text c="dimmed">Care pathway referral:</Text>
        </Grid.Col>
        <Grid.Col span={8}>
          <Flex gap="sm">
            <Text>{originalCarePathway ? carePathwayDisplay[originalCarePathway as CarePathway] + ' > ' : ''}</Text>
            <Text fw="bold">
              {carePathwayDisplay[task.inputBySystem(System.CarePathway)?.valueString as CarePathway]}
            </Text>
          </Flex>
        </Grid.Col>
        {flags.PathwayReviewOwners && (
          <>
            <Grid.Col span={4}>
              <Text c="dimmed">POD:</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <Grid.Col span={8}>
                <Text>{pod?.name}</Text>
              </Grid.Col>
            </Grid.Col>
          </>
        )}
        {referralType !== CarePathwayReferralType.Data && (
          <>
            <Grid.Col span={4}>
              <Text c="dimmed">Description:</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <Text>{task.inputBySystem(System.CarePathwayReferralDescription)?.valueString}</Text>
            </Grid.Col>

            <Grid.Col span={4}>
              <Text c="dimmed">Reason for request:</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <Text>{task.inputBySystem(System.CarePathwayReferralReason)?.valueCodeableConcept?.text}</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <Text c="dimmed">Associated diagnoses:</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <Text>
                {task.inputBySystem(System.CarePathwayReferralAssociatedDiagnoses)?.valueCodeableConcept?.text}
              </Text>
            </Grid.Col>
          </>
        )}
      </Grid>
    </Paper>
  );
};

export default ManualReferral;
