import { ActionIcon, Box, Container, Drawer, Group, Text } from '@mantine/core';
import { IconArrowsDiagonal } from '@tabler/icons-react';
import React from 'react';
import { ResponseCategoryBadge } from './ResponseCategoryBadge';
import { ReadOnlyQuestionnairePageSequence } from '../../../questionnaire/QuestionnairePageSequence';
import { Practitioner, Task } from '@medplum/fhirtypes';
import { TasksFooter } from './TasksFooter';
import { useQuestionnaireResponseContext } from './QuestionnaireResponseProvider';
import { Body } from './Body';
import { TaskDrawerHeader } from '../TaskDrawerHeader';
import { useUserSession } from '@/components/shared/UserSessionContext';

interface TaskDrawerProps {
  setDrawerExpanded: (expanded: boolean) => void;
  drawerExpanded: boolean;
  patientId: string;
  withViewPatientProfile?: boolean;
  closeDrawer: () => void;
  refetchTasks?: () => Promise<unknown>;
}

export function DefaultTaskDrawer(props: TaskDrawerProps): JSX.Element {
  const { profile } = useUserSession();
  const { setDrawerExpanded, refetchTasks, drawerExpanded, closeDrawer, patientId, withViewPatientProfile } = props;
  const {
    responseCategory,
    questionnaireResponse,
    questionnaire,
    refetchPatientTasks,
    questionnaireResponseLoading,
    translatedItemMapping,
    surveyTask,
  } = useQuestionnaireResponseContext();

  return (
    <Drawer.Content>
      <Drawer.Header h={3}>
        <Group ml="auto">
          <ActionIcon onClick={() => setDrawerExpanded(!drawerExpanded)} size="xl" variant="transparent">
            <IconArrowsDiagonal />
          </ActionIcon>
          <Drawer.CloseButton />
        </Group>
      </Drawer.Header>
      <Drawer.Body p={0} style={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 60px)' }}>
        <Box p="xl">
          <Group align="center">
            <Text c="dimmed" size="md" fw={700}>
              {questionnaire?.name ?? 'Survey'}
            </Text>
            <ResponseCategoryBadge size="sm" responseValue={responseCategory} />
          </Group>
          <TaskDrawerHeader patientId={patientId} withViewPatientProfile={withViewPatientProfile} />
        </Box>
        <Container style={{ flexGrow: 1, overflow: 'scroll' }} mx={0}>
          <Body loading={questionnaireResponseLoading} questionnaireResponse={questionnaireResponse}>
            <ReadOnlyQuestionnairePageSequence
              items={questionnaire?.item ?? []}
              response={questionnaireResponse}
              translatedItemMapping={translatedItemMapping}
            />
          </Body>
        </Container>

        {surveyTask && (
          <TasksFooter
            refreshTasks={refetchTasks}
            task={surveyTask.task as Task}
            assignee={surveyTask?.owner as Practitioner}
            profile={profile}
            onCloseDrawer={closeDrawer}
            refetchTasks={refetchPatientTasks}
          />
        )}
      </Drawer.Body>
    </Drawer.Content>
  );
}
