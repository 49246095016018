import { Access, User } from '../io/users';
import { Access as UserAccess } from 'imagine-gql/client';

const formatCsvCell = (value: string) => {
  if (value.includes(',')) {
    return `"${value}"`;
  }
  return value;
};

export const formatCsv = (users: User[]) => {
  const csv = ['id,firstName,lastName,email,roles,access,admin,readonly'];

  users.forEach((user) => {
    const roles = user.roles.map((r) => `${r.market}:${r.role}`).join(';');
    const access = user.access.map(formatUserAccess).join(';');
    csv.push(
      [
        user.id,
        formatCsvCell(user.firstName),
        formatCsvCell(user.lastName),
        formatCsvCell(user.email),
        formatCsvCell(roles),
        formatCsvCell(access),
        user.admin ? 'true' : 'false',
        user.readOnly ? 'true' : 'false',
      ].join(','),
    );
  });

  return csv.join('\n');
};

export const formatUserAccess = (access: Access | UserAccess) => {
  if (access.params.length === 0) {
    return access.policy;
  }

  return `${access.policy}:${access.params
    .map((p) => p.name + '=' + (p.valueReference?.display || p.valueReference?.reference))
    .join(';')}`;
};
