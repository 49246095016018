import { CodeableConcept, Coding } from '@medplum/fhirtypes';
import { CodeableConcept as GraphqlCodeableConcept, Coding as GraphqlCoding } from 'medplum-gql';

export const getFirstCodingBySystem = ({
  codeableConcepts,
  system,
}: {
  codeableConcepts?: CodeableConcept[] | GraphqlCodeableConcept[] | null;
  system: string;
}): Coding | GraphqlCoding | undefined => {
  return codeableConcepts
    ?.find(({ coding }) => coding?.find((c) => c.system === system))
    ?.coding?.find((c) => c.system === system);
};
