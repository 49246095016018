import { Coding, ValueSet, ValueSetComposeIncludeConcept } from '@medplum/fhirtypes';

export function extractValues(valueSet: ValueSet): ValueSetComposeIncludeConcept[] {
  return valueSet.compose?.include?.flatMap((c) => c.concept ?? []) ?? [];
}

export function getCodingDisplayForValueSet(valueSet: ValueSet, coding: Coding): string {
  if (coding.display) {
    return coding.display;
  }

  if (coding.code) {
    return selectDisplayByCode(valueSet, coding.code) ?? coding.display ?? 'unknown';
  }

  return 'unknown';
}

export function selectCodeByDisplay(valueSet: ValueSet, display: string): string | undefined {
  return extractValues(valueSet).find((c) => c.display === display)?.code;
}

export function selectCodingByDisplay(valueSet: ValueSet, display: string): Coding | undefined {
  return extractValues(valueSet).find((c) => c.display === display);
}

export function selectDisplayByCode(valueSet: ValueSet, code: string): string | undefined {
  return extractValues(valueSet).find((c) => c.code === code)?.display;
}
