import React from 'react';
import { Outlet } from 'react-router-dom';
import { PatientSearch } from '@components/PatientSearch';
import { useDocumentTitle } from '@mantine/hooks';
import { PageSkeleton } from '@/design-system/PageSkeleton';

export function PatientsPage(): JSX.Element {
  useDocumentTitle('Patients - Care Hub');

  return (
    <PageSkeleton withPaper={false}>
      <PatientSearch />
      <Outlet />
    </PageSkeleton>
  );
}
