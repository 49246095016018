import { UseFormReturnType, useForm } from '@mantine/form';
import { HasContactFields } from '../../shared/ContactReviewForm';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { validateEmail } from 'imagine-dsl/utils/strings';
import { isDuplicatePhoneNumber } from '@/utils/contactReviewUtils';

const PatientContactForm = (
  initialForm: HasContactFields,
): UseFormReturnType<HasContactFields, (values: HasContactFields) => HasContactFields> => {
  const form = useForm<HasContactFields>({
    initialValues: initialForm,
    validateInputOnBlur: true,
    validate: {
      contact: {
        firstName: (value) => (value ? null : 'First name is required'),
        lastName: (value) => (value ? null : 'Last name is required'),
        emailAddress: (value) => {
          if (!value) {
            return null;
          }

          return validateEmail(value) ? null : 'Invalid email';
        },
        language: (value, values) => {
          if (values.contact.usePatientLanguage) {
            return null;
          } else {
            return value ? null : 'Language is required';
          }
        },
        relationship: (value) => {
          return value ? null : 'Relationship is required';
        },
        phoneNumbers: {
          number: (value, values, path) => {
            if (!value) {
              return 'Phone number is required';
            }

            if (!isValidPhoneNumber(value, 'US')) {
              return 'Invalid phone number';
            }

            const valueIndex = parseInt(path.replace(/[^0-9]/g, ''), 10);

            if (isDuplicatePhoneNumber(value, values.contact.phoneNumbers, valueIndex)) {
              return 'Duplicate phone number';
            }

            return null;
          },
        },
        address: {
          line1: (value, values) => (value || values.contact.usePatientAddress ? null : 'Address line 1 is required'),
          city: (value, values) => (value || values.contact.usePatientAddress ? null : 'City is required'),
          state: (value, values) => (value || values.contact.usePatientAddress ? null : 'State is required'),
          zip: (value, values) => (value || values.contact.usePatientAddress ? null : 'Zip code is required'),
        },
      },
    },
  });

  return form;
};

export default PatientContactForm;
