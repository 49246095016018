import { Coding } from '@medplum/fhirtypes';
import { System } from '../codeSystems/system';

export const enum CareTeamType {
  Internal = 'internal',
  External = 'external',
  ClinicalPod = 'clinical-pod',
  EngagementPod = 'engagement-pod',
  Engagement = 'engagement',
}

export interface ImagineCareTeamTag extends Coding {
  system: System.CareTeam;
  code: CareTeamType;
}

export const CareTeamTypeTag = (careTeamType: CareTeamType): ImagineCareTeamTag => ({
  system: System.CareTeam,
  code: careTeamType,
});

export const enum PractitionerType {
  Internal = 'internal',
  External = 'external',
}

export interface ImaginePractitionerTypeTag extends Coding {
  system: System.Practitioner;
  code: PractitionerType;
}

export const PractitionerTypeTag = (practitionerType: PractitionerType): ImaginePractitionerTypeTag => ({
  system: System.Practitioner,
  code: practitionerType,
});
