import { Button, Group, Loader, Modal, Radio, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useState } from 'react';
import { Maybe, SearchPractitionersQueryVariables, useSearchPractitionersQuery } from 'medplum-gql';
import { HumanNameDisplay } from '@medplum/react';
import { HumanName, Practitioner } from '@medplum/fhirtypes';
import { isEmpty } from 'lodash';

interface AssignToTeamMemberModalProps {
  opened: boolean;
  onClose: () => void;
  assign: (practitionerId: string) => void;
  searchParams: Maybe<SearchPractitionersQueryVariables> | undefined;
  search: (name: Maybe<string>) => void;
}

export function AssignToTeamMemberModal(props: AssignToTeamMemberModalProps): JSX.Element {
  const [selectedPractitionerId, setSelectedPractitionerId] = useState<Maybe<string> | undefined>();
  const { data, loading } = useSearchPractitionersQuery({
    skip: !props.searchParams,
    variables: props.searchParams || {},
  });
  const form = useForm({
    initialValues: {
      query: '',
    },
  });

  const onSearch = (formData: { query: string }): void => {
    const query = formData.query.trim();
    if (!query) {
      return;
    }

    props.search(query);
  };

  const selectPractitioner = (practitioner: Practitioner): void => {
    setSelectedPractitionerId(practitioner.id);
  };

  const close = (): void => {
    setSelectedPractitionerId(null);
    form.reset();
    props.onClose();
  };

  return (
    <Modal
      title={
        <Text fw={700} c="imagine-green">
          Assign
        </Text>
      }
      opened={props.opened}
      onClose={close}
      centered
    >
      <Text>Select which team member you would like to assign this to:</Text>
      <form style={{ display: 'flex' }} onSubmit={form.onSubmit(onSearch)}>
        <TextInput w={264} name="query" placeholder="Search by name" {...form.getInputProps('query')} />
        <Button disabled={!form.values.query} ml="auto" type="submit">
          Search
        </Button>
      </form>
      {loading && <Loader mt={16} />}
      {data && (
        <Radio.Group pt={16}>
          {isEmpty(data.PractitionerList) && <Text>No practitioners found</Text>}
          {!isEmpty(data.PractitionerList) && (
            <Stack bd="1px solid brand-gray.4" style={{ borderRadius: '4px' }}>
              {data.PractitionerList?.map((practitioner) => {
                return (
                  <Radio
                    key={practitioner?.id}
                    label={<HumanNameDisplay value={practitioner?.name?.[0] as HumanName} />}
                    value={practitioner!.id!}
                    onClick={() => selectPractitioner(practitioner as Practitioner)}
                    p={8}
                  />
                );
              })}
            </Stack>
          )}
        </Radio.Group>
      )}
      <Group justify="flex-end" pt={16}>
        <Button variant="outline" onClick={close}>
          Cancel
        </Button>
        <Button disabled={!selectedPractitionerId} onClick={() => props.assign(selectedPractitionerId!)}>
          Assign
        </Button>
      </Group>
    </Modal>
  );
}
