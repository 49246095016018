import { ActionIcon, Button, Group, Loader, Modal, Select } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { PropsWithChildren, useState } from 'react';
import useManagePodAssignment from './useManagePodAssignment';
import { useClinicalPods } from '../../../hooks/usePods';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery } from 'medplum-gql';

interface ManageCareTeamIconActionProps {
  careTeamId: string | undefined;
  patientId: string;
  previousPodId: string | undefined;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

export default function ManagePodAssignmentIconAction({
  children,
  patientId,
  careTeamId,
  previousPodId,
  refetch,
}: PropsWithChildren<ManageCareTeamIconActionProps>): JSX.Element {
  const [opened, { open, close }] = useDisclosure();

  const { pods, loading: loadingPods } = useClinicalPods();

  const [selected, setSelected] = useState<string | null>(previousPodId || null);

  const { mutate: managePodAssignment, loading: mutatingPods } = useManagePodAssignment(
    careTeamId,
    selected || undefined,
    previousPodId,
    refetch,
    patientId,
    close,
  );

  if (loadingPods) {
    return <Loader size={15} />;
  }

  return (
    <>
      <ActionIcon bg="imagine-green.0" style={{ padding: 0 }} onClick={open}>
        {children}
      </ActionIcon>
      <Modal.Root size="lg" padding="xl" radius="xl" centered opened={opened} onClose={close}>
        <Modal.Overlay />
        <Modal.Content style={{ overflow: 'visible' }}>
          <Modal.Header style={{ borderRadius: '100px' }}>
            <Modal.Title fz={20} c="imagine-green">
              Update Pod Assignment
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Select
              disabled={mutatingPods}
              label="Pod"
              placeholder="Select a pod"
              value={selected}
              onChange={setSelected}
              data={pods.map((p) => ({
                value: p.id!,
                label: p.name!,
              }))}
            />
            <br />
            <Group justify="right">
              <Button variant="outline" color="imagine-green" onClick={close} disabled={mutatingPods}>
                Cancel
              </Button>
              <Button color="imagine-green" disabled={!selected} onClick={managePodAssignment} loading={mutatingPods}>
                Update
              </Button>
            </Group>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}
