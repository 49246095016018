import React, { FormEvent } from 'react';
import { Input, InputWrapperProps, MantineSize } from '@mantine/core';
import { IMaskInput, IMaskInputProps } from 'react-imask';
import { omit, pick } from 'lodash';

interface Props extends Omit<IMaskInputProps<HTMLInputElement>, 'size'> {
  size?: MantineSize;
}

const defaultProps = Object.freeze({
  placeholder: '(281) 555-1234',
  label: 'Phone number',
});

export const PhoneInput = (props: Props): React.ReactNode => {
  const wrapperProps: InputWrapperProps = pick(
    props,
    'placeholder',
    'label',
    'required',
    'error',
    'description',
    'id',
    'size',
    'className',
    'style',
  );

  const onAccept = (value: string): void => {
    // Mantine's type system suggests that onChange only accepts FormEvent<HTMLInputElement>,
    // but it actually can accept a string value.
    props.onChange?.(value as unknown as FormEvent<HTMLInputElement>);
  };

  return (
    <Input.Wrapper {...wrapperProps}>
      <Input mask="(000) 000-0000" component={IMaskInput} type="tel" {...omit(props, 'onChange')} onAccept={onAccept} />
    </Input.Wrapper>
  );
};

PhoneInput.defaultProps = defaultProps;
