import { useCallback, useEffect, useState } from 'react';
import { connect, Room, ConnectOptions } from 'twilio-video';

const INITIAL_STATE = {
  room: undefined,
  error: undefined,
};

export type CallbackOptions = { token: string; options: ConnectOptions };

interface RoomState {
  room?: Room;
  error?: Error;
}

interface Result extends RoomState {
  disconnectRoom: () => void;
  connectRoom: (opts: CallbackOptions) => void;
}

export default function useRoomConnection(): Result {
  const [roomState, setRoomState] = useState<RoomState>(INITIAL_STATE);
  const { room } = roomState;

  /**
   * connect to a room
   */
  const connectRoom = useCallback(({ token, options }: CallbackOptions) => {
    connect(token, options)
      .then((room) => {
        setRoomState((c) => ({ ...c, room }));
      })
      .catch((error) => {
        setRoomState((c) => ({ ...c, error }));
      });
  }, []);

  /**
   * Disconnect from room
   */
  const disconnectRoom = useCallback(() => {
    if (room) {
      room.disconnect();
      setRoomState(INITIAL_STATE);
    }
  }, [room]);

  /**
   * handle on beforeunload & on pagehide
   */
  useEffect(() => {
    if (room) {
      window.addEventListener('beforeunload', disconnectRoom);
      window.addEventListener('pagehide', disconnectRoom);

      // remove listener
      room.once('disconnected', () => {
        window.removeEventListener('beforeunload', disconnectRoom);
        window.removeEventListener('pagehide', disconnectRoom);
      });

      return () => {
        window.removeEventListener('beforeunload', disconnectRoom);
        window.removeEventListener('pagehide', disconnectRoom);
      };
    }

    return () => {};
  }, [disconnectRoom, room]);

  return { ...roomState, disconnectRoom, connectRoom };
}
