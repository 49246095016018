import React, { PropsWithChildren } from 'react';
import { Grid, Image } from '@mantine/core';
import defaultImageUrl from '@assets/images/iphone.png';

interface InAppPreviewProps {
  imageUrl?: string;
}

export const InAppPreview = ({ imageUrl, children }: PropsWithChildren<InAppPreviewProps>): JSX.Element => (
  <Grid>
    <Grid.Col span={3}>
      <Image src={imageUrl || defaultImageUrl} alt="Phone" />
    </Grid.Col>
    <Grid.Col span="auto">{children}</Grid.Col>
  </Grid>
);
