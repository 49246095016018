import { useMedplum } from '@medplum/react';
import { useQuery } from '@tanstack/react-query';
import { CareTeamType, System } from 'const-utils';

export const useEngagementPodCareTeams = () => {
  const medplum = useMedplum();

  return useQuery(['engagement-pods'], () => {
    return medplum.search('CareTeam', {
      _tag: CareTeamType.EngagementPod,
      identifier: `${System.Pod}|`,
    });
  });
};
