import React from 'react';
import { ActionIcon, Group, Tooltip } from '@mantine/core';

import {
  IconAspectRatioFilled,
  IconMicrophone,
  IconMicrophoneOff,
  IconPhone,
  IconTexture,
  IconVideo,
  IconVideoOff,
} from '@tabler/icons-react';
import { useVideo } from '../VideoProvider';

interface VideoControlsProps {
  isBlurOn: boolean;
  isCameraOn: boolean;
  isMicrophoneOn: boolean;
  toggleBlur: () => void;
  toggleCamera: () => void;
  toggleMicrophone: () => void;
  endCall: () => void;
}

export default function VideoControls({
  isBlurOn,
  isCameraOn,
  isMicrophoneOn,
  toggleBlur,
  toggleCamera,
  toggleMicrophone,
  endCall,
}: VideoControlsProps): JSX.Element {
  const { isEndingCall } = useVideo();

  return (
    <Group>
      <Tooltip.Floating label={isCameraOn ? 'Turn off camera' : 'Turn on camera'} position="right-end">
        <ActionIcon size="lg" variant="filled" radius="xl" onClick={() => toggleCamera()}>
          {isCameraOn ? <IconVideo height="20px" /> : <IconVideoOff height="20px" />}
        </ActionIcon>
      </Tooltip.Floating>
      <Tooltip.Floating label={isMicrophoneOn ? 'Mute microphone' : 'Unmute microphone'} position="right-end">
        <ActionIcon size="lg" variant="filled" radius="xl" onClick={() => toggleMicrophone()}>
          {isMicrophoneOn ? <IconMicrophone height="20px" /> : <IconMicrophoneOff height="20px" />}
        </ActionIcon>
      </Tooltip.Floating>
      <Tooltip.Floating label={isBlurOn ? 'Turn off blur' : 'Turn on blur'} position="right-end">
        <ActionIcon size="lg" variant="filled" radius="xl" onClick={() => toggleBlur()} disabled={!isCameraOn}>
          {isBlurOn ? <IconTexture height="20px" /> : <IconAspectRatioFilled height="20px" />}
        </ActionIcon>
      </Tooltip.Floating>
      <Tooltip.Floating label="End call" position="right-end">
        <ActionIcon
          data-testid="end-call-btn"
          size="lg"
          variant="filled"
          radius="xl"
          color="red"
          onClick={() => endCall()}
          loading={isEndingCall}
        >
          <IconPhone height="20px" style={{ transform: 'rotate(135deg)' }} />
        </ActionIcon>
      </Tooltip.Floating>
    </Group>
  );
}
