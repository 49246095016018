import { isEmpty, replace } from 'lodash';

export const sortDirections = ['ascending', 'descending'] as const;
export type SortDirection = (typeof sortDirections)[number];

/**
 * @param existingSort - the current sort that needs to be reformatted
 * @param field - the field that will be included in the new sort
 * @param sortString - the string that indicates how to sort the field example: 'name' or '-name'
 * @returns a sort string that represents the combination of the existing sorted fields and new sort fields
 */
export const buildSortString = (existingSort: string, field: string, sortString: string): string => {
  if (isEmpty(existingSort)) {
    return sortString;
  }

  const regex = /-/g;
  const sortedFields = existingSort.split(',');
  const reformattedSortedFields = sortedFields.map((sortedField) => {
    return replace(sortedField, regex, '') === field ? sortString : sortedField;
  });

  if (!reformattedSortedFields.includes(sortString)) {
    reformattedSortedFields.push(sortString);
  }

  return reformattedSortedFields.join(',');
};

/**
 * @param field - the field to be sorted
 * @param direction - direction to sort by
 * @returns a string that will be used to sort the field
 */
export const buildFieldSort = (field: string, direction: SortDirection): string => {
  return direction === 'descending' ? `-${field}` : field;
};
