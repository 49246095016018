import { Divider, Group, Paper, ScrollArea, Stack, Table, Text, ThemeIcon } from '@mantine/core';
import { useGetPatientOutreachAttemptsQuery } from 'medplum-gql';
import React from 'react';
import { IconArrowsShuffle } from '@tabler/icons-react';
import classes from './OutreachSidebar.module.css';
import { mapOutreachQueryResult } from 'imagine-dsl/utils/outreach';
import { TouchpointActivityItem } from '../patientProfile/patientActivity/TouchpointActivityItem';
// TODO: Switch this import when the refactor is complete.
import { CompactPatientHeader } from '../shared/patient/PatientHeader/CompactPatientHeader';
import { FetchedPatient } from '@/utils/queryTypes';

interface OutreachSidebarProps {
  patient: FetchedPatient;
}

export const NoOutreachAttempts = (): JSX.Element => {
  return (
    <Group>
      <ThemeIcon variant="transparent" color="status-info">
        <IconArrowsShuffle />
      </ThemeIcon>
      <Text>Outreach has not contacted this patient.</Text>
    </Group>
  );
};

export default function OutreachSidebar({ patient }: OutreachSidebarProps): JSX.Element {
  const outreachAttempts = useGetPatientOutreachAttemptsQuery({
    variables: {
      id: patient.id,
    },
  });

  const count = outreachAttempts.data?.Patient?.outreachAttempts?.length || 0;

  return (
    <ScrollArea
      type="never"
      style={{
        height: '100vh',
        minHeight: '100%',
        position: 'sticky',
        backgroundColor: 'white',
      }}
    >
      <Paper className={classes.container}>
        <Stack gap="xs" p="xl">
          <Text c="dimmed" size="xs">
            Patient info
          </Text>
          <CompactPatientHeader patientId={patient.id} />
        </Stack>
        <Divider />

        <div style={{ padding: '2em' }}>
          <Text c="imagine-green" style={{ fontSize: '20px' }}>
            Outreach Attempts ({count})
          </Text>
          <br />
          {count === 0 ? (
            <NoOutreachAttempts />
          ) : (
            <Table verticalSpacing="sm" mih="100%">
              <Table.Tbody>
                {mapOutreachQueryResult(outreachAttempts.data!).map((att) => (
                  <TouchpointActivityItem key={att.id} touchpoint={att} patientId={patient.id} hideEditButton />
                ))}
              </Table.Tbody>
            </Table>
          )}
        </div>
      </Paper>
    </ScrollArea>
  );
}
