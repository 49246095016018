import { Switch } from '@mantine/core';
import React from 'react';

interface FiltersProps {
  unreadOnly: boolean;
  setUnreadOnly: (unreadOnly: boolean) => void;
}

export const Filters = ({ unreadOnly, setUnreadOnly }: FiltersProps): JSX.Element => {
  const trackColor = unreadOnly ? 'var(--mantine-color-imagine-green-6)' : 'var(--mantine-color-brand-gray-4)';

  return (
    <Switch
      onClick={() => setUnreadOnly(!unreadOnly)}
      checked={unreadOnly}
      styles={{ track: { backgroundColor: trackColor } }}
      label="Show unread only"
      w="max-content"
    />
  );
};
