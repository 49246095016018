import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useTaskDrawerHandle() {
  const [searchParams, setSearchParams] = useSearchParams();

  const openDrawer = useCallback(
    (taskId: string) => {
      const currentParams = Object.fromEntries(searchParams);
      setSearchParams({
        ...currentParams,
        task: taskId,
      });
    },
    [searchParams, setSearchParams],
  );

  const closeDrawer = useCallback(() => {
    searchParams.delete('task');
    const params = Object.fromEntries(searchParams);
    setSearchParams({ ...params });
  }, [searchParams, setSearchParams]);

  return { openDrawer, closeDrawer };
}
