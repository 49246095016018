import { MedplumClient, createReference } from '@medplum/core';
import { Patient, PatientCommunication } from 'medplum-gql';
import { Patient as FHIRPatient, Practitioner } from '@medplum/fhirtypes';
import { CommunicationCategory, CommunicationMedium, System, testMessage } from 'const-utils';
import { buildCommunication } from 'imagine-dsl/utils/communication';
import { getTranslatedValue } from 'imagine-dsl/utils/localization';

interface TestMessageResponse {
  success: boolean;
}

export const sendTestMessage = async (
  medplum: MedplumClient,
  patient: Patient,
  profile: Practitioner,
): Promise<TestMessageResponse> => {
  const languageCode =
    patient?.communication?.find(
      (c: PatientCommunication) => c?.language?.coding?.[0]?.system === System.PreferredLanguage,
    )?.language?.coding?.[0]?.code || 'en';

  const communicationObj = buildCommunication({
    medium: CommunicationMedium.Chat,
    category: CommunicationCategory.Test,
    contentString: getTranslatedValue(testMessage, languageCode),
    subject: createReference(patient as FHIRPatient),
    sender: createReference(profile),
  });

  const communication = await medplum.createResource(communicationObj);

  return communication ? { success: true } : { success: false };
};
