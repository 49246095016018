import React from 'react';
import { TextInput, TextInputProps } from '@mantine/core';
import { PatientPrimaryInfoFields } from '../../helpers/EditFormHandler';

interface PreferredNameFieldProps extends Omit<TextInputProps, 'label'> {}

const PreferredName = (props: PreferredNameFieldProps): JSX.Element => {
  return <TextInput {...props} label={`Patient's ${PatientPrimaryInfoFields.PREFERRED_NAME}`} data-autofocus mb="lg" />;
};

export default PreferredName;
