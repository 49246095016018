import React, { useMemo } from 'react';
import { formatHumanName } from '@medplum/core';
import { IconVideo } from '@tabler/icons-react';
import { useVideo } from '@/components/video/VideoProvider';
import { notifications } from '@mantine/notifications';
import { useActiveVideo } from '@/hooks/useActiveVideo';
import { PatientProfileAlert } from './PatientProfileAlert';
import { Text, ThemeIcon } from '@mantine/core';

interface ActiveVideoAlertProps {
  patientId: string;
}
export function ActiveVideoAlert({ patientId }: ActiveVideoAlertProps): JSX.Element {
  const { data } = useActiveVideo({ patientId, refetchInterval: 10000 });

  const { startCall, activeCall, isStartingCall } = useVideo();

  const display = useMemo(() => {
    const practitioner = data?.practitioners?.[0];
    const practitionerName = practitioner?.name && formatHumanName(practitioner.name[0]);
    return practitionerName ? `Active Video Call with ${practitionerName}` : 'There is an active video call.';
  }, [data]);

  const joinCall = async () => {
    if (isStartingCall) {
      notifications.show({
        id: 'already-starting-call',
        message: 'You are already starting a call.',
        color: 'yellow',
      });
      return;
    }

    if (!activeCall) {
      await startCall({ reference: `Patient/${patientId}` });
    } else {
      notifications.show({
        id: 'already-in-call',
        message: 'You are already in another video call.',
        color: 'yellow',
      });
    }
  };

  if (!data?.encounter) {
    return <></>;
  }

  return (
    <PatientProfileAlert
      onClick={joinCall}
      icon={
        <ThemeIcon size={20}>
          <IconVideo size={20} />
        </ThemeIcon>
      }
      callToAction="Join Call"
    >
      <Text fw={700}>{display}</Text>
    </PatientProfileAlert>
  );
}
