import { GetAllPractitionersByRoleQuery, GetPatientQuery } from 'medplum-gql';
import { formatHumanName } from '@medplum/core';
import { HumanName, CareTeam as CareTeamFHIR, Practitioner } from '@medplum/fhirtypes';
import { CareTeamMemberRole } from 'const-utils/codeSystems/ImaginePediatrics';
import { isDefined } from './lists';
import { uniqBy } from 'lodash';
import { System } from 'const-utils';

interface CareTeamMember {
  fhirReference: string;
  name: string;
  role: string;
  roleCode: CareTeamMemberRole | undefined;
  association: string | undefined;
  contact: string | undefined;
}

interface CareTeam {
  id: string;
  members: CareTeamMember[];
}

interface Pod {
  id: string;
  name: string;
}

export function extractPod(query: GetPatientQuery): Pod | undefined {
  const careTeamParticipant = query?.Patient?.internalCareTeam?.[0]?.participant?.filter(
    (m) => m.member?.resource?.__typename === 'CareTeam',
  )?.[0];

  if (!careTeamParticipant || careTeamParticipant?.member?.resource?.__typename !== 'CareTeam') {
    return undefined;
  }

  const id = careTeamParticipant.member?.resource?.id;
  const name = careTeamParticipant.member?.resource?.podName;

  if (!id || !name) {
    return undefined;
  }

  return {
    id,
    name,
  };
}

type CareTeamType = 'internalCareTeam' | 'externalCareTeam';
export function extractCareTeam(query: GetPatientQuery, careTeamType: CareTeamType): CareTeam | undefined {
  const careTeam = query.Patient?.[careTeamType]?.[0];

  if (!careTeam) {
    return undefined;
  }

  const id = careTeam.id;
  if (!id) {
    return undefined;
  }

  const members = careTeam.participant
    ?.filter((m) => m?.member?.resource?.__typename === 'Practitioner')
    ?.map((member) => {
      if (member?.member?.resource?.__typename !== 'Practitioner') {
        return undefined;
      }

      const reference = member.member.reference;
      if (!reference) {
        return undefined;
      }

      const name = member.member.resource.name?.[0] && formatHumanName(member.member.resource.name?.[0] as HumanName);
      const role = member.role?.[0]?.coding?.[0]?.display;
      const roleCode = member.role?.[0]?.coding?.[0]?.code;

      const association =
        member.member?.resource?.extension?.find((e) => e.url === System.ExternalPractitionerAssociation.toString())
          ?.valueString || undefined;
      const workPhone = member.member?.resource?.telecom?.find((t) => t.system === 'phone')?.value;
      const fax = member.member?.resource?.telecom?.find((t) => t.system === 'fax')?.value;
      const email = member.member?.resource?.telecom?.find((t) => t.system === 'email')?.value;

      const contact = [workPhone, fax, email].filter(isDefined).join(', ');

      return {
        fhirReference: reference,
        name: name || 'Unknown',
        role: role || 'Unknown',
        roleCode: roleCode as CareTeamMemberRole | undefined, // TODO: Default to RN for now if there is no code for some reason.
        association,
        contact,
      };
    })
    .filter(isDefined);

  return {
    id,
    members: members || [],
  };
}

export interface CareTeamMemberCandidate {
  name: string;
  practitionerId: string;
  role: string;
  email?: string;
}

export function mapGetAllPractitionersByRole(query: GetAllPractitionersByRoleQuery): CareTeamMemberCandidate[] {
  const practitioners = query.practitionersWithRoles?.map((n) => ({
    role: n?.code?.[0]?.coding?.[0]?.display || 'Unknown',
    practitioner: n?.practitioner?.resource,
  }));
  const candidates =
    practitioners
      ?.map(({ role, practitioner: p }) => {
        if (p?.__typename !== 'Practitioner') {
          return undefined;
        }
        const name = p?.name?.[0] && formatHumanName(p.name?.[0] as HumanName);
        const practitionerId = p.id;

        if (!name || !practitionerId) {
          return undefined;
        }

        const candidate: CareTeamMemberCandidate = {
          name: name,
          practitionerId,
          role,
          email: p.telecom?.find((t) => t?.system === 'email')?.value || undefined,
        };

        return candidate;
      })
      .filter(isDefined) || [];

  return uniqBy(candidates, (it) => it.practitionerId);
}

interface PractitionerWithTypename extends Practitioner {
  __typename?: string;
}

export const getExternalCareTeamMemberByRole = (careTeam: CareTeamFHIR[], code: string): string => {
  for (const team of careTeam) {
    for (const participant of team.participant ?? []) {
      if (participant.member && participant.role?.some((role) => role.coding?.some((coding) => coding.code === code))) {
        const name =
          (participant?.member?.resource as PractitionerWithTypename)?.__typename === 'Practitioner'
            ? (participant.member.resource as Practitioner).name?.[0]
            : undefined;
        if (name) {
          return `${name?.text}`.trim();
        }
      }
    }
  }
  return '';
};
