import { ActionIcon, Badge, FloatingPosition, Group, Menu as MantineMenu, MantineShadow } from '@mantine/core';
import React, { useState } from 'react';

interface MenuProps {
  icon: React.ReactNode;
  dropdown: React.ReactNode;
  width?: number;
  position?: FloatingPosition;
  shadow?: MantineShadow;
  iconSize?: number;
  alertContent?: string;
}

export const IconMenu = ({
  icon,
  dropdown,
  width,
  position,
  shadow,
  iconSize,
  alertContent,
}: MenuProps): JSX.Element => {
  const [opened, setOpened] = useState(false);
  const showAlertBubble = alertContent && alertContent !== '0';

  return (
    <MantineMenu opened={opened} onChange={setOpened} shadow={shadow} width={width} position={position}>
      <MantineMenu.Target>
        <Group gap={0} mr="-10px">
          <ActionIcon
            bg={opened || showAlertBubble ? 'primary-50.1' : 'unset'}
            size={iconSize}
            radius="xl"
            variant="outline"
          >
            {icon}
          </ActionIcon>
          {showAlertBubble && (
            <Badge mt="-20px" ml="-12px" style={{ zIndex: 1000 }} bg="status-error" circle>
              {alertContent}
            </Badge>
          )}
        </Group>
      </MantineMenu.Target>
      <MantineMenu.Dropdown p={0}>{dropdown}</MantineMenu.Dropdown>
    </MantineMenu>
  );
};
