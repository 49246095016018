import { Address } from '@medplum/fhirtypes';
import { format } from 'date-fns';
import { Address as GraphqlAddress, Maybe } from 'medplum-gql';

/**
 *
 * @param phoneNumber - the phone number to be cleaned
 * @returns the same phone number with only digits
 */
export const cleanPhoneNumber = (phoneNumber: string): string => phoneNumber.replace(/\D/g, '');

/**
 *
 * @param phone - the phone number to format
 * @returns (xxx) xxx-xxxx
 */
export const formatPhoneString = (phone: string | undefined): string => {
  if (!phone) {
    return '';
  }

  const match = cleanPhoneNumber(phone).match(/^(\d{3})(\d{3})(\d{4})$/);
  if (!match) {
    return phone;
  }

  return `(${match[1]}) ${match[2]}-${match[3]}`;
};

/**
 *
 * @param addressObj - the address obj, either FHIR or GraphQL
 * @returns a formatted address with missing parts taken into account
 */
export const formatAddress = (addressObj?: Address | GraphqlAddress | null): string => {
  if (!addressObj) {
    return ''; // handle undefined and null input
  }

  const addressLines = addressObj.line?.join(' ') || '';
  const city = addressObj.city ? addressObj.city + ',' : '';
  const state = addressObj.state || '';
  const postalCode = addressObj.postalCode || '';

  const address = [addressLines, city, state, postalCode].filter(Boolean);

  return address.join(' ').trim();
};

export interface DateOfBirthResult {
  dateOfBirth: string;
  age: string;
}
/**
 *
 * @param dateOfBirth - the date of birth to format
 * @param abbreviate - whether to abbreviate 'years old' to 'y.o.'
 * @returns an object with dob formatted as 'MM/DD/YYYY' and age as 'x years old'
 */
export const formatDateOfBirth = (
  dateOfBirth: Maybe<string> | undefined,
  abbreviate: boolean = false,
): DateOfBirthResult => {
  if (!dateOfBirth) {
    return { dateOfBirth: '', age: '' };
  }

  const today = new Date(Date.now());
  const birthDate = new Date(`${dateOfBirth}T00:00:00`);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && birthDate.getDate() > today.getDate())) {
    age--;
  }

  return { dateOfBirth: format(birthDate, 'MM/dd/yyyy'), age: `${age} ${abbreviate ? 'y.o.' : 'years old'}` };
};

export const copyToClipboard = async (text: string): Promise<void> => {
  await navigator.clipboard.writeText(text);
};

/**
 *
 * @param dob - the dob to be formatted
 * @param options - options for formatting
 * @param options.emptyText - text to return if dob is empty
 * @param options.abbreviate - whether to abbreviate 'years old' to 'y.o.'
 * @returns formatted age as 'x years old'
 */
export const formatAge = (
  dob: Maybe<string> | undefined,
  options: { emptyText: string; abbreviate?: boolean } = { emptyText: '', abbreviate: false },
): string => {
  return formatDateOfBirth(dob, options.abbreviate)?.age || options.emptyText;
};

/**
 *
 * @param dob - dob to be formatted
 * @param options - options for formatting
 * @returns formatted age and date of birth as 'MM/DD/YYYY (x years old)'
 */
export const formatAgeAndDateOfBirth = (dob: Maybe<string> | undefined, options = { emptyText: '' }): string => {
  const formattedDob = formatDateOfBirth(dob);
  return !dob ? options.emptyText : `${formattedDob.dateOfBirth} (${formattedDob.age})`;
};

/**
 *
 * @param bool - the boolean to convert
 * @returns yes or no
 */
export const yesOrNo = (bool: boolean): string => {
  return bool ? 'yes' : 'no';
};

/**
 *
 * @param name - the name to get initials from
 * @returns initials
 */
export const getInitials = (name: string): string => {
  const nameSplit = name.split(' ');
  const nameLength = nameSplit.length;
  if (nameLength === 1) {
    return `${name.charAt(0)}`.toUpperCase();
  } else if (nameLength > 1) {
    return `${nameSplit[0].charAt(0)}${nameSplit[nameLength - 1].charAt(0)}`.toUpperCase();
  } else {
    return '';
  }
};

export const abbreviate = (text: string): string => {
  const words = text.split(' ');
  if (words.length === 1) {
    return text;
  }
  return words.map((word) => word.charAt(0)).join('');
};

/**
 * @param email - the email to validate
 * @returns true if the email is valid
 * @see https://emailregex.com/
 *
 */
export const validateEmail = (email: string): boolean => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

/**
 *
 * @param list - the list to check
 * @param plural - the plural form of the word
 * @param singular - the singular form of the word
 * @returns the plural or singular form of the word based on the length of the list
 */
export const pluralize = (list: unknown[], plural: string, singular: string): string => {
  return list.length === 1 ? singular : plural;
};

/**
 *
 * @param timeZone - the time zone to format
 * @param short - whether to return the short or long version of the time zone
 * @returns the formatted time zone
 */
export const ianaToTimezone = (timeZone: string, short = false): string => {
  if (!timeZone) {
    return '';
  }
  const date = new Date();
  const options: Intl.DateTimeFormatOptions = { timeZone: timeZone, timeZoneName: short ? 'short' : 'long' };
  const timezoneName = new Intl.DateTimeFormat('en', options).format(date);

  return timezoneName?.split(',')?.[1]?.trim();
};
