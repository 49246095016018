import React from 'react';
import { Grid, Text, Flex, Divider } from '@mantine/core';
import { ContactPoint } from '@medplum/fhirtypes';
import { formatPhoneString } from 'imagine-dsl/utils/strings';
import { System } from 'const-utils';

interface ContactInfoProps {
  contactPoints?: ContactPoint[];
}

const PatientInvalidContacts = ({ contactPoints }: ContactInfoProps): JSX.Element => {
  if (contactPoints && contactPoints.length > 0) {
    return (
      <>
        <Divider mb={10} w="100%" style={{ borderTop: '1px solid var(--mantine-color-brand-gray-2' }} />
        {contactPoints.map((item, index) => {
          const invalidPhoneStatus = item?.extension?.find((ext) => ext.url === System.PhoneStatus.toString())
            ?.valueCode;
          return (
            <Grid
              justify="space-between"
              align="center"
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '28px',
                marginBottom: '15px',
              }}
              key={index}
            >
              <Flex align="start" gap="sm">
                <div>
                  <Text fw={700} c="dimmed">
                    {formatPhoneString(item?.value)}
                  </Text>
                  <Text c="dimmed">
                    {item?.extension?.find(
                      (ext) => ext.url === System.PrimaryPhone.toString() && ext.valueBoolean === true,
                    )?.valueBoolean
                      ? `${invalidPhoneStatus ? invalidPhoneStatus + ' ' : ''}(Primary)`
                      : invalidPhoneStatus}
                  </Text>
                </div>
              </Flex>
            </Grid>
          );
        })}
      </>
    );
  }

  return <></>;
};

export default PatientInvalidContacts;
