import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { GetChatTimelineForPatientQuery, useGetChatTimelineForPatientQuery } from 'imagine-gql/client';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { ApolloError } from '@apollo/client';
import { FetchedPatient } from '@/utils/queryTypes';
import { imagineClient } from '@/hooks/useImagineApolloClient';
import { useUserSession } from '../shared/UserSessionContext';
import { Practitioner } from '@medplum/fhirtypes';

interface ChatDrawerProviderContext {
  loading: boolean;
  timelineItems: GetChatTimelineForPatientQuery['chatTimeline']['timelineItems'];
  incompleteItem?: GetChatTimelineForPatientQuery['chatTimeline']['timelineItems'][0];
  profile: Practitioner;
  opened: boolean;
  timelineError?: ApolloError;
  onClose: () => void;
  patient: FetchedPatient;
}

const ChatDrawerProviderContextValue = createContext<ChatDrawerProviderContext | undefined>(undefined);

export const useNewQueryChatDrawer = (): ChatDrawerProviderContext => {
  const context = useContext(ChatDrawerProviderContextValue);
  if (!context) {
    throw new Error('useNewQueryChatDrawer must be used within a NewQueryChatDrawerProvider');
  }
  return context;
};

interface ChatDrawerProviderProps {
  patient: FetchedPatient;
  close: () => void;
  opened: boolean;
}

export function NewQueryChatDrawerProvider({
  patient,
  opened,
  close,
  children,
}: PropsWithChildren<ChatDrawerProviderProps>): JSX.Element {
  const { profile } = useUserSession();
  const flags = useFeatureFlags();
  const {
    data,
    loading,
    error: timelineError,
  } = useGetChatTimelineForPatientQuery({
    client: imagineClient,
    variables: {
      patientId: patient?.id,
    },
    skip: !patient?.id || !flags.ChatGQLQuery || !opened,
  });

  const timelineItems = useMemo(() => {
    return data?.chatTimeline.timelineItems || [];
  }, [data]);

  const incompleteItem = useMemo(() => {
    return timelineItems.findLast((item) => item.task.open) || undefined;
  }, [timelineItems]);
  const onClose = () => {
    close();
  };

  const contextValue: ChatDrawerProviderContext = {
    loading,
    timelineItems,
    opened,
    timelineError,
    incompleteItem,
    onClose,
    patient,
    profile,
  };

  return (
    <ChatDrawerProviderContextValue.Provider value={contextValue}>{children}</ChatDrawerProviderContextValue.Provider>
  );
}
