import { ValueSet } from '@medplum/fhirtypes';
import { System } from '../codeSystems/system';
import { Concepts, SYSTEM_URL, codeSystemConcepts } from '../codeSystems/ImaginePediatrics';

export const carePathwayReferralReasons = codeSystemConcepts[Concepts.CarePathwayReferralReason].concept!;

export const CarePathwayReferralReason: ValueSet = {
  resourceType: 'ValueSet',
  url: System.CarePathwayReferralReason,
  identifier: [
    {
      system: 'https://imaginepediatrics.org/fhir/ValueSet',
      value: Concepts.CarePathwayReferralReason.toString(),
    },
  ],
  version: '0.0.1',
  name: 'CarePathwayReferralReason',
  title: 'Care Pathway Referral Reason',
  status: 'active',
  experimental: false,
  compose: {
    include: [
      {
        system: SYSTEM_URL,
        concept: carePathwayReferralReasons!.map((c) => ({ code: c.code, display: c.display })),
      },
    ],
  },
};
