import React, { useMemo } from 'react';
import { Avatar, Group, Text, ThemeIcon } from '@mantine/core';
import { Communication, Device, HumanName, Patient, Practitioner } from '@medplum/fhirtypes';
import { formatHumanName } from '@medplum/core';
import { ChatBubble } from './ChatBubble';
import { getSentByImaginePediatrics } from 'imagine-dsl/utils/communication';
import { getInitials } from 'imagine-dsl/utils/strings';
import { CARE_HUB_LOGO_SRC } from '@/components/CareHubLogo';
import { preferredLanguage } from 'imagine-dsl/utils/patient';
import { IconWorld } from '@tabler/icons-react';
import { ResourceName } from '@medplum/react';

interface MessageProps {
  message: Communication;
  sameGroup?: boolean;
  sameSender?: boolean;
}

export function Message({ message, sameGroup = false, sameSender = false }: MessageProps): JSX.Element {
  const sender = message.sender?.resource as Patient | Practitioner | Device;
  const senderInitials = useMemo(() => {
    if (['Patient', 'Practitioner'].includes(sender?.resourceType)) {
      const name = formatHumanName((sender as Patient | Practitioner).name?.[0] as HumanName);
      return getInitials(name);
    } else {
      return 'IP';
    }
  }, [sender]);
  if (
    !message.payload?.[0].contentString &&
    !message.payload?.[0].contentAttachment &&
    !message.payload?.[0].contentReference
  ) {
    return <></>;
  }

  const isSentByPractitioner = getSentByImaginePediatrics(message);
  const language = !isSentByPractitioner ? preferredLanguage(message.sender?.resource as Patient) : null;

  return (
    <Group>
      {!isSentByPractitioner && (
        <>
          {!sameSender && (
            <Group style={{ gap: 4 }}>
              <Avatar
                color="imagine-green"
                variant="filled"
                data-testid="patient-avatar"
                style={{ alignSelf: 'start' }}
                size="md"
                radius="xl"
                src={(sender as Patient)?.photo?.[0]?.url || undefined}
              >
                {senderInitials}
              </Avatar>
              <ResourceName size="sm" fw="bold" value={sender as Patient | Practitioner | Device} />
              {!sameGroup && (
                <Group align="center" style={{ gap: 4 }}>
                  <ThemeIcon>
                    <IconWorld height={14} width={14} />
                  </ThemeIcon>
                  <Text size="md" fw="bold">
                    {language}
                  </Text>
                </Group>
              )}
            </Group>
          )}
          <ChatBubble message={message} sameSender={sameSender} />
        </>
      )}
      {isSentByPractitioner && (
        <>
          <ChatBubble message={message} sameSender={sameSender} />
          {!sameSender && (
            <Avatar
              data-testid="practitioner-avatar"
              style={{ alignSelf: 'start' }}
              src={CARE_HUB_LOGO_SRC}
              size="md"
              radius="xl"
            />
          )}
        </>
      )}
    </Group>
  );
}
