import { Group, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React from 'react';
import { Link } from 'react-router-dom';

interface AlertContentProps {
  id: string;
  message: string;
  path: string;
  linkText: string;
  icon?: React.ReactNode;
}

export function AlertContent({ message, id, path, linkText, icon }: AlertContentProps): JSX.Element {
  return (
    <Group>
      {icon}
      <Stack>
        <Text fw="bold">{message}</Text>
        <Link onClick={() => notifications.hide(id)} to={path}>
          <Text c="imagine-green" fw="bold">
            {linkText}
          </Text>
        </Link>
      </Stack>
    </Group>
  );
}
