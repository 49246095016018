import { Button, Group, Modal, Textarea, UnstyledButton, Divider, Box, Loader } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import React, { useEffect, useState } from 'react';
import { createPatientNote, deletePatientNote, editPatientNote } from 'imagine-dsl/services/notesService';
import { notifications } from '@mantine/notifications';
import { logError } from '@/errors';
import { useForm } from '@mantine/form';
import { CareHubNote } from 'imagine-dsl/utils/note';
import { isEmpty } from 'lodash';
import { Row } from './Row';
import { useUserSession } from '../../UserSessionContext';

interface NotesModalProps {
  patientId: string;
  opened: boolean;
  close: () => void;
  notes: CareHubNote[];
  fetchNotes: () => Promise<unknown>;
}

export function NotesModal({ notes, patientId, opened, close, fetchNotes }: NotesModalProps): JSX.Element {
  const medplum = useMedplum();
  const { profile } = useUserSession();
  const [addingNote, setAddingNote] = useState(notes.length === 0);
  const [noteId, setNoteId] = useState<string | undefined>();
  const [notesStagedForDeletion, setNotesStagedForDeletion] = useState<string[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues = { newNote: '' };
  const form = useForm({
    initialValues,
  });

  useEffect(() => {
    if (notes.length === 0) {
      setAddingNote(true);
    }
  }, [notes]);

  const onSubmit = () => {
    if (!form.values.newNote) {
      return;
    }

    setSubmitting(true);

    if (!noteId) {
      createPatientNote(medplum, { patientId, note: form.values.newNote, author: profile })
        .then(async (_composition) => {
          await fetchNotes();
          form.setFieldValue('newNote', '');
        })
        .catch((err) => {
          notifications.show({ message: 'Something went wrong saving the patient note', color: 'status-error' });
          logError(err);
        })
        .finally(() => setSubmitting(false));
    } else {
      editPatientNote(medplum, { noteId, text: form.values.newNote, author: profile })
        .then(async (_composition) => {
          await fetchNotes();
          form.setFieldValue('newNote', '');
          setNoteId(undefined);
          setAddingNote(false);
        })
        .catch((err) => {
          notifications.show({ message: 'Something went wrong saving the patient note', color: 'status-error' });
          logError(err);
        })
        .finally(() => setSubmitting(false));
    }
  };

  const onCancel = () => {
    form.setFieldValue('newNote', '');
    setNoteId(undefined);
    if (isEmpty(notes)) {
      onClose();
    } else {
      setAddingNote(false);
    }
  };

  const onEditingNote = (note: CareHubNote) => {
    setAddingNote(true);
    setNoteId(note.id);
    form.setFieldValue('newNote', note.latestNoteSection!.noteText);
    form.setDirty({ newNote: false });
  };

  const onDeleteNote = (noteId: string) => {
    setNotesStagedForDeletion([...notesStagedForDeletion, noteId]);
  };

  const onClose = () => {
    setLoading(true);
    deletePatientNote(medplum, notesStagedForDeletion)
      .then(async () => {
        await fetchNotes();
      })
      .catch((err) => logError(err))
      .finally(() => setLoading(false));
    close();
    form.setFieldValue('newNote', '');
    setNoteId(undefined);
    setNotesStagedForDeletion([]);
  };

  const onUndoDelete = (noteId: string) => {
    const notesToDelete = notesStagedForDeletion.filter((id) => id !== noteId);
    setNotesStagedForDeletion(notesToDelete);
  };

  return (
    <Modal.Root size="lg" opened={opened} onClose={onClose}>
      <Modal.Overlay />
      <Modal.Content style={{ alignItems: 'center' }}>
        <Modal.Header>
          <Group>
            <Modal.Title>Notes ({notes.length})</Modal.Title>
            {!addingNote && notes.length > 0 && (
              <Button onClick={() => setAddingNote(true)} variant="outline">
                Add +
              </Button>
            )}
          </Group>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          {loading && <Loader />}
          {addingNote && (
            <form onSubmit={form.onSubmit(onSubmit)}>
              <Textarea
                name="newNote"
                label={noteId ? 'Edit note' : 'Add note'}
                autosize
                {...form.getInputProps('newNote')}
              />
              <Group mt={8} justify="flex-end" ml="auto">
                <UnstyledButton td="underline" c="imagine-green" onClick={onCancel}>
                  Cancel
                </UnstyledButton>
                <Button
                  type="submit"
                  loading={submitting}
                  disabled={!form.values.newNote || !form.isDirty('newNote')}
                  variant="primary"
                >
                  Save note
                </Button>
              </Group>
            </form>
          )}
          {addingNote && <Divider my={8} />}
          <Box style={{ overflowY: 'scroll' }}>
            {notes.map((note, noteIndex) => (
              <Row
                undoDelete={onUndoDelete}
                deleteNote={onDeleteNote}
                key={note.id}
                editNote={onEditingNote}
                note={note}
                noteIndex={noteIndex}
                notesLength={notes.length}
                stagedForDelete={notesStagedForDeletion.includes(note.id!)}
              />
            ))}
          </Box>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}
