import React, { useMemo } from 'react';
import { Table, Stack, Group, Text, Collapse, UnstyledButton, ThemeIcon } from '@mantine/core';
import { IconChevronDown, IconChevronRight, IconVideo } from '@tabler/icons-react';
import { formatDuration, intervalToDuration } from 'date-fns';
import { useDisclosure } from '@mantine/hooks';
import { VideoCall } from './types';
import { TextWithSidekick } from '../helpers/TextWithSidekick';
import { abbreviate } from 'imagine-dsl/utils/strings';
import { formatWithYesterdayAndToday } from 'imagine-dsl/utils/dates';

interface VideoCallActivityTableItemProps {
  video: VideoCall;
}

const TextWithLabel = ({ label, text }: { label: string; text: string }): JSX.Element => {
  return (
    <Group gap={4}>
      <Text size="xs" fw={400} c="imagine-gray">
        {label}:
      </Text>
      <Text size="xs" fw={700} c="imagine-gray">
        {text}
      </Text>
    </Group>
  );
};

export const VideoCallActivityItem = ({ video }: VideoCallActivityTableItemProps): JSX.Element => {
  const duration = useMemo(() => {
    const durationObj =
      video.duration.start &&
      video.duration.end &&
      intervalToDuration({
        start: video.duration.start,
        end: video.duration.end,
      });

    if ((!durationObj && video.status === 'in-progress') || video.status === 'planned') {
      return 'Call is in progress.';
    }

    if (!durationObj) {
      return 'Unknown call duration.';
    }

    return formatDuration(durationObj, {
      format: ['hours', 'minutes', 'seconds'],
      zero: true,
      delimiter: ':',
    });
  }, [video.duration, video.status]);

  const [opened, disclosure] = useDisclosure();

  const practitioner = video.practitioners.find((p) => p.name !== undefined);

  const caregiverAnswered = video.caregivers.length > 0;

  const durationText = caregiverAnswered ? duration : 'Call not answered.';

  return (
    <Table.Tr key={video.encounterId} style={{ verticalAlign: 'top' }}>
      <Table.Td>
        <Stack justify="flex-start" mt="25px">
          <ThemeIcon bd="1px solid blue" radius="100%" c="blue" p={2}>
            <IconVideo size={16} />
          </ThemeIcon>
        </Stack>
      </Table.Td>
      <Table.Td>
        <Stack gap={10}>
          <Text size="xs" c="imagine-gray">
            {formatWithYesterdayAndToday(video.callTime, 'EEE, MMMM do, yyyy hh:mm aaa', true)}
          </Text>
          <Group gap={4}>
            <Text fw={700}>Video visit</Text>
            <Text>conducted by</Text>
            <Text fw={700}>{practitioner?.name}</Text>
            {practitioner?.role && <Text>({practitioner.role})</Text>}
          </Group>
          <TextWithLabel label="Call duration" text={durationText} />
          {caregiverAnswered && (
            <>
              <UnstyledButton c="imagine-green" onClick={() => disclosure.toggle()}>
                <Group m="0.4rem" gap={2} align="center">
                  {opened ? <IconChevronDown size={15} /> : <IconChevronRight size={15} />}
                  <Text fz="xs">{opened ? 'Hide details' : 'View details'}</Text>
                </Group>
              </UnstyledButton>
              <Collapse in={opened}>
                <Text fz="xs" c="brand-gray.7" pb={8}>
                  Participants:
                </Text>
                <Stack gap={4}>
                  {video.practitioners.filter(hasName).map((p) => (
                    <React.Fragment key={`${p.role}${p.name}`}>
                      <TextWithSidekick fz="xs" key={p.name} text={p.name} sidekick={p.role && abbreviate(p.role)} />
                    </React.Fragment>
                  ))}
                  {video.caregivers.filter(hasName).map((c) => (
                    <React.Fragment key={`${c.relationship}${c.name}`}>
                      <TextWithSidekick fz="xs" key={c.name} text={c.name} sidekick={c.relationship} />
                    </React.Fragment>
                  ))}
                </Stack>
              </Collapse>
            </>
          )}
        </Stack>
      </Table.Td>
    </Table.Tr>
  );
};

function hasName<T extends { name?: string }>(nameable: T): nameable is T & { name: string } {
  return !!nameable.name;
}
