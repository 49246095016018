import { CareTeamMemberCandidate } from 'imagine-dsl/utils/careTeam';
import { useMedplum } from '@medplum/react';
import { CareTeamType, System } from 'const-utils';
import { CareTeamMemberRole, CareTeamMemberRoleDisplay } from 'const-utils/codeSystems/ImaginePediatrics';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery } from 'medplum-gql';

type Refetch = () => Promise<ApolloQueryResult<GetPatientQuery>>;

type Callback = () => Promise<void>;

const useManageInternalCareTeamMember = (
  careTeamId: string | undefined,
  candidate: CareTeamMemberCandidate | undefined,
  role: CareTeamMemberRole | undefined,
  previousMemberId: string | undefined,
  refetch: Refetch,
  patientId: string,
  close: () => void,
): Callback => {
  const medplum = useMedplum();

  const addOrReplaceMember = async (): Promise<void> => {
    if (!candidate || !role) {
      return;
    }

    const existing = careTeamId && (await medplum.readResource('CareTeam', careTeamId));

    const newParticipant = {
      role: [
        {
          coding: [
            {
              system: System.CareTeamRole,
              code: role,
              display: CareTeamMemberRoleDisplay[role],
            },
          ],
        },
      ],
      member: {
        reference: `Practitioner/${candidate.practitionerId}`,
      },
    };

    if (existing) {
      const participantsFiltered =
        existing.participant?.filter(
          (m) => !m.member?.reference?.includes(previousMemberId ?? candidate.practitionerId),
        ) || [];
      await medplum.updateResource({
        ...existing,
        participant: [...participantsFiltered, newParticipant],
      });
      await refetch();
    } else {
      await medplum.createResource({
        resourceType: 'CareTeam',
        name: 'Internal Care Team',
        subject: {
          reference: `Patient/${patientId}`,
        },
        participant: [newParticipant],
        meta: {
          tag: [
            {
              system: System.CareTeam,
              code: CareTeamType.Internal,
            },
          ],
        },
      });
    }
    await refetch();
    close();
  };

  return addOrReplaceMember;
};

export default useManageInternalCareTeamMember;
