import { QuestionnaireItem, QuestionnaireResponse, QuestionnaireResponseItem } from '@medplum/fhirtypes';
import { System } from 'const-utils';
import {
  Maybe,
  QuestionnaireResponse as GQLQuestionnaireResponse,
  SurveyReviewQuestionnaireResponseFragment,
} from 'medplum-gql';
import { compact } from 'lodash';
import {
  BHSurveyType,
  GAD7ResponseCategory,
  PHQ9ResponseCategory,
  QuestionnaireResponseCategory,
  QuestionnaireType,
  TaskType,
} from 'const-utils/codeSystems/ImaginePediatrics';

interface CalculateScoreArgs {
  scoreableResponses: QuestionnaireResponseItem[];
  scoreableQuestions: QuestionnaireItem[];
}

interface ScoreResponse {
  score: number;
  positiveForSuicidality?: boolean;
}

export const categorizeBHSurvey = (bhSurveyType: BHSurveyType, score: number): string => {
  switch (bhSurveyType) {
    case BHSurveyType.PHQ9:
      return PHQ9CategoryForScore(score);
    case BHSurveyType.GAD7:
      return GAD7CategoryForScore(score);
    default:
      return 'Unknown';
  }
};

const PHQ9CategoryForScore = (score: number): PHQ9ResponseCategory => {
  if (score < 5) {
    return PHQ9ResponseCategory.NonOrMinimal;
  } else if (score >= 5 && score <= 9) {
    return PHQ9ResponseCategory.Mild;
  } else if (score >= 10 && score <= 14) {
    return PHQ9ResponseCategory.Moderate;
  } else if (score >= 15 && score <= 19) {
    return PHQ9ResponseCategory.Moderate;
  } else {
    return PHQ9ResponseCategory.Severe;
  }
};

const GAD7CategoryForScore = (score: number): GAD7ResponseCategory => {
  if (score < 5) {
    return GAD7ResponseCategory.LowRisk;
  } else if (score >= 5 && score <= 9) {
    return GAD7ResponseCategory.Mild;
  } else if (score >= 10 && score <= 14) {
    return GAD7ResponseCategory.Moderate;
  } else {
    return GAD7ResponseCategory.Severe;
  }
};

export const calculateScore = ({ scoreableQuestions, scoreableResponses }: CalculateScoreArgs): ScoreResponse => {
  let positiveForSuicidality: boolean | undefined = undefined;
  const score = scoreableResponses.reduce<number>((acc, responseItem) => {
    const question = scoreableQuestions.find((q) => q?.linkId === responseItem?.linkId);
    const answerValues: number[] = [];
    responseItem?.answer?.forEach((answer) => {
      const answerValue = question?.answerOption
        ?.find((ao) => ao?.valueCoding?.code === answer?.valueCoding?.code)
        ?.extension?.find((ext) => ext.url === System.QuestionnaireAnswerTally.toString())?.valueInteger;
      if (answerValue) {
        answerValues.push(answerValue);
      }

      const suicidalityQuestion = question?.extension?.find(
        (ext) => ext.url === System.QuestionnaireSucidalityPrompt.toString() && ext.valueBoolean,
      );

      if (suicidalityQuestion && answerValue && answerValue > 0) {
        positiveForSuicidality = true;
      }
    });
    if (answerValues.length > 0) {
      answerValues.forEach((value) => {
        acc += value;
      });
    }
    return acc;
  }, 0);

  return { score, positiveForSuicidality };
};

export const getFlattenedItems = (questionnaireResponse: QuestionnaireResponse): QuestionnaireResponseItem[] => {
  const flatItems: QuestionnaireResponseItem[] = [];

  const flattenItems = (items: QuestionnaireResponseItem[] | undefined) => {
    if (!items) {
      return;
    }

    items.forEach((item) => {
      if (item.answer) {
        flatItems.push(item);
      }
      if (item.item) {
        flattenItems(item.item);
      }
    });
  };

  if (questionnaireResponse.item) {
    flattenItems(questionnaireResponse.item!);
  }

  return flatItems;
};

export const scoreableResponseItems = (
  questionnaireResponse: QuestionnaireResponse,
  scoreableQuestions: QuestionnaireItem[],
): QuestionnaireResponseItem[] => {
  const responses = getFlattenedItems(questionnaireResponse);

  return compact(responses?.filter((item) => scoreableQuestions.some((q) => q?.linkId === item?.linkId)));
};

export const sentForReview = (
  questionnaireResponse: QuestionnaireResponse | GQLQuestionnaireResponse | SurveyReviewQuestionnaireResponseFragment,
): boolean => {
  return (
    questionnaireResponse.extension?.find((ext) => ext.url === System.QuestionnaireResponseInReview.toString())
      ?.valueBoolean || false
  );
};

export const getScore = (
  qr: QuestionnaireResponse | GQLQuestionnaireResponse | SurveyReviewQuestionnaireResponseFragment,
): Maybe<number> => {
  return qr.extension?.find((ext) => ext.url === System.QuestionnaireResponseScore.toString())?.valueInteger || null;
};

interface ICareHubQuestionnaireResponse {
  questionnaireResponse: QuestionnaireResponse | GQLQuestionnaireResponse | SurveyReviewQuestionnaireResponseFragment;
}

export class CareHubQuestionnaireResponse implements ICareHubQuestionnaireResponse {
  questionnaireResponse: QuestionnaireResponse | GQLQuestionnaireResponse | SurveyReviewQuestionnaireResponseFragment;

  constructor(
    questionnaireResponse: QuestionnaireResponse | GQLQuestionnaireResponse | SurveyReviewQuestionnaireResponseFragment,
  ) {
    this.questionnaireResponse = questionnaireResponse;
  }

  get id(): Maybe<string> | undefined {
    return this.questionnaireResponse.id;
  }

  get score(): Maybe<number> {
    return getScore(this.questionnaireResponse);
  }

  get categories(): Maybe<string[]> {
    return compact(
      this.questionnaireResponse.extension
        ?.filter((ext) => ext.url === System.QuestionnaireResponseCategory.toString())
        .map((ext) => ext.valueString),
    );
  }

  get indicatesSuicideRisk(): boolean {
    return !!this.questionnaireResponse.extension?.find(
      (ext) =>
        ext.url === System.QuestionnaireResponseCategory.toString() &&
        ext.valueString === QuestionnaireResponseCategory.SuicideRisk,
    );
  }

  get sentForReview(): boolean {
    return sentForReview(this.questionnaireResponse);
  }

  get subjectReference(): Maybe<string> | undefined {
    if ('subject' in this.questionnaireResponse) {
      return this.questionnaireResponse.subject?.reference;
    } else {
      return undefined;
    }
  }

  get completedTime(): Maybe<string> | undefined {
    return this.questionnaireResponse.authored;
  }

  get type(): Maybe<QuestionnaireType> {
    if (!('meta' in this.questionnaireResponse)) {
      return null;
    }

    const tag = this.questionnaireResponse.meta?.tag?.find((tag) => tag?.system === System.QuestionnaireType)?.code;
    if (!tag) {
      return null;
    }

    return tag as QuestionnaireType;
  }

  get caregiverTaskType(): Maybe<TaskType> {
    if (this.type === QuestionnaireType.Screener) {
      return TaskType.Screener;
    }

    if (this.type === QuestionnaireType.BHSurvey) {
      return TaskType.BHSurvey;
    }

    return null;
  }

  get bhSurveyType(): Maybe<BHSurveyType> {
    if (this.type === QuestionnaireType.BHSurvey && 'meta' in this.questionnaireResponse) {
      const bhType = this.questionnaireResponse.meta?.tag?.find((tag) => tag?.system === System.BHSurveyType)?.code;
      return bhType ? (bhType as BHSurveyType) : null;
    }

    return null;
  }

  get reviewTaskTypeTaskType(): Maybe<TaskType> {
    if (this.type === QuestionnaireType.Screener) {
      return TaskType.ScreenerReview;
    }

    if (this.type === QuestionnaireType.BHSurvey) {
      return TaskType.ReviewBHSurvey;
    }

    return null;
  }
}
