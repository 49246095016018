import { Stack, Text } from '@mantine/core';
import React from 'react';

export const EmptyResource = (): JSX.Element => {
  return (
    <Stack>
      <Text fw="bold" w="75%">
        No resources available
      </Text>
    </Stack>
  );
};
