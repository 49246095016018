import { ActionIcon } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';
import { SaveTouchpointModal } from './SaveTouchpointModal';
import { IconEditCircle } from '@tabler/icons-react';
import { OutreachAttempt } from 'imagine-dsl/utils/outreach';
import { OutreachCallDisposition } from 'const-utils';
import { selectCodingByDisplay } from 'const-utils/valueSets/selectValue';
import {
  CallDisposition,
  OutreachDisposition,
  OutreachModality,
  getOutreachDisposition,
  getOutreachModality,
} from 'const-utils/codeSystems/ImaginePediatrics';
import { Reference } from '@medplum/fhirtypes';

interface EditTouchpointButton {
  patientId: string;
  outreachAttempt: OutreachAttempt;
  basedOn?: Reference[];
}

export const EditTouchpointButton = (props: EditTouchpointButton): JSX.Element => {
  const [open, handle] = useDisclosure();

  // If a touchpoint is edited after deploy but prior to the backfill script being run
  // we should handle the remapping of the old call disposition to the new outreach dispositions
  // and the old call route to the new outreach modalities here in the client
  // TODO: cleanup after cut-over to new outreach modalities and dispositions
  const modality = getOutreachModality(props.outreachAttempt.modality ?? OutreachModality.PhoneOutboundStandard);
  const outcome = getOutreachDisposition(
    selectCodingByDisplay(OutreachCallDisposition, props.outreachAttempt.outcome)?.code as
      | CallDisposition
      | OutreachDisposition,
    modality,
  );

  const close = async (): Promise<void> => {
    handle.close();
  };

  return (
    <>
      <ActionIcon onClick={handle.open}>
        <IconEditCircle size={20} />
      </ActionIcon>
      {open && (
        <SaveTouchpointModal
          basedOn={props.basedOn}
          patientId={props.patientId}
          touchpointId={props.outreachAttempt.id}
          formValues={{
            notes: props.outreachAttempt.note ?? '',
            outcome,
            callbackDateTimeStart: props.outreachAttempt.callbackDateTimeStart,
            callbackDateTimeEnd: props.outreachAttempt.callbackDateTimeEnd,
            callbackTimezone: props.outreachAttempt.callbackTimezone ?? '',
            details: {
              date: props.outreachAttempt?.sentTime ?? new Date(),
              timezone: props.outreachAttempt?.callbackTimezone ?? '',
              modality,
              phoneNumber: props.outreachAttempt.phoneNumber ?? '',
              contactName: props.outreachAttempt.contactName ?? '',
            },
          }}
          submitLabel="Edit Touchpoint"
          opened={open}
          closeModal={close}
        />
      )}
    </>
  );
};
