import { MedplumClient, QueryTypes } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import { useQuery } from '@tanstack/react-query';
import { isDefined } from 'imagine-dsl/utils/lists';
import { isA } from 'imagine-dsl/utils/resource';

const queryFn = (medplum: MedplumClient, patientId?: string) => async () => {
  const query: QueryTypes = {
    _count: 1,
    subject: `Patient/${patientId}`,
    status: 'planned,in-progress',
    type: 'video',
    _include: 'Encounter:participant',
  };
  type ResultType = 'Encounter' | 'Patient' | 'Practitioner';
  const bundle = patientId ? await medplum.search<ResultType>('Encounter', query, { cache: 'no-cache' }) : undefined;
  const resources = bundle?.entry?.map((e) => e.resource).filter(isDefined) || [];
  const encounter = resources.find(isA('Encounter'));
  const practitioners = resources.filter(isA('Practitioner'));
  const patientPresent = resources.some(isA('RelatedPerson')) || resources.some(isA('Patient'));
  return { encounter, practitioners, patientPresent };
};

type UseActiveVideoOptions = {
  patientId: string;
  refetchInterval?: number;
};

export const useActiveVideo = (opts: UseActiveVideoOptions) => {
  const medplum = useMedplum();

  return useQuery(['active-video', opts.patientId], queryFn(medplum, opts.patientId), {
    refetchInterval: opts.refetchInterval || false,
  });
};
