export enum CareTeamMemberRole {
  AdvancedPracticeProvider = 'advancedpracticeprovider',
  BHCareManager = 'bhcaremanager',
  BHClinicalDirector = 'bhclinicaldirector',
  BHTherapist = 'bhtherapist',
  BehavioralHealthApp = 'behavioralhealthapp',
  Cardiologist = 'cardiologist',
  CareCoordinator = 'carecoordinator',
  CareTeamAssistant = 'careteamassistant',
  CommunityHealthNavigator = 'communityhealthnavigator',
  ComplexCarePediatrician = 'complexcarepediatrician',
  Dietitian = 'dietitian',
  ImaginePediatrician = 'imaginepediatrician',
  LaunchCaseManager = 'launchcasemanager',
  Nephrologist = 'nephrologist',
  PalliativeCareSpecialist = 'palliativecarepecialist',
  PediatricRN = 'pediatricrn',
  Pharmacist = 'pharmacist',
  Physician = 'physician',
  PrimaryCareProvider = 'primarycareprovider',
  Psychiatrist = 'psychiatrist',
  Psychologist = 'psychologist',
  Pulmonologist = 'pulmonologist',
  ResourceSpecialist = 'resourcespecialist',
  SocialWorkCaseManager = 'socialworkcasemanager',
}

export const CareTeamMemberRoleDisplay: Record<CareTeamMemberRole, string> = {
  [CareTeamMemberRole.AdvancedPracticeProvider]: 'Advanced Practice Provider',
  [CareTeamMemberRole.BHCareManager]: 'BH Care Manager',
  [CareTeamMemberRole.BHClinicalDirector]: 'BH Clinical Director',
  [CareTeamMemberRole.BHTherapist]: 'BH Therapist',
  [CareTeamMemberRole.BehavioralHealthApp]: 'Behavioral Health App',
  [CareTeamMemberRole.Cardiologist]: 'Cardiologist',
  [CareTeamMemberRole.CareCoordinator]: 'Care Coordinator',
  [CareTeamMemberRole.CareTeamAssistant]: 'Care Team Assistant',
  [CareTeamMemberRole.CommunityHealthNavigator]: 'Community Health Navigator',
  [CareTeamMemberRole.ComplexCarePediatrician]: 'Complex Care Pediatrician',
  [CareTeamMemberRole.Dietitian]: 'Dietitian',
  [CareTeamMemberRole.ImaginePediatrician]: 'Imagine Pediatrician',
  [CareTeamMemberRole.LaunchCaseManager]: 'Launch Case Manager',
  [CareTeamMemberRole.Nephrologist]: 'Nephrologist',
  [CareTeamMemberRole.PalliativeCareSpecialist]: 'Palliative Care Specialist',
  [CareTeamMemberRole.PediatricRN]: 'Pediatric RN',
  [CareTeamMemberRole.Pharmacist]: 'Pharmacist',
  [CareTeamMemberRole.Physician]: 'Physician',
  [CareTeamMemberRole.PrimaryCareProvider]: 'Primary Care Provider',
  [CareTeamMemberRole.Psychiatrist]: 'Psychiatrist',
  [CareTeamMemberRole.Psychologist]: 'Psychologist',
  [CareTeamMemberRole.Pulmonologist]: 'Pulmonologist',
  [CareTeamMemberRole.ResourceSpecialist]: 'Resource Specialist',
  [CareTeamMemberRole.SocialWorkCaseManager]: 'Social Work Case Manager',
};

export enum PractitionerRoleCode {
  MedicalDirector = 'medical-director',
}

export enum UserRole {
  Administrator = 'administrator',
  AdvancedPracticeProvider = 'advancedpracticeprovider',
  AdvancedPracticeProviderLead = 'advancedpracticeproviderlead',
  AdvancedPracticeProviderUrgentVisit = 'appurgentvisit',
  BHClinicalDirector = 'bhclinicaldirector',
  BHSocialWorker = 'bhsocialworker',
  BHTherapist = 'bhtherapist',
  BehavioralHealthAPP = 'behavioralhealthapp',
  Cardiologist = 'cardiologist',
  CareCoordinator = 'carecoordinator',
  ClinicalTeamAssistant = 'clinicalteamassistant',
  CommunityHealthWorker = 'communityhealthworker',
  Dietitian = 'dietitian',
  HealthServicesDirector = 'healthservicesdirector',
  HealthServicesSupervisor = 'healthservicessupervisor',
  ImaginePediatrician = 'imaginepediatrician',
  LicensedClinicalSocialWorker = 'licensedclinicalsocialworker',
  MobileUser = 'mobileuser',
  Nephrologist = 'nephrologist',
  NurseCareManager = 'nursecaremanager',
  Other = 'other',
  Outreach = 'outreach',
  OutreachSupervisor = 'outreachsupervisor',
  PatientEngagementAssociate1 = 'patientengagementassociate1',
  PatientEngagementAssociate2 = 'patientengagementassociate2',
  ProviderEngagementSpecialist = 'providerengagementspecialist',
  Paramedic = 'paramedic',
  PediatricRN = 'pediatricrn',
  Physician = 'physician',
  PhysicianLeader = 'physicianleader',
  PrimaryCareProvider = 'primarycareprovider',
  Psychiatrist = 'psychiatrist',
  Psychologist = 'psychologist',
  Pulmonologist = 'pulmonologist',
  SocialWorker = 'socialworker',
  TechSupport = 'techsupport',
  TransitionOfCareCoordinator = 'transitionofcarecoordinator',
}

export const UserRoleDisplay: Record<UserRole, string> = {
  [UserRole.Administrator]: 'Administrator',
  [UserRole.AdvancedPracticeProviderLead]: 'Advanced Practice Provider Lead',
  [UserRole.AdvancedPracticeProviderUrgentVisit]: 'APP (Urgent Visit)',
  [UserRole.AdvancedPracticeProvider]: 'Advanced Practice Provider',
  [UserRole.BHClinicalDirector]: 'BH Clinical Director',
  [UserRole.BHSocialWorker]: 'BH Social Worker',
  [UserRole.BHTherapist]: 'BH Therapist',
  [UserRole.BehavioralHealthAPP]: 'Behavioral Health APP',
  [UserRole.Cardiologist]: 'Cardiologist',
  [UserRole.CareCoordinator]: 'Care Coordinator',
  [UserRole.ClinicalTeamAssistant]: 'Clinical Team Assistant',
  [UserRole.CommunityHealthWorker]: 'Community Health Worker',
  [UserRole.Dietitian]: 'Dietitian',
  [UserRole.HealthServicesDirector]: 'Health Services Director',
  [UserRole.HealthServicesSupervisor]: 'Health Services Supervisor',
  [UserRole.ImaginePediatrician]: 'Imagine Pediatrician',
  [UserRole.LicensedClinicalSocialWorker]: 'Licensed Clinical Social Worker',
  [UserRole.MobileUser]: 'Mobile User',
  [UserRole.Nephrologist]: 'Nephrologist',
  [UserRole.NurseCareManager]: 'Nurse Care Manager',
  [UserRole.Other]: 'Other',
  [UserRole.OutreachSupervisor]: 'Outreach Supervisor',
  [UserRole.Outreach]: 'Outreach',
  [UserRole.PatientEngagementAssociate1]: 'Patient Engagement Associate 1',
  [UserRole.PatientEngagementAssociate2]: 'Patient Engagement Associate 2',
  [UserRole.ProviderEngagementSpecialist]: 'Provider Engagement Specialist',
  [UserRole.Paramedic]: 'Paramedic',
  [UserRole.PediatricRN]: 'Pediatric RN',
  [UserRole.PhysicianLeader]: 'Physician Leader',
  [UserRole.Physician]: 'Physician',
  [UserRole.PrimaryCareProvider]: 'Primary Care Provider',
  [UserRole.Psychiatrist]: 'Psychiatrist',
  [UserRole.Psychologist]: 'Psychologist',
  [UserRole.Pulmonologist]: 'Pulmonologist',
  [UserRole.SocialWorker]: 'Social Worker',
  [UserRole.TechSupport]: 'Tech Support',
  [UserRole.TransitionOfCareCoordinator]: 'Transition of Care Coordinator',
};

export const isUserRole = (role?: string): role is UserRole => !!role && !!UserRoleDisplay[role as UserRole];
