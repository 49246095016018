import { useForm, UseFormReturnType } from '@mantine/form';
import { ScheduleOnboardingVisitFormValues } from '../types';

export const useScheduleOnboardingVisitForm = (): UseFormReturnType<ScheduleOnboardingVisitFormValues> => {
  return useForm<ScheduleOnboardingVisitFormValues>({
    initialValues: {
      visitScheduled: null,
      notes: '',
    },
    validate: {
      visitScheduled: (value) => (value ? null : 'Please select an option'),
    },
  });
};
