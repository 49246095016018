import { ValueSet } from '@medplum/fhirtypes';
import { SYSTEM_URL, Concepts, codeSystemConcepts } from '../codeSystems/ImaginePediatrics';

const callDispositionCodes = codeSystemConcepts[Concepts.CallDisposition].concept;

export const OutreachCallDisposition: ValueSet = {
  resourceType: 'ValueSet',
  url: 'https://imaginepediatrics.org/fhir/ValueSet/outreach-call-disposition',
  identifier: [
    {
      system: 'https://imaginepediatrics.org/fhir/ValueSet',
      value: 'outreach-call-disposition',
    },
  ],
  version: '1.0.0',
  name: 'OutreachCallDisposition',
  title: 'Outreach Call Disposition',
  status: 'active',
  experimental: false,
  compose: {
    include: [
      {
        system: SYSTEM_URL,
        concept: callDispositionCodes!.map((c) => ({ code: c.code, display: c.display })),
      },
    ],
  },
};
