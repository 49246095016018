import { useState } from 'react';
import { createReference } from '@medplum/core';
import { Reference, Resource } from '@medplum/fhirtypes';
import { useMedplum, useMedplumProfile } from '@medplum/react';
import { buildOutreachAttemptCommunication } from 'imagine-dsl/services/outreachService';
import { SaveTouchpointFormValues } from './SaveTouchpointModal';
import { OutreachCallDisposition, extractValues } from 'const-utils';
import { outreachDispositionMap, outreachModalityMap } from 'const-utils/codeSystems/ImaginePediatrics';
import { format, isSameDay } from 'date-fns';

export const OutreachCallDispositionCodes = extractValues(OutreachCallDisposition);

type CallbackWithLoader = [() => Promise<Resource | undefined>, boolean];

export const useSaveTouchpoint = (
  patientId: string,
  formValues: SaveTouchpointFormValues,
  touchpointId?: string,
  basedOn?: Reference[],
): CallbackWithLoader => {
  const medplum = useMedplum();
  const profile = useMedplumProfile();

  const [loading, setLoading] = useState(false);

  const callDetails = formValues.details;

  const saveTouchpoint = async (): Promise<Resource | undefined> => {
    if (!profile) {
      return undefined;
    }

    if (!callDetails?.modality || !formValues.outcome) {
      return undefined;
    }
    const modality = callDetails.modality;
    const outcome = formValues.outcome;
    if (!outreachModalityMap[modality].dispositions.some((d) => d === outcome)) {
      return undefined;
    }

    const callbackStart = formValues.callbackDateTimeStart;
    const callbackEnd = formValues.callbackDateTimeEnd;

    let callbackTime: string | undefined;
    if (callbackStart && callbackEnd) {
      callbackTime = `${format(callbackStart, 'MM/dd/yyyy hh:mm aaa')} - ${
        isSameDay(callbackStart, callbackEnd)
          ? format(callbackEnd, 'hh:mm aaa')
          : format(callbackEnd, 'MM/dd/yyyy hh:mm aaa')
      }`;
    }
    setLoading(true);

    const communication = buildOutreachAttemptCommunication({
      patientRef: { reference: 'Patient/' + patientId },
      practitionerRef: createReference(profile),
      outcome: {
        code: outcome,
        display: outreachDispositionMap[outcome].display,
      },
      sent: callDetails.date!,
      modality,
      phoneNumber: callDetails.phoneNumber,
      callbackTime,
      callbackStart,
      callbackEnd,
      callbackTimezone: formValues.callbackTimezone,
      contactName: callDetails.contactName,
      notes: formValues.notes,
      basedOn,
    });

    let result: Resource;
    if (touchpointId) {
      result = await medplum.updateResource({ id: touchpointId, ...communication });
    } else {
      result = await medplum.createResource(communication);
    }
    setLoading(false);
    return result;
  };

  return [saveTouchpoint, loading];
};
