import { EngagementTeamPod } from '../codeSystems/ImaginePediatrics';

export const podToPostalCodesMap: Record<EngagementTeamPod, string[]> = {
  [EngagementTeamPod.PodA]: [
    '78358',
    '78381',
    '78382',
    '78353',
    '78355',
    '78520',
    '78521',
    '78522',
    '78523',
    '78526',
    '78535',
    '78550',
    '78551',
    '78552',
    '78553',
    '78559',
    '78566',
    '78567',
    '78568',
    '78575',
    '78578',
    '78583',
    '78586',
    '78592',
    '78593',
    '78597',
    '78341',
    '78349',
    '78357',
    '78376',
    '78384',
    '78501',
    '78502',
    '78503',
    '78504',
    '78505',
    '78516',
    '78537',
    '78538',
    '78539',
    '78540',
    '78541',
    '78542',
    '78543',
    '78549',
    '78557',
    '78558',
    '78560',
    '78562',
    '78563',
    '78565',
    '78570',
    '78572',
    '78573',
    '78574',
    '78576',
    '78577',
    '78579',
    '78589',
    '78595',
    '78596',
    '78599',
    '78360',
    '78361',
    '78332',
    '78342',
    '78372',
    '78375',
    '78338',
    '78385',
    '78363',
    '78379',
    '78330',
    '78339',
    '78343',
    '78347',
    '78351',
    '78373',
    '78380',
    '78401',
    '78402',
    '78403',
    '78404',
    '78405',
    '78406',
    '78407',
    '78408',
    '78409',
    '78410',
    '78411',
    '78412',
    '78413',
    '78414',
    '78415',
    '78416',
    '78417',
    '78418',
    '78419',
    '78426',
    '78427',
    '78460',
    '78463',
    '78465',
    '78466',
    '78467',
    '78468',
    '78469',
    '78480',
    '77950',
    '77990',
    '78340',
    '78377',
    '78393',
    '78335',
    '78336',
    '78352',
    '78359',
    '78362',
    '78368',
    '78370',
    '78374',
    '78387',
    '78390',
    '78536',
    '78545',
    '78547',
    '78548',
    '78582',
    '78584',
    '78585',
    '78588',
    '78591',
    '78561',
    '78569',
    '78580',
    '78590',
    '78594',
    '78598',
    '78067',
    '78076',
    '78564',
  ],
  [EngagementTeamPod.PodB]: [
    '75779',
    '75801',
    '75802',
    '75803',
    '75832',
    '75839',
    '75853',
    '75861',
    '75880',
    '75882',
    '75884',
    '75886',
    '76501',
    '76502',
    '76503',
    '76504',
    '76508',
    '76511',
    '76513',
    '76519',
    '76533',
    '76534',
    '76540',
    '76541',
    '76542',
    '76543',
    '76544',
    '76547',
    '76548',
    '76549',
    '76554',
    '76559',
    '76564',
    '76569',
    '76571',
    '76579',
    '76634',
    '76637',
    '76649',
    '76652',
    '76665',
    '76671',
    '76689',
    '76690',
    '75501',
    '75503',
    '75504',
    '75505',
    '75559',
    '75561',
    '75567',
    '75569',
    '75570',
    '75573',
    '75574',
    '75599',
    '76432',
    '76801',
    '76802',
    '76803',
    '76804',
    '76823',
    '76827',
    '76857',
    '76890',
    '75451',
    '75686',
    '75551',
    '75555',
    '75556',
    '75560',
    '75562',
    '75563',
    '75565',
    '75566',
    '75568',
    '75572',
    '75656',
    '75759',
    '75764',
    '75766',
    '75772',
    '75780',
    '75785',
    '75925',
    '75976',
    '76228',
    '76352',
    '76357',
    '76365',
    '76377',
    '75002',
    '75009',
    '75013',
    '75023',
    '75024',
    '75025',
    '75026',
    '75034',
    '75035',
    '75069',
    '75070',
    '75071',
    '75072',
    '75074',
    '75075',
    '75078',
    '75082',
    '75086',
    '75093',
    '75094',
    '75097',
    '75098',
    '75121',
    '75164',
    '75166',
    '75173',
    '75252',
    '75370',
    '75379',
    '75407',
    '75409',
    '75424',
    '75442',
    '75454',
    '75485',
    '76436',
    '76442',
    '76444',
    '76452',
    '76455',
    '76468',
    '76474',
    '76238',
    '76240',
    '76241',
    '76250',
    '76252',
    '76253',
    '76263',
    '76272',
    '76522',
    '76525',
    '76526',
    '76528',
    '76538',
    '76558',
    '76561',
    '76566',
    '76596',
    '76597',
    '76598',
    '76599',
    '75001',
    '75006',
    '75011',
    '75014',
    '75015',
    '75016',
    '75017',
    '75019',
    '75030',
    '75038',
    '75039',
    '75040',
    '75041',
    '75042',
    '75043',
    '75044',
    '75045',
    '75046',
    '75047',
    '75048',
    '75049',
    '75050',
    '75051',
    '75052',
    '75053',
    '75059',
    '75060',
    '75061',
    '75062',
    '75063',
    '75080',
    '75081',
    '75083',
    '75085',
    '75088',
    '75089',
    '75099',
    '75104',
    '75106',
    '75115',
    '75116',
    '75123',
    '75134',
    '75137',
    '75138',
    '75141',
    '75146',
    '75149',
    '75150',
    '75159',
    '75172',
    '75180',
    '75181',
    '75182',
    '75185',
    '75187',
    '75201',
    '75202',
    '75203',
    '75204',
    '75205',
    '75206',
    '75207',
    '75208',
    '75209',
    '75210',
    '75211',
    '75212',
    '75214',
    '75215',
    '75216',
    '75217',
    '75218',
    '75219',
    '75220',
    '75221',
    '75222',
    '75223',
    '75224',
    '75225',
    '75226',
    '75227',
    '75228',
    '75229',
    '75230',
    '75231',
    '75232',
    '75233',
    '75234',
    '75235',
    '75236',
    '75237',
    '75238',
    '75240',
    '75241',
    '75242',
    '75243',
    '75244',
    '75246',
    '75247',
    '75248',
    '75249',
    '75250',
    '75251',
    '75253',
    '75254',
    '75260',
    '75270',
    '75313',
    '75315',
    '75336',
    '75339',
    '75342',
    '75354',
    '75355',
    '75356',
    '75357',
    '75360',
    '75367',
    '75371',
    '75372',
    '75374',
    '75376',
    '75378',
    '75380',
    '75381',
    '75382',
    '75390',
    '75398',
    '75415',
    '75432',
    '75441',
    '75448',
    '75450',
    '75469',
    '75007',
    '75010',
    '75022',
    '75027',
    '75028',
    '75029',
    '75033',
    '75036',
    '75056',
    '75057',
    '75065',
    '75067',
    '75068',
    '75077',
    '75287',
    '76201',
    '76202',
    '76203',
    '76204',
    '76205',
    '76206',
    '76207',
    '76208',
    '76209',
    '76210',
    '76226',
    '76227',
    '76247',
    '76249',
    '76258',
    '76259',
    '76262',
    '76266',
    '75101',
    '75119',
    '75125',
    '75152',
    '75154',
    '75165',
    '75167',
    '75168',
    '76041',
    '76064',
    '76065',
    '76623',
    '76651',
    '76670',
    '76401',
    '76402',
    '76433',
    '76446',
    '76461',
    '76463',
    '76465',
    '76570',
    '76632',
    '76656',
    '76661',
    '76680',
    '76685',
    '75413',
    '75418',
    '75438',
    '75439',
    '75443',
    '75446',
    '75447',
    '75449',
    '75452',
    '75475',
    '75476',
    '75479',
    '75488',
    '75490',
    '75492',
    '75457',
    '75480',
    '75838',
    '75840',
    '75848',
    '75859',
    '75860',
    '76693',
    '75020',
    '75021',
    '75058',
    '75076',
    '75090',
    '75091',
    '75092',
    '75414',
    '75459',
    '75489',
    '75491',
    '75495',
    '76233',
    '76245',
    '76264',
    '76268',
    '76271',
    '76273',
    '75601',
    '75602',
    '75603',
    '75604',
    '75605',
    '75606',
    '75607',
    '75608',
    '75615',
    '75641',
    '75647',
    '75660',
    '75662',
    '75663',
    '75693',
    '76457',
    '76531',
    '76565',
    '75642',
    '75650',
    '75651',
    '75659',
    '75661',
    '75670',
    '75671',
    '75672',
    '75688',
    '75692',
    '75694',
    '75124',
    '75143',
    '75148',
    '75156',
    '75163',
    '75751',
    '75752',
    '75756',
    '75758',
    '75763',
    '75770',
    '75778',
    '75782',
    '76055',
    '76621',
    '76622',
    '76627',
    '76628',
    '76631',
    '76636',
    '76645',
    '76648',
    '76650',
    '76660',
    '76666',
    '76673',
    '76676',
    '76692',
    '76048',
    '76049',
    '76462',
    '76467',
    '76476',
    '75420',
    '75431',
    '75433',
    '75437',
    '75471',
    '75478',
    '75481',
    '75482',
    '75483',
    '75835',
    '75844',
    '75847',
    '75849',
    '75851',
    '75858',
    '75135',
    '75401',
    '75402',
    '75403',
    '75404',
    '75422',
    '75423',
    '75428',
    '75453',
    '75458',
    '75474',
    '75496',
    '76427',
    '76458',
    '76459',
    '76486',
    '76009',
    '76028',
    '76031',
    '76033',
    '76044',
    '76050',
    '76058',
    '76059',
    '76061',
    '76084',
    '76093',
    '76097',
    '75114',
    '75118',
    '75126',
    '75142',
    '75147',
    '75157',
    '75158',
    '75160',
    '75161',
    '75411',
    '75416',
    '75421',
    '75425',
    '75434',
    '75435',
    '75460',
    '75461',
    '75462',
    '75468',
    '75470',
    '75473',
    '75477',
    '75486',
    '75831',
    '75833',
    '75846',
    '75850',
    '75855',
    '77850',
    '77855',
    '77865',
    '77871',
    '76635',
    '76642',
    '76653',
    '76667',
    '76678',
    '76686',
    '76687',
    '75564',
    '75630',
    '75657',
    '76524',
    '76557',
    '76624',
    '76630',
    '76633',
    '76638',
    '76640',
    '76643',
    '76654',
    '76655',
    '76657',
    '76664',
    '76682',
    '76684',
    '76691',
    '76701',
    '76702',
    '76703',
    '76704',
    '76705',
    '76706',
    '76707',
    '76708',
    '76710',
    '76711',
    '76712',
    '76714',
    '76716',
    '76797',
    '76798',
    '76799',
    '76230',
    '76239',
    '76251',
    '76255',
    '76261',
    '76265',
    '76270',
    '75571',
    '75636',
    '75638',
    '75668',
    '75760',
    '75788',
    '75937',
    '75943',
    '75944',
    '75946',
    '75958',
    '75961',
    '75963',
    '75964',
    '75965',
    '75978',
    '75102',
    '75105',
    '75109',
    '75110',
    '75144',
    '75151',
    '75153',
    '75155',
    '76626',
    '76639',
    '76641',
    '76679',
    '76681',
    '76067',
    '76449',
    '76453',
    '76472',
    '76475',
    '76484',
    '75631',
    '75633',
    '75637',
    '75639',
    '75643',
    '75669',
    '75685',
    '76008',
    '76035',
    '76066',
    '76082',
    '76085',
    '76086',
    '76087',
    '76088',
    '76098',
    '76439',
    '76485',
    '76487',
    '76490',
    '75440',
    '75472',
    '75412',
    '75417',
    '75426',
    '75436',
    '75550',
    '75554',
    '75032',
    '75087',
    '75132',
    '75189',
    '75652',
    '75654',
    '75658',
    '75666',
    '75667',
    '75680',
    '75681',
    '75682',
    '75684',
    '75687',
    '75689',
    '75691',
    '75784',
    '75929',
    '75972',
    '75935',
    '75954',
    '75973',
    '75974',
    '75975',
    '75701',
    '75702',
    '75703',
    '75704',
    '75705',
    '75706',
    '75707',
    '75708',
    '75709',
    '75710',
    '75711',
    '75712',
    '75713',
    '75750',
    '75757',
    '75762',
    '75771',
    '75789',
    '75791',
    '75792',
    '75798',
    '75799',
    '76043',
    '76070',
    '76077',
    '75054',
    '75261',
    '76001',
    '76002',
    '76003',
    '76004',
    '76005',
    '76006',
    '76007',
    '76010',
    '76011',
    '76012',
    '76013',
    '76014',
    '76015',
    '76016',
    '76017',
    '76018',
    '76019',
    '76020',
    '76021',
    '76022',
    '76034',
    '76036',
    '76039',
    '76040',
    '76051',
    '76052',
    '76053',
    '76054',
    '76060',
    '76063',
    '76092',
    '76094',
    '76095',
    '76096',
    '76099',
    '76101',
    '76102',
    '76103',
    '76104',
    '76105',
    '76106',
    '76107',
    '76108',
    '76109',
    '76110',
    '76111',
    '76112',
    '76113',
    '76114',
    '76115',
    '76116',
    '76117',
    '76118',
    '76119',
    '76120',
    '76121',
    '76122',
    '76123',
    '76124',
    '76126',
    '76127',
    '76129',
    '76130',
    '76131',
    '76132',
    '76133',
    '76134',
    '76135',
    '76136',
    '76137',
    '76140',
    '76147',
    '76148',
    '76155',
    '76161',
    '76162',
    '76163',
    '76164',
    '76177',
    '76179',
    '76180',
    '76182',
    '76185',
    '76190',
    '76191',
    '76192',
    '76193',
    '76195',
    '76196',
    '76197',
    '76199',
    '76244',
    '76248',
    '75455',
    '75456',
    '75487',
    '75493',
    '75558',
    '75640',
    '75644',
    '75645',
    '75683',
    '75755',
    '75103',
    '75117',
    '75127',
    '75140',
    '75169',
    '75754',
    '75790',
    '76023',
    '76071',
    '76073',
    '76078',
    '76225',
    '76234',
    '76246',
    '76267',
    '76426',
    '76431',
    '75410',
    '75444',
    '75494',
    '75497',
    '75765',
    '75773',
    '75783',
  ],
  [EngagementTeamPod.PodC]: [
    '77001',
    '77002',
    '77003',
    '77004',
    '77005',
    '77006',
    '77007',
    '77008',
    '77009',
    '77010',
    '77011',
    '77012',
    '77013',
    '77014',
    '77015',
    '77016',
    '77017',
    '77018',
    '77019',
    '77020',
    '77021',
    '77022',
    '77023',
    '77024',
    '77025',
    '77026',
    '77027',
    '77028',
    '77029',
    '77030',
    '77031',
    '77032',
    '77033',
    '77034',
    '77035',
    '77036',
    '77037',
    '77038',
    '77039',
    '77040',
    '77041',
    '77042',
    '77043',
    '77044',
    '77045',
    '77046',
    '77047',
    '77048',
    '77049',
    '77050',
    '77051',
    '77052',
    '77054',
    '77055',
    '77056',
    '77057',
    '77058',
    '77059',
    '77060',
    '77061',
    '77062',
    '77063',
    '77064',
    '77065',
    '77066',
    '77067',
    '77068',
    '77069',
    '77070',
    '77071',
    '77072',
    '77073',
    '77074',
    '77075',
    '77076',
    '77077',
    '77078',
    '77079',
    '77080',
    '77081',
    '77082',
    '77083',
    '77084',
    '77085',
    '77086',
    '77087',
    '77088',
    '77089',
    '77090',
    '77091',
    '77092',
    '77093',
    '77094',
    '77095',
    '77096',
    '77098',
    '77099',
    '77201',
    '77204',
    '77205',
    '77206',
    '77207',
    '77208',
    '77210',
    '77215',
    '77217',
    '77218',
    '77219',
    '77220',
    '77221',
    '77222',
    '77223',
    '77224',
    '77225',
    '77226',
    '77227',
    '77228',
    '77230',
    '77231',
    '77233',
    '77234',
    '77235',
    '77236',
    '77237',
    '77238',
    '77240',
    '77241',
    '77242',
    '77243',
    '77244',
    '77245',
    '77248',
    '77249',
    '77251',
    '77252',
    '77253',
    '77254',
    '77255',
    '77256',
    '77257',
    '77258',
    '77259',
    '77261',
    '77262',
    '77263',
    '77265',
    '77266',
    '77267',
    '77268',
    '77269',
    '77270',
    '77271',
    '77272',
    '77273',
    '77274',
    '77275',
    '77277',
    '77279',
    '77280',
    '77282',
    '77284',
    '77287',
    '77288',
    '77289',
    '77290',
    '77291',
    '77292',
    '77293',
    '77315',
    '77325',
    '77336',
    '77338',
    '77339',
    '77345',
    '77346',
    '77347',
    '77373',
    '77375',
    '77377',
    '77379',
    '77383',
    '77388',
    '77389',
    '77391',
    '77396',
    '77401',
    '77402',
    '77410',
    '77411',
    '77413',
    '77429',
    '77433',
    '77447',
    '77449',
    '77450',
    '77491',
    '77492',
    '77493',
    '77501',
    '77502',
    '77503',
    '77504',
    '77505',
    '77506',
    '77507',
    '77508',
    '77520',
    '77521',
    '77522',
    '77530',
    '77532',
    '77536',
    '77547',
    '77562',
    '77571',
    '77572',
    '77586',
    '77587',
    '77598',
    '78610',
    '78619',
    '78620',
    '78640',
    '78666',
    '78667',
    '78676',
    '78737',
    '73301',
    '78617',
    '78645',
    '78652',
    '78653',
    '78660',
    '78669',
    '78691',
    '78701',
    '78702',
    '78703',
    '78704',
    '78705',
    '78708',
    '78709',
    '78710',
    '78711',
    '78712',
    '78713',
    '78714',
    '78715',
    '78716',
    '78718',
    '78719',
    '78720',
    '78721',
    '78722',
    '78723',
    '78724',
    '78725',
    '78726',
    '78727',
    '78728',
    '78730',
    '78731',
    '78732',
    '78733',
    '78734',
    '78735',
    '78736',
    '78738',
    '78739',
    '78741',
    '78742',
    '78744',
    '78745',
    '78746',
    '78747',
    '78748',
    '78749',
    '78750',
    '78751',
    '78752',
    '78753',
    '78754',
    '78755',
    '78756',
    '78757',
    '78758',
    '78759',
    '78760',
    '78761',
    '78762',
    '78763',
    '78764',
    '78765',
    '78766',
    '78767',
    '78768',
    '78772',
    '78774',
    '78778',
    '78779',
    '78799',
  ],
  [EngagementTeamPod.PodD]: [
    '77368',
    '75901',
    '75902',
    '75903',
    '75904',
    '75915',
    '75941',
    '75949',
    '75969',
    '75980',
    '78002',
    '78015',
    '78023',
    '78054',
    '78073',
    '78109',
    '78112',
    '78148',
    '78150',
    '78152',
    '78201',
    '78202',
    '78203',
    '78204',
    '78205',
    '78206',
    '78207',
    '78208',
    '78209',
    '78210',
    '78211',
    '78212',
    '78213',
    '78214',
    '78215',
    '78216',
    '78217',
    '78218',
    '78219',
    '78220',
    '78221',
    '78222',
    '78223',
    '78224',
    '78225',
    '78226',
    '78227',
    '78228',
    '78229',
    '78230',
    '78231',
    '78232',
    '78233',
    '78234',
    '78235',
    '78236',
    '78237',
    '78238',
    '78239',
    '78240',
    '78241',
    '78242',
    '78243',
    '78244',
    '78245',
    '78246',
    '78247',
    '78248',
    '78249',
    '78250',
    '78251',
    '78252',
    '78253',
    '78254',
    '78255',
    '78256',
    '78257',
    '78258',
    '78259',
    '78260',
    '78261',
    '78263',
    '78264',
    '78265',
    '78268',
    '78269',
    '78270',
    '78278',
    '78279',
    '78280',
    '78283',
    '78284',
    '78285',
    '78288',
    '78291',
    '78292',
    '78293',
    '78294',
    '78295',
    '78296',
    '78297',
    '78298',
    '78299',
    '77422',
    '77430',
    '77431',
    '77463',
    '77480',
    '77486',
    '77511',
    '77512',
    '77515',
    '77516',
    '77531',
    '77534',
    '77541',
    '77542',
    '77566',
    '77577',
    '77578',
    '77581',
    '77583',
    '77584',
    '77588',
    '77514',
    '77523',
    '77560',
    '77580',
    '77597',
    '77661',
    '77665',
    '77053',
    '77406',
    '77407',
    '77417',
    '77441',
    '77444',
    '77451',
    '77459',
    '77461',
    '77464',
    '77469',
    '77471',
    '77476',
    '77477',
    '77478',
    '77479',
    '77481',
    '77487',
    '77489',
    '77494',
    '77496',
    '77497',
    '77498',
    '77545',
    '77510',
    '77517',
    '77518',
    '77539',
    '77546',
    '77549',
    '77550',
    '77551',
    '77552',
    '77553',
    '77554',
    '77555',
    '77563',
    '77565',
    '77568',
    '77573',
    '77574',
    '77590',
    '77591',
    '77617',
    '77623',
    '77650',
    '77363',
    '77830',
    '77831',
    '77861',
    '77868',
    '77873',
    '77875',
    '77876',
    '77374',
    '77376',
    '77519',
    '77585',
    '77625',
    '77656',
    '77657',
    '77659',
    '77663',
    '75931',
    '75951',
    '75956',
    '77612',
    '77615',
    '77613',
    '77619',
    '77622',
    '77627',
    '77629',
    '77640',
    '77642',
    '77651',
    '77655',
    '77701',
    '77702',
    '77703',
    '77704',
    '77705',
    '77706',
    '77707',
    '77708',
    '77713',
    '77720',
    '77725',
    '77726',
    '77327',
    '77369',
    '77533',
    '77535',
    '77538',
    '77561',
    '77564',
    '77575',
    '77582',
    '75852',
    '77864',
    '77872',
    '77301',
    '77302',
    '77303',
    '77304',
    '77305',
    '77306',
    '77316',
    '77318',
    '77328',
    '77333',
    '77353',
    '77354',
    '77355',
    '77356',
    '77357',
    '77362',
    '77365',
    '77372',
    '77378',
    '77380',
    '77381',
    '77382',
    '77384',
    '77385',
    '77386',
    '77387',
    '77393',
    '75928',
    '75932',
    '75933',
    '75966',
    '75977',
    '77614',
    '77611',
    '77626',
    '77630',
    '77632',
    '77639',
    '77662',
    '77670',
    '75934',
    '75939',
    '75960',
    '77326',
    '77332',
    '77335',
    '77350',
    '77351',
    '77360',
    '77399',
    '77331',
    '77359',
    '77364',
    '77371',
    '75834',
    '75845',
    '75856',
    '75862',
    '75865',
    '75926',
    '75936',
    '75938',
    '75942',
    '75979',
    '75990',
    '77616',
    '77624',
    '77660',
    '77664',
    '77320',
    '77334',
    '77340',
    '77341',
    '77342',
    '77358',
    '77367',
    '77423',
    '77445',
    '77446',
    '77466',
    '77484',
    '77420',
    '77432',
    '77435',
    '77436',
    '77437',
    '77443',
    '77448',
    '77453',
    '77454',
    '77455',
    '77467',
    '77488',
  ],
  [EngagementTeamPod.PodE]: [
    '79714',
    '76351',
    '76366',
    '76370',
    '76379',
    '76389',
    '79019',
    '79094',
    '78008',
    '78011',
    '78012',
    '78026',
    '78050',
    '78052',
    '78064',
    '78065',
    '78069',
    '77418',
    '77452',
    '77473',
    '77474',
    '77485',
    '78931',
    '78933',
    '78944',
    '78950',
    '79324',
    '79344',
    '79347',
    '78003',
    '78055',
    '78063',
    '78883',
    '78884',
    '78885',
    '78602',
    '78612',
    '78621',
    '78650',
    '78659',
    '78662',
    '78953',
    '78957',
    '76380',
    '78102',
    '78104',
    '78125',
    '78142',
    '78145',
    '78146',
    '78162',
    '78389',
    '78391',
    '78606',
    '78635',
    '78636',
    '78663',
    '79738',
    '77801',
    '77802',
    '77803',
    '77805',
    '77806',
    '77807',
    '77808',
    '77840',
    '77841',
    '77842',
    '77843',
    '77844',
    '77845',
    '77862',
    '77866',
    '77881',
    '79830',
    '79832',
    '79834',
    '79842',
    '79852',
    '79255',
    '79257',
    '77836',
    '77838',
    '77852',
    '77863',
    '77878',
    '77879',
    '78605',
    '78608',
    '78611',
    '78654',
    '78616',
    '78622',
    '78632',
    '78644',
    '78648',
    '78655',
    '78656',
    '78661',
    '77978',
    '77979',
    '77982',
    '77983',
    '76443',
    '76469',
    '79504',
    '79510',
    '79039',
    '79068',
    '79097',
    '79027',
    '79043',
    '79063',
    '79085',
    '79201',
    '79314',
    '79346',
    '79379',
    '76933',
    '76945',
    '76949',
    '76953',
    '79506',
    '76828',
    '76834',
    '76845',
    '76873',
    '76878',
    '76882',
    '76884',
    '76888',
    '79519',
    '79538',
    '79095',
    '79230',
    '79251',
    '77412',
    '77434',
    '77442',
    '77460',
    '77470',
    '77475',
    '78934',
    '78935',
    '78943',
    '78951',
    '78962',
    '78070',
    '78130',
    '78131',
    '78132',
    '78133',
    '78135',
    '78163',
    '78266',
    '78623',
    '76837',
    '76855',
    '76862',
    '76866',
    '76955',
    '79223',
    '79248',
    '79731',
    '76943',
    '79322',
    '79343',
    '79357',
    '79847',
    '79855',
    '79022',
    '79051',
    '79087',
    '79331',
    '79377',
    '79713',
    '79025',
    '79045',
    '77954',
    '77967',
    '77989',
    '77994',
    '78141',
    '78164',
    '79220',
    '79229',
    '79243',
    '79370',
    '78827',
    '78830',
    '78834',
    '78836',
    '79226',
    '79237',
    '79240',
    '76435',
    '76437',
    '76445',
    '76448',
    '76454',
    '76466',
    '76470',
    '76471',
    '79741',
    '79758',
    '79759',
    '79760',
    '79761',
    '79762',
    '79763',
    '79764',
    '79765',
    '79766',
    '79768',
    '79769',
    '79776',
    '78880',
    '79821',
    '79835',
    '79836',
    '79838',
    '79849',
    '79853',
    '79901',
    '79902',
    '79903',
    '79904',
    '79905',
    '79906',
    '79907',
    '79908',
    '79910',
    '79911',
    '79912',
    '79913',
    '79914',
    '79915',
    '79916',
    '79917',
    '79918',
    '79922',
    '79923',
    '79924',
    '79925',
    '79926',
    '79927',
    '79928',
    '79929',
    '79930',
    '79931',
    '79932',
    '79934',
    '79935',
    '79936',
    '79937',
    '79938',
    '79940',
    '79941',
    '79942',
    '79943',
    '79944',
    '79945',
    '79946',
    '79947',
    '79948',
    '79949',
    '79950',
    '79951',
    '79952',
    '79953',
    '79954',
    '79955',
    '79968',
    '79995',
    '79996',
    '79997',
    '78932',
    '78938',
    '78940',
    '78941',
    '78945',
    '78949',
    '78952',
    '78954',
    '78956',
    '78960',
    '78963',
    '79534',
    '79543',
    '79546',
    '79560',
    '79221',
    '79231',
    '79235',
    '79241',
    '79258',
    '79227',
    '78005',
    '78017',
    '78057',
    '78061',
    '79342',
    '79359',
    '79360',
    '79330',
    '79356',
    '78618',
    '78624',
    '78631',
    '78671',
    '78675',
    '79739',
    '77960',
    '77963',
    '77993',
    '78107',
    '78122',
    '78140',
    '78159',
    '78604',
    '78614',
    '78629',
    '78677',
    '78959',
    '79002',
    '79054',
    '79057',
    '79065',
    '79066',
    '78108',
    '78115',
    '78123',
    '78124',
    '78154',
    '78155',
    '78156',
    '78638',
    '78670',
    '79021',
    '79032',
    '79041',
    '79072',
    '79073',
    '79250',
    '79311',
    '79233',
    '79239',
    '79245',
    '79259',
    '79261',
    '79040',
    '79081',
    '79225',
    '79252',
    '79044',
    '76388',
    '79521',
    '79539',
    '79544',
    '79547',
    '79548',
    '79014',
    '79313',
    '79336',
    '79353',
    '79358',
    '79367',
    '79372',
    '79380',
    '79511',
    '79720',
    '79733',
    '79748',
    '79837',
    '79839',
    '79851',
    '79007',
    '79008',
    '79036',
    '79062',
    '79078',
    '79080',
    '79083',
    '76930',
    '76941',
    '77957',
    '77961',
    '77962',
    '77969',
    '77970',
    '77971',
    '77991',
    '79734',
    '79854',
    '79501',
    '79503',
    '79520',
    '79525',
    '79533',
    '79553',
    '78062',
    '78111',
    '78113',
    '78116',
    '78117',
    '78118',
    '78119',
    '78144',
    '78151',
    '78004',
    '78006',
    '78013',
    '78027',
    '78074',
    '79518',
    '79528',
    '78010',
    '78024',
    '78025',
    '78028',
    '78029',
    '78058',
    '76849',
    '76854',
    '76883',
    '79236',
    '78832',
    '76363',
    '76371',
    '79505',
    '79529',
    '78001',
    '78014',
    '78019',
    '78021',
    '79031',
    '79064',
    '79082',
    '79312',
    '79326',
    '79339',
    '79369',
    '79371',
    '76539',
    '76550',
    '76824',
    '76853',
    '77964',
    '77975',
    '77984',
    '77986',
    '77987',
    '77995',
    '77853',
    '78942',
    '78946',
    '78947',
    '78948',
    '79005',
    '79024',
    '79034',
    '79046',
    '79056',
    '78022',
    '78060',
    '78071',
    '78075',
    '78350',
    '78383',
    '76831',
    '76885',
    '78607',
    '78609',
    '78639',
    '78643',
    '78657',
    '78672',
    '79754',
    '79329',
    '79350',
    '79363',
    '79364',
    '79366',
    '79382',
    '79401',
    '79402',
    '79403',
    '79404',
    '79406',
    '79407',
    '79408',
    '79409',
    '79410',
    '79411',
    '79412',
    '79413',
    '79414',
    '79415',
    '79416',
    '79423',
    '79424',
    '79430',
    '79452',
    '79453',
    '79464',
    '79490',
    '79491',
    '79493',
    '79499',
    '79351',
    '79373',
    '79381',
    '79383',
    '79749',
    '79782',
    '79783',
    '76820',
    '76842',
    '76856',
    '76869',
    '77404',
    '77414',
    '77415',
    '77419',
    '77428',
    '77440',
    '77456',
    '77457',
    '77458',
    '77465',
    '77468',
    '77482',
    '77483',
    '78852',
    '78853',
    '78860',
    '78877',
    '76825',
    '76836',
    '76852',
    '76858',
    '76872',
    '76887',
    '78007',
    '78072',
    '78009',
    '78016',
    '78039',
    '78056',
    '78059',
    '78066',
    '78850',
    '78861',
    '78886',
    '76841',
    '76848',
    '76859',
    '79701',
    '79702',
    '79703',
    '79704',
    '79705',
    '79706',
    '79707',
    '79708',
    '79710',
    '79711',
    '79712',
    '76518',
    '76520',
    '76523',
    '76556',
    '76567',
    '76577',
    '77857',
    '76844',
    '76864',
    '76870',
    '76880',
    '79512',
    '79532',
    '79565',
    '79013',
    '79018',
    '79029',
    '79086',
    '79234',
    '79244',
    '79256',
    '79535',
    '79537',
    '79545',
    '79556',
    '79033',
    '79070',
    '79093',
    '79001',
    '79010',
    '79092',
    '79098',
    '79009',
    '79035',
    '79053',
    '79325',
    '79730',
    '79735',
    '79740',
    '79743',
    '79744',
    '79781',
    '79012',
    '79058',
    '79101',
    '79102',
    '79103',
    '79104',
    '79105',
    '79106',
    '79107',
    '79108',
    '79111',
    '79116',
    '79117',
    '79120',
    '79124',
    '79159',
    '79843',
    '79845',
    '79846',
    '79015',
    '79016',
    '79091',
    '79109',
    '79110',
    '79114',
    '79118',
    '79119',
    '79121',
    '76932',
    '78828',
    '78833',
    '78873',
    '78879',
    '79718',
    '79770',
    '79772',
    '79780',
    '79785',
    '79059',
    '76629',
    '77837',
    '77856',
    '77859',
    '77867',
    '77870',
    '77882',
    '76821',
    '76865',
    '76875',
    '79567',
    '75930',
    '75948',
    '75959',
    '75968',
    '76832',
    '76871',
    '76877',
    '76936',
    '79516',
    '79517',
    '79526',
    '79527',
    '79549',
    '76430',
    '76464',
    '73960',
    '79084',
    '76424',
    '76429',
    '76951',
    '79502',
    '79540',
    '76874',
    '76950',
    '79042',
    '79052',
    '79088',
    '79508',
    '79530',
    '79536',
    '79541',
    '79561',
    '79562',
    '79563',
    '79566',
    '79601',
    '79602',
    '79603',
    '79604',
    '79605',
    '79606',
    '79607',
    '79608',
    '79697',
    '79698',
    '79699',
    '78851',
    '79848',
    '79316',
    '79345',
    '79376',
    '79378',
    '76483',
    '76491',
    '76861',
    '76886',
    '76901',
    '76902',
    '76903',
    '76904',
    '76905',
    '76906',
    '76908',
    '76909',
    '76934',
    '76935',
    '76937',
    '76939',
    '76940',
    '76957',
    '76958',
    '79752',
    '79755',
    '79778',
    '78801',
    '78802',
    '78838',
    '78870',
    '78881',
    '78837',
    '78840',
    '78841',
    '78842',
    '78843',
    '78847',
    '78871',
    '77901',
    '77902',
    '77903',
    '77904',
    '77905',
    '77951',
    '77968',
    '77973',
    '77974',
    '77976',
    '77977',
    '77988',
    '79719',
    '79742',
    '79756',
    '79777',
    '79788',
    '77426',
    '77833',
    '77834',
    '77835',
    '77880',
    '78040',
    '78041',
    '78042',
    '78043',
    '78044',
    '78045',
    '78046',
    '78344',
    '78369',
    '78371',
    '79003',
    '79011',
    '79061',
    '79079',
    '79096',
    '79096',
    '76301',
    '76302',
    '76305',
    '76306',
    '76307',
    '76308',
    '76309',
    '76310',
    '76311',
    '76354',
    '76360',
    '76367',
    '76369',
    '76364',
    '76373',
    '76384',
    '79247',
    '76527',
    '76530',
    '76537',
    '76573',
    '76574',
    '76578',
    '78613',
    '78615',
    '78626',
    '78627',
    '78628',
    '78630',
    '78633',
    '78634',
    '78641',
    '78642',
    '78646',
    '78651',
    '78664',
    '78665',
    '78673',
    '78674',
    '78680',
    '78681',
    '78682',
    '78683',
    '78717',
    '78729',
    '78101',
    '78114',
    '78121',
    '78143',
    '78147',
    '78160',
    '78161',
    '79745',
    '79789',
    '79323',
    '79355',
    '76372',
    '76374',
    '76450',
    '76460',
    '76481',
    '78829',
    '78839',
    '78872',
  ],
  [EngagementTeamPod.PodF]: [
    '31304',
    '31305',
    '31319',
    '31327',
    '31331',
    '31501',
    '31502',
    '31503',
    '31510',
    '31512',
    '31516',
    '31519',
    '31520',
    '31521',
    '31522',
    '31523',
    '31524',
    '31525',
    '31527',
    '31533',
    '31534',
    '31535',
    '31537',
    '31542',
    '31543',
    '31547',
    '31548',
    '31550',
    '31551',
    '31552',
    '31553',
    '31554',
    '31556',
    '31557',
    '31558',
    '31561',
    '31562',
    '31564',
    '31565',
    '31566',
    '31567',
    '31568',
    '31569',
    '31601',
    '31602',
    '31603',
    '31604',
    '31605',
    '31606',
    '31620',
    '31622',
    '31623',
    '31624',
    '31625',
    '31626',
    '31627',
    '31629',
    '31630',
    '31631',
    '31632',
    '31634',
    '31635',
    '31636',
    '31637',
    '31638',
    '31639',
    '31641',
    '31642',
    '31643',
    '31645',
    '31647',
    '31648',
    '31649',
    '31650',
    '31698',
    '31699',
    '31714',
    '31720',
    '31722',
    '31727',
    '31733',
    '31738',
    '31739',
    '31744',
    '31747',
    '31749',
    '31753',
    '31756',
    '31757',
    '31758',
    '31760',
    '31765',
    '31768',
    '31771',
    '31773',
    '31775',
    '31776',
    '31778',
    '31779',
    '31783',
    '31788',
    '31790',
    '31792',
    '31793',
    '31794',
    '31795',
    '31798',
    '31799',
    '32003',
    '32004',
    '32006',
    '32007',
    '32008',
    '32009',
    '32011',
    '32013',
    '32024',
    '32025',
    '32026',
    '32030',
    '32033',
    '32034',
    '32035',
    '32038',
    '32040',
    '32041',
    '32042',
    '32043',
    '32044',
    '32046',
    '32050',
    '32052',
    '32053',
    '32054',
    '32055',
    '32056',
    '32058',
    '32059',
    '32060',
    '32061',
    '32062',
    '32063',
    '32064',
    '32065',
    '32066',
    '32067',
    '32068',
    '32071',
    '32072',
    '32073',
    '32079',
    '32080',
    '32081',
    '32082',
    '32083',
    '32084',
    '32085',
    '32086',
    '32087',
    '32091',
    '32092',
    '32094',
    '32095',
    '32096',
    '32097',
    '32099',
    '32102',
    '32105',
    '32110',
    '32111',
    '32112',
    '32113',
    '32114',
    '32115',
    '32116',
    '32117',
    '32118',
    '32119',
    '32120',
    '32121',
    '32122',
    '32123',
    '32124',
    '32125',
    '32126',
    '32127',
    '32128',
    '32129',
    '32130',
    '32131',
    '32132',
    '32133',
    '32134',
    '32135',
    '32136',
    '32137',
    '32138',
    '32139',
    '32140',
    '32141',
    '32142',
    '32143',
    '32145',
    '32147',
    '32148',
    '32149',
    '32157',
    '32158',
    '32159',
    '32160',
    '32162',
    '32163',
    '32164',
    '32168',
    '32169',
    '32170',
    '32173',
    '32174',
    '32175',
    '32176',
    '32177',
    '32178',
    '32179',
    '32180',
    '32181',
    '32182',
    '32183',
    '32185',
    '32187',
    '32189',
    '32190',
    '32192',
    '32193',
    '32195',
    '32198',
    '32201',
    '32202',
    '32203',
    '32204',
    '32205',
    '32206',
    '32207',
    '32208',
    '32209',
    '32210',
    '32211',
    '32212',
    '32214',
    '32216',
    '32217',
    '32218',
    '32219',
    '32220',
    '32221',
    '32222',
    '32223',
    '32224',
    '32225',
    '32226',
    '32227',
    '32228',
    '32229',
    '32231',
    '32232',
    '32233',
    '32234',
    '32235',
    '32236',
    '32237',
    '32238',
    '32239',
    '32240',
    '32241',
    '32244',
    '32245',
    '32246',
    '32247',
    '32250',
    '32254',
    '32255',
    '32256',
    '32257',
    '32258',
    '32259',
    '32260',
    '32266',
    '32277',
    '32301',
    '32302',
    '32303',
    '32304',
    '32305',
    '32306',
    '32307',
    '32308',
    '32309',
    '32310',
    '32311',
    '32312',
    '32313',
    '32314',
    '32315',
    '32316',
    '32317',
    '32318',
    '32320',
    '32321',
    '32322',
    '32323',
    '32324',
    '32326',
    '32327',
    '32328',
    '32329',
    '32330',
    '32331',
    '32332',
    '32333',
    '32334',
    '32335',
    '32336',
    '32337',
    '32340',
    '32341',
    '32343',
    '32344',
    '32345',
    '32346',
    '32347',
    '32348',
    '32350',
    '32351',
    '32352',
    '32353',
    '32355',
    '32356',
    '32357',
    '32358',
    '32359',
    '32360',
    '32361',
    '32362',
    '32395',
    '32399',
    '32401',
    '32402',
    '32403',
    '32404',
    '32405',
    '32406',
    '32407',
    '32408',
    '32409',
    '32410',
    '32411',
    '32412',
    '32413',
    '32417',
    '32420',
    '32421',
    '32422',
    '32424',
    '32428',
    '32430',
    '32431',
    '32432',
    '32433',
    '32434',
    '32435',
    '32437',
    '32438',
    '32439',
    '32440',
    '32442',
    '32443',
    '32444',
    '32446',
    '32447',
    '32448',
    '32449',
    '32455',
    '32456',
    '32457',
    '32459',
    '32460',
    '32461',
    '32462',
    '32463',
    '32465',
    '32466',
    '32501',
    '32502',
    '32503',
    '32504',
    '32505',
    '32506',
    '32507',
    '32508',
    '32509',
    '32511',
    '32512',
    '32513',
    '32514',
    '32516',
    '32520',
    '32521',
    '32522',
    '32523',
    '32524',
    '32526',
    '32530',
    '32531',
    '32533',
    '32534',
    '32535',
    '32536',
    '32537',
    '32538',
    '32539',
    '32540',
    '32541',
    '32542',
    '32544',
    '32547',
    '32548',
    '32549',
    '32550',
    '32559',
    '32560',
    '32561',
    '32562',
    '32563',
    '32564',
    '32565',
    '32566',
    '32567',
    '32568',
    '32569',
    '32570',
    '32571',
    '32572',
    '32577',
    '32578',
    '32579',
    '32580',
    '32583',
    '32588',
    '32591',
    '32601',
    '32602',
    '32603',
    '32604',
    '32605',
    '32606',
    '32607',
    '32608',
    '32609',
    '32610',
    '32611',
    '32612',
    '32614',
    '32615',
    '32616',
    '32617',
    '32618',
    '32619',
    '32621',
    '32622',
    '32625',
    '32626',
    '32627',
    '32628',
    '32631',
    '32633',
    '32634',
    '32635',
    '32639',
    '32640',
    '32641',
    '32643',
    '32644',
    '32648',
    '32653',
    '32654',
    '32655',
    '32656',
    '32658',
    '32662',
    '32663',
    '32664',
    '32666',
    '32667',
    '32668',
    '32669',
    '32680',
    '32681',
    '32683',
    '32686',
    '32692',
    '32693',
    '32694',
    '32696',
    '32697',
    '32701',
    '32702',
    '32703',
    '32704',
    '32706',
    '32707',
    '32708',
    '32709',
    '32710',
    '32712',
    '32713',
    '32714',
    '32715',
    '32716',
    '32718',
    '32719',
    '32720',
    '32721',
    '32722',
    '32723',
    '32724',
    '32725',
    '32726',
    '32727',
    '32728',
    '32730',
    '32732',
    '32733',
    '32735',
    '32736',
    '32738',
    '32739',
    '32744',
    '32745',
    '32746',
    '32747',
    '32750',
    '32751',
    '32752',
    '32753',
    '32754',
    '32756',
    '32757',
    '32759',
    '32762',
    '32763',
    '32764',
    '32765',
    '32766',
    '32767',
    '32768',
    '32771',
    '32772',
    '32773',
    '32774',
    '32775',
    '32776',
    '32777',
    '32778',
    '32779',
    '32780',
    '32781',
    '32783',
    '32784',
    '32789',
    '32790',
    '32791',
    '32792',
    '32793',
    '32794',
    '32795',
    '32796',
    '32798',
    '32799',
    '32801',
    '32802',
    '32803',
    '32804',
    '32805',
    '32806',
    '32807',
    '32808',
    '32809',
    '32810',
    '32811',
    '32812',
    '32814',
    '32815',
    '32816',
    '32817',
    '32818',
    '32819',
    '32820',
    '32821',
    '32822',
    '32824',
    '32825',
    '32826',
    '32827',
    '32828',
    '32829',
    '32830',
    '32831',
    '32832',
    '32833',
    '32834',
    '32835',
    '32836',
    '32837',
    '32839',
    '32853',
    '32854',
    '32855',
    '32856',
    '32857',
    '32858',
    '32859',
    '32860',
    '32861',
    '32862',
    '32867',
    '32868',
    '32869',
    '32872',
    '32877',
    '32878',
    '32885',
    '32886',
    '32887',
    '32891',
    '32896',
    '32897',
    '32899',
    '32901',
    '32902',
    '32903',
    '32904',
    '32905',
    '32906',
    '32907',
    '32908',
    '32909',
    '32910',
    '32911',
    '32912',
    '32919',
    '32920',
    '32922',
    '32923',
    '32924',
    '32925',
    '32926',
    '32927',
    '32931',
    '32932',
    '32934',
    '32935',
    '32936',
    '32937',
    '32940',
    '32941',
    '32948',
    '32949',
    '32950',
    '32951',
    '32952',
    '32953',
    '32954',
    '32955',
    '32956',
    '32957',
    '32958',
    '32959',
    '32960',
    '32961',
    '32962',
    '32963',
    '32964',
    '32965',
    '32966',
    '32967',
    '32968',
    '32969',
    '32970',
    '32971',
    '32976',
    '32978',
    '33001',
    '33002',
    '33004',
    '33008',
    '33009',
    '33010',
    '33011',
    '33012',
    '33013',
    '33014',
    '33015',
    '33016',
    '33017',
    '33018',
    '33019',
    '33020',
    '33021',
    '33022',
    '33023',
    '33024',
    '33025',
    '33026',
    '33027',
    '33028',
    '33029',
    '33030',
    '33031',
    '33032',
    '33033',
    '33034',
    '33035',
    '33036',
    '33037',
    '33039',
    '33040',
    '33041',
    '33042',
    '33043',
    '33045',
    '33050',
    '33051',
    '33052',
    '33054',
    '33055',
    '33056',
    '33060',
    '33061',
    '33062',
    '33063',
    '33064',
    '33065',
    '33066',
    '33067',
    '33068',
    '33069',
    '33070',
    '33071',
    '33072',
    '33073',
    '33074',
    '33075',
    '33076',
    '33077',
    '33081',
    '33082',
    '33083',
    '33084',
    '33090',
    '33092',
    '33093',
    '33097',
    '33101',
    '33102',
    '33106',
    '33109',
    '33111',
    '33112',
    '33114',
    '33116',
    '33119',
    '33122',
    '33124',
    '33125',
    '33126',
    '33127',
    '33128',
    '33129',
    '33130',
    '33131',
    '33132',
    '33133',
    '33134',
    '33135',
    '33136',
    '33137',
    '33138',
    '33139',
    '33140',
    '33141',
    '33142',
    '33143',
    '33144',
    '33145',
    '33146',
    '33147',
    '33149',
    '33150',
    '33151',
    '33152',
    '33153',
    '33154',
    '33155',
    '33156',
    '33157',
    '33158',
    '33160',
    '33161',
    '33162',
    '33163',
    '33164',
    '33165',
    '33166',
    '33167',
    '33168',
    '33169',
    '33170',
    '33172',
    '33173',
    '33174',
    '33175',
    '33176',
    '33177',
    '33178',
    '33179',
    '33180',
    '33181',
    '33182',
    '33183',
    '33184',
    '33185',
    '33186',
    '33187',
    '33188',
    '33189',
    '33190',
    '33191',
    '33192',
    '33193',
    '33194',
    '33195',
    '33196',
    '33197',
    '33198',
    '33199',
    '33206',
    '33222',
    '33231',
    '33233',
    '33234',
    '33238',
    '33239',
    '33242',
    '33243',
    '33245',
    '33247',
    '33255',
    '33256',
    '33257',
    '33261',
    '33265',
    '33266',
    '33269',
    '33280',
    '33283',
    '33296',
    '33299',
    '33301',
    '33302',
    '33303',
    '33304',
    '33305',
    '33306',
    '33307',
    '33308',
    '33309',
    '33310',
    '33311',
    '33312',
    '33313',
    '33314',
    '33315',
    '33316',
    '33317',
    '33318',
    '33319',
    '33320',
    '33321',
    '33322',
    '33323',
    '33324',
    '33325',
    '33326',
    '33327',
    '33328',
    '33329',
    '33330',
    '33331',
    '33332',
    '33334',
    '33335',
    '33336',
    '33337',
    '33338',
    '33339',
    '33340',
    '33345',
    '33346',
    '33348',
    '33349',
    '33351',
    '33355',
    '33359',
    '33388',
    '33394',
    '33401',
    '33402',
    '33403',
    '33404',
    '33405',
    '33406',
    '33407',
    '33408',
    '33409',
    '33410',
    '33411',
    '33412',
    '33413',
    '33414',
    '33415',
    '33416',
    '33417',
    '33418',
    '33419',
    '33420',
    '33421',
    '33422',
    '33424',
    '33425',
    '33426',
    '33427',
    '33428',
    '33429',
    '33430',
    '33431',
    '33432',
    '33433',
    '33434',
    '33435',
    '33436',
    '33437',
    '33438',
    '33440',
    '33441',
    '33442',
    '33443',
    '33444',
    '33445',
    '33446',
    '33448',
    '33449',
    '33454',
    '33455',
    '33458',
    '33459',
    '33460',
    '33461',
    '33462',
    '33463',
    '33464',
    '33465',
    '33466',
    '33467',
    '33468',
    '33469',
    '33470',
    '33471',
    '33472',
    '33473',
    '33474',
    '33475',
    '33476',
    '33477',
    '33478',
    '33480',
    '33481',
    '33482',
    '33483',
    '33484',
    '33486',
    '33487',
    '33488',
    '33493',
    '33496',
    '33497',
    '33498',
    '33499',
    '33503',
    '33508',
    '33509',
    '33510',
    '33511',
    '33513',
    '33514',
    '33521',
    '33523',
    '33524',
    '33525',
    '33526',
    '33527',
    '33530',
    '33534',
    '33537',
    '33538',
    '33539',
    '33540',
    '33541',
    '33542',
    '33543',
    '33544',
    '33545',
    '33547',
    '33548',
    '33549',
    '33550',
    '33556',
    '33558',
    '33559',
    '33563',
    '33564',
    '33565',
    '33566',
    '33567',
    '33568',
    '33569',
    '33570',
    '33571',
    '33572',
    '33573',
    '33574',
    '33575',
    '33576',
    '33578',
    '33579',
    '33583',
    '33584',
    '33585',
    '33586',
    '33587',
    '33592',
    '33593',
    '33594',
    '33595',
    '33596',
    '33597',
    '33598',
    '33601',
    '33602',
    '33603',
    '33604',
    '33605',
    '33606',
    '33607',
    '33608',
    '33609',
    '33610',
    '33611',
    '33612',
    '33613',
    '33614',
    '33615',
    '33616',
    '33617',
    '33618',
    '33619',
    '33620',
    '33621',
    '33622',
    '33623',
    '33624',
    '33625',
    '33626',
    '33629',
    '33630',
    '33631',
    '33633',
    '33634',
    '33635',
    '33637',
    '33646',
    '33647',
    '33650',
    '33655',
    '33660',
    '33661',
    '33662',
    '33663',
    '33664',
    '33672',
    '33673',
    '33674',
    '33675',
    '33677',
    '33679',
    '33680',
    '33681',
    '33682',
    '33684',
    '33685',
    '33686',
    '33687',
    '33688',
    '33689',
    '33694',
    '33701',
    '33702',
    '33703',
    '33704',
    '33705',
    '33706',
    '33707',
    '33708',
    '33709',
    '33710',
    '33711',
    '33712',
    '33713',
    '33714',
    '33715',
    '33716',
    '33729',
    '33730',
    '33731',
    '33732',
    '33733',
    '33734',
    '33736',
    '33738',
    '33740',
    '33741',
    '33742',
    '33743',
    '33744',
    '33747',
    '33755',
    '33756',
    '33757',
    '33758',
    '33759',
    '33760',
    '33761',
    '33762',
    '33763',
    '33764',
    '33765',
    '33766',
    '33767',
    '33769',
    '33770',
    '33771',
    '33772',
    '33773',
    '33774',
    '33775',
    '33776',
    '33777',
    '33778',
    '33779',
    '33780',
    '33781',
    '33782',
    '33784',
    '33785',
    '33786',
    '33801',
    '33802',
    '33803',
    '33804',
    '33805',
    '33806',
    '33807',
    '33809',
    '33810',
    '33811',
    '33812',
    '33813',
    '33815',
    '33820',
    '33823',
    '33825',
    '33826',
    '33827',
    '33830',
    '33831',
    '33834',
    '33835',
    '33836',
    '33837',
    '33838',
    '33839',
    '33840',
    '33841',
    '33843',
    '33844',
    '33845',
    '33846',
    '33847',
    '33848',
    '33849',
    '33850',
    '33851',
    '33852',
    '33853',
    '33854',
    '33855',
    '33856',
    '33857',
    '33858',
    '33859',
    '33860',
    '33862',
    '33863',
    '33865',
    '33867',
    '33868',
    '33870',
    '33871',
    '33872',
    '33873',
    '33875',
    '33876',
    '33877',
    '33880',
    '33881',
    '33882',
    '33883',
    '33884',
    '33885',
    '33888',
    '33890',
    '33896',
    '33897',
    '33898',
    '33901',
    '33902',
    '33903',
    '33904',
    '33905',
    '33906',
    '33907',
    '33908',
    '33909',
    '33910',
    '33911',
    '33912',
    '33913',
    '33914',
    '33915',
    '33916',
    '33917',
    '33918',
    '33919',
    '33920',
    '33921',
    '33922',
    '33924',
    '33927',
    '33928',
    '33929',
    '33930',
    '33931',
    '33932',
    '33935',
    '33936',
    '33938',
    '33944',
    '33945',
    '33946',
    '33947',
    '33948',
    '33949',
    '33950',
    '33951',
    '33952',
    '33953',
    '33954',
    '33955',
    '33956',
    '33957',
    '33960',
    '33965',
    '33966',
    '33967',
    '33970',
    '33971',
    '33972',
    '33973',
    '33974',
    '33975',
    '33976',
    '33980',
    '33981',
    '33982',
    '33983',
    '33990',
    '33991',
    '33993',
    '33994',
    '34101',
    '34102',
    '34103',
    '34104',
    '34105',
    '34106',
    '34107',
    '34108',
    '34109',
    '34110',
    '34112',
    '34113',
    '34114',
    '34116',
    '34117',
    '34119',
    '34120',
    '34133',
    '34134',
    '34135',
    '34136',
    '34137',
    '34138',
    '34139',
    '34140',
    '34141',
    '34142',
    '34143',
    '34145',
    '34146',
    '34201',
    '34202',
    '34203',
    '34204',
    '34205',
    '34206',
    '34207',
    '34208',
    '34209',
    '34210',
    '34211',
    '34212',
    '34215',
    '34216',
    '34217',
    '34218',
    '34219',
    '34220',
    '34221',
    '34222',
    '34223',
    '34224',
    '34228',
    '34229',
    '34230',
    '34231',
    '34232',
    '34233',
    '34234',
    '34235',
    '34236',
    '34237',
    '34238',
    '34239',
    '34240',
    '34241',
    '34242',
    '34243',
    '34249',
    '34250',
    '34251',
    '34260',
    '34264',
    '34265',
    '34266',
    '34267',
    '34268',
    '34269',
    '34270',
    '34272',
    '34274',
    '34275',
    '34276',
    '34277',
    '34278',
    '34280',
    '34281',
    '34282',
    '34284',
    '34285',
    '34286',
    '34287',
    '34288',
    '34289',
    '34290',
    '34291',
    '34292',
    '34293',
    '34295',
    '34420',
    '34421',
    '34423',
    '34428',
    '34429',
    '34430',
    '34431',
    '34432',
    '34433',
    '34434',
    '34436',
    '34441',
    '34442',
    '34445',
    '34446',
    '34447',
    '34448',
    '34449',
    '34450',
    '34451',
    '34452',
    '34453',
    '34460',
    '34461',
    '34464',
    '34465',
    '34470',
    '34471',
    '34472',
    '34473',
    '34474',
    '34475',
    '34476',
    '34477',
    '34478',
    '34479',
    '34480',
    '34481',
    '34482',
    '34483',
    '34484',
    '34487',
    '34488',
    '34489',
    '34491',
    '34492',
    '34498',
    '34601',
    '34602',
    '34603',
    '34604',
    '34605',
    '34606',
    '34607',
    '34608',
    '34609',
    '34610',
    '34611',
    '34613',
    '34614',
    '34636',
    '34637',
    '34638',
    '34639',
    '34652',
    '34653',
    '34654',
    '34655',
    '34656',
    '34660',
    '34661',
    '34667',
    '34668',
    '34669',
    '34673',
    '34674',
    '34677',
    '34679',
    '34680',
    '34681',
    '34682',
    '34683',
    '34684',
    '34685',
    '34688',
    '34689',
    '34690',
    '34691',
    '34692',
    '34695',
    '34697',
    '34698',
    '34705',
    '34711',
    '34712',
    '34713',
    '34714',
    '34715',
    '34729',
    '34731',
    '34734',
    '34736',
    '34737',
    '34739',
    '34740',
    '34741',
    '34742',
    '34743',
    '34744',
    '34745',
    '34746',
    '34747',
    '34748',
    '34749',
    '34753',
    '34755',
    '34756',
    '34758',
    '34759',
    '34760',
    '34761',
    '34762',
    '34769',
    '34770',
    '34771',
    '34772',
    '34773',
    '34777',
    '34778',
    '34785',
    '34786',
    '34787',
    '34788',
    '34789',
    '34797',
    '34945',
    '34946',
    '34947',
    '34948',
    '34949',
    '34950',
    '34951',
    '34952',
    '34953',
    '34954',
    '34956',
    '34957',
    '34958',
    '34972',
    '34973',
    '34974',
    '34979',
    '34981',
    '34982',
    '34983',
    '34984',
    '34985',
    '34986',
    '34987',
    '34988',
    '34990',
    '34991',
    '34992',
    '34994',
    '34995',
    '34996',
    '34997',
    '36028',
    '36038',
    '36420',
    '36421',
    '36426',
    '36427',
    '36432',
    '36441',
    '36442',
    '36454',
    '36455',
    '36473',
    '36474',
    '36476',
    '36483',
    '36502',
    '36503',
    '36504',
    '36543',
    '36549',
    '36574',
    '39815',
    '39817',
    '39818',
    '39819',
    '39825',
    '39827',
    '39828',
    '39829',
    '39834',
    '39837',
    '39852',
    '39897',
  ],
};

export const postalCodeToPod = Object.entries(podToPostalCodesMap).reduce<Record<string, EngagementTeamPod>>(
  (acc, [pod, postalCodes]) => {
    postalCodes.forEach((postalCode) => {
      acc[postalCode] = pod as EngagementTeamPod;
    });
    return acc;
  },
  {},
);
