export const enum ContactType {
  DurablePOA = 'durable-poa',
  EmergencyContact = 'emergency-contact',
  HealthCareProxy = 'health-care-proxy',
  HealthCarePOA = 'health-care-poa',
  LegalGuardian = 'legal-guardian',
  PatientDesignatedRepresentative = 'pacient-designated-representative',
  Self = 'self',
  Other = 'other',
}
