import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Text, Container, Loader, Paper, Table, Group, Menu, ActionIcon, Badge, Flex, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { UserRole } from 'const-utils';
import { useMedplum } from '@medplum/react';
import { useUserSession } from '@/components/shared/UserSessionContext';
import { IconDots, IconSearch } from '@tabler/icons-react';
import { UserActions } from './UserActions';
import { useDebouncedState } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { logError } from '@/errors';
import { UpsertForm } from './UpsertForm';
import { useExportUsersCsvLazyQuery, useGetUserPageQuery } from 'imagine-gql/client';
import { imagineClient } from '@/hooks/useImagineApolloClient';
import { isDefined } from 'imagine-dsl/utils/lists';
import { formatUserAccess } from 'imagine-dsl/utils/user';
import { PageSkeleton } from '@/design-system/PageSkeleton';
import { Pagination } from '@/design-system/Pagination';
import { usePagination } from '@/design-system/hooks/usePagination';

const PER_PAGE = 10;

const useCsvDownload = () => {
  const [query] = useExportUsersCsvLazyQuery({
    client: imagineClient,
  });

  const [downloading, setDownloading] = useState(false);
  const downloadCsv = useCallback(async () => {
    setDownloading(true);
    const { data, error } = await query();
    if (data) {
      const url = window.URL.createObjectURL(new Blob([atob(data.exportUsers.csv)], { type: 'text/csv' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'users.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (error) {
      notifications.show({
        message: error.message,
      });
    }
    setDownloading(false);
  }, [query]);

  return { downloadCsv, downloading };
};

export const UsersPage: React.FC = () => {
  const medplum = useMedplum();
  const { roles } = useUserSession();
  const [nameFilter, setNameFilter] = useDebouncedState<string>('', 200);
  const [onboarding, setOnboarding] = useState(false);
  const [editingUserId, setEditingUserId] = useState<string>();

  const { downloadCsv, downloading } = useCsvDownload();
  const { changePage, currentPage } = usePagination({});

  const isPermitted =
    useMemo(() => roles.some((r) => r.code === UserRole.TechSupport), [roles]) || medplum.isProjectAdmin();

  const { data, error, loading, refetch } = useGetUserPageQuery({
    client: imagineClient,
    variables: {
      page: currentPage - 1,
      count: PER_PAGE,
      name: nameFilter,
    },
  });

  useEffect(() => {
    if (error) {
      logError(error);
      notifications.show({
        message: error.message,
        color: 'status-error',
      });
    }
  }, [error]);

  const editingUser = useMemo(() => {
    return data?.listUsers?.users.find((user) => user.id === editingUserId);
  }, [data, editingUserId]);

  const searchForm = useForm({
    initialValues: {
      name: '',
    },
    onValuesChange: (e) => {
      setNameFilter(e.name);
      changePage(1);
    },
  });

  if (!isPermitted) {
    return (
      <Container m="xl" size="xl" w="100%" mt="xl">
        <Paper radius="lg" shadow="xs" p="xl">
          <Text size="lg">You are not permitted to view this page</Text>
        </Paper>
      </Container>
    );
  }

  return (
    <PageSkeleton>
      <Group justify="space-between">
        <TextInput
          leftSection={<IconSearch size={18} />}
          placeholder="Search by name"
          {...searchForm.getInputProps('name')}
        />
        <Menu>
          <Menu.Target>
            <ActionIcon>
              <IconDots />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={() => setOnboarding(true)}>Onboard new user</Menu.Item>
            <Menu.Item onClick={() => downloadCsv()} disabled={downloading}>
              Export list
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      {loading && <Loader />}
      {onboarding && (
        <UpsertForm title="Onboard Care Hub user" onClose={() => setOnboarding(false)} onSuccess={refetch} />
      )}
      {editingUser && (
        <UpsertForm
          title="Edit Care Hub user"
          user={editingUser}
          onClose={() => setEditingUserId(undefined)}
          onSuccess={refetch}
        />
      )}
      {data?.listUsers?.users && !loading && (
        <Table highlightOnHover horizontalSpacing="xs" verticalSpacing="xs">
          <Table.Thead>
            <Table.Tr>
              <Table.Th w="400px">User</Table.Th>
              <Table.Th>Role(s)</Table.Th>
              <Table.Th>Access</Table.Th>
              <Table.Th>Admin?</Table.Th>
              <Table.Th>Read only?</Table.Th>
              <Table.Th />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {data.listUsers.users.map((user) => (
              <Table.Tr key={user.id}>
                <Table.Td>
                  <Text>
                    {user.firstName} {user.lastName}
                  </Text>
                  <Text c="gray" size="xs">
                    {user.email}
                  </Text>
                </Table.Td>
                <Table.Td>
                  {user.roles?.map((role, idx) => (
                    <Text key={`${user.id}:role:${idx}`} size="xs">
                      {role.market + ': ' + role.role}
                    </Text>
                  ))}
                </Table.Td>
                <Table.Td>
                  {user.access?.filter(isDefined).map((access, idx) => (
                    <Text key={`${user.id}:access:${idx}`} size="xs">
                      {formatUserAccess(access)}
                    </Text>
                  ))}
                </Table.Td>
                <Table.Td>{user.admin && <Badge variant="outline">Admin</Badge>}</Table.Td>
                <Table.Td>{user.readOnly && <Badge variant="outline">Read Only</Badge>}</Table.Td>
                <Table.Td>
                  <UserActions
                    practitionerId={user.id}
                    removeUser={() => refetch()}
                    editUser={() => setEditingUserId(user.id)}
                  />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      )}
      {data?.listUsers.users?.length !== 0 ? (
        <Pagination perPage={PER_PAGE} count={data?.listUsers.total || 0} />
      ) : (
        <Flex align="center" justify="center" style={{ height: '200px' }}>
          <Text c="gray">No users found</Text>
        </Flex>
      )}
    </PageSkeleton>
  );
};
