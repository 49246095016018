import { Button, Group, Modal, Select, Stack, Text, TextInput, ThemeIcon } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DisengagementReason, ProgramStatus } from 'const-utils';
import { GetBasicPatientDocument, GetPatientDocument, GetPatientPanelDocument } from 'medplum-gql';
import { getProgramStatusFromEpisodeOfCareStatus } from 'imagine-dsl/utils/patient';
import React, { useEffect, useState } from 'react';
import programStatusDataRetention from '../programStatus/ProgramStatusDataRetention';
import { useMedplum } from '@medplum/react';
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';
import { IconAlertTriangleFilled } from '@tabler/icons-react';
import { logError } from '@/errors';
import { capitalize } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { EpisodeOfCare } from '@medplum/fhirtypes';
import { useQueryClient } from '@tanstack/react-query';
import { PatientWithMeta } from 'imagine-dsl/utils/types/patient';

interface ProgramStatusEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  patient: PatientWithMeta;
  episodeOfCare: EpisodeOfCare;
}

const [disengagementReasonData] =
  DisengagementReason?.compose?.include?.map((c) => c.concept?.map((d) => d.display)) ?? [];

export const ProgramStatusEditModal = ({ isOpen, onClose, patient, episodeOfCare }: ProgramStatusEditModalProps) => {
  const medplum = useMedplum();
  const [loading, setLoading] = useState(false);
  const [programStatusData, setProgramStatusData] = useState([ProgramStatus.Onboarded, ProgramStatus.Disenrolled]);
  const apolloClient = useApolloClient();
  const tanstackClient = useQueryClient();
  const flags = useFeatureFlags();

  const episodeOfCareStatus = episodeOfCare.status || '';
  const programStatus = getProgramStatusFromEpisodeOfCareStatus(episodeOfCareStatus);
  const programStatusDate = new Date().toISOString().split('T')[0];

  useEffect(() => {
    let newProgramStatusData = [ProgramStatus.Onboarded, ProgramStatus.Disenrolled];

    (async () => {
      if (!patient.id) {
        return;
      }

      const consents = await medplum.search('Consent', { patient: patient.id });
      const isActiveConsentFound = consents?.entry?.some((entry) => entry?.resource?.status === 'active');
      if (programStatus === ProgramStatus.Disenrolled) {
        newProgramStatusData = Object.values(ProgramStatus);

        if (!isActiveConsentFound) {
          newProgramStatusData = newProgramStatusData.filter((status) => status === ProgramStatus.NotEnrolled);
        } else {
          newProgramStatusData = newProgramStatusData.filter((status) => status !== ProgramStatus.NotEnrolled);
        }

        setProgramStatusData(newProgramStatusData);
      }
    })().catch((error) => {
      logError(error);
    });
  }, [patient.id, medplum, programStatus]);

  const form = useForm({
    initialValues: {
      status: '',
      reason: '',
      date: programStatusDate,
    },
  });

  const confirmationModal = async (status: ProgramStatus, message: string) =>
    modals.openConfirmModal({
      title: (
        <>
          <ThemeIcon mb={10} color="status-warn" variant="transparent">
            <IconAlertTriangleFilled size="40" />
          </ThemeIcon>
          <Text size="sm" fw={700} c="imagine-green">
            Are you sure you want to change program status to "{capitalize(status)}"?
          </Text>
        </>
      ),
      children: (
        <Text>
          {message}{' '}
          <Text fw={700} component="span">
            This can't be undone.
          </Text>
        </Text>
      ),
      centered: true,
      radius: 'lg',
      size: 'lg',
      labels: { confirm: `Yes, change program status`, cancel: 'Cancel' },
      onConfirm: () =>
        handleSubmit({
          status,
          reason: form.values.reason,
          date: form.values.date,
        }).catch(logError),
    });

  const submitHandler = (values: { status: string; reason: string; date: string }) => {
    if (values.status === ProgramStatus.NotEnrolled.toString()) {
      confirmationModal(
        ProgramStatus.NotEnrolled,
        'Changing this patient\'s status to "Not Enrolled" will require them to go through Outreach in order to be re-enrolled.',
      ).catch(logError);
    } else if (values.status === ProgramStatus.Disenrolled.toString() && values.reason === 'Deceased') {
      confirmationModal(
        ProgramStatus.Disenrolled,
        'This patient\'s status is being changed to "Disenrolled" with reason "Deceased".',
      ).catch(logError);
    } else {
      handleSubmit(values).catch((e) => logError(e));
    }
  };

  const handleSubmit = async (values: { status: string; reason: string; date: string }) => {
    setLoading(true);

    try {
      await programStatusDataRetention(
        values.status as ProgramStatus,
        values.reason,
        episodeOfCare,
        values.date,
        patient.id!,
        medplum,
      );
      await apolloClient.refetchQueries({
        include: [GetPatientPanelDocument, GetPatientDocument, GetBasicPatientDocument],
      });
      await tanstackClient.invalidateQueries([`episodeOfCare-${patient.id}`]);
      onClose();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error instanceof Error ? error.message : String(error),
        color: 'status-error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title="Edit patient status"
      size="lg"
      radius="lg"
      c="imagine-green"
      centered
    >
      <form onSubmit={form.onSubmit(submitHandler)}>
        <Stack gap="md" c="black">
          <Text fz="xs" mb="sm">
            Select the status you would like to assign
          </Text>
          <Select
            label="Status"
            {...form.getInputProps('status')}
            clearable
            searchable={false}
            data={programStatusData}
          />
          {form.values.status === ProgramStatus.Disenrolled.toString() && (
            <Select
              data={disengagementReasonData as string[]}
              label="Reason"
              {...form.getInputProps('reason')}
              error={
                form.values.status === ProgramStatus.Disenrolled.toString() && !form.values.reason
                  ? 'This field is required'
                  : undefined
              }
              withAsterisk
            />
          )}
          <TextInput
            required
            label="Date"
            type="date"
            mb="md"
            {...form.getInputProps('date')}
            min={flags.ProgramStatusDateSelection ? programStatusDate : undefined}
          />

          <Group mt="md" justify="flex-end">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" disabled={!form.isDirty('status')} loading={loading}>
              Save changes
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};
