import React from 'react';
import { SelectInput, SelectInputProps } from '../../helpers/SelectInput';
import { Flex, Group, Radio, Text } from '@mantine/core';

interface LanguageData {
  code: string | undefined;
  display: string | undefined;
}

interface LanguagePreferenceFieldProps {
  languagePreferenceProps: Omit<SelectInputProps, 'label' | 'data'>;
  requiresInterpreterProps: Omit<SelectInputProps, 'label' | 'data'>;
  preferredLanguageData: LanguageData[];
}

const LanguagePreference = ({
  languagePreferenceProps,
  requiresInterpreterProps,
  preferredLanguageData,
}: LanguagePreferenceFieldProps): JSX.Element => {
  return (
    <Flex justify="space-between" gap="sm">
      <SelectInput
        data={preferredLanguageData.map((d) => d?.display).filter((item): item is string => Boolean(item))}
        label="Preferred language"
        {...languagePreferenceProps}
      />

      <Radio.Group label={<Text mb="xs">Requires interpreter</Text>} {...requiresInterpreterProps}>
        <Group>
          <Radio label="Yes" value="yes" />
          <Radio label="No" value="no" />
        </Group>
      </Radio.Group>
    </Flex>
  );
};

export default LanguagePreference;
