import React from 'react';
import { Loader, Stack, Text } from '@mantine/core';
import { GetPatientQuery, Patient } from 'medplum-gql';
import { LinkPatientRows } from './LinkPatientRows';
import { LinkPatientProvider } from './LinkPatientContext';
import { ApolloQueryResult } from '@apollo/client';
import { useRecommendedLinks } from '@/hooks/useRecommendedLinks';

interface RecommendedLinksProps {
  patient: Patient;
  refetchPatient: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

export function RecommendedLinks({ patient, refetchPatient }: RecommendedLinksProps): JSX.Element {
  const { data: recommendedLinks = [], loading } = useRecommendedLinks(patient);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Text>
        There are{' '}
        <Text component="span" fw="bold">
          {recommendedLinks.length}
        </Text>{' '}
        patient(s) who may be associated with this patient.
      </Text>
      <Stack p="md" gap={0}>
        <LinkPatientProvider refetchPatient={refetchPatient}>
          <LinkPatientRows primaryPatient={patient} linkPatients={recommendedLinks} />
        </LinkPatientProvider>
      </Stack>
    </>
  );
}
