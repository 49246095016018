import React from 'react';
import { List, Stack, Text } from '@mantine/core';
import { formatCoding, getTypedPropertyValue, TypedValue } from '@medplum/core';
import { QuestionnaireItem, QuestionnaireResponseItem, QuestionnaireResponseItemAnswer } from '@medplum/fhirtypes';
import { QuestionnaireItemType } from 'const-utils/codeSystems/ImaginePediatrics';
import { TranslationCodeMapping, translatedDisplayByCode } from 'imagine-dsl/services/questionnaireService';

export interface readOnlyQuestionnaireFormItemProps {
  readonly item: QuestionnaireItem;
  readonly index: number;
  readonly response: QuestionnaireResponseItem;
  translatedItemMapping: TranslationCodeMapping[];
}

export function ReadOnlyQuestionnaireFormItem(props: readOnlyQuestionnaireFormItemProps): JSX.Element | null {
  const { item, response, translatedItemMapping } = props;

  const type = item.type;
  if (!type) {
    return null;
  }

  const name = item.linkId;
  if (!name) {
    return null;
  }

  const initial = item.initial && item.initial.length > 0 ? item.initial[0] : undefined;
  const defaultValue =
    getCurrentAnswer(response, props.index) ??
    getTypedPropertyValue({ type: 'QuestionnaireItemInitial', value: initial }, 'value');

  const translate = (value: TypedValue): string | undefined => {
    if (!value?.value?.code || translatedItemMapping.length === 0) {
      return undefined;
    }

    return translatedDisplayByCode(translatedItemMapping, value.value.code);
  };

  switch (type) {
    case QuestionnaireItemType.display:
      return <p key={props.item.linkId}>{item.text}</p>;
    case QuestionnaireItemType.boolean:
      return (
        <Text key={item.linkId} ml="xl">
          {translate(defaultValue)}{' '}
        </Text>
      );
    case QuestionnaireItemType.date:
    case QuestionnaireItemType.dateTime:
    case QuestionnaireItemType.time:
    case QuestionnaireItemType.url:
    case QuestionnaireItemType.text:
    case QuestionnaireItemType.attachment:
    case QuestionnaireItemType.reference:
    case QuestionnaireItemType.quantity:
      return (
        <Text key={item.linkId} ml="xl">
          {defaultValue?.value?.display}
        </Text>
      );
    case QuestionnaireItemType.decimal:
    case QuestionnaireItemType.string:
    case QuestionnaireItemType.integer:
      return (
        <Text key={item.linkId} ml="xl">
          {defaultValue?.value}
        </Text>
      );
    case QuestionnaireItemType.choice:
    case QuestionnaireItemType.openChoice: {
      if (item.repeats) {
        const currentAnswer = getCurrentMultiSelectAnswer(response);
        return (
          <Stack gap="xs">
            {response.answer?.map((answer, index) => (
              <List ml="xl" key={item.linkId + index}>
                <List.Item>
                  <Text>{answer ? translate(getItemValue(answer)) || currentAnswer[index] : currentAnswer[index]}</Text>
                </List.Item>
              </List>
            ))}
          </Stack>
        );
      } else {
        return (
          <Text key={item.linkId} ml="xl" mt="xs">
            {translate(defaultValue) || defaultValue?.value?.display}
          </Text>
        );
      }
    }

    default:
      return null;
  }
}

function getItemValue(answer: QuestionnaireResponseItemAnswer): TypedValue {
  const itemValue = getTypedPropertyValue({ type: 'QuestionnaireItemAnswer', value: answer }, 'value') as TypedValue;
  return itemValue;
}

function getCurrentAnswer(response: QuestionnaireResponseItem, index: number = 0): TypedValue {
  const results = response.answer;
  return getItemValue(results?.[index] ?? {});
}

function getCurrentMultiSelectAnswer(response: QuestionnaireResponseItem): string[] {
  const results = response.answer;
  if (!results) {
    return [];
  }
  const typedValues = results.map((a) => getItemValue(a));
  return typedValues.map((type) => formatCoding(type?.value) || type?.value);
}
