import { Participant, Room } from 'twilio-video';
import useDominantSpeaker from './useDominantSpeaker';
import useRoomConnection, { CallbackOptions } from './useRoomConnection';
import useCamera from './useCamera';
import useToggleMicrophone from './useToggleMicrophone';
import useParticipant from '../participants/useParticipant';

export interface Result {
  room?: Room;
  error?: Error;
  connectRoom: (opts: CallbackOptions) => void;
  disconnectRoom: () => void;
  localParticipant?: Participant;
  remoteParticipants: Participant[];
  dominantSpeaker?: Participant;
  isBlurOn: boolean;
  toggleBlur: () => void;
  isCameraOn: boolean;
  toggleCamera: () => void;
  isMicrophoneOn: boolean;
  toggleMicrophone: () => void;
}

export default function useRoom(): Result {
  const { room, error, connectRoom, disconnectRoom } = useRoomConnection();

  const { localParticipant, remoteParticipants } = useParticipant({ room });

  const { dominantSpeaker } = useDominantSpeaker({ room });

  const { isBlurOn, toggleBlur, isCameraOn, toggleCamera } = useCamera({
    room,
    initialCameraState: true,
    initialBlurState: true,
  });

  const { isMicrophoneOn, toggleMicrophone } = useToggleMicrophone({
    room,
    initialState: true,
  });

  return {
    room,
    error,
    connectRoom,
    disconnectRoom,
    localParticipant,
    remoteParticipants,
    dominantSpeaker,
    isBlurOn,
    toggleBlur,
    isCameraOn,
    toggleCamera,
    isMicrophoneOn,
    toggleMicrophone,
  };
}
