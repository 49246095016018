import React, { forwardRef, useCallback } from 'react';
import { Group, Text } from '@mantine/core';
import { ValueSetExpandParams } from '@medplum/core';
import { ValueSetExpansionContains } from '@medplum/fhirtypes';
import { AsyncAutocomplete, AsyncAutocompleteOption, AsyncAutocompleteProps } from './medplum/AsyncAutocomplete';
import { IconCheck } from '@tabler/icons-react';
import { HL7System } from 'const-utils';

export interface ICD10AutocompleteProps
  extends Omit<AsyncAutocompleteProps<ValueSetExpansionContains>, 'loadOptions' | 'toKey' | 'toOption'> {
  readonly expandParams?: Partial<ValueSetExpandParams>;
  readonly withHelpText?: boolean;
}

function toKey(element: ValueSetExpansionContains): string {
  if (typeof element.code === 'string') {
    return element.code;
  }
  return JSON.stringify(element);
}

function getDisplay(item: ValueSetExpansionContains): string {
  if (typeof item.display === 'string') {
    return item.display;
  }
  return toKey(item);
}

function toOption(element: ValueSetExpansionContains): AsyncAutocompleteOption<ValueSetExpansionContains> {
  return {
    value: toKey(element),
    label: getDisplay(element),
    resource: element,
  };
}

export function ICD10AutocompleteNIHGOV(props: ICD10AutocompleteProps): JSX.Element {
  const { expandParams, ...rest } = props;

  const loadValues = useCallback(
    async (input: string, signal: AbortSignal): Promise<ValueSetExpansionContains[]> => {
      // https://clinicaltables.nlm.nih.gov/apidoc/icd10cm/v3/doc.html
      const res = await fetch(
        `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${input}&count=${
          expandParams?.count ?? 50
        }&offset=${expandParams?.offset ?? 0}`,
        {
          signal,
        },
      );
      if (!res.ok) {
        throw new Error(`Failed to search icd10cm: ${res.status} ${res.statusText}`);
      }

      const json = (await res.json()) as unknown[];
      if (!Array.isArray(json)) {
        throw new Error(`Invalid response type from icd10cm ${typeof json}`);
      }

      const data = json.at(3) as [string, string][] | undefined;
      if (!Array.isArray(data)) {
        throw new Error(`Invalid response data from icd10cm ${typeof data}`);
      }

      const seenCodes = new Map<string, boolean>();
      const newData: ValueSetExpansionContains[] = [];
      for (const d of data) {
        if (!d || d.length < 2) {
          continue;
        }
        const [code, display] = d;
        if (!code || seenCodes.has(code)) {
          continue;
        }
        seenCodes.set(code, true);

        newData.push({
          system: HL7System.ICD10,
          code,
          display,
        });
      }

      return newData;
    },
    [expandParams],
  );

  return (
    <AsyncAutocomplete
      {...rest}
      creatable={false}
      clearable
      toOption={toOption}
      loadOptions={loadValues}
      itemComponent={ItemComponent}
    />
  );
}

const ItemComponent = forwardRef<
  HTMLDivElement,
  AsyncAutocompleteOption<ValueSetExpansionContains> & { active: boolean }
>(
  (
    { label, resource, active, ...others }: AsyncAutocompleteOption<ValueSetExpansionContains> & { active: boolean },
    ref,
  ) => {
    return (
      <div ref={ref} {...others}>
        <Group wrap="nowrap">
          {active && <IconCheck size={12} />}
          <div>
            <Text>{label}</Text>
            <Text size="xs" c="dimmed">
              {`${resource.system}#${resource.code}`}
            </Text>
          </div>
        </Group>
      </div>
    );
  },
);
