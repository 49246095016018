import React from 'react';
import { Paper } from '@mantine/core';
import { TasksSearch } from '@/components/tasks/TasksSearch';
import { TasksTable } from '@/components/tasks/TasksTable';
import { PageSkeleton } from '@/design-system/PageSkeleton';
import { useTaskAlertContext } from './TaskAlertProvider';
import { ResolvedTasksTable } from '@/components/tasks/ResolvedTasksTable';

const TaskContent = () => {
  const { resolvedTasks } = useTaskAlertContext();

  return (
    <PageSkeleton withPaper={false}>
      <TasksSearch />
      <Paper radius="lg" shadow="xs" p="xl" mt="xl" mb="xl">
        {resolvedTasks ? <ResolvedTasksTable /> : <TasksTable />}
      </Paper>
    </PageSkeleton>
  );
};

export const TaskPage = (): JSX.Element => {
  return <TaskContent />;
};
