import {
  Attachment,
  BundleEntry,
  Encounter,
  Communication as FHIRCommunication,
  Practitioner,
  Task,
} from '@medplum/fhirtypes';
import React, { RefObject, createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Stack, Container, Button, Flex, Box } from '@mantine/core';
import { Communication, Maybe, Task as GraphQLTask } from 'medplum-gql';
import { useMedplum } from '@medplum/react';
import { taskAssignmentHistory } from 'imagine-dsl/services/taskService';
import { buildTimelineItems } from './utils/timeline';
import { debounce, groupBy } from 'lodash';
import { TimelineGroup } from './timelineGroup';
import { getPrefersReducedMotion } from '@/utils/prefersReducedMotion';
import { logError } from '@/errors';

interface ChatTimelineProps {
  messages: Communication[];
  currentTask?: Task;
  completedTasks?: GraphQLTask[];
  loadMoreTimeline: () => Promise<void>;
  totalTimelineCount: number;
}

export interface ChatFormData {
  contentString: string;
  attachment: Attachment | undefined;
}

export function ChatTimeline({
  messages,
  currentTask,
  completedTasks,
  loadMoreTimeline,
  totalTimelineCount,
}: ChatTimelineProps): JSX.Element {
  const medplum = useMedplum();
  const [assignmentHistory, setAssignmentHistory] = useState<BundleEntry<Task>[]>([]);
  const messageListRef = useRef<HTMLDivElement>(null);
  const topElementRef = useRef<HTMLDivElement>(null);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);
  const [showHasNewMessages, setShowHasNewMessages] = useState(false);
  const prevMessageRef = useRef(messages);
  const [firstItemId, setFirstItemId] = useState<string | undefined>();
  const [itemRefs, setItemRefs] = useState<Record<string, RefObject<HTMLDivElement>>>({});
  const [moreLoading, setMoreLoading] = useState(false);

  const scrollToBottom = useCallback((options?: { behavior?: ScrollBehavior }): void => {
    const rect = messageListRef.current?.getBoundingClientRect();
    if (!rect) {
      return;
    }

    let behavior = options?.behavior;
    if (!behavior) {
      const prefersReducedMotion = getPrefersReducedMotion();
      behavior = prefersReducedMotion ? 'instant' : 'smooth';
    }

    messageListRef.current?.parentElement?.scrollTo({
      top: rect.height,
      behavior,
    });
  }, []);

  useEffect(() => {
    if (prevMessageRef.current.at(0)?.id === messages.at(0)?.id) {
      return;
    }
    prevMessageRef.current = messages;

    if (autoScrollEnabled) {
      scrollToBottom();
    } else {
      setShowHasNewMessages(true);
    }
  }, [messages, scrollToBottom, autoScrollEnabled]);

  useEffect(() => {
    const element = messageListRef.current;
    if (!element) {
      return () => {};
    }

    const onScroll = (): void => {
      const scrolledAwayFromBottom =
        (element.parentElement?.scrollHeight || 0) >=
        (element.parentElement?.scrollTop || 0) + (element.parentElement?.clientHeight || 0) + 10;

      if (scrolledAwayFromBottom) {
        setAutoScrollEnabled(false);
        setShowHasNewMessages(true);
      } else {
        setAutoScrollEnabled(true);
        setShowHasNewMessages(false);
      }
    };
    element.parentElement?.addEventListener('scroll', onScroll);

    // The initial scroll to bottom on mount does not consistently hit the bottom possibly due to the container height not being stable yet?
    // The following improves the consistency with which the initial scroll hits the bottom of the container by waiting for the height to be stable
    // and then scrolling to the bottom and removing the resize observer
    const ro = new ResizeObserver(
      debounce((items) => {
        for (const el of items) {
          if (el.target !== element) {
            return;
          }

          ro.unobserve(element);
          scrollToBottom({ behavior: 'instant' });
        }
      }, 250),
    );
    ro.observe(element);

    return () => {
      element.parentElement?.removeEventListener('scroll', onScroll);
      ro.unobserve(element);
    };
  }, [scrollToBottom]);

  useEffect(() => {
    if (!currentTask) {
      return;
    }

    taskAssignmentHistory(medplum, currentTask.id!)
      .then((data) => {
        setAssignmentHistory(data);
      })
      .catch((error) => logError(error));
  }, [medplum, currentTask]);

  const timelineItems = useMemo(
    () => buildTimelineItems(assignmentHistory, messages as FHIRCommunication[]),
    [assignmentHistory, messages],
  );

  const groupedItems = useMemo(() => {
    return groupBy(timelineItems, 'id');
  }, [timelineItems]);

  const groupedItemKeys = useMemo(() => Object.keys(groupedItems), [groupedItems]);

  useEffect(() => {
    setFirstItemId(timelineItems.at(0)?.resource?.id);
    const resourceIds = timelineItems.map((item) => item.resource?.id);
    const refsByResourceId: Record<string, RefObject<HTMLDivElement>> = resourceIds.reduce((acc, id) => {
      if (!id) {
        return acc;
      }
      return { ...acc, [id]: createRef<HTMLDivElement>() };
    }, {});

    setItemRefs(refsByResourceId);
  }, [timelineItems]);

  const priorityForTask = useCallback(
    (encounterId: string): 'routine' | 'urgent' | 'asap' | 'stat' => {
      if (encounterId === currentTask?.focus?.resource?.id) {
        return currentTask?.priority || 'routine';
      }
      const taskForEncounter = completedTasks?.find(
        (completedTask) => (completedTask?.focus?.resource as Encounter)?.id === encounterId,
      );

      return (taskForEncounter as Task)?.priority || 'routine';
    },
    [currentTask, completedTasks],
  );

  const assigneeForCompletedTask = useCallback(
    (encounterId: string): Maybe<Practitioner> => {
      // If the encounter is for the current task, no need to find the assignee
      if (encounterId === currentTask?.focus?.resource?.id) {
        return null;
      }
      const taskForEncounter = completedTasks?.find(
        (completedTask) => (completedTask?.focus?.resource as Encounter)?.id === encounterId,
      );

      return taskForEncounter?.owner?.resource as Maybe<Practitioner>;
    },
    [currentTask, completedTasks],
  );

  const previousGroupTime = useCallback(
    (index: number): string => {
      if (index === 0) {
        return '';
      }

      const key = groupedItemKeys[index - 1];
      return groupedItems[key]?.at(-1)?.relevantTime || '';
    },
    [groupedItemKeys, groupedItems],
  );

  const onLoadMoreTimeline = async (): Promise<void> => {
    const whereToScroll = firstItemId ? itemRefs[firstItemId].current : null;
    setMoreLoading(true);

    await loadMoreTimeline();

    whereToScroll?.scrollIntoView({ behavior: 'instant' });
    setMoreLoading(false);
  };

  const canLoadMoreTimeline = useMemo(
    () => (completedTasks?.length || 0) + messages.length < totalTimelineCount,
    [messages, totalTimelineCount, completedTasks],
  );

  return (
    <>
      <Container ref={messageListRef} style={{ position: 'relative', width: '100%' }}>
        {canLoadMoreTimeline && (
          <Box mb={8} display="flex" style={{ justifyContent: 'center' }}>
            <Button loading={moreLoading} radius={32} onClick={onLoadMoreTimeline}>
              Load more
            </Button>
          </Box>
        )}
        {messages && (
          <Stack gap="xl">
            {groupedItemKeys.map((encounterId, index) => {
              return (
                <TimelineGroup
                  ref={topElementRef}
                  itemRefs={itemRefs}
                  previousGroupTime={previousGroupTime(index)}
                  key={encounterId}
                  encounterId={encounterId}
                  currentTaskEncounterId={currentTask?.focus?.resource?.id}
                  priority={priorityForTask(encounterId)}
                  items={groupedItems[encounterId]}
                  assigneeForTask={assigneeForCompletedTask(encounterId)}
                />
              );
            })}
          </Stack>
        )}
        {showHasNewMessages && (
          <Flex style={{ position: 'sticky', bottom: 0, width: '100%' }} justify="center">
            <Button radius={32} onClick={() => scrollToBottom()}>
              Latest activity
            </Button>
          </Flex>
        )}
      </Container>
    </>
  );
}
