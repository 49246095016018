import { ContactCaregiverFormValues } from '../types';
import { useForm, UseFormReturnType } from '@mantine/form';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { GetPatientQuery } from 'medplum-gql';

interface UseContactReviewFormInput {
  patient: GetPatientQuery['Patient'];
  caregiver: GetPatientQuery['Patient'];
  phoneNumbers: string[];
}

export const useContactCaregiverForm = ({
  patient,
  caregiver,
  phoneNumbers,
}: UseContactReviewFormInput): UseFormReturnType<ContactCaregiverFormValues> => {
  return useForm<ContactCaregiverFormValues>({
    initialValues: {
      patientId: patient!.id as string,
      phoneNumber: phoneNumbers[0] || '',
      date: new Date(),
      timezone: '',
      modality: undefined,
      contactFirstName: caregiver?.name?.[0]?.given?.[0] || '',
      contactLastName: caregiver?.name?.[0]?.family || '',
    },
    validate: {
      phoneNumber: (value) => {
        if (!value) {
          return 'Phone number is required';
        }

        if (!isValidPhoneNumber(value, 'US')) {
          return 'Invalid phone number';
        }

        return null;
      },
      date: (value) => (value ? null : 'Date is required'),
      modality: (value) => (value ? null : 'Modality is required'),
    },
  });
};
