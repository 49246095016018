import { Flex, Loader, rem, Text } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { isEmpty } from 'lodash';
import React from 'react';

interface EmptyStateProps {
  loading: boolean;
  emptyStateText: string;
  noResultsText: string;
  data: unknown;
  searchPerformed: boolean;
}

export function EmptyState({
  data,
  searchPerformed,
  loading,
  emptyStateText,
  noResultsText,
}: EmptyStateProps): JSX.Element {
  return (
    <>
      {loading && (
        <Flex justify="center">
          <Loader mt={rem('24px')} />
        </Flex>
      )}
      {!loading && isEmpty(data) && (
        <Flex justify="center" my={rem('30px')} align="center" gap="sm">
          <IconInfoCircle style={{ color: 'var(--mantine-color-status-highlight-5)' }} />
          <Text>{searchPerformed ? noResultsText : emptyStateText}</Text>
        </Flex>
      )}
    </>
  );
}
