import { logError } from '@/errors';
import { useMedplum } from '@medplum/react';
import { useQuery } from '@tanstack/react-query';
import { System, TaskType } from 'const-utils';
import { PatientWithCoverage, PatientWithManagingOrg } from 'imagine-dsl/utils/types/patient';
import { CoverageInfoFragment, OrganizationInfoFragment } from 'medplum-gql';
import { useEffect, useMemo } from 'react';

export * from './useConductOutreach';

export const useHasOpenCarePathwayReferralReviewTask = (patientId: string | undefined | null) => {
  const medplum = useMedplum();
  const result = useQuery(['hasOpenCarePathwayReferralReviewTask', patientId], async () => {
    if (!patientId) {
      return false;
    }
    const task = await medplum.searchOne(
      'Task',
      `patient=Patient/${patientId}&_tag=${TaskType.CarePathwayReferralReview}&status:not=accepted,rejected,cancelled`,
    );
    return !!task;
  });

  useEffect(() => {
    if (result.error) {
      logError(result.error);
    }
  }, [result.error]);

  return result;
};

export const useManagingOrganization = (patient: PatientWithManagingOrg): OrganizationInfoFragment | undefined => {
  if (!patient) {
    return undefined;
  }

  if (!patient.managingOrganization) {
    return undefined;
  }

  if (patient.managingOrganization.resource?.__typename !== 'Organization') {
    return undefined;
  }

  return patient.managingOrganization.resource;
};

export const useHealthPlan = (coverageInfo?: CoverageInfoFragment[]) =>
  useMemo(() => {
    if (!coverageInfo) {
      return undefined;
    }
    const activeCoverage = coverageInfo?.find((cov) => cov?.status === 'active');

    if (activeCoverage?.payor?.[0].resource?.__typename !== 'Organization') {
      return undefined;
    }

    return activeCoverage?.payor?.[0].resource;
  }, [coverageInfo]);

export const useLineOfBusiness = (patient: PatientWithCoverage) =>
  useMemo(() => {
    if (!patient) {
      return undefined;
    }
    const activeCoverage = patient?.CoverageList?.find((cov) => cov?.status === 'active');

    return activeCoverage?.class?.find((cls) => cls?.type?.coding?.some((c) => c.system === System.LineOfBusiness));
  }, [patient]);
