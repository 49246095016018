import { ObjectId } from 'mongodb';

export enum StatusVal {
  AgedOut = 'Aged Out',
  Deceased = 'Deceased',
  DisenrolledChangedPlan = 'Disenrolled - Changed Plan',
  DisenrolledNoLongerQualifies = 'Disenrolled - No Longer Qualifies',
  DisenrolledOptOut = 'Disenrolled - Opt Out',
  DisenrolledOutOfCoverageArea = 'Disenrolled - Out of Coverage Area',
  Eligible = 'Eligible',
  Engaged = 'Engaged',
  Enrolled = 'Enrolled',
  IneligibleHealthPlanEligibility = 'Ineligible - Health Plan Eligibility',
  IneligibleWithPlan = 'Ineligible with Plan',
}

export enum CareTeamMemberRoleRef {
  AdvancedPracticeProvider = 'advancedpracticeprovider',
  BehavioralHealthApp = 'behavioralhealthapp',
  Cardiologist = 'cardiologist',
  CareCoordinator = 'carecoordinator',
  // The Verity system spells it dietician so we must not change the spelling as it will break the mapping
  Dietician = 'dietician',
  ImaginePediatrician = 'imaginepediatrician',
  Nephrologist = 'nephrologist',
  PediatricRN = 'pediatricrn',
  Pharmacist = 'pharmacist',
  Physician = 'physician',
  PrimaryCareProvider = 'primarycareprovider',
  Psychiatrist = 'psychiatrist',
  Pulmonologist = 'pulmonologist',
  SocialWorker = 'socialworker',
}

export interface Patient {
  _id: ObjectId;
  pid: number;
  pFn: string;
  pMn?: string;
  pLn: string;
  prefName?: string;
  prefix?: string;
  suffix?: string;
  dob: Date;
  email?: string;
  hPlan: string;
  hPlanBrand: string;
  lanSpknRef?: string;
  lanSpkn?: string;
  lanWrtnRef?: string;
  lanWrtn?: string;
  bSexRef: number;
  reqInterpreter?: boolean;
  bSex: string;
  gIdentityRef: 'male' | 'female' | 'non-binary' | 'transgender-male' | 'transgender-female' | 'other' | 'non-disclose';
  gIdentity: string;
  prefProNoun?: string;
  prefProNounRef?: string;
  crtdOn: string;
  crtdRef: string;
  crtdUN: string;
  crtdTZ: string;
  updOn: string;
  updRef: string;
  updUN: string;
  updTZ: string;
  bDeceased: boolean;
  active: boolean;
  exclusionPatient: unknown[];
  planType: string;
  enrolledIndicator: number;
  bIsProgramEligible: boolean;
  oldConvUNs: unknown[];
  livesWith: { ref: number; val: string }[];
  lvngSitn?: string;
  lvngSitnRef?: string;
  access: PatientAccess[];
  linkUsr: unknown[];
  addresses?: Address[];
  phones: Phone[];
  insurance: unknown[];
  consent: Consent[];
  bCtrlLine: boolean;
  bTracheostomy: boolean;
  dda: string;
  ethnicity1: string;
  ethnicity1Ref: string;
  erVisits: number;
  ipInstances: number;
  contacts: Contact[];
  linkid: string;
  convUN?: string;
  isMsgScnOpen: boolean;
  msgScnExpTime: string;
}

interface PatientAccess {
  accessRef: number;
  strtDate: string;
  portfolioRef: number;
  portfolioName: string;
  marketRef: number;
  marketName: string;
  jvRef: number;
  jvName: string;
  practiceRef: number;
  practiceName: string;
}

interface Address {
  typeId: number;
  typeVal: string;
  hTypeRef: number;
  hTypeVal: string;
  st1: string;
  st2: string;
  city: string;
  stCode: string;
  stName: string;
  z5: string | number;
  crtdOn: string;
  crtdRef: string;
  crtdUN: string;
  crtdTZ: string;
  updOn: string;
  updRef: string;
  updUN: string;
  updTZ: string;
  lastUpdated: string;
  source: string;
  _id: ObjectId;
}

export interface Phone {
  pn: number | string;
  typeId: number;
  typeVal: string;
  primary: boolean;
  statusRef: number;
  status: string;
  prefRef: number;
  pref: string;
  txtRemin: boolean;
  crtdOn: string;
  crtdRef: string;
  crtdUN: string;
  crtdTZ: string;
  updOn: string;
  updRef: string;
  updUN: string;
  updTZ: string;
  lastUpdated: string;
  source: string;
  _id: ObjectId;
}

interface Consent {
  consentTypeRef: number;
  consentTypeVal: string;
  signeeName: string;
  signeeRelationRef: number;
  signeeRelation: string;
  acceptedOn: string;
  docName: string;
  timeZone: string;
  caregiverName: string;
  signature: string;
  _id: ObjectId;
}

export interface Contact {
  fn: string;
  ln: string;
  typeVal: TypeVal[];
  primary: boolean;
  inLieuOfPat: boolean;
  permToContact: boolean;
  relationRef: number;
  isPhoneAvailable: boolean;
  relation: 'Father' | 'Mother' | 'Cousin' | 'Aunt';
  email?: string;
  livesWith: boolean;
  crtdOn: string;
  crtdRef: string;
  crtdUN: string;
  crtdTZ: string;
  updOn: string;
  updRef: string;
  updUN: string;
  updTZ: string;
  lastUpdated: string;
  source: string;
  contactPhones?: ContactPhone[];
  lanSpknRef: string;
  lanSpkn?: string;
  lanWrttnRef: string;
  reqInterpreter: boolean;
  mobileAppConsent: boolean;
  _id: ObjectId;
}

export interface DeviceInformation {
  biometricPublicKey?: string;
  deviceId: string | number;
  deviceModel?: string | number;
  deviceBrand: string;
  deviceLat: number;
  deviceLong: number;
  deviceOsName: string | number;
  deviceOsVersion?: string | number;
  pToken: string;
  _id: ObjectId;
}
export interface Ula {
  caregiverName: string;
  dateSigned: Date;
  docName: string;
}

export interface User {
  _id: ObjectId;
  fn: string;
  ln: string;
  un: string;
  roleRef: string;
  roleVal: string;
  isActive: boolean;
  oktaId: string;
  oktaStatus: boolean;
  isMobileUser: boolean;
  contactsRef: string;
  lastLoggedIn: Date;
  lastLoggedInTZ: string;
  linkid: string;
  requiresChangePassword: boolean;
  convUN: string;
  oldConvUNs: string[];
  deviceInformations?: DeviceInformation[];
  crtdOn: Date;
  crtdRef: string;
  crtdUN: string;
  crtdTZ: string;
  updOn: Date;
  updRef: string;
  updUN: string;
  updTZ: string;
  access?: Access[];
  ula?: Ula;
}

interface TypeVal {
  ref: number;
  val: string;
  _id: ObjectId;
}

export interface ContactPhone {
  pn: string | number;
  typeId: number;
  typeVal: 'Home' | 'Work' | 'Mobile' | 'Other';
  status: string;
  statusRef: number;
  primary: boolean;
  pref: string;
  prefRef: number;
  txtRemin: boolean;
  crtdOn: string;
  crtdRef: string;
  crtdUN: string;
  crtdTZ: string;
  updOn: string;
  updRef: string;
  updUN: string;
  updTZ: string;
  lastUpdated: string;
  source: string;
  _id: ObjectId;
  crtAcct?: CrtAcct;
}

interface CrtAcct {
  iscrtCredLnk: boolean;
  uName: string;
}

export interface Access {
  _id: ObjectId;
  __v: number;
  crtdOn: string;
  crtdRef: string;
  crtdTZ: string;
  crtdUN: string;
  healthPlanPhones: string[];
  jvName: string;
  jvRef: number;
  marketName: string;
  marketRef: number;
  outreachPhones: string[];
  portfolioName: string;
  portfolioRef: number;
  practiceName: string;
  practiceRef: number;
}

export interface PatientClinicalData {
  _id: ObjectId;
  pRef: number;
  access: PatientClinicalDataAccess[];
  acuity: Acuity[];
  careTeamAssignment: CareTeamAssignment[];
  programs: Program[];
}

export interface CareTeamAssignment {
  _id: ObjectId;
  roleRef: CareTeamMemberRoleRef;
  roleName: string;
  careTeamMember: CareTeamMember[];
}

export interface CareTeamMember {
  _id: ObjectId;
  isVerityUser: boolean;
  userRef?: string;
  userName: string;
  startDate?: string;
  phoneNumber?: string;
  association?: string;
}

export interface PatientClinicalDataAccess {
  accessRef: number;
  strtDate: string;
  portfolioRef: number;
  portfolioName: string;
  marketRef: number;
  marketName: string;
  jvRef: number;
  jvName: string;
  practiceRef: number;
  practiceName: string;
}

export interface Acuity {
  type: string;
  typeRef: number;
  severity: string;
  crtdOn: string;
  crtdRef: string;
  crtdUN: string;
  crtdTZ: string;
  updOn: string;
  updRef: string;
  updUN: string;
  updTZ: string;
  lastUpdated: string;
  source: string;
  _id: ObjectId;
}

export interface Program {
  programRef: number;
  programName?: string;
  status: Status[];
  referralSrcRef?: number;
  referralSrc?: string;
  source?: string;
  lastUpdated?: string;
  crtdOn?: string;
  crtdRef?: string;
  crtdUN?: string;
  crtdTZ?: string;
  updOn?: string;
  updRef?: string;
  updUN?: string;
  updTZ?: string;
  _id: ObjectId;
}

export interface Status {
  statusVal: StatusVal;
  statusId?: number;
  statusDate: string;
  order: number;
  active: boolean;
  label: string;
  _id: ObjectId;
}

export enum CallStatus {
  TalkedToPrimaryContactCaregiver = 'Talked to Primary Contact/Caregiver',
  NoAnswer = 'No Answer',
  Deferred = 'Deferred',
  Researching = 'Researching',
  LeftVoicemail = 'Left Voicemail',
  MaxCallAttempt = 'Max Call Attempt',
  NewContactAvailable = 'New Contact Available',
  TalkedToPerson = 'Talked to Person',
  WrongNumber = 'Wrong Number',
  DoNotCall = 'Do Not Call',
  TalkedToPatient = 'Talked to Patient',
  InvalidNumber = 'Invalid Number',
  ScheduledWithCareTeam = 'Scheduled with Care Team',
}

export interface Touchpoint {
  _id: ObjectId;
  uid: string;
  route: string;
  touchpointType: string;
  phoneNumber: number;
  contactName: string;
  contactRelation: string;
  callTime: Date;
  callStatus: CallStatus | null;
  note: string;
  callbackDateTime?: Date;
  crtdOn: Date;
  crtdUN: string;
  crtdRef: string;
}

export interface Interaction {
  _id: ObjectId;
  pRef: number;
  typeVal: string;
  crtdRef: string;
  touchPoint: {
    touchPoints: Touchpoint[];
  };
}

export interface PatientWithJoinedData extends Patient {
  clinicalData: PatientClinicalData | null;
  interactions: Interaction[];
}
