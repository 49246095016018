export const enum GenderIdentity {
  Male = 'male',
  Female = 'female',
  NonBinary = 'non-binary',
  TransgenderMale = 'transgender-male',
  TransgenderFemale = 'transgender-female',
  Other = 'other',
  NonDisclose = 'non-disclose',
}

export const enum PreferredPronoun {
  He = 'he/him',
  She = 'she/her',
  They = 'they/them',
  Ze = 'ze/zir',
  Xie = 'xie/hir',
  Co = 'co/co',
  En = 'en/en',
  Ey = 'ey/em',
  Yo = 'yo/yo',
  Ve = 've/vis',
  unknown = 'unknown',
}

export const enum PreferredLanguage {
  Ar = 'ar',
  Asl = 'asl',
  Bn = 'bn',
  Cs = 'cs',
  Da = 'da',
  De = 'de',
  DeAT = 'de-AT',
  DeCH = 'de-CH',
  DeDE = 'de-DE',
  El = 'el',
  En = 'en',
  EnAU = 'en-AU',
  EnCA = 'en-CA',
  EnGB = 'en-GB',
  EnIN = 'en-IN',
  EnNZ = 'en-NZ',
  EnSG = 'en-SG',
  EnUS = 'en-US',
  Es = 'es',
  EsAR = 'es-AR',
  EsES = 'es-ES',
  EsUY = 'es-UY',
  Fi = 'fi',
  Fr = 'fr',
  FrBE = 'fr-BE',
  FrCH = 'fr-CH',
  FrFR = 'fr-FR',
  Fy = 'fy',
  FyNL = 'fy-NL',
  Hat = 'hat',
  Hi = 'hi',
  Hr = 'hr',
  It = 'it',
  ItCH = 'it-CH',
  ItIT = 'it-IT',
  Ja = 'ja',
  Ko = 'ko',
  Nl = 'nl',
  NlBE = 'nl-BE',
  NlNL = 'nl-NL',
  No = 'no',
  NoNO = 'no-NO',
  Pa = 'pa',
  Pl = 'pl',
  Pt = 'pt',
  PtBR = 'pt-BR',
  Ru = 'ru',
  RuRU = 'ru-RU',
  Sr = 'sr',
  SrRS = 'sr-RS',
  Sv = 'sv',
  SvSE = 'sv-SE',
  Te = 'te',
  Zh = 'zh',
  ZhCN = 'zh-CN',
  ZhHK = 'zh-HK',
  ZhSG = 'zh-SG',
  ZhTW = 'zh-TW',
  Vi = 'vi',
  Fa = 'fa',
  Sw = 'sw',
  Unknown = 'unknown',
  Misc = 'misc',
  Cpf = 'cpf',
  Ps = 'ps',
}

export const enum LivingSituation {
  HL = 'hl',
  M = 'm',
  T = 't',
  I = 'i',
  CS = 'cs',
  G = 'g',
  N = 'n',
  X = 'x',
  PR = 'pr',
  H = 'h',
  R = 'r',
  SL = 'sl',
  IL = 'il',
}

export enum AddressType {
  Home = 'home',
  Mailing = 'mailing',
  Visit = 'visit',
}

export enum PhoneStatus {
  WrongNumber = 'wrong-number',
  Invalid = 'invalid',
  DoNotCall = 'do-not-call',
}
