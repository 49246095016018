import { Avatar } from '@mantine/core';
import { IconChevronsDown, IconChevronsUp, IconEqual } from '@tabler/icons-react';
import React, { useMemo } from 'react';

interface PriorityBadgeProps {
  priority: string;
}

export function PriorityBadge({ priority }: PriorityBadgeProps): JSX.Element {
  const badge = useMemo((): { color?: string; icon?: JSX.Element; variant?: string } => {
    if (!priority) {
      return {};
    }

    if (priority === 'low') {
      return { color: 'status-success', icon: <IconChevronsDown color="black" />, variant: 'light' };
    } else if (priority === 'medium') {
      return { color: 'status-warn', icon: <IconEqual color="black" />, variant: 'light' };
    } else {
      return { color: 'status-error', icon: <IconChevronsUp color="white" />, variant: 'filled' };
    }
  }, [priority]);

  if (!priority) {
    return <div>--</div>;
  }

  return (
    <Avatar data-testid={`priority-badge-${badge.color}`} color={badge.color} variant={badge.variant} size="sm">
      {badge.icon}
    </Avatar>
  );
}
