import { getConfig } from '@/config';
import { sessionStorageKeys } from '@/localAndSessionStorage';
import { onError } from '@apollo/client/link/error';
import { notifications } from '@mantine/notifications';
import { MedplumClient } from '@medplum/core';

const config = getConfig();

export const logout = (): void => {
  const redirectTo = window.location.pathname + window.location.search;
  sessionStorage.setItem(sessionStorageKeys.redirectTo(), redirectTo);
  localStorage.removeItem('activeLogin');
  window.location.href = '/signin';
};

export const errorLink = onError(({ networkError }) => {
  if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
    notifications.show({
      message: 'Session expired. Please log in again',
      autoClose: false,
      color: 'status-error',
    });
    logout();
  }
});

export const getAuthHeaders = (accessToken?: string) => {
  return {
    'X-Medplum': 'extended',
    'Cache-Control': 'max-age=0',
    accept: 'application/fhir+json',
    authorization: accessToken ? `Bearer ${accessToken}` : '',
  };
};

export const medplum = new MedplumClient({
  onUnauthenticated: logout,
  baseUrl: config.baseUrl,
  clientId: config.clientId,
  refreshGracePeriod: 1000 * 60 * 5,
});
