import { useMedplum } from '@medplum/react';
import { CareTeamType, CareTeamTypeTag, System } from 'const-utils';
import { CareTeamMemberRole, CareTeamMemberRoleDisplay } from 'const-utils/codeSystems/ImaginePediatrics';
import { createReference } from '@medplum/core';
import { Practitioner } from '@medplum/fhirtypes';
import { useState } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery } from 'medplum-gql';

type Refetch = () => Promise<ApolloQueryResult<GetPatientQuery>>;

type CallbackWithLoader = [() => Promise<void>, boolean];

const useManageExternalCareTeamMember = (
  careTeamId: string | undefined,
  candidate: Practitioner | undefined,
  role: CareTeamMemberRole | undefined,
  previousMemberId: string | undefined,
  refetch: Refetch,
  patientId: string,
  close: () => void,
): CallbackWithLoader => {
  const medplum = useMedplum();
  const [loading, setLoading] = useState(false);

  const addOrReplaceMember = async (): Promise<void> => {
    if (!candidate || !role) {
      return;
    }

    setLoading(true);

    const practitioner = await (candidate.id ? medplum.updateResource(candidate) : medplum.createResource(candidate));

    const existing = careTeamId && (await medplum.readResource('CareTeam', careTeamId));

    const newParticipant = {
      role: [
        {
          coding: [
            {
              system: System.CareTeamRole,
              code: role,
              display: CareTeamMemberRoleDisplay[role] ?? role,
            },
          ],
        },
      ],
      member: createReference(practitioner),
    };

    if (existing) {
      const participantsFiltered = existing.participant?.filter((m) => m.member?.reference !== previousMemberId) || [];
      await medplum.updateResource({
        ...existing,
        participant: [...participantsFiltered, newParticipant],
      });
      await refetch();
    } else {
      await medplum.createResource({
        resourceType: 'CareTeam',
        name: 'External Care Team',
        subject: {
          reference: `Patient/${patientId}`,
        },
        participant: [newParticipant],
        meta: {
          tag: [CareTeamTypeTag(CareTeamType.External)],
        },
      });
    }
    setLoading(false);
    await refetch();
    close();
  };

  return [addOrReplaceMember, loading];
};

export default useManageExternalCareTeamMember;
