import { Avatar, Box, Group, MantineStyleProp, Stack, Text, ThemeIcon } from '@mantine/core';
import { GetChatTimelineForPatientQuery, SenderType, TaskPriority } from 'imagine-gql/client';
import React, { useMemo } from 'react';
import { CARE_HUB_LOGO_SRC } from '../CareHubLogo';
import { formatWithYesterdayAndToday } from 'imagine-dsl/utils/dates';
import { AttachmentDisplay } from './AttachmentDisplay';
import { map } from 'lodash';
import { IconUserCheck } from '@tabler/icons-react';

interface ChatTimelineProps {
  timelineItems: GetChatTimelineForPatientQuery['chatTimeline']['timelineItems'];
}

type TimelineItem = GetChatTimelineForPatientQuery['chatTimeline']['timelineItems'][0];

const senderImaginePediatrics = (senderType: SenderType) => {
  return [SenderType.Practitioner, SenderType.Device].includes(senderType);
};

export const NewQueryChatTimeline = ({ timelineItems }: ChatTimelineProps) => {
  const sameSender = (item: TimelineItem, index: number): boolean => {
    if (index === 0) {
      return false;
    }

    const currentSender = item.communication.sender.id;
    const previousSender = timelineItems[index - 1].communication.sender?.id;

    return currentSender === previousSender;
  };

  const contentAttachmentsForItem = (item: TimelineItem) => {
    return item.communication.payload.contentAttachments || [];
  };

  const groupedTimelineItems = useMemo(() => {
    const groupedItems: Record<string, TimelineItem[]> = {};
    timelineItems.forEach((item) => {
      if (!groupedItems[item.communication.encounterId]) {
        groupedItems[item.communication.encounterId] = [];
      }
      groupedItems[item.communication.encounterId].push(item);
    });

    return groupedItems;
  }, [timelineItems]);

  const accentColor = (items: TimelineItem[]): 'red' | 'green' | 'gray' => {
    const tasks = map(items, 'task');
    const anyOpen = tasks.some((t) => t.open);
    if (anyOpen) {
      return map(items, 'task').some((t) => t.priority === TaskPriority.Urgent) ? 'red' : 'green';
    } else {
      return 'gray';
    }
  };

  const hasAssignee = (items: TimelineItem[]): boolean => {
    return items.some((item) => !!item.task?.assignee);
  };

  return (
    <Stack>
      {Object.keys(groupedTimelineItems).map((encounterId) => (
        <React.Fragment key={encounterId}>
          <DotBorderComponent
            hasAssignee={hasAssignee(groupedTimelineItems[encounterId])}
            accentColor={accentColor(groupedTimelineItems[encounterId])}
          >
            <Box>
              {groupedTimelineItems[encounterId].map((item, index) => (
                <Group
                  pl={24}
                  style={{
                    borderLeft: `1px solid ${accentColor(groupedTimelineItems[encounterId])}`,
                  }}
                  key={index}
                  justify={senderImaginePediatrics(item.communication.senderType) ? 'flex-end' : 'flex-start'}
                >
                  {item.communication.payload.contentString && (
                    <>
                      <ChatBubble communication={item.communication} sameSender={sameSender(item, index)} />
                    </>
                  )}
                  {contentAttachmentsForItem(item).length > 0 &&
                    contentAttachmentsForItem(item).map((contentAttachment) => {
                      const key = contentAttachment?.url?.split('?')[0];
                      return (
                        contentAttachment && (
                          <Stack key={key}>
                            <AttachmentDisplay value={contentAttachment} maxWidth={200} />
                            <Text size="xs" c="dimmed">
                              {formatWithYesterdayAndToday(
                                item.communication.sent,
                                'EEE, MMMM do, yyyy hh:mm aaa',
                                true,
                              )}
                            </Text>
                          </Stack>
                        )
                      );
                    })}
                </Group>
              ))}
              {groupedTimelineItems[encounterId][0].task.assignee && (
                <Group
                  style={{ borderLeft: `1px solid ${accentColor(groupedTimelineItems[encounterId])}` }}
                  gap={4}
                  pl={24}
                >
                  <ThemeIcon>
                    <IconUserCheck height={24} width={24} />
                  </ThemeIcon>
                  <Text>Alert was assigned to</Text>
                  <Text fw="bold">{groupedTimelineItems[encounterId][0].task.assignee!.name}</Text>
                </Group>
              )}
            </Box>
          </DotBorderComponent>
        </React.Fragment>
      ))}
    </Stack>
  );
};

interface ChatBubbleProps {
  communication: GetChatTimelineForPatientQuery['chatTimeline']['timelineItems'][0]['communication'];
  sameSender: boolean;
}

const ChatBubble = ({ communication, sameSender }: ChatBubbleProps): JSX.Element => {
  const contentString = communication.payload.contentString;
  const senderType = communication.senderType;
  const sender = communication.sender;
  const senderImaginePediatrics = [SenderType.Practitioner, SenderType.Device].includes(senderType);

  if (!contentString) {
    return <></>;
  }

  return (
    <Stack
      key={communication.id}
      gap={0}
      w={400}
      ta={senderImaginePediatrics ? 'right' : 'left'}
      align={senderImaginePediatrics ? 'end' : 'start'}
    >
      <Group wrap="nowrap">
        {!senderImaginePediatrics && !sameSender && <CaregiverAvatar senderName={sender.name} />}
        <Box>
          {!sameSender && (
            <Text size="sm" fw="bold">
              {sender.name}
            </Text>
          )}
          <Bubble contentString={contentString} senderType={senderType} />
          <Text size="xs" c="dimmed">
            {formatWithYesterdayAndToday(communication.sent, 'EEE, MMMM do, yyyy hh:mm aaa', true)}
          </Text>
          {!!communication.received && (
            <Text fw={600} size="xs" c="dimmed">
              Read
            </Text>
          )}
        </Box>
        {senderImaginePediatrics && !sameSender && <ImagineAvatar />}
      </Group>
    </Stack>
  );
};

const Bubble = ({ contentString, senderType }: { contentString: string; senderType: SenderType }): JSX.Element => {
  const styles: MantineStyleProp = senderImaginePediatrics(senderType)
    ? {
        borderRadius: '12px',
        borderTopRightRadius: 0,
        whiteSpace: 'pre-line',
        maxWidth: '100%',
        wordBreak: 'break-word',
        textAlign: 'left',
      }
    : {
        borderRadius: '12px',
        borderTopLeftRadius: 0,
        whiteSpace: 'pre-line',
        maxWidth: '100%',
        wordBreak: 'break-word',
      };

  const color = senderImaginePediatrics(senderType) ? 'primary-50.1' : 'var(--mantine-color-imagine-gray-0)';

  return (
    <Text w="fit-content" p="sm" style={styles} bg={color} size="md" my={4}>
      {contentString}
    </Text>
  );
};

const ImagineAvatar = () => {
  return (
    <Avatar
      data-testid="practitioner-avatar"
      style={{ alignSelf: 'center' }}
      src={CARE_HUB_LOGO_SRC}
      size="md"
      radius="xl"
    />
  );
};

const CaregiverAvatar = ({ senderName }: { senderName: string }): JSX.Element => {
  return (
    <Avatar
      color="imagine-green"
      variant="filled"
      data-testid="patient-avatar"
      style={{ alignSelf: 'center' }}
      size="md"
      radius="xl"
    >
      {senderName
        .split(' ')
        .map((c) => c[0])
        .join('')}
    </Avatar>
  );
};

const DotBorderComponent = ({
  children,
  accentColor,
  hasAssignee,
}: {
  children: JSX.Element;
  accentColor: string;
  hasAssignee: boolean;
}) => {
  return (
    <Box
      style={{
        position: 'relative',
        border: '2px solid transparent', // Set the border width and make it transparent
        padding: '0 0px', // Adjust padding to make space for the dots
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '8px', // Set width of the dot
          height: '8px', // Set height of the dot
          backgroundColor: accentColor, // Set color of the dot
          borderRadius: '50%', // Make it a circle
          left: -3,
          top: 0,
        }}
      />
      <div
        style={{
          position: 'absolute',
          width: '8px', // Set width of the dot
          height: '8px', // Set height of the dot
          backgroundColor: accentColor, // Set color of the dot
          borderRadius: '50%', // Make it a circle
          bottom: hasAssignee ? -5 : 0,
          left: -3,
        }}
      />
      {children}
    </Box>
  );
};
