import React, { useMemo, useState } from 'react';
import { Modal, Button, Select, Checkbox, Paper, Text, Group } from '@mantine/core';
import { useApiClient } from '@/hooks/useApiClient';
import { showNotification } from '@mantine/notifications';
import { sendAppDownloadLink, updateCaregiverSmsConsent } from '@/pages/Outreach/handlers/appDownload';
import { Patient, RelatedPerson, ContactPoint } from '@medplum/fhirtypes';
import { System } from 'const-utils';
import { getAllPhoneContactPoints, isPrimaryPhone } from 'imagine-dsl/utils/patient';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery, Patient as GraphqlPatient } from 'medplum-gql';
import { formatPhoneString } from 'imagine-dsl/utils/strings';
import { useMedplum } from '@medplum/react';
import { logError } from '@/errors';

interface ExtendedRelatedPerson extends RelatedPerson {
  PatientList?: Patient[];
}
interface SendAppDownloadLinkModalProps {
  selectedPerson: ExtendedRelatedPerson;
  setSelectedPerson: (value: ExtendedRelatedPerson | undefined) => void;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

export const SendAppDownloadLinkModal = ({
  selectedPerson,
  setSelectedPerson,
  refetch,
}: SendAppDownloadLinkModalProps): JSX.Element => {
  const apiClient = useApiClient();
  const medplum = useMedplum();
  const caregiver = selectedPerson?.PatientList?.[0] as GraphqlPatient;

  const contactPoints = getAllPhoneContactPoints(caregiver); // All caregiver phone numbers in order of sequence with primary first
  const hasSmsConsent = caregiver?.extension?.some(
    (ext) => ext.url === System.CaregiverSMSConsent.toString() && ext.valueBoolean === true,
  );

  const [consent, setConsent] = useState(hasSmsConsent);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>(contactPoints[0]?.value ?? '');
  const [loading, setLoading] = useState(false);

  const phoneNumbersWithPrimary = useMemo(
    () =>
      contactPoints.map((contactPoint) => {
        const isPrimary = caregiver?.telecom?.some(
          (telecomItem) =>
            telecomItem.system === 'phone' &&
            telecomItem.value === contactPoint.value &&
            isPrimaryPhone(telecomItem as ContactPoint),
        );

        return {
          value: contactPoint.value!,
          label: isPrimary
            ? `${contactPoint.value ? formatPhoneString(contactPoint.value) + ' ' : ''}(Primary)`
            : formatPhoneString(contactPoint.value),
        };
      }),
    [contactPoints, caregiver],
  );

  const handleSmsConsentChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const initialConsent = consent;
    const caregiverId = caregiver?.id;
    if (!caregiverId) {
      return;
    }
    setConsent(event.target.checked);

    try {
      await updateCaregiverSmsConsent(medplum, caregiverId!, event.target.checked);
    } catch (error: unknown) {
      logError(error);
      setConsent(initialConsent);
      showNotification({
        title: 'Error',
        message: 'Unable to update caregiver consent. Please try again.',
        color: 'status-error',
      });
    }
  };

  const handleSendAppDownloadLink = async (): Promise<void> => {
    if (!consent) {
      showNotification({
        title: 'Consent Required',
        message: 'Consent to send text messages is required.',
        color: 'status-error',
      });
      return;
    }
    setLoading(true);
    try {
      if (selectedPhoneNumber && caregiver.id) {
        await sendAppDownloadLink(apiClient, selectedPhoneNumber, caregiver.id);
        await refetch();
        showNotification({
          title: 'Success',
          message: 'App download link sent successfully.',
          color: 'green',
        });
      }
    } catch (error) {
      logError(error);
      showNotification({
        title: 'Error',
        message: 'Failed to send app download link.',
        color: 'status-error',
      });
    } finally {
      setSelectedPerson(undefined);
      setLoading(false);
    }
  };

  return (
    <Modal
      opened={!!selectedPerson}
      onClose={() => {
        setSelectedPerson(undefined);
      }}
      title="Send app download link"
      centered
      radius="lg"
      size="lg"
      c="imagine-green"
    >
      {!hasSmsConsent && (
        <Paper withBorder p="md" mb="md" radius="md">
          <Text mb="xs">Consent to send text messages *</Text>
          <Checkbox
            id="consent"
            label="Caregiver has given consent to receive text messages"
            checked={consent}
            onChange={handleSmsConsentChange}
            fw="bold"
            required
          />
        </Paper>
      )}
      {consent && (
        <Select
          label="Select phone number"
          description={
            <>
              <strong>NOTE:</strong> This will be the phone number the caregiver uses to log in
            </>
          }
          placeholder="Phone number"
          required
          value={selectedPhoneNumber}
          onChange={(value) => setSelectedPhoneNumber(value as string)}
          data={phoneNumbersWithPrimary}
          searchable={false}
        />
      )}

      <Group mt="md" justify="flex-end">
        <Button onClick={() => setSelectedPerson(undefined)} variant="outline" radius="md">
          Cancel
        </Button>
        <Button onClick={handleSendAppDownloadLink} radius="md" loading={loading}>
          Send app download link
        </Button>
      </Group>
    </Modal>
  );
};
