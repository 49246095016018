import { PatientEligibilityBadge } from '@/components/shared/patient/PatientEligibilityBadge';
import { Button, Group, Stack, Text, Input, Select, Box } from '@mantine/core';
import { Maybe, Patient } from 'medplum-gql';
import { HumanName } from '@medplum/fhirtypes';
import { CodingInput, HumanNameDisplay } from '@medplum/react';
import React, { useMemo } from 'react';
import classes from './LinkedPatients.module.css';
import { compact } from 'lodash';
import { HL7ValueSet } from 'const-utils';
import { IconLink, IconPlus } from '@tabler/icons-react';
import { useLinkPatient } from './LinkPatientContext';
import { formatDateOfBirth } from 'imagine-dsl/utils/strings';
import { caregiversFromPatient } from 'imagine-dsl/utils/patient';
import { formatHumanName } from '@medplum/core';
import { isDefined } from 'imagine-dsl/utils/lists';
import { getUnlinkedRecommendations } from '@/hooks/useRecommendedLinks';

const caregiversToSelectOptions = (caregivers: Maybe<Patient>[]): { value: string; label: string }[] => {
  return compact(caregivers)
    .map((caregiver) => {
      const caregiverName = caregiver.name?.at(0);
      if (!caregiverName) {
        return undefined;
      }

      const label = formatHumanName(caregiverName as HumanName);
      if (!label) {
        return undefined;
      }
      if (!caregiver.id) {
        return undefined;
      }

      return {
        value: caregiver.id,
        label,
      };
    })
    .filter(isDefined);
};

interface LinkPatientRowsProps {
  linkPatients: Patient[];
  primaryPatient: Patient;
}

export function LinkPatientRows({ linkPatients, primaryPatient }: LinkPatientRowsProps): JSX.Element {
  const {
    addSelectedCaregiverForPatient,
    onRelationshipSelected,
    selectedRelationship,
    onCreateLinkClick: onLinkClick,
    linkingLoading,
    linkingDisabled,
    linking,
    onNewLinkClick,
  } = useLinkPatient();

  const primaryPatientCaregivers = useMemo(() => caregiversFromPatient(primaryPatient), [primaryPatient]);
  const primaryPatientCaregiverSelectOptions = useMemo(
    () => caregiversToSelectOptions(primaryPatientCaregivers),
    [primaryPatientCaregivers],
  );

  const unlinkedPatients = useMemo(() => {
    return getUnlinkedRecommendations(primaryPatient, linkPatients) ?? [];
  }, [primaryPatient, linkPatients]);

  return (
    <>
      {unlinkedPatients.map((patientResult) => {
        const isLinking = linking(patientResult.id!);
        const dob = formatDateOfBirth(patientResult.birthDate);

        return (
          <Group key={patientResult.id} justify="space-between" py="md" className={classes.lineSeparatedListItem}>
            <Stack gap={0} style={{ width: '100%' }}>
              <Group>
                <Box style={{ flexGrow: 1 }}>
                  <Text component="span" className={classes.patientName} style={{ marginRight: '.5rem' }}>
                    <HumanNameDisplay value={patientResult.name?.[0] as HumanName} />
                  </Text>
                  <PatientEligibilityBadge size="sm" variant="outline" patientTags={patientResult.meta?.tag || []} />
                </Box>
                {!isLinking && (
                  <Button
                    variant="outline"
                    onClick={() => onNewLinkClick(patientResult)}
                    leftSection={<IconPlus size={16} />}
                  >
                    New Link
                  </Button>
                )}
              </Group>
              <Text>
                {dob.dateOfBirth}
                {dob.age && <Text component="span"> ({dob.age})</Text>}
              </Text>
            </Stack>
            {isLinking && (
              <Group style={{ width: '100%' }}>
                {primaryPatientCaregivers.length > 0 && (
                  <>
                    <Select
                      required
                      style={{ flexGrow: 1, maxWidth: '40%' }}
                      label="Caregiver"
                      onChange={(value) => addSelectedCaregiverForPatient(value!, patientResult.id!)}
                      data={primaryPatientCaregiverSelectOptions}
                      placeholder="Caregiver to link through"
                    />
                    <Input.Wrapper required label="Relationship to patient" style={{ flexGrow: 1, maxWidth: '40%' }}>
                      <Input
                        path="patient-relationship"
                        name="patient-relationship"
                        className={classes.codingInput}
                        binding={HL7ValueSet.RelatedPersonRelationshipType}
                        placeholder={selectedRelationship[patientResult.id ?? ''] ? undefined : 'Please select'}
                        component={CodingInput}
                        onChange={(coding) => onRelationshipSelected(patientResult.id!, coding)}
                        withHelpText={false}
                      />
                    </Input.Wrapper>
                    <Button
                      className={classes.linkPatientButton}
                      radius="md"
                      leftSection={<IconLink className={classes.linkIcon} />}
                      onClick={() => onLinkClick(patientResult, primaryPatient)}
                      loading={linkingLoading(patientResult.id!)}
                      disabled={linkingDisabled(patientResult.id!)}
                    >
                      Create link
                    </Button>
                  </>
                )}
                {primaryPatientCaregivers.length === 0 && (
                  <Text>A Contact must be added before a patient can be linked</Text>
                )}
              </Group>
            )}
          </Group>
        );
      })}
    </>
  );
}
