import { RemoteAudioTrack, RemoteParticipant } from 'twilio-video';
import useRemoteTracks from './useRemoteTracks';
import React from 'react';
import AudioElement from './AudioElement';

function RemoteParticipantAudio({ participant }: { participant: RemoteParticipant }): JSX.Element | null {
  const tracks = useRemoteTracks(participant);
  const audioTrack = tracks.find((track) => track.kind === 'audio') as RemoteAudioTrack | undefined;

  if (audioTrack?.kind === 'audio') {
    return <AudioElement track={audioTrack} />;
  }

  return null;
}

export function ParticipantAudioTracks({ participants }: { participants: RemoteParticipant[] }): JSX.Element {
  return (
    <>
      {participants.map((participant) => (
        <RemoteParticipantAudio key={participant.sid} participant={participant} />
      ))}
    </>
  );
}
