import { Client } from '@/hooks/useApiClient';
import {
  RegisterRequest,
  RegisterResponse,
  RegisterSuccessResponse,
  SMSErrorResponse,
} from 'imagine-dsl/services/registerService';

export const sendTempCredentials = async (
  client: Client,
  caregiverId: string,
  phoneNumber: string,
): Promise<RegisterSuccessResponse | SMSErrorResponse> => {
  const body: RegisterRequest = {
    caregiverId,
    phoneNumber,
  };

  const response = await client.fetch('/api/enrollment/register', {
    method: 'POST',
    body: JSON.stringify(body),
  });

  const result = (await response.json()) as RegisterResponse;

  if (!result.success) {
    if (result.errorType === 'RegisterError') {
      throw new Error(result.error);
    }

    return {
      success: false,
      error: result.error,
      errorType: result.errorType,
      generatedPassword: result.generatedPassword,
    };
  }

  return result;
};

export const validateCredentials = async (credentials: string | null): Promise<void> => {
  if (!credentials) {
    throw new Error('Sending temporary password is required');
  }
};
