import { System, TaskType } from 'const-utils';
import { BaseTaskWrapper } from './baseTask';
import { Maybe, PractitionerInfoFragment } from 'medplum-gql';

export class CarePathwayReferralTask extends BaseTaskWrapper<TaskType.CarePathwayReferralReview> {
  get owner(): Maybe<PractitionerInfoFragment> {
    if (this.task.owner?.resource?.__typename === 'Practitioner') {
      return this.task.owner.resource;
    }
    return null;
  }

  get extension() {
    return this.task.extension;
  }

  get requester() {
    return this.task.requester;
  }

  get referralType() {
    return this.task.meta?.tag?.find((t) => t.system === System.CarePathwayReferralType.toString())?.code;
  }

  get referralNoteCode() {
    return this.extension?.find((e) => e.url === System.CarePathwayReferralReviewNote.toString())?.valueCode;
  }

  inputBySystem(system: string) {
    return this.task?.input?.find((i) => i.type.coding?.some((c) => c.system === system));
  }

  get requestedPathway() {
    return this.inputBySystem(System.CarePathway)?.valueString;
  }

  get originalPathway() {
    return this.extension?.find((e) => e.url === System.OriginalCarePathwayValue.toString())?.valueString;
  }

  get reasonsForReferral() {
    return this.inputBySystem(System.CarePathwayReferralReason)?.valueCodeableConcept?.coding?.map((c) => c.display);
  }

  get icd10Codes() {
    return this.inputBySystem(System.CarePathwayReferralAssociatedDiagnoses)?.valueCodeableConcept?.coding?.map(
      (c) => c.display,
    );
  }

  get description() {
    return this.inputBySystem(System.CarePathwayReferralDescription)?.valueString;
  }
}
