import { Task } from '@medplum/fhirtypes';
import { System, TaskType } from 'const-utils';
import {
  Maybe,
  PatientFromTaskFragment,
  TaskInfoFragment,
  BaseTaskInfoFragment,
  SurveyTaskInfoFragment,
  SurveyReviewTaskFragment,
  OutreachTaskInfoFragment,
  CareTeam,
  PractitionerInfoFragment,
} from 'medplum-gql';
import { SurveyReviewTask } from './surveyReviewTask';
import { CarePathwayReferralTask } from './carePathwayReferralTask';
import { SurveyTask } from './surveyTask';
import { OutreachTask } from './outreachTask';
import { BHSurveyType } from 'const-utils/codeSystems/ImaginePediatrics';
import { isAfter } from 'date-fns';

// Define a type that maps each task type to another TypeScript type
export type TaskTypeMap = {
  [TaskType.ScreenerReview]: SurveyReviewTaskFragment;
  [TaskType.ReviewBHSurvey]: SurveyReviewTaskFragment;
  [TaskType.CarePathwayReferralReview]: TaskInfoFragment;
  [TaskType.Chat]: TaskInfoFragment;
  [TaskType.Document]: TaskInfoFragment;
  [TaskType.Screener]: SurveyTaskInfoFragment;
  [TaskType.BHSurvey]: SurveyTaskInfoFragment;
  [TaskType.AppointmentRequest]: OutreachTaskInfoFragment;
  [TaskType.Callback]: OutreachTaskInfoFragment;
  [TaskType.CallPartneringProvider]: OutreachTaskInfoFragment;
  [TaskType.FirstCall]: OutreachTaskInfoFragment;
  [TaskType.InformationRequest]: OutreachTaskInfoFragment;
  [TaskType.InformationRequestVerification]: OutreachTaskInfoFragment;
  [TaskType.NetworkEngagement]: OutreachTaskInfoFragment;
  [TaskType.OutreachFollowUp]: OutreachTaskInfoFragment;
  [TaskType.BHTOC]: BaseTaskInfoFragment;
};

// Create a generic interface using conditional types
interface IBaseTask<T extends keyof TaskTypeMap> {
  task: TaskTypeMap[T];
}

export class BaseTask {
  constructor(public task: BaseTaskInfoFragment) {
    this.task = task;
  }

  static fromSurveyReviewTask(task: SurveyReviewTask): BaseTask {
    return new BaseTask(task.task);
  }

  get id(): string | null | undefined {
    return this.task.id;
  }

  get authoredOn(): string | null | undefined {
    return this.task.authoredOn;
  }

  get lastModified(): string | null | undefined {
    return this.task.lastModified;
  }

  get isResolved(): boolean {
    return (
      this.status === 'completed' ||
      this.status === 'cancelled' ||
      this.status === 'rejected' ||
      this.status === 'accepted'
    );
  }

  get assigned(): boolean {
    return !!this.task.owner;
  }

  get completed(): boolean {
    return this.status === 'completed';
  }

  get dueDate(): string | null | undefined {
    return this.task.restriction?.period?.end;
  }

  get isOverdue(): boolean {
    if (this.task.restriction?.period?.end === undefined || this.task.restriction?.period?.end === null) {
      return false;
    }
    return isAfter(new Date(), this.task.restriction?.period?.end);
  }

  get status(): Task['status'] {
    return this.task.status as Task['status'];
  }

  get taskType(): { code: string; display: string } {
    return {
      code: this.task.code?.coding?.[0].code ?? 'Unknown',
      display: this.task.code?.coding?.[0].display ?? 'Unknown',
    };
  }

  get carePathwayReferralType(): string | null | undefined {
    return this.task.meta?.tag?.find((e) => e.system === System.CarePathwayReferralType)?.code;
  }

  get owner(): Maybe<PractitionerInfoFragment> {
    if (this.task.owner?.resource?.__typename !== 'Practitioner') {
      return null;
    }

    return this.task.owner.resource;
  }

  get outreachTaskType() {
    return this.task?.code?.coding?.[0];
  }

  get priority(): Maybe<string> {
    return (
      this.task.businessStatus?.coding?.find((c) => c.system === System.TaskPriority)?.code ??
      this.task.priority ??
      null
    );
  }

  get type(): TaskType | null {
    return this.task?.meta?.tag?.find((t) => t.system === System.TaskType)?.code as TaskType;
  }

  get for(): Maybe<PatientFromTaskFragment> {
    if (this.task.for?.resource?.__typename === 'Patient') {
      return this.task.for.resource;
    } else {
      return null;
    }
  }

  get market(): Maybe<string> {
    if (this.for?.managingOrganization?.resource?.__typename === 'Organization') {
      return this.for.managingOrganization.resource.id ?? null;
    } else {
      return null;
    }
  }

  get pod(): { id: string; name: string } {
    const careTeam = this.for?.CareTeamList?.[0]?.participant?.find(
      (p) => p.member?.resource?.__typename === 'CareTeam',
    )?.member?.resource as CareTeam | undefined;

    return { id: careTeam?.id ?? 'unknown', name: careTeam?.name ?? 'Unknown' };
  }

  get isBHSurveyTask() {
    return this.type === TaskType.ReviewBHSurvey;
  }

  get bhSurveyType(): Maybe<BHSurveyType> {
    const bhType = this.task.meta?.tag?.find((tag) => tag.system === System.BHSurveyType)?.code;
    if (!bhType) {
      return null;
    }

    return bhType as BHSurveyType;
  }

  isCarePathwayTask(): this is CarePathwayReferralTask {
    return this.type === TaskType.CarePathwayReferralReview;
  }

  isSurveyReviewTask(): this is SurveyReviewTask {
    return this.type === TaskType.ScreenerReview || this.type === TaskType.ReviewBHSurvey;
  }

  isSurveyTask(): this is SurveyTask {
    return this.type === TaskType.Screener || this.type === TaskType.BHSurvey;
  }

  isOutreachTask(): this is OutreachTask {
    return (
      this.type === TaskType.AppointmentRequest ||
      this.type === TaskType.Callback ||
      this.type === TaskType.CallPartneringProvider ||
      this.type === TaskType.FirstCall ||
      this.type === TaskType.InformationRequest ||
      this.type === TaskType.InformationRequestVerification ||
      this.type === TaskType.NetworkEngagement ||
      this.type === TaskType.OutreachFollowUp
    );
  }
}

// Create a generic class using conditional types
export class BaseTaskWrapper<T extends keyof TaskTypeMap> implements IBaseTask<T> {
  constructor(public task: TaskTypeMap[T]) {
    this.task = task;
  }

  get id(): string | null | undefined {
    return this.task.id;
  }

  get authoredOn(): string | null | undefined {
    return this.task.authoredOn;
  }

  get assigned(): boolean {
    return !!this.task.owner;
  }

  get completed(): boolean {
    return this.status === 'completed';
  }

  get dueDate(): string | null | undefined {
    return this.task.restriction?.period?.end;
  }

  get isOverdue(): boolean {
    if (this.task.restriction?.period?.end === undefined || this.task.restriction?.period?.end === null) {
      return false;
    }
    return isAfter(new Date(), this.task.restriction?.period?.end);
  }

  get status(): Task['status'] {
    return this.task.status as Task['status'];
  }

  get type(): TaskType | null {
    return this.task?.meta?.tag?.find((t) => t.system === System.TaskType)?.code as TaskType;
  }

  get for(): Maybe<PatientFromTaskFragment> {
    if (this.task.for?.resource?.__typename === 'Patient') {
      return this.task.for.resource;
    } else {
      return null;
    }
  }

  get market(): Maybe<string> {
    if (this.for?.managingOrganization?.resource?.__typename === 'Organization') {
      return this.for.managingOrganization.resource.id ?? null;
    } else {
      return null;
    }
  }

  get isResolved(): boolean {
    return (
      this.status === 'completed' ||
      this.status === 'cancelled' ||
      this.status === 'rejected' ||
      this.status === 'accepted'
    );
  }

  get engagementPod(): { id: string; name: string } {
    const careTeam = this.for?.CareTeamList?.[0]?.participant?.[0]?.member?.resource as CareTeam | undefined;
    return { id: careTeam?.id ?? 'unknown', name: careTeam?.name ?? 'Unknown' };
  }

  isCarePathwayTask(): this is CarePathwayReferralTask {
    return this.type === TaskType.CarePathwayReferralReview;
  }

  isSurveyReviewTask(): this is SurveyReviewTask {
    return this.type === TaskType.ScreenerReview || this.type === TaskType.ReviewBHSurvey;
  }

  isSurveyTask(): this is SurveyTask {
    return this.type === TaskType.Screener || this.type === TaskType.BHSurvey;
  }

  isOutreachTask(): this is OutreachTask {
    return (
      this.type === TaskType.AppointmentRequest ||
      this.type === TaskType.Callback ||
      this.type === TaskType.CallPartneringProvider ||
      this.type === TaskType.FirstCall ||
      this.type === TaskType.InformationRequest ||
      this.type === TaskType.InformationRequestVerification ||
      this.type === TaskType.NetworkEngagement ||
      this.type === TaskType.OutreachFollowUp
    );
  }
}
