import { Group, Text, ThemeIcon } from '@mantine/core';
import { IconExclamationCircleFilled } from '@tabler/icons-react';
import React, { useMemo } from 'react';

interface AlertTitleProps {
  encounterId: string;
  currentTaskEncounterId: string;
  priority: 'routine' | 'urgent' | 'asap' | 'stat';
}

export function AlertTitle({ encounterId, currentTaskEncounterId, priority }: AlertTitleProps): JSX.Element {
  const titleColor = useMemo((): string => {
    return priority === 'urgent' ? 'status-error' : 'imagine-green';
  }, [priority]);

  return (
    <Group style={{ gap: 4 }}>
      {currentTaskEncounterId === encounterId && (
        <Text style={{ fontSize: 12 }} c={titleColor} fw="bold">
          Active Alert:
        </Text>
      )}
      {currentTaskEncounterId !== encounterId && (
        <Text style={{ fontSize: 12 }} c="imagine-gray.7" fw="bold">
          Alert:
        </Text>
      )}
      {priority === 'urgent' && (
        <>
          <ThemeIcon c="status-error">
            <IconExclamationCircleFilled />
          </ThemeIcon>
          <Text style={{ fontSize: 12 }} fw="bold">
            Urgent
          </Text>
        </>
      )}
      {priority !== 'urgent' && (
        <Text style={{ fontSize: 12 }} c="imagine-gray.7">
          Not urgent
        </Text>
      )}
    </Group>
  );
}
