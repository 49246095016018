import { Extension } from '@medplum/fhirtypes';
import { merge } from './lists';

export const addExtensions = (
  existingExtensions?: Extension[],
  newExtensions?: (Extension | undefined | null | false)[],
): Extension[] => {
  const _existingExtensions = existingExtensions ?? [];
  const _newExtensions = (newExtensions ?? []).filter((e) => !!e) as Extension[];

  return merge(_existingExtensions, _newExtensions, 'url');
};

export const selectExtension = (extensions: Extension[] | undefined, url: string): Extension | undefined => {
  return extensions?.find((e) => e.url === url);
};
