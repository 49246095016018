export const enum BHSurveyType {
  GAD7 = 'gad7',
  PHQ9 = 'phq9',
}

export const enum GAD7ResponseCategory {
  LowRisk = 'No to Low Risk',
  Mild = 'Mild',
  Moderate = 'Moderate',
  Severe = 'Severe',
}

export const enum PHQ9ResponseCategory {
  NonOrMinimal = 'Non-Minimal',
  Mild = 'Mild',
  Moderate = 'Moderate',
  ModeratelySevere = 'Moderately Severe',
  Severe = 'Severe',
}

export const enum QuestionnaireType {
  Screener = 'screener',
  BHSurvey = 'bh-survey',
}

export const enum QuestionnaireResponseCategory {
  Positive = 'positive',
  Negative = 'negative',
  SuicideRisk = 'suicide risk',
  PendingScore = 'pending score',
}

export enum QuestionnaireItemType {
  group = 'group',
  display = 'display',
  question = 'question',
  boolean = 'boolean',
  decimal = 'decimal',
  integer = 'integer',
  date = 'date',
  dateTime = 'dateTime',
  time = 'time',
  string = 'string',
  text = 'text',
  url = 'url',
  choice = 'choice',
  openChoice = 'open-choice',
  attachment = 'attachment',
  reference = 'reference',
  quantity = 'quantity',
}
