import { MedplumClient } from '@medplum/core';
import { HL7System, PreferredLanguage, System } from 'const-utils';

const languagePreferenceDataRetention = async (
  languagePreferenceValue: string,
  requiresInterpreterValue: string,
  patientId: string,
  medplum: MedplumClient,
): Promise<void> => {
  const preferredLanguageData =
    PreferredLanguage?.compose?.include?.flatMap(
      (c) => c.concept?.map(({ code, display }) => ({ code, display })) ?? [],
    ) ?? [];

  const refreshedPatient = await medplum.readResource('Patient', patientId);
  const code = preferredLanguageData.find((language) => language.display === languagePreferenceValue)?.code;
  const filteredExtensions =
    refreshedPatient?.extension?.filter((ext) => {
      return ext.url !== HL7System.InterpreterRequired.toString();
    }) ?? [];
  const updatedExtensions = [
    ...filteredExtensions,
    {
      url: HL7System.InterpreterRequired,
      valueBoolean: requiresInterpreterValue === 'yes',
    },
  ];
  const newLanguagePreference = {
    language: {
      coding: [
        {
          system: System.PreferredLanguage,
          code: code,
          display: languagePreferenceValue,
        },
      ],
    },
  };

  await medplum.updateResource({
    ...refreshedPatient,
    extension: updatedExtensions,
    communication: [newLanguagePreference],
  });
};

export default languagePreferenceDataRetention;
