import { MedplumClient, formatHumanName } from '@medplum/core';
import { Composition, CompositionSection, Practitioner } from '@medplum/fhirtypes';
import { System } from 'const-utils';
import { CompositionType } from 'const-utils/codeSystems/ImaginePediatrics';

interface PatientNoteArgs {
  patientId: string;
  note: string;
  author: Practitioner;
}

export const createPatientNote = async (medplum: MedplumClient, args: PatientNoteArgs): Promise<Composition> => {
  const { patientId, note, author } = args;

  const composition: Composition = {
    resourceType: 'Composition',
    status: 'final',
    title: 'Patient Note',
    date: new Date().toISOString(),
    type: {
      coding: [
        {
          system: System.CompositionType,
          code: CompositionType.PatientNote,
          display: 'Patient Note',
        },
      ],
      text: 'Patient Note',
    },
    subject: {
      reference: 'Patient/' + patientId,
    },
    author: [
      {
        reference: 'Practitioner/' + author.id,
        display: author.name ? formatHumanName(author.name?.[0]) : undefined,
      },
    ],
    section: [
      {
        title: 'Patient Note',
        text: {
          status: 'generated',
          div: note,
        },
        extension: [
          {
            url: System.PatientNoteCreation,
            valueDateTime: new Date().toISOString(),
          },
        ],
        author: [
          {
            reference: 'Practitioner/' + author.id,
            display: author.name ? formatHumanName(author.name?.[0]) : undefined,
          },
        ],
      },
    ],
  };

  return medplum.createResource(composition);
};

interface EditPatientNoteArgs {
  noteId: string;
  text: string;
  author: Practitioner;
}

export const editPatientNote = async (
  medplum: MedplumClient,
  { noteId, text, author }: EditPatientNoteArgs,
): Promise<Composition> => {
  const section: CompositionSection = {
    text: {
      status: 'additional',
      div: text,
    },
    extension: [
      {
        url: System.PatientNoteCreation,
        valueDateTime: new Date().toISOString(),
      },
    ],
    author: [
      {
        reference: 'Practitioner/' + author.id,
        display: author.name ? formatHumanName(author.name?.[0]) : undefined,
      },
    ],
  };

  return medplum.patchResource('Composition', noteId, [
    {
      op: 'add',
      path: '/section/0',
      value: section,
    },
  ]);
};

export const deletePatientNote = async (medplum: MedplumClient, noteIds: string[]): Promise<void> => {
  noteIds.forEach(async (noteId) => {
    await medplum.deleteResource('Composition', noteId);
  });
};
