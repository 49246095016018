import { Text, Button, Stack, InputLabel } from '@mantine/core';
import { IconEye } from '@tabler/icons-react';
import React from 'react';
import { useOutreach } from '../Context';
import { checkConsents } from '../handlers/checkConsents';
import { InAppPreview } from '../InAppPreview';
import { SuccessMessage } from '../SuccessMessage';
import { useMedplum } from '@medplum/react';
import imageUrl from '@assets/images/in-app-previews/consents.png';

export const CheckConsentsStep = (): JSX.Element => {
  const medplum = useMedplum();
  const { patient, wrapHandler, error, loading, consentsSignedAt, setConsentsSignedAt, setConsentsCheckedAt } =
    useOutreach();

  const handleCheckConsents = async (): Promise<void> =>
    wrapHandler(async () => {
      const { isSigned, signedAt } = await checkConsents(medplum, patient);

      if (isSigned && signedAt) {
        setConsentsCheckedAt(new Date());
        setConsentsSignedAt(signedAt);
      } else {
        throw new Error('Caregiver has not signed consents');
      }
    });

  return (
    <form id="send-credentials-form">
      <InAppPreview imageUrl={imageUrl}>
        <Stack gap="md">
          <InputLabel required>Check consents</InputLabel>
          <Text size="xs" c="gray">
            NOTE: Caregivers must open and agree to each document before they continue.
          </Text>
          <Button
            loading={loading}
            disabled={!!consentsSignedAt}
            leftSection={<IconEye />}
            onClick={handleCheckConsents}
            variant="outline"
            maw={200}
          >
            Check consents
          </Button>
          <Stack gap="xs">
            {error && <Text color="red">{error}</Text>}
            {consentsSignedAt && (
              <SuccessMessage>
                Consents signed by caregiver at: <strong>{consentsSignedAt.toLocaleString()}</strong>
              </SuccessMessage>
            )}
          </Stack>
        </Stack>
      </InAppPreview>
    </form>
  );
};
