import React from 'react';
import { WaitTimeBadge } from '../WaitTimeBadge';

import classes from './TaskTable.module.css';
import { Maybe } from 'medplum-gql';
import { format } from 'date-fns';

interface WaitTimeColumnProps {
  sent?: Maybe<string>;
  practitionerResponded: boolean;
}

export function WaitTimeColumn({ sent, practitionerResponded }: WaitTimeColumnProps): JSX.Element {
  if (!sent) {
    return <></>;
  }

  if (!practitionerResponded) {
    return <WaitTimeBadge date={sent} />;
  }

  return <span className={classes.respondedAt}>Responded {format(sent, 'MM/d/yy, h:mm aaa')}</span>;
}
