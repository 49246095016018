import React, { useMemo, useState } from 'react';
import { TaskType } from 'const-utils';
import { CarePathwayReferralReviewDrawerContent } from './carePathwayReferralReview/DrawerContent';
import { SurveyReviewDrawerContent } from './screenerReview/DrawerContent';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { SurveyTask } from 'imagine-dsl/models/tasks/surveyTask';
import { match } from 'ts-pattern';
import { OutreachDrawerContent } from './outreach/DrawerContent';
import { Maybe, PatientFromTaskFragment } from 'medplum-gql';
import { ExpandableDrawer } from '@/design-system/ExpandableDrawer';
import { BHTOCDrawerContent } from './bhTOC/DrawerContent';

export type TaskDrawerContentRendererProps = {
  withViewPatientProfile?: boolean;
  patientId?: string | null;
  patient?: Maybe<PatientFromTaskFragment>;
  drawerExpanded: boolean;
  setDrawerExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  closeDrawer: () => void;
  refetchTasks: () => Promise<unknown>;
};

export type TaskDrawerContentRenderer = (
  props: TaskDrawerContentRendererProps & {
    task?: BaseTask;
  },
) => React.ReactNode;

interface TaskDrawerProps {
  task?: BaseTask;
  surveyTask?: SurveyTask;
  patientId?: string;
  onClose: () => void;
  opened: boolean;
  refetchTasks: () => Promise<unknown>;
  withViewPatientProfile?: boolean;
}

export const TasksDrawer = ({
  task,
  patientId,
  onClose,
  opened,
  withViewPatientProfile,
  refetchTasks,
}: TaskDrawerProps): JSX.Element => {
  const [drawerExpanded, setDrawerExpanded] = useState(false);

  const taskDrawerContent = useMemo(() => {
    const contentProps: TaskDrawerContentRendererProps = {
      withViewPatientProfile,
      drawerExpanded,
      setDrawerExpanded,
      closeDrawer: onClose,
      refetchTasks,
    };

    if (!task) {
      // if (surveyTask) {
      //   return match(surveyTask)
      //     .with({ type: TaskType.Screener }, { type: TaskType.BHSurvey }, () => {
      //       return <SurveyReviewDrawerContent patientId={patientId} {...contentProps} />;
      //     })
      //     .otherwise(() => {
      //       return null;
      //     });
      // } else {
      //   return null;
      // }
      return null;
    }

    return match(task)
      .with({ type: TaskType.CarePathwayReferralReview }, () => {
        return task?.isCarePathwayTask() ? (
          <CarePathwayReferralReviewDrawerContent task={task} {...contentProps} />
        ) : null;
      })
      .with({ type: TaskType.ScreenerReview }, { type: TaskType.ReviewBHSurvey }, () => {
        return task?.isSurveyReviewTask() ? (
          <SurveyReviewDrawerContent task={task} patientId={task.for?.id ?? patientId} {...contentProps} />
        ) : null;
      })
      .with({ type: TaskType.BHTOC }, () => {
        return <BHTOCDrawerContent task={task} patientId={task.for?.id ?? patientId} {...contentProps} />;
      })
      .with({ type: TaskType.ScheduleAppointmentBHTOC }, () => {
        return <OutreachDrawerContent task={task} patient={task.for} {...contentProps} />;
      })
      .with({ type: TaskType.ScheduleVisitsForCarePathwayChange }, () => {
        return <OutreachDrawerContent task={task} patient={task.for} {...contentProps} />;
      })
      .with({ type: TaskType.CancelAllLongitudinalAppointments }, () => {
        return <OutreachDrawerContent task={task} patient={task.for} {...contentProps} />;
      })
      .when(
        (task) => task.isOutreachTask(),
        (task) => {
          return task.isOutreachTask() ? (
            <OutreachDrawerContent task={task} patient={task.for} {...contentProps} />
          ) : null;
        },
      )
      .otherwise(() => {
        return null;
      });
  }, [drawerExpanded, onClose, patientId, refetchTasks, task, withViewPatientProfile]);

  const closeDrawer = () => {
    setDrawerExpanded(false);
    onClose();
  };

  if (!task) {
    return <></>;
  }

  if (task?.isSurveyReviewTask()) {
    return (
      <ExpandableDrawer expanded={drawerExpanded} id="task-drawer" size="xxl" opened={opened} close={onClose}>
        {taskDrawerContent}
      </ExpandableDrawer>
    );
  }

  return (
    <ExpandableDrawer id="task-drawer" size="xxl" opened={opened} close={closeDrawer}>
      {taskDrawerContent}
    </ExpandableDrawer>
  );
};
