import { Coding, Extension } from '@medplum/fhirtypes';

const InteractionFilter = (interaction: 'create' | 'update' | 'delete'): Extension => ({
  url: 'https://medplum.com/fhir/StructureDefinition/subscription-supported-interaction',
  valueCode: interaction,
});

const SubscriptionSecret = (secret: string): Extension => ({
  url: 'https://www.medplum.com/fhir/StructureDefinition/subscription-secret',
  valueString: secret,
});

const SubscriptionFilterExpression = (expression: string): Extension => ({
  url: 'https://medplum.com/fhir/StructureDefinition/fhir-path-criteria-expression',
  valueString: expression,
});

export const EssentialSubscription: Coding = {
  system: 'https://imaginepediatrics.org/essential-subscription',
  code: 'essential',
};

export const SubscriptionName = (name: string): Coding => ({
  system: 'https://imaginepediatrics.org/subscription-name',
  code: name,
});

export default {
  InteractionFilter,
  SubscriptionSecret,
  SubscriptionFilterExpression,
};
