import { Organization, Practitioner, Task } from '@medplum/fhirtypes';
import { CareTeam, GetIncompleteChatTasksQuery, Maybe, Patient } from 'medplum-gql';
import { pediatricRnFromPatient } from 'imagine-dsl/utils/patient';
import { isSuicideAlertTask } from 'imagine-dsl/services/taskService';

export type SupportedFields = 'assigneeId' | 'patientId' | 'category' | 'primaryRnId' | 'marketId';

export type FilterValues = {
  [key in SupportedFields]: Maybe<string>;
};

const FILTERER: {
  [key in SupportedFields]: (
    tasks: GetIncompleteChatTasksQuery['TaskList'],
    value: string,
  ) => GetIncompleteChatTasksQuery['TaskList'];
} = {
  assigneeId: (
    tasks: GetIncompleteChatTasksQuery['TaskList'],
    value: string,
  ): GetIncompleteChatTasksQuery['TaskList'] => {
    return filterAssignee(tasks, value);
  },
  category: (
    tasks: GetIncompleteChatTasksQuery['TaskList'],
    value: string,
  ): GetIncompleteChatTasksQuery['TaskList'] => {
    return filterCategory(tasks, value);
  },
  patientId: (
    tasks: GetIncompleteChatTasksQuery['TaskList'],
    value: string,
  ): GetIncompleteChatTasksQuery['TaskList'] => {
    return filterPatient(tasks, value);
  },
  primaryRnId: (
    tasks: GetIncompleteChatTasksQuery['TaskList'],
    value: string,
  ): GetIncompleteChatTasksQuery['TaskList'] => {
    return filterPrimaryRn(tasks, value);
  },
  marketId: (
    tasks: GetIncompleteChatTasksQuery['TaskList'],
    value: string,
  ): GetIncompleteChatTasksQuery['TaskList'] => {
    return filterMarket(tasks, value);
  },
};

export const filterBy = (
  tasks: GetIncompleteChatTasksQuery['TaskList'],
  value: Maybe<string>,
  field: SupportedFields,
): GetIncompleteChatTasksQuery['TaskList'] => {
  if (!value) {
    return tasks;
  }

  return FILTERER[field](tasks, value);
};

const filterAssignee = (
  tasks: GetIncompleteChatTasksQuery['TaskList'],
  value: string,
): GetIncompleteChatTasksQuery['TaskList'] => {
  return tasks?.filter((t) => {
    const assignee = t?.owner?.resource as Practitioner;
    if (value === 'unassigned') {
      return !assignee;
    } else {
      return assignee?.id === value;
    }
  });
};

const filterPatient = (
  tasks: GetIncompleteChatTasksQuery['TaskList'],
  value: string,
): GetIncompleteChatTasksQuery['TaskList'] => {
  return tasks?.filter((t) => {
    const patient = t?.for?.resource as Patient;
    return patient?.id === value;
  });
};

const filterCategory = (
  tasks: GetIncompleteChatTasksQuery['TaskList'],
  value: string,
): GetIncompleteChatTasksQuery['TaskList'] => {
  return tasks?.filter((t) => {
    if (value === 'suicide-risk') {
      return isSuicideAlertTask(t as Task);
    }

    const taskCategory = t?.priority || 'non-urgent';
    return value === 'urgent' ? taskCategory === value : taskCategory !== 'urgent';
  });
};

const filterPrimaryRn = (
  tasks: GetIncompleteChatTasksQuery['TaskList'],
  value: string,
): GetIncompleteChatTasksQuery['TaskList'] => {
  return tasks?.filter((t) => {
    const taskPrimaryRn = pediatricRnFromPatient((t?.for?.resource as Patient)?.CareTeamList as CareTeam[]);
    return taskPrimaryRn?.id === value;
  });
};

const filterMarket = (
  tasks: GetIncompleteChatTasksQuery['TaskList'],
  value: string,
): GetIncompleteChatTasksQuery['TaskList'] => {
  return tasks?.filter((t) => {
    const patient = t?.for?.resource as Patient;
    const taskMarket = patient?.managingOrganization?.resource as Organization;
    return taskMarket?.id === value;
  });
};
