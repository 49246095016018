import { useApiClient } from '@/hooks/useApiClient';
import { Text, Select, Button, Stack, Input } from '@mantine/core';
import { IconDeviceMobileUp } from '@tabler/icons-react';
import React from 'react';
import { useOutreach } from '../Context';
import { sendTempCredentials } from '../handlers/sendCredentials';
import { InAppPreview } from '../InAppPreview';
import { SuccessMessage } from '../SuccessMessage';
import { getPatientCaregiver, getCaregiverPhoneNumbers, getPhoneNumberOptions } from '../util';
import appImage from '@assets/images/in-app-previews/send-credentials.png';

export const SendCredentialsStep = (): JSX.Element => {
  const {
    patient,
    wrapHandler,
    setError,
    appDownloadForm,
    credentials,
    setCredentials,
    credentialsSentAt,
    sendCredentialsForm,
    setCredentialsSentAt,
    error,
    loading,
  } = useOutreach();

  const apiClient = useApiClient();
  const caregiver = getPatientCaregiver(patient);
  const phoneNumbers = getCaregiverPhoneNumbers(caregiver);
  const phoneNumberOptions = getPhoneNumberOptions(phoneNumbers);

  const handleSendSendCredentialsLink = async (): Promise<void> =>
    wrapHandler(async () => {
      sendCredentialsForm.validate();

      if (!sendCredentialsForm.isValid()) {
        return;
      }

      // This should never happen
      if (!caregiver) {
        return;
      }

      const phoneNumber = hasSmsConsent ? appDownloadForm.values.phoneNumber : sendCredentialsForm.values.phoneNumber;

      const { success, generatedPassword, ...rest } = await sendTempCredentials(apiClient, caregiver.id!, phoneNumber);

      setCredentials(generatedPassword);
      if (!success && 'error' in rest) {
        if (rest.errorType !== 'SendSMSError') {
          setCredentialsSentAt(new Date());
        }
        setError(rest.error);
      } else {
        setError(null);
        setCredentialsSentAt(new Date());
      }
    });

  const hasSmsConsent = appDownloadForm.values.consent;

  let ctaText;

  if (hasSmsConsent) {
    ctaText = credentials ? 'Resend temporary password' : 'Send temporary password';
  } else {
    ctaText = credentials ? 'Regenerate temporary password' : 'Generate temporary password';
  }

  return (
    <form id="send-credentials-form">
      <InAppPreview imageUrl={appImage}>
        <Stack gap="md">
          {hasSmsConsent ? (
            <Input.Wrapper label="Send temporary password" required>
              <Text c="dimmed">
                Password will be texted to the caregiver’s phone number. The caregiver can use this password to log in
                for the first time.
              </Text>
            </Input.Wrapper>
          ) : (
            <>
              <Select
                label="Select phone number"
                description={
                  <Text>
                    <strong>NOTE:</strong> This will be the phone number the caregiver uses to log in
                  </Text>
                }
                placeholder="Phone number"
                required
                {...sendCredentialsForm.getInputProps('phoneNumber')}
                data={phoneNumberOptions}
                style={{
                  maxWidth: '289px',
                }}
              />
              <Input.Wrapper label="Generate temporary password" required>
                <Text c="dimmed">
                  Generate a temporary password the caregiver can use to log in for the first time.
                </Text>
              </Input.Wrapper>
            </>
          )}
          <Button
            loading={loading}
            disabled={loading}
            leftSection={<IconDeviceMobileUp />}
            onClick={handleSendSendCredentialsLink}
            variant="outline"
          >
            {ctaText}
          </Button>
          <Stack gap="xs">
            {error && <Text c="red">{error}</Text>}
            {credentials && (
              <Text>
                Temporary password: <strong>{credentials}</strong>
              </Text>
            )}
            {credentialsSentAt && (
              <SuccessMessage>
                Temporary password sent at: <strong>{credentialsSentAt.toLocaleString()}</strong>
              </SuccessMessage>
            )}
          </Stack>
        </Stack>
      </InAppPreview>
    </form>
  );
};
