import { ICD10AutocompleteNIHGOV } from '@/components/ICD10AutocompleteNIHGOV';
import { logError } from '@/errors';
import { useGetCarePathway } from '@/hooks/useGetCarePathway';
import { useApolloClient } from '@apollo/client';
import {
  Alert,
  Box,
  Button,
  CheckIcon,
  Combobox,
  Group,
  Modal,
  NativeSelect,
  Pill,
  PillsInput,
  ScrollAreaAutosize,
  Stack,
  Text,
  Textarea,
  useCombobox,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { createReference } from '@medplum/core';
import { CodeSystemConcept, Coding, Patient, Task, TaskInput } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { IconExclamationCircle } from '@tabler/icons-react';
import { System } from 'const-utils';
import {
  CarePathway,
  CarePathwayReferralType,
  TaskType,
  carePathwayDeescalationReferralReasons,
  carePathwayDisplay,
} from 'const-utils/codeSystems/ImaginePediatrics';
import { carePathwayReferralReasons } from 'const-utils/valueSets/CarePathwayReferralReason';
import { GetIncompleteTasksByPatientIdDocument } from 'medplum-gql';
import { isDefined } from 'imagine-dsl/utils/lists';
import { buildTask, getCarePathwayReviewTaskOwner } from 'imagine-dsl/utils/task';
import React, { useEffect, useState } from 'react';
import { useUserSession } from '@/components/shared/UserSessionContext';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';

type CarePathwayReferralForm = {
  carePathway: CarePathway;
  description: string;
  reasonsForReferral: CodeSystemConcept[];
  icd10Codes: Coding[];
};

interface CarePathwayReferralModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => Promise<void>;
  cluster: string;
  patientId: string;
  carePathway?: CarePathway;
}

export const CarePathwayReferralModal = ({
  isOpen,
  onClose,
  refetch,
  cluster,
  patientId,
  carePathway,
}: CarePathwayReferralModalProps) => {
  const { profile } = useUserSession();
  const [loading, setLoading] = useState(false);
  const medplum = useMedplum();
  const flags = useFeatureFlags();
  const [patient, setPatient] = useState<Patient>();

  useEffect(() => {
    medplum
      .readResource('Patient', patientId)
      .then((p) => setPatient(p))
      .catch((e) => logError(e));
  }, [medplum, patientId]);

  const patientCarePathwayFromCluster = cluster.split(':')[0] as CarePathway;

  const { carePathway: originalCarePathwayValue } = useGetCarePathway({ patientId: patient?.id });

  const form = useForm<CarePathwayReferralForm>({
    initialValues: {
      carePathway: carePathway ? carePathway : patientCarePathwayFromCluster,
      description: '',
      reasonsForReferral: [],
      icd10Codes: [],
    },
  });
  const apolloClient = useApolloClient();

  const downgradingCarePathway = form.isDirty('carePathway') && form.values.carePathway === CarePathway.CoreCare;

  if (!patient) {
    return <></>;
  }

  const handleSubmit = async (values: CarePathwayReferralForm) => {
    setLoading(true);
    const taskInput: (TaskInput | undefined)[] = [
      {
        valueString: values.carePathway,
        type: {
          coding: [
            {
              system: System.CarePathway,
            },
          ],
        },
      },
      values.description.length
        ? {
            valueString: values.description,
            type: {
              coding: [
                {
                  system: System.CarePathwayReferralDescription,
                },
              ],
            },
          }
        : undefined,
      values.reasonsForReferral.length
        ? {
            valueCodeableConcept: {
              coding: values.reasonsForReferral.map((reason) => ({
                system: System.CarePathwayReferralReason,
                code: reason.code,
                display: reason.display,
              })),
              text: values.reasonsForReferral.map((reason) => reason.display ?? reason.code).join(', '),
            },
            type: {
              coding: [
                {
                  system: System.CarePathwayReferralReason,
                },
              ],
            },
          }
        : undefined,
      values.icd10Codes.length
        ? {
            valueCodeableConcept: {
              coding: values.icd10Codes,
              text: values.icd10Codes.map((c) => c.display ?? c.code).join(', '),
            },
            type: {
              coding: [
                {
                  system: System.CarePathwayReferralAssociatedDiagnoses,
                },
              ],
            },
          }
        : undefined,
    ];

    try {
      const owner = flags.PathwayReviewOwners
        ? undefined
        : await getCarePathwayReviewTaskOwner(medplum, patient, values.carePathway as CarePathway);

      const carePathwayReferralReviewTask: Task = buildTask({
        status: 'requested',
        description: 'Care Pathway Referral Review',
        type: TaskType.CarePathwayReferralReview,
        for: createReference(patient),
        input: taskInput.filter(isDefined),
        requester: createReference(profile),
        owner: owner ? createReference(owner) : undefined,
        extension: [
          {
            url: System.OriginalCarePathwayValue,
            valueString: originalCarePathwayValue,
          },
        ],
        meta: {
          tag: [
            {
              system: System.CarePathwayReferralType,
              code: CarePathwayReferralType.Manual,
            },
          ],
        },
      });

      await medplum.createResourceIfNoneExist(
        carePathwayReferralReviewTask,
        `subject=Patient/${patient.id}&_tag=${TaskType.CarePathwayReferralReview}&status=requested,in-progress`,
      );

      notifications.show({
        message: 'Care pathway referral has been submitted for review.',
        color: 'status-success',
      });
      await refetch().catch(logError);
      apolloClient
        .refetchQueries({
          include: [GetIncompleteTasksByPatientIdDocument],
        })
        .catch((e) => logError(e));
      onClose();
    } catch (error) {
      logError(error);
      notifications.show({
        message: 'Error submitting care pathway referral for review',
        color: 'status-error',
      });
    } finally {
      setLoading(false);
    }
  };

  const carePathwayOptions = Object.entries(carePathwayDisplay).map(([pathwayCode, pathwayDisplay]) => ({
    value: pathwayCode,
    label: pathwayDisplay,
  }));

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title="Care pathway referral"
      size="xl"
      radius="lg"
      c="imagine-green"
      centered
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap="md" c="black">
          <Text>Select the care pathway you would like to request the patient be assigned to.</Text>
          <NativeSelect
            label="Care pathway"
            data={carePathwayOptions}
            required
            {...form.getInputProps('carePathway')}
          />
          {flags.DeescalateCarePathway ? (
            <></>
          ) : (
            downgradingCarePathway && (
              <Alert
                icon={<IconExclamationCircle />}
                color="red"
                variant="outline"
                title="Patients currently cannot be changed to Core Care. However, the option to return a patient to Core Care is
              coming soon."
              />
            )
          )}

          <CarePathwayReferralReasonSelect
            value={form.getInputProps('reasonsForReferral').value}
            onChange={form.getInputProps('reasonsForReferral').onChange}
            {...(flags.DeescalateCarePathway && { downgradingCarePathway })}
          />

          <ICD10AutocompleteNIHGOV label="Associated diagnoses (ICD-10)" {...form.getInputProps('icd10Codes')} />
          <Textarea label="Description" {...form.getInputProps('description')} />
          <Group mt="md" justify="flex-end">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={
                !form.isDirty('carePathway') ||
                !form.values.reasonsForReferral.length ||
                (flags.DeescalateCarePathway ? !form.isDirty('reasonsForReferral') : downgradingCarePathway)
              }
              loading={loading}
            >
              Submit for approval
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};

interface CarePathwayReferralReasonSelectProps {
  onChange: (option: CodeSystemConcept[]) => void;
  value: CodeSystemConcept[];
  downgradingCarePathway?: boolean;
}

export const CarePathwayReferralReasonSelect = ({
  onChange,
  value,
  downgradingCarePathway,
}: CarePathwayReferralReasonSelectProps) => {
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      setSearch('');
    },
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const [search, setSearch] = useState('');

  const handleValueSelect = (val: CodeSystemConcept) =>
    onChange(value.some((c) => c.code === val.code) ? value.filter((v) => v.code !== val.code) : [...value, val]);

  const handleValueRemove = (val: CodeSystemConcept) => onChange(value.filter((v) => v.code !== val.code));

  const carePathwayDeescalationReferralReasonsOptions = Object.entries(carePathwayDeescalationReferralReasons).map(
    ([reasonCode, reasonDisplay]) => ({
      code: reasonCode,
      display: reasonDisplay,
    }),
  );

  const options = (downgradingCarePathway ? carePathwayDeescalationReferralReasonsOptions : carePathwayReferralReasons)
    .filter((reason) => {
      const searchLower = search.toLowerCase();
      return reason.display?.toLowerCase().includes(searchLower) || reason.code.toLowerCase().includes(searchLower);
    })
    .map((reason) => {
      const active = value.some((v) => v.code === reason.code);
      const [title, subText] = reason.display?.split(' (ie:') ?? [reason?.display, ''];

      return (
        <Combobox.Option value={reason.code} key={reason.code} active={active}>
          <Group gap={0} wrap="nowrap" align="start">
            {active && (
              <Box h={8} mr={4}>
                <CheckIcon height="100%" />
              </Box>
            )}
            <Stack gap={0}>
              <Text fw="bold">{title}</Text>
              {subText ? (
                <Text size="xs" c="dimmed" style={{ fontStyle: 'italic' }}>
                  (ie: {subText.trim()}
                </Text>
              ) : null}
            </Stack>
          </Group>
        </Combobox.Option>
      );
    });

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(v) =>
        handleValueSelect(
          downgradingCarePathway
            ? carePathwayDeescalationReferralReasonsOptions.find((item) => item.code === v)!
            : carePathwayReferralReasons.find((item) => item.code === v)!,
        )
      }
    >
      <Combobox.DropdownTarget>
        <PillsInput required label="Reason(s) for referral" onClick={() => combobox.openDropdown()}>
          <Pill.Group>
            {value.map((item) => (
              <Pill key={item.code} withRemoveButton onRemove={() => handleValueRemove(item)}>
                {(item.display ?? item.code).split(' (ie:').at(0)}
              </Pill>
            ))}

            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder="Please type or select"
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && search.length === 0) {
                    event.preventDefault();
                    handleValueRemove(value[value.length - 1]);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>
          <ScrollAreaAutosize mah={300}>
            {options.length > 0 ? options : <Combobox.Empty>Nothing found...</Combobox.Empty>}
          </ScrollAreaAutosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
