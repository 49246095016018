import React from 'react';
import { QuestionnaireItemType } from 'const-utils/codeSystems/ImaginePediatrics';
import { Stack } from '@mantine/core';
import { QuestionnaireItem, QuestionnaireResponse } from '@medplum/fhirtypes';
import { ReadOnlyQuestionnaireRepeatedGroup } from './QuestionnaireFormGroup';
import { ReadOnlyQuestionnaireRepeatableItem } from './QuestionnaireRepeatableItem';
import { TranslationCodeMapping } from 'imagine-dsl/services/questionnaireService';
import { Maybe } from 'medplum-gql';
import { getPreviousItemsResponseLength, getResponseItemByLinkId } from './utils';

interface readOnlyQuestionnairePageSequenceProps {
  readonly items: QuestionnaireItem[];
  readonly response: Maybe<QuestionnaireResponse> | undefined;
  defaultOpen?: boolean;
  translatedItemMapping: TranslationCodeMapping[];
}

export function ReadOnlyQuestionnairePageSequence(props: readOnlyQuestionnairePageSequenceProps): JSX.Element {
  const { items, response, translatedItemMapping, defaultOpen } = props;

  const form = items.map((item, index) => {
    const itemResponse = getResponseItemByLinkId(response, item.linkId);
    const previousItemsResponseLength = getPreviousItemsResponseLength(response, items, index);

    const repeatedItem =
      item.type === QuestionnaireItemType.group ? (
        <ReadOnlyQuestionnaireRepeatedGroup
          translatedItemMapping={translatedItemMapping}
          key={item.linkId}
          item={item}
          response={itemResponse}
          defaultOpen={defaultOpen}
          previousItemTotal={previousItemsResponseLength}
        />
      ) : (
        <ReadOnlyQuestionnaireRepeatableItem
          translatedItemMapping={translatedItemMapping}
          key={item.linkId}
          item={item}
          response={itemResponse?.[0]}
        />
      );

    return repeatedItem;
  });

  return <Stack>{form}</Stack>;
}
