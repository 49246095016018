import React, { useEffect, useMemo, useState } from 'react';
import { Box, Container, Grid, Group, LoadingOverlay, Paper, Text } from '@mantine/core';
import { LinkedPatients } from '@/components/patientProfile/LinkedPatients';
import { PatientPrimaryInformation } from '@/components/patientProfile/patientPrimaryInformation';
import { PatientActivity } from '@/components/patientProfile/patientActivity/PatientActivity';
import { PatientContactOverview } from '@/components/patientProfile/patientContacts/PatientContactOverview';
import {
  useGetPatientQuery,
  Patient as GraphqlPatient,
  RelatedPerson,
  useGetQuestionnaireResponseByAuthorIdQuery,
  QuestionnaireResponse,
  Task,
  useGetReviewTasksByPatientIdQuery,
  useGetIncompleteTasksByPatientIdQuery,
  GetPatientQuery,
} from 'medplum-gql';
import { useParams, useSearchParams } from 'react-router-dom';
import { PatientDocuments } from '@/components/patientProfile/patientDocuments/PatientDocuments';
import { ProgramStatus, System, TaskType } from 'const-utils';
import PatientCareTeam from '@/components/patientProfile/PatientCareTeam';
import { Patient } from '@medplum/fhirtypes';
import { ChatDrawer } from '@/components/chat';
import { useDisclosure, useDocumentTitle } from '@mantine/hooks';
import { getName } from 'imagine-dsl/utils/patient';
import { ChatDrawerProvider } from '@/components/chat/ChatDrawerProvider';
import { useRecentPatients } from '@/hooks/usePatientList';
import { IconChecklist, IconPointFilled } from '@tabler/icons-react';
import { TasksDrawer } from '@/components/tasks/drawer';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { CareHubQuestionnaireResponse } from 'imagine-dsl/utils/questionnaireResponse';
import { PhoneDisplay } from '@/components/shared/patient/PhoneDisplay';
import { AddressDisplay } from '@/components/shared/patient/AddressDisplay';
import { TimeZoneDisplay } from '@/components/shared/patient/TimeZoneDisplay';
import { SurveyReviewAlert } from '@/components/patientProfile/alerts/SurveyReviewAlert';
import { ActiveVideoAlert } from '@/components/patientProfile/alerts/ActiveVideoAlert';
import { ResetDemoButton } from '@/components/ResetDemoButton';
import { notifications } from '@mantine/notifications';
import { PatientProfileAlert } from '@/components/patientProfile/alerts/PatientProfileAlert';
import { SurveyReviewTask } from 'imagine-dsl/models/tasks/surveyReviewTask';
import { NotesFullWidth } from '@/components/shared/patient/notes';
import { isValidPatient } from '@/utils/queryTypes';
import { PatientHeader } from '@/components/shared/patient/PatientHeader';
import { compact } from 'lodash';
import { QuestionnaireType } from 'const-utils/codeSystems/ImaginePediatrics';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { ResetPatientButton } from '@/components/ResetPatientButton';
import { useMedplum } from '@medplum/react';
import { ApolloQueryResult } from '@apollo/client';

interface Params extends Record<string, string | undefined> {
  id?: string;
}

export function PatientProfile(): JSX.Element {
  const { id = '' } = useParams<Params>();
  const { add: addRecentPatient, patientIds: recentPatients } = useRecentPatients();
  const [chatDrawerOpened, { open: openChatDrawer, close: closeChatDrawer }] = useDisclosure();
  const [questionnaireResponses, setQuestionnaireResponses] = useState<CareHubQuestionnaireResponse[]>([]);
  const [questionnaireResponseAuthorId, setQuestionnaireResponseAuthorId] = useState<string | undefined>(undefined);
  const flags = useFeatureFlags();
  const medplum = useMedplum();
  const [searchParams, setSearchParams] = useSearchParams();

  const focusedTaskId = useMemo(() => {
    return searchParams.get('task');
  }, [searchParams]);

  const taskTags = [TaskType.ScreenerReview, TaskType.ReviewBHSurvey];

  const { data: surveyReviewTasksData, refetch: refetchSurveyReviewTasks } = useGetReviewTasksByPatientIdQuery({
    variables: {
      patientId: id,
      tags: taskTags.join(','),
    },
  });
  const reviewTasks = compact(surveyReviewTasksData?.TaskList).map((t) => new SurveyReviewTask(t));

  const screenerReviewTask = reviewTasks.find((t) => !t.completed && t.type === TaskType.ScreenerReview);
  const bhReviewTasks: SurveyReviewTask[] = reviewTasks.filter(
    (t) => !t.completed && t.type === TaskType.ReviewBHSurvey,
  );

  const { data: carePathwayReferralReviewTasksData, refetch: refetchCarePathwayReferralTasks } =
    useGetIncompleteTasksByPatientIdQuery({
      variables: {
        patientId: id,
        tag: TaskType.CarePathwayReferralReview,
      },
    });

  const carePathwayReferralReviewTasks = useMemo(() => {
    return compact(carePathwayReferralReviewTasksData?.TaskList).map((t) => new BaseTask(t)) || [];
  }, [carePathwayReferralReviewTasksData]);

  useEffect(() => {
    if (!screenerReviewTask) {
      return;
    }

    const basedOnTask = screenerReviewTask.task.basedOn?.[0]?.resource as Task;
    const basedOnOwner = basedOnTask?.owner?.resource as Patient;
    setQuestionnaireResponseAuthorId(basedOnOwner?.id);
  }, [screenerReviewTask]);

  const { data: questionnaireResponseData } = useGetQuestionnaireResponseByAuthorIdQuery({
    variables: {
      author: `Patient/${questionnaireResponseAuthorId}`,
      tag: QuestionnaireType.Screener,
    },
    skip: !questionnaireResponseAuthorId,
  });

  useEffect(() => {
    if (!questionnaireResponseData?.QuestionnaireResponseList) {
      return;
    }

    setQuestionnaireResponses(
      questionnaireResponseData.QuestionnaireResponseList.map(
        (r) => new CareHubQuestionnaireResponse(r as QuestionnaireResponse),
      ),
    );
  }, [questionnaireResponseData]);

  const screenerResponseCategories = questionnaireResponses[0]?.categories || ['pending score'];

  type bhResponseCategory = {
    categories: string[];
    id: string;
  };
  const bhResponseCategories: bhResponseCategory[] = [];

  bhReviewTasks.forEach((bhReviewTask) => {
    const attachedResponse = new CareHubQuestionnaireResponse(bhReviewTask?.focus as QuestionnaireResponse);
    bhResponseCategories.push({
      id: attachedResponse.id!,
      categories: attachedResponse.categories || ['pending score'],
    });
  });

  const focusedTask = useMemo(() => {
    if (!reviewTasks && !bhReviewTasks && !carePathwayReferralReviewTasks) {
      return undefined;
    }

    const allTasks = [...reviewTasks, ...bhReviewTasks, ...carePathwayReferralReviewTasks];
    return allTasks.find((task) => task?.id === focusedTaskId);
  }, [reviewTasks, bhReviewTasks, carePathwayReferralReviewTasks, focusedTaskId]);

  useEffect(() => {
    if (id && recentPatients.at(0) !== id) {
      addRecentPatient(id);
    }
  }, [addRecentPatient, recentPatients, id]);

  const result = useGetPatientQuery({
    variables: {
      id,
    },
  });

  const patient = useMemo(() => result.data?.Patient, [result]);

  const name = getName(patient);

  useDocumentTitle(patient ? `${name} - Care Hub` : 'Loading - Care Hub');

  if (result.loading) {
    return <LoadingOverlay visible />;
  }

  if (!isValidPatient(patient)) {
    return <div>No patient found</div>;
  }

  const programStatus = patient.meta?.tag?.find((tag) => tag.system === System.ProgramStatus)?.code;

  const refetch = async (): Promise<ApolloQueryResult<GetPatientQuery>> => {
    return result.refetch();
  };

  const openDrawer = (taskId: string) => {
    setSearchParams({ ...searchParams, task: taskId });
  };

  const closeDrawer = () => {
    searchParams.delete('task');
    setSearchParams({ ...searchParams });
  };

  return (
    <Container w="100%" my={20} size="xl">
      <Paper mb={20} radius="lg" shadow="xs" style={{ overflow: 'hidden' }}>
        <Box p="xl" pb="sm">
          <PatientHeader
            patientId={patient.id}
            withOutreachButton={programStatus === ProgramStatus.NotEnrolled}
            withVideoCallButton={programStatus !== ProgramStatus.NotEnrolled}
            openChatDrawer={programStatus !== ProgramStatus.NotEnrolled ? openChatDrawer : undefined}
          />
        </Box>
        <Box style={{ padding: 'var(--mantine-spacing-sm) var(--mantine-spacing-lg)' }} bg="imagine-green.0">
          <Group>
            <PhoneDisplay patient={patient} />
            <IconPointFilled size={14} />
            <AddressDisplay patient={patient} />
            <IconPointFilled size={14} />
            <TimeZoneDisplay patient={patient} />
          </Group>
        </Box>
      </Paper>
      <NotesFullWidth patientId={patient.id!} />
      <ActiveVideoAlert patientId={id} />
      {screenerReviewTask && !screenerReviewTask.completed && (
        <SurveyReviewAlert
          title="PRAPARE Screener"
          onClick={() => {
            openDrawer(screenerReviewTask.id!);
          }}
          name={name || ''}
          responseCategories={screenerResponseCategories}
        />
      )}
      {bhReviewTasks.map((bhReviewTask) => {
        return (
          <SurveyReviewAlert
            title={`BH Survey ${bhReviewTask.bhSurveyType?.toUpperCase()}`}
            onClick={() => {
              openDrawer(bhReviewTask.id!);
            }}
            name={name || ''}
            responseCategories={
              bhResponseCategories?.find((bhResponse) => bhResponse.id === bhReviewTask.focus?.id)?.categories || [
                'pending score',
              ]
            }
          />
        );
      })}
      {!flags.PathwayReviewOwners && carePathwayReferralReviewTasks[0] && (
        <PatientProfileAlert
          onClick={() => {
            openDrawer(carePathwayReferralReviewTasks[0].id!);
          }}
          icon={<IconChecklist size={20} />}
          callToAction="View Task"
        >
          <Group gap={4}>
            <Text fw={700}>{name}</Text>
            <Text>has an open task: Care Pathway Referral Review</Text>
          </Group>
        </PatientProfileAlert>
      )}
      {flags.PathwayReviewOwners &&
        carePathwayReferralReviewTasks.map((task) => (
          <PatientProfileAlert
            onClick={() => {
              openDrawer(task.id!);
            }}
            icon={<IconChecklist size={20} />}
            callToAction="View Task"
          >
            <Group gap={4}>
              <Text fw={700}>{name}</Text>
              <Text>has an open task: Care Pathway Referral Review</Text>
            </Group>
          </PatientProfileAlert>
        ))}
      <Grid gutter="md">
        <Grid.Col span={3}>
          <PatientContactOverview patient={patient as Patient} refetch={refetch} />
          <LinkedPatients
            patient={patient as GraphqlPatient}
            linkedPatients={patient.RelatedPersonList as RelatedPerson[]}
            refetch={result.refetch}
          />
          <PatientDocuments
            patient={patient as GraphqlPatient}
            linkedPatients={patient.RelatedPersonList as RelatedPerson[]}
            refetch={result.refetch}
          />
          <Group justify="center" mt={10}>
            <ResetDemoButton
              patientId={id}
              onSuccess={async () => {
                await refetch();
                notifications.show({
                  message: 'Demo Reset Successful',
                });
              }}
            />
          </Group>
          {medplum.isProjectAdmin() && (
            <Group justify="center" mt={10}>
              <ResetPatientButton patientId={id} />
            </Group>
          )}
        </Grid.Col>
        <Grid.Col span={9}>
          <PatientCareTeam patient={result.data} refetch={refetch} />
          <PatientPrimaryInformation patient={patient as GraphqlPatient} refetch={refetch} />
          <PatientActivity patientId={id} refresh={refetch} />
        </Grid.Col>
      </Grid>
      <ChatDrawerProvider opened={chatDrawerOpened} patient={patient} close={closeChatDrawer}>
        <ChatDrawer />
      </ChatDrawerProvider>
      {focusedTask && focusedTask.isSurveyReviewTask() && focusedTask.isScreenerReviewTask && (
        <TasksDrawer
          task={BaseTask.fromSurveyReviewTask(focusedTask)}
          opened={!!focusedTask}
          onClose={closeDrawer}
          withViewPatientProfile
          refetchTasks={refetchSurveyReviewTasks}
        />
      )}
      {focusedTask && focusedTask.isSurveyReviewTask() && focusedTask.isBHSurveyTask && (
        <TasksDrawer
          task={BaseTask.fromSurveyReviewTask(focusedTask)}
          opened={!!focusedTask}
          onClose={closeDrawer}
          withViewPatientProfile
          refetchTasks={refetchSurveyReviewTasks}
        />
      )}
      {focusedTask && (
        <TasksDrawer
          task={focusedTask as BaseTask}
          opened={!!focusedTask}
          onClose={closeDrawer}
          withViewPatientProfile
          refetchTasks={refetchCarePathwayReferralTasks}
        />
      )}
    </Container>
  );
}
