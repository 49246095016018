import { logError } from '@/errors';
import { notifications } from '@mantine/notifications';
import { useMedplum } from '@medplum/react';
import { CareTeamType } from 'const-utils';
import { CareTeam } from '@medplum/fhirtypes';
import { unpackBundle } from 'imagine-dsl/utils/medplum';
import { useState, useEffect } from 'react';

export const useClinicalPods = () => {
  const [loading, setLoading] = useState(false);
  const [pods, setPods] = useState<CareTeam[]>([]);
  const medplum = useMedplum();

  useEffect(() => {
    let ignore = false;

    const load = async (): Promise<void> => {
      const result = await medplum
        .search('CareTeam', {
          _tag: CareTeamType.ClinicalPod,
        })
        .then(unpackBundle);

      if (!ignore) {
        setPods(result);
      }
    };
    setLoading(true);
    load()
      .catch((e) => {
        logError(e);
        notifications.show({
          message: 'Failed to load clinical pod list.',
          color: 'status-error',
        });
      })
      .finally(() => {
        if (!ignore) {
          setLoading(false);
        }
      });
    return () => {
      ignore = true;
    };
  }, [medplum]);

  return { pods, loading };
};

export const usePods = () => {
  const [loading, setLoading] = useState(false);
  const [pods, setPods] = useState<CareTeam[]>([]);
  const medplum = useMedplum();

  useEffect(() => {
    let ignore = false;

    const load = async (): Promise<void> => {
      const result = await medplum
        .search('CareTeam', {
          _tag: `${CareTeamType.ClinicalPod},${CareTeamType.EngagementPod}`,
        })
        .then(unpackBundle);

      if (!ignore) {
        setPods(result);
      }
    };
    setLoading(true);
    load()
      .catch((e) => {
        logError(e);
        notifications.show({
          message: 'Failed to load pod list.',
          color: 'status-error',
        });
      })
      .finally(() => {
        if (!ignore) {
          setLoading(false);
        }
      });
    return () => {
      ignore = true;
    };
  }, [medplum]);

  return { pods, loading };
};
