export enum ProgramStatus {
  // Eligible = 'eligible',
  NotEnrolled = 'not-enrolled',
  Enrolled = 'enrolled',
  Onboarded = 'onboarded',
  Disenrolled = 'disenrolled',
}

export const programStatusDisplay: Record<ProgramStatus, string> = {
  [ProgramStatus.NotEnrolled]: 'Not Enrolled',
  [ProgramStatus.Enrolled]: 'Enrolled',
  [ProgramStatus.Onboarded]: 'Onboarded',
  [ProgramStatus.Disenrolled]: 'Disenrolled',
};

export const programStatusOptions = Object.entries(programStatusDisplay).map(([value, label]) => ({
  value,
  label,
}));

export const enum DisEngagementReason {
  IneligibleWithPlan = 'ineligible-with-plan',
  OptOut = 'opt-out',
  InappropriateConduct = 'inappropriate-conduct',
  DueToIncome = 'due-to-income',
  OutOfCoverageArea = 'out-of-coverage-area',
  ChangedPlan = 'changed-plan',
  NoLongerQualifies = 'no-longer-qualifies',
  Deceased = 'deceased',
  AgedOut = 'aged-out',
}

export enum Attribution {
  Attributed = 'attributed',
  NotAttributed = 'not-attributed',
}

export const attributionDisplay: Record<Attribution, string> = {
  [Attribution.Attributed]: 'Attributed',
  [Attribution.NotAttributed]: 'Not Attributed',
};

export const attributionOptions = Object.entries(attributionDisplay).map(([value, label]) => ({
  value,
  label,
}));
