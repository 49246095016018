import { ActionIcon, Group, Paper, Stack, Text, Tooltip } from '@mantine/core';
import { HumanName, Patient } from '@medplum/fhirtypes';
import { HumanNameDisplay } from '@medplum/react';
import { IconCirclePlusFilled } from '@tabler/icons-react';
import { RelatedPerson, Patient as GraphqlPatient } from 'medplum-gql';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import classes from './LinkedPatients.module.css';
import { getName, makeGroupedLinkedPatientsList } from 'imagine-dsl/utils/patient';
import { getRelatedPersonRelationship } from 'imagine-dsl/utils/relatedPerson';
import { capitalize } from '@medplum/core';

interface LinkedPatientCardProps {
  editLinkedPatients: () => void;
  linkedPatients: RelatedPerson[];
  primaryPatient: GraphqlPatient;
}

export function LinkedPatientCard({
  linkedPatients,
  editLinkedPatients,
  primaryPatient,
}: LinkedPatientCardProps): JSX.Element {
  const hasContacts = useMemo(() => (primaryPatient?.RelatedPersonList?.length || 0) > 0, [primaryPatient]);

  const groupedLinkedPatients = useMemo(() => {
    return makeGroupedLinkedPatientsList(linkedPatients, primaryPatient as Patient);
  }, [linkedPatients, primaryPatient]);

  return (
    <Paper p={20} mb={20} shadow="xs" radius="lg">
      <Stack>
        <Group justify="space-between">
          <Text size="xl" c="imagine-green">
            Linked patients
          </Text>
          <Tooltip label="A Contact must be added before a patient can be linked" disabled={hasContacts}>
            <span>
              <ActionIcon disabled={!hasContacts} onClick={editLinkedPatients}>
                <IconCirclePlusFilled size={24} />
              </ActionIcon>
            </span>
          </Tooltip>
          {groupedLinkedPatients.length === 0 ? (
            <Text c="dimmed" fz="xs">
              This patient has no Linked Patients
            </Text>
          ) : (
            <Stack w="100%">
              {groupedLinkedPatients.map((linkedPatient) => {
                return (
                  <Stack key={linkedPatient.patient.id} gap={0}>
                    <Link className={classes.patientUrl} to={`/Patient/${linkedPatient.patient.id}`}>
                      <HumanNameDisplay value={linkedPatient.patient.name?.[0] as HumanName} />
                    </Link>
                    <Stack gap={0}>
                      {linkedPatient.linkedThrough.map((rp) => {
                        const relationship = getRelatedPersonRelationship(rp)?.display;
                        return (
                          <Text size="xs" c="dimmed" key={rp.id}>
                            {getName(rp.patient.resource as Patient)}
                            {relationship ? `, ${capitalize(relationship)}` : ''}
                          </Text>
                        );
                      })}
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          )}
        </Group>
      </Stack>
    </Paper>
  );
}
