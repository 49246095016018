import { ContactReviewForm } from '@/components/shared/ContactReviewForm';
import { logError, pleaseFixErrorsAboveMessage } from '@/errors';
import { Client } from '@/hooks/useApiClient';
import { UseFormReturnType } from '@mantine/form';
import { Patient } from '@medplum/fhirtypes';

export const validateContactReviewForm = async (form: UseFormReturnType<ContactReviewForm>): Promise<void> => {
  form.validate();

  if (!form.isValid()) {
    throw new Error(pleaseFixErrorsAboveMessage);
  }
};

export const contactReviewContinue = async (
  client: Client,
  patientId: string,
  form: UseFormReturnType<ContactReviewForm>,
  refreshPatient: () => Promise<unknown>,
): Promise<Patient> => {
  const contactAddress = form.values?.contact?.address || form.values.address;
  return client
    .fetch('/api/enrollment/contact', {
      method: 'POST',
      body: JSON.stringify({
        patientId,
        ...form.values,
        contact: {
          ...form.values.contact,
          address: contactAddress,
          language: form.values.contact?.language || form.values.languagePreferences?.language,
          requiresInterpreter:
            form.values.contact?.requiresInterpreter || form.values.languagePreferences?.requiresInterpreter,
        },
      }),
    })
    .then((res) => res.json())
    .then(async (res) => {
      if ('error' in res) {
        throw new Error(res.error);
      }
      await refreshPatient();
      form.setFieldValue('contact.contactId', res.caregiver.id);
      return res.caregiver as Patient;
    })
    .catch((err) => {
      logError(err);
      throw new Error('Failed to save contact info');
    });
};
