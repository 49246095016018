import React from 'react';
import { getConfig } from '@/config';
import { Text } from '@mantine/core';

export const AppVersion = ({ prefix = 'version: ' }: { prefix?: string }): JSX.Element => {
  const config = getConfig();
  return (
    <Text c="dimmed" style={{ fontSize: '.5rem', textAlign: 'center' }}>
      {/*
        until we have a more formal versioning system for care hub, capture 7 digits of the git commit hash and display under the title
        displaying this version number can be valuable when debugging / triaging issues
        https://git-scm.com/book/en/v2/Git-Tools-Revision-Selection#Short-SHA-1
      */}
      {prefix}
      {config.commitHash.slice(0, 7)}
    </Text>
  );
};
