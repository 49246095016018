import React from 'react';
import { Grid, Text, Flex, ActionIcon } from '@mantine/core';
import { ContactPoint, Patient, RelatedPerson } from '@medplum/fhirtypes';
import { IconCopy } from '@tabler/icons-react';
import { formatAddress, formatPhoneString, yesOrNo } from 'imagine-dsl/utils/strings';
import { getPatientLanguagePreference, getPatientRequiresInterpreter } from 'imagine-dsl/utils/patient';
import { System } from 'const-utils';
import { copyToClipboardHandler } from '@/components/shared/patient/clipboard';

interface ExtendedRelatedPerson extends RelatedPerson {
  PatientList?: Patient[];
}
interface ContactInfoProps {
  contactPoints?: ContactPoint[];
  patient?: Patient;
  caregiver?: ExtendedRelatedPerson;
  type?: 'Address' | 'Language';
}

const PatientContactInfo = ({ contactPoints, type, caregiver }: ContactInfoProps): JSX.Element => {
  if (contactPoints) {
    return (
      <>
        {contactPoints?.map((item, index) => (
          <Grid
            justify="space-between"
            align="center"
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '28px',
              marginBottom: '15px',
            }}
            key={index}
          >
            {item?.system === 'phone' && (
              <Flex align="start" gap="sm">
                <div>
                  <Text fw={700}>{formatPhoneString(item?.value)}</Text>
                  <Text c="dimmed">
                    {item?.extension?.find(
                      (ext) => ext.url === System.PrimaryPhone.toString() && ext.valueBoolean === true,
                    )?.valueBoolean
                      ? `${item.use ? item.use + ' ' : ''}(Primary)`
                      : item.use}
                  </Text>
                </div>
                <ActionIcon color="imagine-green" size="sm" onClick={() => copyToClipboardHandler(item?.value)}>
                  <IconCopy />
                </ActionIcon>
              </Flex>
            )}
            {item?.system === 'email' && (
              <Flex align="center">
                <Text fz="sm">{item?.value}</Text>
              </Flex>
            )}
          </Grid>
        ))}
      </>
    );
  }

  if (type === 'Language') {
    const languagePreference = getPatientLanguagePreference(caregiver?.PatientList?.[0]?.communication || []);
    const requiresInterpreter = yesOrNo(getPatientRequiresInterpreter(caregiver?.PatientList?.[0]?.extension || []));

    return (
      <>
        <Grid justify="space-between" align="center" style={{ marginLeft: '28px', marginBottom: '3px' }}>
          <Text fz="sm">{`${languagePreference}${
            requiresInterpreter === 'yes' ? ', Requires interpreter' : ', Does not require interpreter'
          }`}</Text>
        </Grid>
      </>
    );
  }

  if (type === 'Address') {
    const address = formatAddress(caregiver?.PatientList?.[0]?.address?.[0]);

    return (
      <>
        <Grid justify="space-between" align="center" style={{ marginLeft: '28px', marginBottom: '3px' }}>
          <Text fz="sm">{address}</Text>
        </Grid>
      </>
    );
  }

  return <></>;
};

export default PatientContactInfo;
