import React from 'react';
import { Grid, Text, Paper } from '@mantine/core';
import { System } from 'const-utils';
import { Extension, PatientCommunication } from '@medplum/fhirtypes';
import { GetPatientQuery, Patient } from 'medplum-gql';
import {
  getPatientExtension,
  getPatientLanguagePreference,
  getName,
  getPatientRequiresInterpreter,
} from 'imagine-dsl/utils/patient';
import { AddressType } from 'const-utils/codeSystems/ImaginePediatrics';
import { capitalize } from 'lodash';
import { formatAddress, formatDateOfBirth, yesOrNo } from 'imagine-dsl/utils/strings';
import { PatientPrimaryInfoFields } from '../helpers/EditFormHandler';
import { EditableField } from './EditableField';
import { Field } from './Field';
import { ApolloQueryResult } from '@apollo/client';

interface PatientPrimaryInformationProps {
  patient: Patient;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

export const PatientPrimaryInformation = ({ patient, refetch }: PatientPrimaryInformationProps): JSX.Element => {
  const { gender, address, birthDate, extension, communication } = patient;

  const livingArrangement = getPatientExtension(extension as Extension[], System.LivingArrangement);
  const genderIdentity = getPatientExtension(extension as Extension[], System.GenderIdentity);
  const preferredPronoun = getPatientExtension(extension as Extension[], System.PreferredPronoun);
  const languagePreference = getPatientLanguagePreference(communication as PatientCommunication[]);
  const requiresInterpreter = yesOrNo(getPatientRequiresInterpreter((extension as Extension[]) || []));
  const preferredName = getName(patient, { use: 'usual', givenOnly: true });
  const officialName = getName(patient, { use: 'official' });
  const homeAddress = formatAddress(
    address?.find((addr) => {
      return addr.extension?.some(
        (ext) => ext.url === System.AddressType.toString() && ext.valueCode === AddressType.Home,
      );
    }),
  );

  const handleDataChange = async (): Promise<void> => {
    await refetch();
  };

  return (
    <Paper shadow="xs" radius="lg" style={{ padding: '20px', marginBottom: '20px' }}>
      <Text size="xl" style={{ marginBottom: '10px' }} c="imagine-green">
        Primary information
      </Text>
      <Grid gutter="xs" justify="space-between">
        <Grid.Col span={4}>
          <Field label="Name" value={officialName || '--'} />
          <Field label="Birth sex" value={capitalize(gender!)} />
          <EditableField
            label={PatientPrimaryInfoFields.ADDRESS}
            value={homeAddress ? homeAddress : '--'}
            patient={patient}
            onDataChanged={handleDataChange}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <EditableField
            label={PatientPrimaryInfoFields.PREFERRED_NAME}
            value={preferredName ? preferredName : '--'}
            onDataChanged={handleDataChange}
            patient={patient}
          />
          <EditableField
            label={PatientPrimaryInfoFields.GENDER_IDENTITY}
            value={
              genderIdentity ? `${capitalize(genderIdentity)}${preferredPronoun ? ', ' + preferredPronoun : ''}` : '--'
            }
            onDataChanged={handleDataChange}
            patient={patient}
          />
          <EditableField
            label={PatientPrimaryInfoFields.LIVING_SITUATION}
            value={livingArrangement ? livingArrangement : '--'}
            onDataChanged={handleDataChange}
            patient={patient}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Field label="Date of birth" value={formatDateOfBirth(birthDate).dateOfBirth} />
          <EditableField
            label={PatientPrimaryInfoFields.LANGUAGE_PREFERENCE}
            value={
              languagePreference && languagePreference?.[0]?.trim() !== ''
                ? `${languagePreference}, ${
                    requiresInterpreter === 'yes' ? 'Requires interpreter' : 'Does not require interpreter'
                  }`
                : '--'
            }
            patient={patient}
            onDataChanged={handleDataChange}
          />
        </Grid.Col>
      </Grid>
    </Paper>
  );
};
