import React from 'react';
import { ThemeIcon, Tooltip } from '@mantine/core';
import { IconRating21Plus } from '@tabler/icons-react';

export const Over21Badge = (): JSX.Element => {
  return (
    <Tooltip label="Nurse only visit">
      <ThemeIcon>
        <IconRating21Plus size={20} />
      </ThemeIcon>
    </Tooltip>
  );
};
