import { isEmpty, isUndefined } from 'lodash';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Header } from './types';
import { HeaderMenu } from './HeaderMenu';
import { SortDirection } from 'imagine-dsl/utils/search';
import { Table } from '@mantine/core';

export interface TableHeaderProps {
  headers: Header[];
  onSort?: (header: Header, direction?: SortDirection) => void;
  resultsFound: boolean;
  onFilter?: (field: string, value: string) => void;
  filtersReset: boolean;
}

export function TableHead(props: PropsWithChildren<TableHeaderProps>): JSX.Element {
  const { headers, onSort, resultsFound, children, onFilter, filtersReset } = props;
  const [sortedFields, setSortedFields] = useState<{ [key: string]: SortDirection | undefined }>({});
  const [filteredFields, setFilteredFields] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (filtersReset) {
      setSortedFields({});
      setFilteredFields({});
    }
  }, [filtersReset]);

  const onSortClick = (header: Header, direction?: SortDirection): void => {
    if (onSort) {
      sortedFields[header.field] = direction;
      onSort(header, direction);
    }
  };

  const onFilterClick = (field: string, value: string): void => {
    if (onFilter) {
      filteredFields[field] = value;
      onFilter(field, value);
    }
  };

  const showMenu = (header: Header): boolean | undefined => {
    return (header.sortable && !isUndefined(onSort)) || (header.filterOptions && !isUndefined(onFilter));
  };

  const fieldSorted = (header: Header): boolean => {
    return !isEmpty(sortedFields[header.field]);
  };

  const sortDirection = (header: Header): SortDirection | undefined => {
    return sortedFields[header.field];
  };

  const fieldFiltered = (header: Header): boolean => {
    if (header.filterOptions) {
      const filterField = header.filterOptions[0].field;
      return !isEmpty(filteredFields[filterField]);
    }
    return false;
  };

  return (
    <Table.Thead>
      <Table.Tr>
        {headers.map((header) => (
          <React.Fragment key={header.field}>
            {!showMenu(header) && <Table.Th key={header.field}>{header.display}</Table.Th>}
            {showMenu(header) && (
              <Table.Th key={header.field} style={header.style}>
                <HeaderMenu
                  onSort={onSortClick}
                  sorted={fieldSorted(header)}
                  sortDirection={sortDirection(header)}
                  disabled={!resultsFound || filtersReset}
                  header={header}
                  onFilter={onFilterClick}
                  filtered={fieldFiltered(header)}
                />
              </Table.Th>
            )}
          </React.Fragment>
        ))}
        {children}
      </Table.Tr>
    </Table.Thead>
  );
}
