import React from 'react';
import { TaskTable } from './taskTable';
import { useDocumentTitle } from '@mantine/hooks';
import { PageSkeleton } from '@/design-system/PageSkeleton';
import { CommandCenterAlertProvider } from './CommandCenterAlertProvider';

export function CommandCenterPage(): JSX.Element {
  useDocumentTitle('Command Center - Care Hub');

  return (
    <PageSkeleton>
      <CommandCenterAlertProvider>
        <TaskTable />
      </CommandCenterAlertProvider>
    </PageSkeleton>
  );
}
