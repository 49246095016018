import React, { useMemo, useState } from 'react';
import { Grid, Text, Paper, Stack, ActionIcon, Box } from '@mantine/core';
import { ProgramStatus, System } from 'const-utils';
import { IconCirclePlusFilled } from '@tabler/icons-react';
import { PatientContacts } from './PatientContacts';
import _ from 'lodash';
import { Coding, Patient, RelatedPerson } from '@medplum/fhirtypes';
import PatientContactsList from './PatientContactsList';
import PatientContactModal from '../modals/PatientContactModal';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery } from 'medplum-gql';

interface ExtendedRelatedPerson extends RelatedPerson {
  PatientList?: Patient[];
}
interface ExtendedPatient extends Patient {
  RelatedPersonList?: ExtendedRelatedPerson[];
}

interface PatientContactsProps {
  patient: ExtendedPatient;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

export const PatientContactOverview = ({ patient, refetch }: PatientContactsProps): JSX.Element => {
  type OpenedContactsType = {
    [key: string]: boolean;
    [key: number]: boolean;
  };
  const [openedContacts, setOpenedContacts] = useState<OpenedContactsType>({});
  const programStatus = patient?.meta?.tag?.find((tag: Coding) => tag.system === System.ProgramStatus)?.code;
  const [addContactModalOpen, setAddContactModalOpen] = useState(false);

  const toggleDetails = (contactId: string | number): void => {
    setOpenedContacts((prev) => ({
      ...prev,
      [contactId]: !prev[contactId],
    }));
  };

  const hasContacts = useMemo(() => (patient?.RelatedPersonList?.length || 0) > 0, [patient]);

  if (!hasContacts && programStatus === ProgramStatus.NotEnrolled) {
    return (
      <Paper shadow="xs" p="xl" radius="lg" style={{ padding: '20px', marginBottom: '20px' }}>
        <Stack gap="sm">
          <Text size="xl" c="imagine-green">
            Contacts
          </Text>
          <PatientContacts patient={patient as Patient} refetch={refetch} />
        </Stack>
      </Paper>
    );
  }

  return (
    <>
      <Paper mb={20} shadow="xs" p="xl" radius="lg">
        <Stack gap="sm">
          <Grid justify="space-between" align="center">
            <Text size="xl" c="imagine-green">
              Contacts
            </Text>
            <ActionIcon onClick={() => setAddContactModalOpen(true)}>
              <IconCirclePlusFilled size={24} />
            </ActionIcon>
          </Grid>

          {hasContacts ? (
            <Box>
              <PatientContactsList
                patient={patient}
                refetch={refetch}
                toggleDetails={toggleDetails}
                openedContacts={openedContacts}
              />
            </Box>
          ) : (
            <Text mt={10} c="dimmed" fz="xs">
              This patient has no Contacts
            </Text>
          )}
        </Stack>
      </Paper>
      {addContactModalOpen && (
        <PatientContactModal
          patient={patient}
          refetch={refetch}
          isModalOpen={addContactModalOpen}
          closeModal={() => setAddContactModalOpen(false)}
          label="Add contact"
        />
      )}
    </>
  );
};
