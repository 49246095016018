import { AssigneeSection } from '@/components/chat/footer/AssigneeSection';
import { UnassignedBadge } from '@/components/shared/UnassignedBadge';
import { useUserSession } from '@/components/shared/UserSessionContext';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { Grid, Stack, Group, Button, Container, Tooltip, Text } from '@mantine/core';
import { formatHumanName } from '@medplum/core';
import { HumanName } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { IconCheck, IconX } from '@tabler/icons-react';
import { CarePathwayReferralTask } from 'imagine-dsl/models/tasks/carePathwayReferralTask';
import { getName } from 'imagine-dsl/utils/patient';
import { carePathwayReferralReviewOwnerEmails } from 'imagine-dsl/utils/task';
import { includes } from 'lodash';
import React, { useMemo } from 'react';

interface TaskActionsProps {
  task: CarePathwayReferralTask;
  refetchTasks: () => Promise<unknown>;
  setActionIntent: (intent: 'approve' | 'deny' | undefined) => void;
  hasPermission: boolean;
  carePathwayTaskResolveAllowed: boolean;
}

export const TaskActions = (props: TaskActionsProps): JSX.Element => {
  const { task, setActionIntent, hasPermission, carePathwayTaskResolveAllowed } = props;
  const { isResolved } = task;
  const flags = useFeatureFlags();

  const isActionable = !isResolved;
  const disableApproveAction = !hasPermission || !carePathwayTaskResolveAllowed;
  const disableDenyAction = !hasPermission;

  let approveTooltipLabel;
  let denyTooltipLabel;

  if (hasPermission) {
    if (!carePathwayTaskResolveAllowed) {
      approveTooltipLabel =
        'Missing or incomplete sub-tasks that must be created or completed before resolving the parent task';
    } else {
      approveTooltipLabel = 'The task will be assigned to you when you perform this action';
    }
    denyTooltipLabel = 'The task will be assigned to you when you perform this action';
  } else {
    approveTooltipLabel = 'You do not have the appropriate permissions to take action on this task';
    denyTooltipLabel = 'You do not have the appropriate permissions to take action on this task';
  }

  if (!isActionable) {
    return <></>;
  }

  if (!flags.PathwayReviewOwners) {
    return <OriginalTaskActions {...props} />;
  }

  return (
    <Container pt={16} pb={24} mx={10}>
      <Grid justify="space-between">
        <Stack style={{ gap: 0 }}>
          <Group>
            <Text size="xs" c="brand-gray.7">
              Assigned to:
            </Text>
            {task.owner && <Text>{formatHumanName((task.owner.name?.[0] || {}) as HumanName)}</Text>}
            {!task.owner && <UnassignedBadge />}
          </Group>
        </Stack>
        <Group>
          <Tooltip label={approveTooltipLabel}>
            <Button
              disabled={disableApproveAction}
              onClick={() => setActionIntent('approve')}
              variant="outline"
              leftSection={<IconCheck />}
            >
              Approve
            </Button>
          </Tooltip>
          <Tooltip label={denyTooltipLabel}>
            <Button
              disabled={disableDenyAction}
              onClick={() => setActionIntent('deny')}
              variant="outline"
              leftSection={<IconX />}
            >
              Deny
            </Button>
          </Tooltip>
        </Group>
      </Grid>
    </Container>
  );
};

const OriginalTaskActions = (props: TaskActionsProps): JSX.Element => {
  const { profile } = useUserSession();
  const { task, refetchTasks, setActionIntent } = props;
  const medplum = useMedplum();
  const isOwnedByMe = task?.owner?.id === profile?.id;

  const isActionable = useMemo(
    () =>
      profile?.telecom?.some((t) => includes(carePathwayReferralReviewOwnerEmails, t.value)) ||
      medplum.isProjectAdmin(),
    [profile, medplum],
  );
  const assignee = task.owner;

  return (
    <>
      {!task.isResolved && isActionable && (
        <Container pt={16} pb={24} mx={10}>
          <Grid justify="space-between">
            <Stack style={{ gap: 0 }}>
              <AssigneeSection
                taskMarket={task.market!}
                assigneeId={assignee?.id}
                assigneeName={getName(assignee)}
                taskId={task.id!}
                disabled={task.completed}
                refreshTasks={refetchTasks}
                profile={profile}
              />
            </Stack>
            <Group>
              <Tooltip label="The task must be assigned to you, in order to take action" disabled={isOwnedByMe}>
                <Button
                  disabled={!isOwnedByMe}
                  onClick={() => setActionIntent('approve')}
                  variant="outline"
                  leftSection={<IconCheck />}
                >
                  Approve
                </Button>
              </Tooltip>
              <Tooltip label="The task must be assigned to you, in order to take action" disabled={isOwnedByMe}>
                <Button
                  disabled={!isOwnedByMe}
                  onClick={() => setActionIntent('deny')}
                  variant="outline"
                  leftSection={<IconX />}
                >
                  Deny
                </Button>
              </Tooltip>
            </Group>
          </Grid>
        </Container>
      )}
    </>
  );
};
