import React from 'react';
import { Group, Stack, Text, Anchor } from '@mantine/core';
import { GetPatientQuery, Patient } from 'medplum-gql';
import { IconSearch } from '@tabler/icons-react';
import { RecommendedLinks } from './RecommendedLinks';
import { ApolloQueryResult } from '@apollo/client';

interface RecommendedLinksModalBodyProps {
  onSearchClick: () => void;
  patient: Patient;
  refetchPatient: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

export function RecommendedLinksModalBody({
  refetchPatient,
  patient,
  onSearchClick,
}: RecommendedLinksModalBodyProps): JSX.Element {
  return (
    <Stack>
      <RecommendedLinks patient={patient} refetchPatient={refetchPatient} />
      <Group>
        <Text size="sm">Not seeing the patient(s) you're looking for?</Text>
        <Anchor fw="bold" onClick={onSearchClick} style={{ display: 'flex', gap: '.5rem' }}>
          <IconSearch size={20} />
          Search Patients
        </Anchor>
      </Group>
    </Stack>
  );
}
