import { CommunicationMedium } from 'const-utils';
import { NotificationCommunicationFragment } from 'medplum-gql';

type CommunicationTypeMap = {
  [CommunicationMedium.Notification]: NotificationCommunicationFragment;
};

interface IBaseCommunication<T extends keyof CommunicationTypeMap> {
  communication: CommunicationTypeMap[T];
}

export class BaseCommunication<T extends keyof CommunicationTypeMap> implements IBaseCommunication<T> {
  constructor(public communication: CommunicationTypeMap[T]) {
    this.communication = communication;
  }

  get id(): string | null | undefined {
    return this.communication.id;
  }

  get sent(): boolean {
    return !!this.communication.sent;
  }

  get sentTime(): Date | null {
    if (!this.communication.sent) {
      return null;
    }

    return new Date(this.communication.sent);
  }

  get contentString(): string | null | undefined {
    return this.communication.payload?.[0].contentString;
  }

  get subjectReference(): string | null | undefined {
    return this.communication.subject?.reference;
  }
}
