import { UnitedStates } from '@/utils/usa';
import { Grid, Group, Select, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';
import { PatientEditModalForm } from '../../helpers/EditFormInterfaces';

export interface PatientAddressProps {
  form: UseFormReturnType<PatientEditModalForm>;
}

export const PatientAddress = ({ form }: PatientAddressProps): JSX.Element => {
  return (
    <>
      <Group grow>
        <TextInput
          withAsterisk
          label="Address line 1"
          placeholder="123 E Main Street"
          {...form.getInputProps('address.line1')}
        />
        <TextInput label="Address line 2" placeholder="Unit 1" {...form.getInputProps('address.line2')} />
      </Group>
      <br />
      <Grid grow mb="md">
        <Grid.Col span={6}>
          <TextInput withAsterisk label="City" placeholder="Chicago" {...form.getInputProps('address.city')} />
        </Grid.Col>
        <Grid.Col span={3}>
          <Select
            withAsterisk
            label="State"
            placeholder="Select state"
            data={UnitedStates.map((state) => ({ label: state.name, value: state.abbreviation }))}
            {...form.getInputProps('address.state')}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <TextInput withAsterisk label="Zip code" placeholder="60601" {...form.getInputProps('address.zip')} />
        </Grid.Col>
      </Grid>
    </>
  );
};
