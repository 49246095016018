import React from 'react';
import { Badge, Box, Group, Paper, Stack, Text, Tooltip, Divider } from '@mantine/core';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import { format } from 'date-fns';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { getInitials } from 'imagine-dsl/utils/strings';
import { useDisclosure } from '@mantine/hooks';
import { AddTouchpointButton } from '@/components/outreach/AddTouchpointButton';
import { ResolveTaskButton } from '@/components/tasks/ResolveTaskButton';
import { PriorityBadge } from '@/components/tasks/PriorityBadge';
import { useActivity } from '@/hooks/useActivity';
import { getName } from 'imagine-dsl/utils/patient';

export const useSubTask = (subTask: BaseTask) => {
  const [viewActivity, viewActivityHandler] = useDisclosure();

  const ownerNameObj = subTask.owner;
  const ownerName = getName(ownerNameObj);
  let dueDateBadge = undefined;
  if (subTask.status === 'completed') {
    dueDateBadge = (
      <Badge bg="status-success.1" c="status-success.9">
        Completed
      </Badge>
    );
  } else if (subTask.task.restriction?.period?.end) {
    const end = new Date(subTask.task.restriction?.period?.end);
    dueDateBadge = (
      <Badge bg="brand-blue.1" c="brand-blue.9">
        {format(end, 'MM/dd/yyyy')}
      </Badge>
    );
  }
  const authoredOn = subTask.authoredOn ? new Date(subTask.authoredOn) : undefined;
  const executionPeriodEnd = subTask.task.executionPeriod?.end ? new Date(subTask.task.executionPeriod.end) : undefined;

  return {
    viewActivity,
    viewActivityHandler,
    ownerName,
    dueDateBadge,
    authoredOn,
    executionPeriodEnd,
  };
};

export const RenderSubTask = (subTask: BaseTask, patientId: string) => {
  const { viewActivity, viewActivityHandler, ownerName, dueDateBadge, authoredOn, executionPeriodEnd } =
    useSubTask(subTask);

  const { renderActivity } = useActivity(patientId, authoredOn, executionPeriodEnd);

  return (
    <Paper key={subTask.id} withBorder p="md">
      <Stack>
        <Group justify="space-between">
          <Text key={subTask.id} fw="bold">
            {subTask.taskType?.display}
          </Text>
          <Group>
            {ownerName && (
              <Tooltip label={ownerName}>
                <Box
                  bg="imagine-green"
                  p={5}
                  style={{
                    borderRadius: '50%',
                  }}
                >
                  <Text c="white" fw="bold">
                    {getInitials(ownerName)}
                  </Text>
                </Box>
              </Tooltip>
            )}
            {subTask.priority && <PriorityBadge priority={subTask.priority} />}
            {dueDateBadge}
          </Group>
        </Group>
        <Divider />
        <Group style={{ cursor: 'pointer' }} onClick={() => viewActivityHandler.toggle()}>
          {viewActivity ? <IconChevronDown /> : <IconChevronRight />}
          <Text c="imagine-green">Activity</Text>
        </Group>
        {viewActivity && renderActivity()}
        {subTask.status !== 'completed' && (
          <>
            <Divider />
            <Group>
              <AddTouchpointButton
                basedOn={[{ reference: `Task/${subTask.id}`, display: subTask.taskType?.display }]}
                patientId={patientId}
                variant="filled"
              />
              <ResolveTaskButton taskId={subTask.id!} />
            </Group>
          </>
        )}
      </Stack>
    </Paper>
  );
};
