import { ApolloClient, createHttpLink, from, InMemoryCache, useApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { errorLink, getAuthHeaders, medplum } from '@/utils/session';

const imagineGqlLink = createHttpLink({
  uri: '/api/graphql',
});

const authLink = setContext(async (_, { headers }) => {
  await medplum.refreshIfExpired();
  const login = medplum.getActiveLogin();
  const accessToken = login?.accessToken;

  return {
    headers: {
      ...headers,
      ...getAuthHeaders(accessToken),
    },
  };
});

export const imagineClient = new ApolloClient({
  link: from([authLink, errorLink, imagineGqlLink]),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    watchQuery: {
      errorPolicy: 'all',
    },
  },
  cache: new InMemoryCache({
    addTypename: true,
  }),
});

export const useImagineApolloClient = () => {
  return useApolloClient(imagineClient);
};
