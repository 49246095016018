import { Coding } from '@medplum/fhirtypes';
import { System } from '../codeSystems/system';

export const enum PatientType {
  Patient = 'patient',
  Caregiver = 'caregiver',
}

export interface ImaginePatientTag extends Coding {
  system: System.PatientType;
  code: PatientType;
}

export const PatientTypeTag = (patientType: PatientType): ImaginePatientTag => ({
  system: System.PatientType,
  code: patientType,
});
