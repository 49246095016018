import { useDisclosure } from '@mantine/hooks';
import React from 'react';
import { GetPatientQuery, Patient, RelatedPerson } from 'medplum-gql';

import { LinkedPatientCard } from './LinkedPatientCard';
import { LinkedPatientModal } from './LinkedPatientModal';
import { ApolloQueryResult } from '@apollo/client';

interface LinkedPatientsProps {
  linkedPatients: RelatedPerson[];
  patient: Patient;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

export function LinkedPatients({ refetch, patient, linkedPatients }: LinkedPatientsProps): JSX.Element {
  const [opened, { open, close }] = useDisclosure();

  const editLinkedPatients = (): void => {
    open();
  };

  return (
    <>
      <LinkedPatientModal refetch={refetch} opened={opened} close={close} patient={patient} />
      <LinkedPatientCard
        primaryPatient={patient}
        linkedPatients={linkedPatients}
        editLinkedPatients={editLinkedPatients}
      />
    </>
  );
}
