export const enum DocumentReferenceTag {
  ManualConsentUpload = 'manual-consent-upload',
}
export const enum DocumentType {
  ChatAttachment = 'chat-attachment',
  DisasterPlan = 'disaster-plan',
  SickPlan = 'sick-plan',
}

export const documentTypeMap = [
  {
    code: DocumentType.ChatAttachment,
    display: 'Chat Attachment',
  },
  {
    code: DocumentType.DisasterPlan,
    display: 'Disaster Plan',
  },
  {
    code: DocumentType.SickPlan,
    display: 'Sick Plan',
  },
];
