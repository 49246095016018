import { System, TaskType } from 'const-utils';
import { BaseTaskWrapper } from './baseTask';
import { BHSurveyType } from 'const-utils/codeSystems/ImaginePediatrics';

export class SurveyTask extends BaseTaskWrapper<TaskType.Screener | TaskType.BHSurvey> {
  get focus() {
    if (this.task.focus?.resource?.__typename === 'Questionnaire') {
      return this.task.focus?.resource;
    } else {
      return null;
    }
  }

  get owner() {
    if (this.task.owner?.resource?.__typename === 'Patient') {
      return this.task.owner.resource;
    }

    return null;
  }

  get executionPeriod() {
    return this.task.executionPeriod;
  }

  get isBHSurveyTask() {
    return this.type === TaskType.BHSurvey;
  }

  get bhSurveyType() {
    const bhType = this.task.meta?.tag?.find((tag) => tag.system === System.BHSurveyType)?.code;
    if (!bhType) {
      return null;
    }

    return bhType as BHSurveyType;
  }
}
