import React from 'react';
import { useDisclosure } from '@mantine/hooks';
import { ActionIcon } from '@mantine/core';
import { IconEditCircle } from '@tabler/icons-react';
import UpsertTaskModal, { TaskFormValues } from '../shared/UpsertTaskModal';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { TaskType } from 'const-utils';

type EditTaskButtonProps = {
  task: TaskFormValues;
  patientId: string;
};
export const EditTaskButton = (props: EditTaskButtonProps): JSX.Element => {
  const [open, handle] = useDisclosure();

  const flags = useFeatureFlags();

  const taskTypes = [
    {
      label: 'Schedule Appointment BH TOC',
      value: TaskType.ScheduleAppointmentBHTOC,
    },
  ];

  const closeTaskModal = async (): Promise<void> => {
    handle.close();
  };

  return (
    <>
      <ActionIcon onClick={handle.open}>
        <IconEditCircle size={20} />
      </ActionIcon>
      {open && (
        <UpsertTaskModal
          operation="Edit"
          existingTask={props.task}
          opened={open}
          closeModal={closeTaskModal}
          patientId={props.patientId}
          {...(flags.Fuh && props.task.taskType === TaskType.ScheduleAppointmentBHTOC.toString() ? { taskTypes } : {})}
        />
      )}
    </>
  );
};
