import { IconArrowsSort, IconSortAscending, IconSortDescending } from '@tabler/icons-react';
import React from 'react';

export function SortIcon({ isSortField, sortOrder }: { isSortField: boolean; sortOrder: string }): JSX.Element {
  if (!isSortField) {
    return <IconArrowsSort size={12} />;
  }

  return sortOrder === 'asc' ? <IconSortAscending size={12} /> : <IconSortDescending size={12} />;
}
