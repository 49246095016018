import React from 'react';
import { Text, Table, Group, Stack, Button, ThemeIcon } from '@mantine/core';
import { TaskActivity } from './types';
import { formatWithYesterdayAndToday } from 'imagine-dsl/utils/dates';
import { IconAlertCircleFilled, IconMessages } from '@tabler/icons-react';
import { Concepts, codeSystemConcepts, TaskType } from 'const-utils';
import { useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';

interface AlertActivityTableItemProps {
  alert: TaskActivity;
  patientId: string;
}
export const AlertActivityItem = ({ alert, patientId }: AlertActivityTableItemProps): JSX.Element => {
  const TaskTypeCodes = codeSystemConcepts[Concepts.TaskType].concept;
  const thisTaskTypeCode = TaskTypeCodes?.find((c) => (c.code as TaskType) === alert.type);

  const isUrgent = alert.priority === 'urgent';

  const urgentColor = isUrgent ? 'status-error' : 'imagine-green';
  const isResolved = alert.status === 'completed';

  const navigate = useNavigate();

  const goToAlert = () => {
    if (!isResolved) {
      navigate(`/?patient=${patientId}`);
    } else {
      notifications.show({
        message: 'This alert has already been resolved.',
        color: 'status-warn',
      });
    }
  };

  return (
    <Table.Tr style={{ verticalAlign: 'top' }} key={alert.id}>
      <Table.Td>
        <Stack justify="flex-start" mt="25px">
          <ThemeIcon c={urgentColor} bd={`1px solid ${urgentColor}`} radius="100%" p={2}>
            <IconMessages size={16} />
          </ThemeIcon>
        </Stack>
      </Table.Td>

      <Table.Td>
        <Stack gap={10}>
          {alert.date && (
            <Text size="xs" c="imagine-gray">
              {formatWithYesterdayAndToday(alert.date, 'EEE, MMMM do, yyyy hh:mm aaa', true)}
            </Text>
          )}
          <Group gap={4}>
            <Text fw="bold" size="sm">
              Alert:{' '}
            </Text>
            {isUrgent ? (
              <>
                <ThemeIcon c={urgentColor}>
                  <IconAlertCircleFilled />
                </ThemeIcon>
                <Text size="sm" fw="bold">
                  Urgent{' '}
                </Text>
              </>
            ) : (
              <Text size="sm" fw="bold">
                Not Urgent
              </Text>
            )}
            {alert.owner?.name && (isResolved ? <Text>resolved by</Text> : <Text>is assigned to</Text>)}
            {alert.owner?.name && (
              <Group gap={4}>
                <Text fw={700} size="sm">
                  {alert.owner.name}
                </Text>
                {alert.owner.role && (
                  <Text fw={400} c="imagine-gray">
                    ({alert.owner.role})
                  </Text>
                )}
              </Group>
            )}
          </Group>
          <Group>
            <Text>{'Description: ' + thisTaskTypeCode?.display}</Text>
          </Group>
          <Button h={25} w={100} fz={12} onClick={goToAlert} variant="outline">
            View alert
          </Button>
        </Stack>
      </Table.Td>
    </Table.Tr>
  );
};
