import { logError } from '@/errors';
import { sha256 } from '@/utils/sha256';
import { useState, useEffect } from 'react';

export const useSha256 = (val: object | null | undefined): string | undefined => {
  const [hash, setHash] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (val === null || val === undefined) {
      setHash(undefined);
      return;
    }

    sha256(JSON.stringify(val))
      .then(setHash)
      .catch((err) => {
        logError(err);
        setHash(undefined);
      });
  }, [val]);
  return hash;
};
