import { Box, Group, Table, Text, ThemeIcon } from '@mantine/core';
import { IconAlertCircle, IconUsersGroup } from '@tabler/icons-react';
import { GetPatientQuery } from 'medplum-gql';
import React from 'react';
import { EngagementTabParticipants } from './EngagementTabParticipants';
import { CareTeam } from '@medplum/fhirtypes';

interface EngagementTabProps {
  patient: GetPatientQuery | undefined;
}

export const EngagementTab = ({ patient }: EngagementTabProps) => {
  const engagementCareTeam = patient?.Patient?.engagementCareTeam?.[0]?.participant?.[0]?.member;
  const pod = engagementCareTeam?.display;

  return (
    <>
      <Group mt="lg" justify="space-between" bg="imagine-green.0" p={10} style={{ borderRadius: '10px' }}>
        <Group>
          <Text size="md">Engagement team</Text>
          <Box h={20} ml={10} style={{ borderLeft: '1px solid var(--mantine-color-imagine-gray-6)' }} />
          <Text fw={700}>Pod:</Text>

          {pod ? (
            <Group align="center" gap={10}>
              <IconUsersGroup size={16} />
              <Text size="md">{pod}</Text>
            </Group>
          ) : (
            <Group justify="center" gap={10}>
              <ThemeIcon color="status-error">
                <IconAlertCircle size={16} />
              </ThemeIcon>
              <Text fz="xs">No Pod Assigned</Text>
            </Group>
          )}
        </Group>
      </Group>
      <Table verticalSpacing="sm">
        <Table.Thead>
          <Table.Tr>
            <Table.Th w="400px">Role</Table.Th>
            <Table.Th>Assigned team member</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {(engagementCareTeam?.resource as CareTeam)?.participant?.map((member) => {
            const participantId = member?.member?.reference?.split('/')[1] ?? '';
            const name = member?.member?.display ?? '';
            return <EngagementTabParticipants key={participantId} participantId={participantId} name={name} />;
          })}
        </Table.Tbody>
      </Table>
    </>
  );
};
