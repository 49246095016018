import { CallDisposition, System } from 'const-utils';
import { GetPatientQuery } from 'medplum-gql';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Coding, Patient } from '@medplum/fhirtypes';
import { uniq } from 'lodash';

export const getPatientCaregiver = (patient: GetPatientQuery['Patient']): GetPatientQuery['Patient'] => {
  return patient?.RelatedPersonList?.[0]?.PatientList?.[0];
};

export const getCaregiverPhoneNumbers = (caregiver: GetPatientQuery['Patient']): string[] => {
  return caregiver?.telecom?.filter((t) => t.system === 'phone').map((t) => t.value!) ?? [];
};

export const getPhoneNumberOptions = (phoneNumbers: string[]): { value: string; label: string }[] => {
  return uniq(phoneNumbers).map((number) => ({
    value: number,
    label: parsePhoneNumber(number, 'US')?.formatNational(),
  }));
};

// formatHourAndMinutes access HH:MM notation in 24-hour format
// and returns proper time in 12-hour format with AM/PM
export const formatHourAndMinutes = (hourAndMinutes: string): string => {
  const [hour, minutes = '00'] = hourAndMinutes.split(':');
  const hour24 = parseInt(hour, 10);
  const period = hour24 < 12 ? 'AM' : 'PM';
  return `${((hour24 - 1) % 12) + 1}:${minutes} ${period}`;
};

type HasOutcome = {
  outcome?: Coding;
};
export const isCallbackRequested = (values: HasOutcome): boolean => {
  return values.outcome?.code === CallDisposition.CallbackRequested;
};

// NOTE: This is equivalent to the `hasSMSConsent` function in the `api` module.
// Let's consolidate our logic to one place soon.
export const hasSMSConsent = (caregiver: Patient): boolean =>
  !!caregiver.extension?.some(
    (extension) => extension.url === System.CaregiverSMSConsent.toString() && extension.valueBoolean,
  );
