import { useMedplumProfile } from '@medplum/react';
import Pusher, { Channel } from 'pusher-js';
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import {
  NewAssignedTask,
  NewTaskActivity,
  PatientChatEventType,
  PractitionerEventType,
  TaskStatusEventType,
} from 'imagine-dsl/services/notificationService';

interface PusherContext {
  pusher: Pusher;
}

const PusherContextValue = createContext<PusherContext | undefined>(undefined);

interface PusherProviderProps {
  pusher: Pusher;
}

export function PusherProvider({ pusher, children }: PropsWithChildren<PusherProviderProps>): JSX.Element {
  return <PusherContextValue.Provider value={{ pusher }}>{children}</PusherContextValue.Provider>;
}

export function usePusher(): Pusher {
  const context = useContext(PusherContextValue);
  if (!context) {
    throw new Error('usePusher must be used within a PusherProvider');
  }

  const { pusher } = context;
  return pusher;
}

type SubscriptionCallback<T> = (data: T) => void;

function useChannelSubscription<T>(
  channelName: string,
  eventName: string,
  callback: SubscriptionCallback<T>,
  skip: boolean = false,
): void {
  const pusher = usePusher();

  useEffect(() => {
    if (skip) {
      return () => {};
    }

    const channel = pusher.subscribe(channelName);
    channel.bind(eventName, callback);
    return () => {
      channel.unbind(eventName, callback);
    };
  }, [pusher, channelName, eventName, callback, skip]);
}

export function usePractitionerChannelSubscription<T>(eventName: string, callback: SubscriptionCallback<T>): void {
  const medplumProfile = useMedplumProfile();
  const pusher = usePusher();

  useEffect(() => {
    if (medplumProfile) {
      const channel = pusher.subscribe('imagine-user-' + medplumProfile.id);
      channel.bind(eventName, callback);

      return () => {
        channel.unbind(eventName, callback);
      };
    }
    return () => {};
  }, [pusher, medplumProfile, eventName, callback]);
}

type SubscriptionBinding = {
  eventType: PractitionerEventType;
  callback: SubscriptionCallback<NewAssignedTask | NewTaskActivity>;
};

export function usePractitionerChannelSubscriptions(callbacks: SubscriptionBinding[]): void {
  const medplumProfile = useMedplumProfile();
  const pusher = usePusher();

  useEffect(() => {
    if (medplumProfile) {
      const channel = pusher.subscribe('imagine-user-' + medplumProfile.id);

      callbacks.forEach((binding) => {
        channel.bind(binding.eventType, binding.callback);
      });

      return () => {
        callbacks.forEach((binding) => {
          channel.unbind(binding.eventType, binding.callback);
        });
      };
    }
    return () => {};
  }, [pusher, medplumProfile, callbacks]);
}

interface PatientChatChannelSubscriptionVariables {
  patientId: string | undefined;
  eventName: PatientChatEventType;
  skip?: boolean;
}

export function usePatientChatChannelSubscription<T>(
  variables: PatientChatChannelSubscriptionVariables,
  callback: SubscriptionCallback<T>,
): Channel | undefined {
  const { patientId, eventName, skip } = variables;
  const pusher = usePusher();
  const [channel, setChannel] = useState(undefined as Channel | undefined);

  useEffect(() => {
    if (skip) {
      return () => {};
    }

    if (patientId) {
      const topic = `imagine-patient-chat-${patientId}`;
      const channel = pusher.subscribe(topic);
      setChannel(channel);
      channel.bind(eventName, callback);
      return () => {
        channel.unbind(eventName, callback);
      };
    }
    return () => {};
  }, [pusher, patientId, eventName, callback, skip]);

  return channel;
}

interface PatientTaskChannelSubscriptionVariables {
  patientId: string | undefined;
  eventName: TaskStatusEventType;
  skip?: boolean;
}

export function usePatientTaskChannelSubscription<T>(
  variables: PatientTaskChannelSubscriptionVariables,
  callback: SubscriptionCallback<T>,
): Channel | undefined {
  const { patientId, eventName, skip } = variables;

  const pusher = usePusher();
  const [channel, setChannel] = useState(undefined as Channel | undefined);

  useEffect(() => {
    if (skip) {
      return () => {};
    }

    if (patientId) {
      const topic = `imagine-patient-task-${patientId}`;
      const channel = pusher.subscribe(topic);
      setChannel(channel);
      channel.bind(eventName, callback);
      return () => {
        channel.unbind(eventName, callback);
      };
    }
    return () => {};
  }, [pusher, patientId, eventName, callback, skip]);

  return channel;
}

export function useGlobalChannelSubscription<T>(
  eventName: string,
  callback: SubscriptionCallback<T>,
  skip: boolean = false,
): void {
  useChannelSubscription('imagine-global', eventName, callback, skip);
}
