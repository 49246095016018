import { logError } from '@/errors';
import { useCallback, useEffect, useState } from 'react';
import { AudioTrackPublication, LocalAudioTrack, Room } from 'twilio-video';

interface Options {
  room?: Room;
  initialState: boolean;
}

interface Result {
  isMicrophoneOn: boolean;
  toggleMicrophone: () => void;
}

export default function useToggleMicrophone({ room, initialState }: Options): Result {
  const [isMicrophoneOn, setIsMicrophoneOn] = useState(initialState);

  useEffect(() => {
    if (!room) {
      setIsMicrophoneOn(initialState);
    }
  }, [room, initialState]);

  const toggleMicrophone = useCallback(() => {
    if (room) {
      if (isMicrophoneOn) {
        // turn off
        room.localParticipant.audioTracks.forEach((publication: AudioTrackPublication) => {
          (publication?.track as LocalAudioTrack)?.disable();
        });

        setIsMicrophoneOn(false);
      } else {
        // turn on
        room.localParticipant.audioTracks.forEach(async (publication: AudioTrackPublication) => {
          const localTrack = publication?.track as LocalAudioTrack;
          localTrack?.enable();
          //set krisp noise cancellation on when mic is on
          await localTrack?.noiseCancellation?.enable();
        });

        setIsMicrophoneOn(true);
      }
    } else {
      logError('room is undefined');
      setIsMicrophoneOn(initialState);
    }
  }, [isMicrophoneOn, room, initialState]);

  return { isMicrophoneOn, toggleMicrophone };
}
