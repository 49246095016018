import { Button, Group, Modal, Select, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { OutreachStatus } from 'const-utils';
import {
  Patient,
  GetPatientQuery,
  GetPatientPanelDocument,
  GetPatientDocument,
  GetBasicPatientDocument,
} from 'medplum-gql';
import React, { useMemo, useState } from 'react';
import { useMedplum } from '@medplum/react';
import { notifications } from '@mantine/notifications';
import { logError } from '@/errors';
import outreachStatusDataRetention from '../programStatus/OutreachStatusDataRetention';
import { outreachStatusOptions } from 'const-utils/codeSystems/ImaginePediatrics';
import { useApolloClient } from '@apollo/client';
import { EpisodeOfCare } from '@medplum/fhirtypes';
import { useQuery, useQueryClient } from '@tanstack/react-query';

interface OutreachStatusEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  patient: Patient | GetPatientQuery['Patient'];
  episodeOfCare?: EpisodeOfCare;
}

export const OutreachStatusEditModal = ({ isOpen, onClose, patient, episodeOfCare }: OutreachStatusEditModalProps) => {
  const medplum = useMedplum();
  const [loading, setLoading] = useState(false);
  const apolloClient = useApolloClient();
  const tanstackClient = useQueryClient();

  const result = useQuery([`episodeOfCare-${patient?.id}`], async () => {
    if (!patient?.id) {
      return null;
    }

    return medplum.searchResources('EpisodeOfCare', `patient=Patient/${patient.id}`);
  });

  const fetchedEpisodeOfCare = useMemo(() => result.data?.at(0), [result.data]);

  const form = useForm({
    initialValues: {
      status: '',
      reason: '',
      date: new Date().toISOString().split('T')[0],
    },
  });

  const definedEpisodeOfCare = useMemo(
    () => episodeOfCare ?? fetchedEpisodeOfCare,
    [episodeOfCare, fetchedEpisodeOfCare],
  );

  const submitHandler = async (values: { status: string; reason: string; date: string }) => {
    await handleSubmit(values).catch(logError);
  };

  const handleSubmit = async (values: { status: string; reason: string; date: string }) => {
    if (!patient?.id) {
      return;
    }

    setLoading(true);

    try {
      await outreachStatusDataRetention(
        values.status as OutreachStatus,
        values.date,
        patient.id!,
        medplum,
        definedEpisodeOfCare,
      );
      await apolloClient
        .refetchQueries({
          include: [GetPatientPanelDocument, GetPatientDocument, GetBasicPatientDocument],
        })
        .catch(logError);
      await tanstackClient.invalidateQueries([`episodeOfCare-${patient.id}`]);
      onClose();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error instanceof Error ? error.message : String(error),
        color: 'status-error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title="Edit patient outreach status"
      size="lg"
      radius="lg"
      c="imagine-green"
      centered
    >
      <form onSubmit={form.onSubmit(submitHandler)}>
        <Stack gap="md" c="black">
          <Text fz="xs" mb="sm">
            Select the status you would like to assign
          </Text>
          <Select
            label="Status"
            {...form.getInputProps('status')}
            clearable
            searchable={false}
            data={outreachStatusOptions}
          />
          <TextInput required label="Date" type="date" mb="md" {...form.getInputProps('date')} />

          <Group mt="md" justify="flex-end">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" disabled={!form.isDirty('status')} loading={loading}>
              Save changes
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};
