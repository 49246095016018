import { Badge, BadgeProps } from '@mantine/core';
import { Attribution } from 'const-utils/codeSystems/ImaginePediatrics';
import { isEligible, PatientTag } from 'imagine-dsl/utils/patient';
import React from 'react';

interface PatientEligibilityBadgeProps extends BadgeProps {
  patientTags: PatientTag[];
}

export function PatientEligibilityBadge({
  patientTags,
  variant = 'light',
  size = 'sm',
  ...badgeProps
}: PatientEligibilityBadgeProps): JSX.Element {
  return (
    <>
      {isEligible(patientTags) ? (
        <Badge size={size} variant={variant} color="green" {...badgeProps}>
          {Attribution.Attributed}
        </Badge>
      ) : (
        <Badge size={size} variant={variant} color="red" {...badgeProps}>
          {Attribution.NotAttributed}
        </Badge>
      )}
    </>
  );
}
