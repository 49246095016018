import { useNotificationContext } from '@/pages/Notifications/NotificationsProvider';
import React from 'react';
import { MenuItem } from './MenuItem';
import { Divider, Loader, Menu, Stack, Text, ThemeIcon } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';

export const MenuDropdown = (): JSX.Element => {
  const { notifications, allNotificationsLoading } = useNotificationContext();
  const empty = notifications.length === 0;

  if (allNotificationsLoading) {
    return (
      <Menu.Item p={0} styles={{ item: { opacity: 'unset' } }}>
        <Loader />
      </Menu.Item>
    );
  }

  return (
    <>
      {empty && (
        <Menu.Item opacity="unset" p={0} disabled>
          <Stack align="center" m={8} gap={2}>
            <ThemeIcon variant="transparent" h={34} w={34} color="imagine-green">
              <IconCheck />
            </ThemeIcon>
            <Text fw="bold" c="black">
              You're all caught up!
            </Text>
            <Text c="black">There are no new notifications for you to review.</Text>
          </Stack>
          <Divider />
        </Menu.Item>
      )}
      {!empty &&
        notifications.slice(0, 3).map((notification) => (
          <React.Fragment key={notification.id}>
            <MenuItem notification={notification} />
            <Divider />
          </React.Fragment>
        ))}
      <Menu.Item href="/notifications" component="a">
        <Text c="imagine-green" td="underline" ta="center" fw="bold">
          See all notifications
        </Text>
      </Menu.Item>
    </>
  );
};
