// TODO: cleanup once merge-task-views is deployed

import React, { createContext, useContext, useMemo } from 'react';
import { GetIncompleteOutreachTasksQuery, useGetIncompleteOutreachTasksQuery } from 'medplum-gql';
import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { useMedplumProfile } from '@medplum/react';
import { countTasksAssignedToProfile, hasOverdueAssignedTasks } from 'imagine-dsl/utils/task';
import { compact } from 'lodash';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';

interface OutreachTaskAlertProviderProps {}

interface OutreachTaskAlertContext {
  tasks: BaseTask[];
  loading: boolean;
  error?: ApolloError;
  outreachTasksAssignedToMeCount: number;
  hasOverdueTasks: boolean;
  refetchTasks: () => Promise<[ApolloQueryResult<GetIncompleteOutreachTasksQuery>]>;
}

const OutreachTaskAlertContextValue = createContext<OutreachTaskAlertContext | undefined>(undefined);

export const useOutreachTaskAlertContext = (): OutreachTaskAlertContext => {
  const context = useContext(OutreachTaskAlertContextValue);
  if (!context) {
    throw new Error('useOutreachTaskAlertContext must be used within a OutreachTaskAlertProvider');
  }
  return context;
};

//TODO: post MVP notifications to alert for these tasks pusher or websockets
export function OutreachTaskAlertProvider({
  children,
}: React.PropsWithChildren<OutreachTaskAlertProviderProps>): JSX.Element {
  const flags = useFeatureFlags();

  const { data, loading, error, refetch } = useGetIncompleteOutreachTasksQuery({
    pollInterval: 60000,
    skip: flags.MergeTaskViews,
  });

  const profile = useMedplumProfile();

  const tasks = useMemo(() => compact(data?.TaskList) || [], [data]).map((t) => new BaseTask(t));

  const outreachTasksAssignedToMeCount = useMemo(
    () =>
      profile
        ? countTasksAssignedToProfile(
            tasks.map((t) => t.owner?.id || ''),
            profile,
          )
        : 0,
    [tasks, profile],
  );
  const hasOverdueTasks = useMemo(() => {
    if (!profile) {
      return false;
    }
    return hasOverdueAssignedTasks(tasks.filter((t) => t.owner?.id === profile.id).map((t) => t.dueDate ?? ''));
  }, [profile, tasks]);

  const refetchTasks = async () => {
    return Promise.all([refetch()]);
  };

  return (
    <OutreachTaskAlertContextValue.Provider
      value={{
        tasks,
        loading,
        error,
        hasOverdueTasks,
        refetchTasks,
        outreachTasksAssignedToMeCount,
      }}
    >
      {children}
    </OutreachTaskAlertContextValue.Provider>
  );
}
