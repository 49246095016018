import { imagineClient } from '@/hooks/useImagineApolloClient';
import { notifications } from '@mantine/notifications';
import { useRevokeCarehubAccessMutation } from 'imagine-gql/client';
import { useCallback } from 'react';

type Revoke = () => Promise<void>;

export const useRevoke = (practitionerId: string): Revoke => {
  const [revokeMutation] = useRevokeCarehubAccessMutation({
    client: imagineClient,
    onCompleted: () => {
      notifications.show({ message: "User's access revoked.", color: 'status-success' });
    },
  });

  const revoke = useCallback(async () => {
    await revokeMutation({
      variables: {
        userIds: [practitionerId],
      },
    });
  }, [revokeMutation, practitionerId]);

  return revoke;
};
