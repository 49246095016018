import React from 'react';
import { Header } from './types';
import { Text, ActionIcon, Flex, Menu, Tooltip } from '@mantine/core';
import {
  IconArrowNarrowDown,
  IconArrowNarrowUp,
  IconDotsVertical,
  IconFilter,
  IconSortAscending,
  IconSortDescending,
} from '@tabler/icons-react';
import { SortDirection, sortDirections } from 'imagine-dsl/utils/search';

interface HeaderMenuProps {
  header: Header;
  sorted: boolean;
  onSort: (header: Header, direction?: SortDirection) => void;
  disabled?: boolean;
  sortDirection?: SortDirection;
  onFilter?: (field: string, value: string) => void;
  filtered: boolean;
}

const styles = {
  icon: {
    color: 'var(--mantine-color-gray-darken-7)',
  },
  activeIcon: {
    color: 'var(--mantine-color-imagine-green-6)',
  },
  tooltip: {
    width: '150px',
    fontSize: '12px',
  },
  header: {
    width: 'max-content',
  },
};

export function HeaderMenu(props: HeaderMenuProps): JSX.Element {
  const { disabled, header, sorted, onSort, sortDirection, onFilter, filtered } = props;

  const removeFilters = (header: Header): void => {
    if (header.filterOptions && onFilter) {
      onFilter(header.filterOptions[0].field, '');
    }
    if (header.sortable && onSort) {
      onSort(header);
    }
  };

  const sortDirectionIcon = (direction: SortDirection): JSX.Element => {
    return direction === 'ascending' ? (
      <IconSortAscending style={styles.icon} />
    ) : (
      <IconSortDescending style={styles.icon} />
    );
  };

  return (
    <>
      <Flex gap={0} align="center">
        {!disabled && sorted && (
          <>
            <Tooltip
              style={{ tooltip: { width: '150px', fontSize: '12px' } }}
              color="gray"
              multiline
              transitionProps={{ transition: 'pop' }}
              position="bottom"
              label={`Remove sort ${header.display} by ${sortDirection}`}
            >
              <ActionIcon onClick={() => removeFilters(header)}>
                <>
                  {sortDirection === 'ascending' && <IconArrowNarrowUp style={styles.activeIcon} />}
                  {sortDirection === 'descending' && <IconArrowNarrowDown style={styles.activeIcon} />}
                </>
              </ActionIcon>
            </Tooltip>
          </>
        )}
        {!disabled && filtered && (
          <Tooltip
            style={{ tooltip: { width: '150px', fontSize: '12px' } }}
            label={`Remove filter for ${header.display}`}
            color="gray"
            position="bottom"
            multiline
            transitionProps={{ transition: 'pop' }}
          >
            <ActionIcon onClick={() => removeFilters(header)}>
              <IconFilter style={styles.activeIcon} />
            </ActionIcon>
          </Tooltip>
        )}
        <span style={styles.header}>{header.display}</span>{' '}
        <Menu disabled={disabled} shadow="md" width={250}>
          <Menu.Target>
            <ActionIcon>
              <IconDotsVertical style={styles.icon} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            {header.sortable && (
              <>
                {sortDirections.map((direction) => (
                  <Menu.Item
                    key={direction}
                    leftSection={sortDirectionIcon(direction)}
                    disabled={disabled || (sorted && sortDirection === direction)}
                    onClick={() => onSort(header, direction)}
                  >
                    <Text>
                      Sort by {header.display} {direction}
                    </Text>
                  </Menu.Item>
                ))}
              </>
            )}
            {onFilter &&
              header.filterOptions?.map((option, index) => (
                <Menu.Item
                  key={index}
                  leftSection={<IconFilter style={styles.icon} />}
                  disabled={disabled}
                  onClick={() => onFilter(option.field, option.value)}
                >
                  <Text>Filter {option.display} only</Text>
                </Menu.Item>
              ))}
          </Menu.Dropdown>
        </Menu>
      </Flex>
    </>
  );
}
