import React, { useState } from 'react';
import { PatientPrimaryInfoFields } from '../helpers/EditFormHandler';
import { ActionIcon, Modal, Text, Flex, Group } from '@mantine/core';
import { IconEditCircle } from '@tabler/icons-react';
import PatientProfileEditModal from '../modals/PatientProfileEditModal';
import { PatientProfileEditProvider } from '../modals/PatientProfileEditProvider';
import { Patient } from 'medplum-gql';

interface EditableFieldProps {
  label: PatientPrimaryInfoFields;
  patient: Patient;
  onDataChanged: () => void;
  value: string;
}

export function EditableField({ label, value, onDataChanged, patient }: EditableFieldProps): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const title = <Text size="lg" fw={700} c="imagine-green">{`Edit ${label}`}</Text>;

  return (
    <Flex direction="column" mb="md">
      <Group justify="space-between">
        <Text c="dimmed">{label}</Text>
        <ActionIcon
          color="imagine-green"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          <IconEditCircle size={20} />
        </ActionIcon>
      </Group>
      <Text size="sm">{value}</Text>
      <Modal
        title={title}
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size={label === PatientPrimaryInfoFields.ADDRESS ? 'lg' : 'md'}
        radius="lg"
        centered
      >
        <PatientProfileEditProvider
          patient={patient}
          field={label}
          onDataChanged={onDataChanged}
          closeModal={() => setIsModalOpen(false)}
        >
          <PatientProfileEditModal
            title={`Edit ${label}`}
            field={label}
            patientId={patient.id!}
            onDataChanged={onDataChanged}
            closeModal={() => setIsModalOpen(false)}
          />
        </PatientProfileEditProvider>
      </Modal>
    </Flex>
  );
}
