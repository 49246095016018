import React, { useMemo } from 'react';
import { Stack, Container, Group, Button, Grid } from '@mantine/core';
import { Patient, Practitioner, Task } from '@medplum/fhirtypes';
import { Maybe } from 'medplum-gql';
import { IconCheck } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { AssigneeSection } from '../../../chat/footer/AssigneeSection';
import { TasksResolveAlertModal } from './TasksResolveAlertModal';
import { getName } from 'imagine-dsl/utils/patient';

interface FooterProps {
  assignee: Maybe<Practitioner>;
  profile: Practitioner;
  task: Task;
  refreshTasks?: () => Promise<unknown>;
  onCloseDrawer: () => void;
  refetchTasks?: () => Promise<unknown>;
}

export function TasksFooter({
  assignee,
  refreshTasks,
  profile,
  task,
  onCloseDrawer,
  refetchTasks,
}: FooterProps): JSX.Element {
  const [resolveModalOpened, { close: closeResolveModal, open: openResolveModal }] = useDisclosure();

  const taskMarket = useMemo(() => {
    return (task?.for?.resource as Patient)?.managingOrganization?.resource?.id;
  }, [task]);

  const isResolved =
    task?.status === 'completed' ||
    task?.status === 'cancelled' ||
    task?.status === 'rejected' ||
    task?.status === 'accepted';

  const onCloseHandler = async () => {
    await refetchTasks?.();
    closeResolveModal();
    onCloseDrawer();
  };

  return (
    <>
      <TasksResolveAlertModal
        refreshTasks={refreshTasks}
        taskId={task.id!}
        opened={resolveModalOpened}
        onClose={onCloseHandler}
      />
      {!isResolved && (
        <Container pt={16} pb={24} mx={10}>
          <Grid justify="space-between">
            <Stack style={{ gap: 0 }}>
              <AssigneeSection
                taskMarket={taskMarket!}
                assigneeId={assignee?.id}
                assigneeName={getName(assignee)}
                taskId={task.id!}
                disabled={task.status === 'completed'}
                refreshTasks={refreshTasks ?? Promise.resolve}
                profile={profile}
              />
            </Stack>
            <Group>
              <Button onClick={() => openResolveModal()} variant="outline" leftSection={<IconCheck />}>
                Resolve
              </Button>
            </Group>
          </Grid>
        </Container>
      )}
    </>
  );
}
