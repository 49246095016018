import React from 'react';
import { Group, NavLink, NavLinkProps, rem } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { omit } from 'lodash';

import classes from './StyledNavLink.module.css';

const activeStyle: React.CSSProperties = {
  fontWeight: 'bold',
  backgroundColor: 'var(--mantine-color-imagine-green-0)',
  alignItems: 'center',
  borderRight: '3px solid var(--mantine-color-imagine-green-6)',
};
const defaultStyle: React.CSSProperties = {
  width: '100%',
  paddingLeft: '.3rem',
};

interface StyledNavLinkProps extends NavLinkProps {
  to: string;
  rightSection?: React.ReactNode;
}

export const StyledNavLink = (props: StyledNavLinkProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();

  if (!props.rightSection) {
    return (
      <NavLink
        pl={rem('1.2rem')}
        active={location.pathname === props.to}
        onClick={() => navigate(props.to)}
        style={location.pathname === props.to ? { ...defaultStyle, ...activeStyle } : defaultStyle}
        {...props}
      />
    );
  }

  return (
    <Group
      className={classes.linkWithRightSection}
      wrap="nowrap"
      gap={0}
      style={location.pathname === props.to ? { ...defaultStyle, ...activeStyle } : defaultStyle}
    >
      <NavLink
        active={location.pathname === props.to}
        onClick={() => navigate(props.to)}
        {...omit(props, 'rightSection')}
      />
      {props.rightSection}
    </Group>
  );
};
