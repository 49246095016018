const prefix = '@ipch';
const makeKey = (key: string): string => `${prefix}:${key}`;
const makeKeyWithUser =
  (key: string) =>
  (userId?: string): string =>
    makeKey(userId ? `${userId}:${key}` : `${key}`);

export const localStorageKeys = {
  lastActive: makeKeyWithUser('lastActive'),
  inactiveAlert: makeKey('inactiveAlert'),
  processingCode: makeKey('processingCode'),
  profilePreferences: makeKey('profilePreferences'),
};

export const sessionStorageKeys = {
  redirectTo: makeKeyWithUser('redirectTo'),
};
