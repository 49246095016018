import React from 'react';
import { Group, Table, Text } from '@mantine/core';
import { useGetPractitionerRoleQuery } from 'medplum-gql';

type EngagementTabParticipantsProps = {
  participantId: string;
  name: string;
};

export function EngagementTabParticipants({ participantId, name }: EngagementTabParticipantsProps) {
  const { data: practitioner } = useGetPractitionerRoleQuery({
    variables: { id: participantId },
  });
  const practitionerRole = practitioner?.Practitioner?.PractitionerRoleList?.[0]?.code?.[0]?.coding?.[0]?.display;

  return (
    <Table.Tr key={participantId}>
      <Table.Td style={{ width: '200px' }}>
        <Text>{practitionerRole}</Text>
      </Table.Td>
      <Table.Td>
        <Group>
          <Text>{name}</Text>
        </Group>
      </Table.Td>
    </Table.Tr>
  );
}
