import { getErrorMessage } from '@/errors';
import { Button, Group, Modal, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMedplum } from '@medplum/react';
import { resolveTask } from 'imagine-dsl/services/taskService';
import React, { useState } from 'react';

interface TasksResolveAlertModalProps {
  opened: boolean;
  onClose: () => void;
  taskId: string;
  refreshTasks?: () => Promise<unknown>;
}

export function TasksResolveAlertModal({
  opened,
  onClose,
  taskId,
  refreshTasks,
}: TasksResolveAlertModalProps): JSX.Element {
  const medplum = useMedplum();
  const [isResolving, setIsResolving] = useState(false);

  const onStatusUpdate = async (): Promise<void> => {
    if (isResolving) {
      return;
    }

    setIsResolving(true);

    try {
      await resolveTask(medplum, taskId);
      notifications.show({
        message: 'Task resolved',
        color: 'status-success',
      });
      await refreshTasks?.();
    } catch (err) {
      notifications.show({
        color: 'status-error',
        title: 'Error resolving task',
        message: getErrorMessage(err),
      });
    } finally {
      setIsResolving(false);
      onClose();
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      title={
        <Text c="imagine-green" size="lg">
          Resolve task
        </Text>
      }
    >
      <Text>Are you sure you want to resolve this task?</Text>
      <Group pt={16} style={{ justifyContent: 'flex-end' }}>
        <Button variant="outline" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button loading={isResolving} onClick={() => onStatusUpdate()}>
          Resolve task
        </Button>
      </Group>
    </Modal>
  );
}
