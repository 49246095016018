import { Group, MantineSize, Text } from '@mantine/core';
import React from 'react';

interface TextWithSidekickProps {
  text: string;
  sidekick?: string;
  fz?: MantineSize;
}
export const TextWithSidekick = ({ text, sidekick, fz = 'sm' }: TextWithSidekickProps): JSX.Element => {
  return (
    <Group gap={4}>
      <Text size={fz}>{text}</Text>
      {sidekick && (
        <Text size={fz} c="brand-gray.7">
          ({sidekick})
        </Text>
      )}
    </Group>
  );
};
