import { Badge, Box, Group, Text, ThemeIcon } from '@mantine/core';
import { IconMoodSick, IconTableShortcut } from '@tabler/icons-react';
import { DocumentType } from 'const-utils/codeSystems/ImaginePediatrics';
import React from 'react';

const documentTypeLookup: Record<
  DocumentType | 'Unknown',
  {
    display: string;
    icon?: JSX.Element;
    background: string;
    color: string;
  }
> = {
  [DocumentType.SickPlan]: {
    display: 'Sick Plan',
    icon: (
      <ThemeIcon c={'brand-secondary.8'} size={16}>
        <IconMoodSick size={16} />
      </ThemeIcon>
    ),
    background: 'imagine-green.0',
    color: 'brand-secondary.8',
  },
  [DocumentType.DisasterPlan]: {
    display: 'Disaster Plan',
    icon: (
      <ThemeIcon c={'brand-secondary.8'} size={16}>
        <IconTableShortcut size={16} />
      </ThemeIcon>
    ),
    background: 'brand-yellow.2',
    color: 'brand-secondary.8',
  },
  [DocumentType.ChatAttachment]: {
    display: '',
    icon: undefined,
    background: '',
    color: '',
  },
  Unknown: {
    display: 'Unknown',
    background: 'transparent',
    color: 'brand-gray.8',
  },
};

interface DocumentTypeBadgeProps {
  docType: string;
  condensedDisplay?: boolean;
  iconOnly?: boolean;
}

export const DocumentTypeBadge = ({ docType, condensedDisplay, iconOnly }: DocumentTypeBadgeProps): JSX.Element => {
  const options = docType === 'Unknown' ? documentTypeLookup[docType] : documentTypeLookup[docType as DocumentType];

  if (!options) {
    return <></>;
  }

  const Icon = options.icon;

  return (
    <Group gap="xs">
      <Badge
        leftSection={iconOnly ? undefined : Icon}
        c={options.color}
        bg={options.background}
        variant="light"
        p={condensedDisplay ? '0' : undefined}
        circle={!!iconOnly}
      >
        {iconOnly && <Box mt={6}>{Icon}</Box>}
        {!condensedDisplay && <Group gap="xs">{options.display}</Group>}
      </Badge>
      {condensedDisplay && <Text c={'dimmed'}>{options.display}</Text>}
    </Group>
  );
};
