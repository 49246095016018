import { Badge } from '@mantine/core';
import React, { useEffect, useMemo, useState } from 'react';
import { timeSince } from 'imagine-dsl/utils/dates';
import { formatDistance } from 'date-fns';

interface WaitTimeBadgeProps {
  date: string;
}

const FIVE_MINUTES = 300000;
const TEN_MINUTES = 600000;

export function WaitTimeBadge({ date }: WaitTimeBadgeProps): JSX.Element {
  const [waitTime, setWaitTime] = useState(timeSince(date));
  const [formattedTimeSince, setFormattedTimeSince] = useState(
    formatDistance(date, new Date(), { includeSeconds: true, addSuffix: true }),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setWaitTime(timeSince(date));
      setFormattedTimeSince(formatDistance(date, new Date(), { includeSeconds: true, addSuffix: true }));
    }, 30000);
    return () => clearInterval(interval);
  });

  const color = useMemo((): string => {
    if (!waitTime) {
      return '';
    }

    const absWaitTime = Math.abs(waitTime);

    if (absWaitTime < FIVE_MINUTES) {
      return 'green';
    } else if (absWaitTime >= FIVE_MINUTES && absWaitTime < TEN_MINUTES) {
      return 'orange';
    } else {
      return 'red';
    }
  }, [waitTime]);

  if (!date) {
    return <div>--</div>;
  }

  return (
    <Badge data-testid={`wait-time-badge-${color}`} color={color} variant="light">
      {formattedTimeSince}
    </Badge>
  );
}
