export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

export function defined<T>(values: (T | undefined)[]): T[] {
  return values.filter(isDefined);
}

type RecordWithStringKeys<T> = {
  [K in Extract<keyof T, string>]?: T[K];
};

export function merge<T extends RecordWithStringKeys<T>>(
  narray1: T[] | undefined,
  narray2: T[] | undefined,
  key: keyof T,
): T[] {
  // Combine the arrays
  const array1 = narray1 || [];
  const array2 = narray2 || [];
  const mergedArray: T[] = array1.concat(array2);

  // Create a Map to store unique keys and their corresponding objects
  const uniqueObjects = new Map<T[keyof T], T>();

  // Iterate through the merged array and prioritize array2 values
  mergedArray.forEach((obj) => {
    const keyValue = obj[key];

    // If the key is not in the Map or array2 has a value for the key, update the Map
    if (!uniqueObjects.has(keyValue) || array2.some((item) => item[key] === keyValue)) {
      uniqueObjects.set(keyValue, obj);
    }
  });

  // Convert the Map values to an array
  const resultArray = Array.from(uniqueObjects.values());

  return resultArray;
}
