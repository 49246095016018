import { MedplumClient } from '@medplum/core';
import { EpisodeOfCare, EpisodeOfCareStatusHistory } from '@medplum/fhirtypes';
import { ProgramStatus, System } from 'const-utils';
import { updatePatientTag } from 'imagine-dsl/services/patientService';
import { getEpisodeOfCareStatusFromProgramStatus, updateProgramStatusHistory } from 'imagine-dsl/utils/patient';

const programStatusDataRetention = async (
  programStatusValue: ProgramStatus,
  disengagementReasonValue: string,
  episodeOfCare: EpisodeOfCare,
  programStatusDate: string,
  patientId: string,
  medplum: MedplumClient,
): Promise<void> => {
  const refreshedPatient = await medplum.readResource('Patient', patientId);

  const episodeOfCareId = episodeOfCare.id || '';

  if (!episodeOfCareId) {
    throw new Error('EpisodeOfCare not found, please contact support.');
  }
  const refreshedEpisodeOfCare = await medplum.readResource('EpisodeOfCare', episodeOfCareId);
  const status = getEpisodeOfCareStatusFromProgramStatus(programStatusValue);

  const formattedDate = new Date(programStatusDate).toISOString();
  const statusHistory = refreshedEpisodeOfCare?.statusHistory || [];
  const newStatusHistory: EpisodeOfCareStatusHistory = {
    status,
    period: {
      start: formattedDate,
    },
  };

  const updatedStatusHistory = updateProgramStatusHistory(
    statusHistory,
    newStatusHistory,
    formattedDate,
    System.DisengagementReason.toString(),
    disengagementReasonValue,
  );

  if (programStatusValue === ProgramStatus.Disenrolled) {
    if (!newStatusHistory.extension) {
      newStatusHistory.extension = [];
    }
    const disengagementExtension = newStatusHistory.extension.find(
      (ext) => ext.url === System.DisengagementReason.toString(),
    );

    if (disengagementExtension) {
      disengagementExtension.valueCode = disengagementReasonValue;
    } else {
      newStatusHistory.extension.push({
        url: System.DisengagementReason,
        valueCode: disengagementReasonValue,
      });
    }
  }

  const updatedEpisodeOfCare = {
    ...refreshedEpisodeOfCare,
    status: newStatusHistory?.status,
    period: newStatusHistory?.period,
    statusHistory: updatedStatusHistory,
  };

  await medplum.updateResource(updatedEpisodeOfCare);
  await updatePatientTag(medplum, refreshedPatient, System.ProgramStatus, programStatusValue);
};

export default programStatusDataRetention;
