import { Button, Group, Paper } from '@mantine/core';
import React from 'react';
import { useOutreach } from './Context';
import { useMaybeExistingContacts } from '@/components/MaybeExistingContactForm/MaybeExistingContactContext';
import { FlowStep } from './types';

export const Footer = (): JSX.Element => {
  const {
    loading,
    handleContinue,
    handleBack,
    handleClickLogCallAndExit,
    canContinue,
    canBack,
    canComplete,
    flowStep,
    queuedPatientsToLink,
  } = useOutreach();
  const maybeExistingContacts = useMaybeExistingContacts();

  return (
    <Paper
      p="md"
      style={{
        position: 'sticky',
        bottom: 0,
        zIndex: 100,
      }}
    >
      <Group justify="right">
        <Button variant="subtle" disabled={loading} onClick={handleClickLogCallAndExit}>
          Log call & exit
        </Button>
        {canBack && (
          <Button onClick={handleBack} variant="outline" disabled={loading}>
            Back
          </Button>
        )}
        {canContinue && (
          <Button
            type="submit"
            onClick={handleContinue}
            variant="filled"
            disabled={
              loading ||
              (flowStep === FlowStep.ContactInfo &&
                (maybeExistingContacts.inputRequired || queuedPatientsToLink.some((q) => !q.relationship)))
            }
          >
            Continue
          </Button>
        )}
        {canComplete && (
          <Button type="submit" onClick={handleContinue} variant="filled" disabled={loading}>
            Complete Enrollment
          </Button>
        )}
      </Group>
    </Paper>
  );
};

export default Footer;
