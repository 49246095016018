import { MedplumClient } from '@medplum/core';
import { Bundle, Group } from '@medplum/fhirtypes';
import { isDefined } from '../utils/lists';
import { System } from 'const-utils';

export interface PatientListService {
  get(owner: string, type: PatientListsType): Promise<string[]>;
  updateList(owner: string, type: PatientListsType, list: string[]): Promise<void>;
}

export enum PatientListsType {
  recent = 'recentPatients',
  pinned = 'pinnedPatients',
}

export const PatientListTagMapping: Record<PatientListsType, string> = {
  [PatientListsType.recent]: 'recent-patients',
  [PatientListsType.pinned]: 'pinned-patients',
};

export const buildPatientListService = (medplum: MedplumClient): PatientListService => {
  return {
    async get(owner: string, type: PatientListsType): Promise<string[]> {
      return medplum
        .searchResources(
          'Group',
          {
            'managing-entity': `Practitioner/${owner}`,
            _tag: PatientListTagMapping[type],
          },
          { cache: 'no-cache' },
        )
        .then((result) =>
          result
            .flatMap((group) => {
              return group.member;
            })
            .map((m) => m?.entity?.reference?.split('/')[1])
            .filter(isDefined),
        );
    },
    async updateList(owner: string, type: PatientListsType, patientIds: string[]): Promise<void> {
      const members = patientIds.map((patientId) => {
        return {
          entity: {
            reference: `Patient/${patientId}`,
          },
        };
      });
      const group: Group = {
        resourceType: 'Group',
        type: 'person',
        actual: true,
        active: true,
        managingEntity: {
          reference: `Practitioner/${owner}`,
        },
        meta: {
          tag: [
            {
              system: System.PatientList,
              code: PatientListTagMapping[type],
            },
          ],
        },
        member: members,
      };
      const bundle: Bundle = {
        resourceType: 'Bundle',
        type: 'transaction',
        entry: [
          {
            request: {
              method: 'PUT',
              url: `Group?managing-entity=Practitioner/${owner}&_tag=${PatientListTagMapping[type]}`,
            },
            resource: group,
          },
        ],
      };
      const batchResult = await medplum.executeBatch(bundle, { cache: 'no-cache' });
      for (const entry of batchResult.entry || []) {
        if (entry.response?.status !== '200' && entry.response?.status !== '201') {
          throw new Error('Failed to update patient list');
        }
      }
    },
  };
};
