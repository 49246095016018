import { Button, Group, Modal, Stack, Text, Textarea } from '@mantine/core';
import React, { useState } from 'react';
import { Patient, RelatedPerson } from '@medplum/fhirtypes';
import { useReactivateCaregiver } from '@/hooks/useReactivateCaregiver';
import { notifications } from '@mantine/notifications';
import { getErrorMessage, logError } from '@/errors';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery } from 'medplum-gql';

interface ExtendedRelatedPerson extends RelatedPerson {
  PatientList?: Patient[];
}

interface credentialsProps {
  selectedPerson: ExtendedRelatedPerson;
  setSelectedPerson: (value: ExtendedRelatedPerson | undefined) => void;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

const CaregiverReactivationModal = ({ selectedPerson, setSelectedPerson, refetch }: credentialsProps): JSX.Element => {
  const [activationReason, setActivationReason] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const caregiver = selectedPerson?.PatientList?.[0];

  const caregiverId = caregiver?.id || '';

  const reactivate = useReactivateCaregiver(caregiverId, activationReason);

  const handleSubmit = async (): Promise<void> => {
    if (activationReason.length <= 0) {
      notifications.show({ message: 'Please provide a reason for reactivation', color: 'status-error' });
      return;
    }
    setLoading(true);

    try {
      await reactivate();
      await refetch();
      setSelectedPerson(undefined);
    } catch (error: unknown) {
      notifications.show({
        title: 'Error',
        message: getErrorMessage(error),
        color: 'status-error',
      });
      logError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <Text size="sm" fw={700} c="imagine-green">
          Reactivate Caregivers Account
        </Text>
      }
      opened={!!selectedPerson}
      onClose={() => {
        setSelectedPerson(undefined);
      }}
      size="lg"
      radius="lg"
      centered
    >
      <Stack gap="sm">
        <Text>
          Reactivate a caregivers account that they have deactivated from the mobile application. Provide a written
          reason for reactivation to document this request.
        </Text>
        <Textarea
          required
          value={activationReason}
          placeholder="Provide the reason for activation"
          error={activationReason.length <= 0}
          onChange={(event) => setActivationReason(event.currentTarget.value)}
        />
        <Group justify="flex-end">
          <Button
            variant="outline"
            color="imagine-green"
            radius="md"
            onClick={() => {
              setSelectedPerson(undefined);
            }}
            style={{ marginRight: '10px' }}
          >
            Go Back
          </Button>
          <Button
            onClick={handleSubmit}
            type="submit"
            color="imagine-green"
            radius="md"
            disabled={loading}
            loading={loading}
          >
            Reactivate Account
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default CaregiverReactivationModal;
