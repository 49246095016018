import sanitizeHtml from 'sanitize-html';

/**
 *
 * @param str - the string to sanitize
 * @returns the sanitized string without any html tags
 */
export const sanitizeHtmlFromString = (str: string): string => {
  return sanitizeHtml(str, { allowedTags: [] });
};
