import React from 'react';
import { getConfig } from '@/config';
import { Button, Tooltip } from '@mantine/core';
import { IconBombFilled } from '@tabler/icons-react';
import { useMedplum } from '@medplum/react';
import { useResetPatient } from '@/hooks/useResetPatient';

const config = getConfig();

interface ResetPatientButtonProps {
  patientId: string;
}

export function ResetPatientButton(props: ResetPatientButtonProps) {
  const reset = useResetPatient(props.patientId);

  const medplum = useMedplum();

  if (!medplum.isProjectAdmin()) {
    return null;
  }

  if (config.appEnv === 'prod') {
    return null;
  }
  const onReset = async () => {
    await reset();
    window.location.reload();
  };
  return (
    <Tooltip label="Reset patient to not enrolled and delete messages, etc">
      <Button variant="subtle" leftSection={<IconBombFilled />} onClick={onReset}>
        Reset Patient
      </Button>
    </Tooltip>
  );
}
