import { Group, ThemeIcon } from '@mantine/core';
import { IconExclamationCircleFilled, IconFlagFilled } from '@tabler/icons-react';
import { Maybe } from 'medplum-gql';
import { capitalize } from 'lodash';
import React from 'react';

interface CategoryColumnProps {
  priority?: Maybe<string>;
  isSuicideAlertTask?: boolean;
}

export function CategoryColumn({ priority, isSuicideAlertTask }: CategoryColumnProps): JSX.Element {
  if (isSuicideAlertTask) {
    return (
      <Group gap={8}>
        <ThemeIcon c="status-error">
          <IconFlagFilled />{' '}
        </ThemeIcon>
        <span style={{ fontWeight: 'bold' }}>Suicide Risk</span>
      </Group>
    );
  }
  if (priority !== 'urgent') {
    return <div>Not urgent</div>;
  }

  return (
    <Group gap={8}>
      <ThemeIcon c="status-error">
        <IconExclamationCircleFilled />{' '}
      </ThemeIcon>
      <span style={{ fontWeight: 'bold' }}>{capitalize(priority)}</span>
    </Group>
  );
}
