import { useEffect, useState } from 'react';
import {
  LocalAudioTrack,
  LocalDataTrack,
  LocalTrackPublication,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteDataTrack,
  RemoteTrackPublication,
  RemoteVideoTrack,
} from 'twilio-video';

type MaybeTrack =
  | LocalAudioTrack
  | LocalVideoTrack
  | LocalDataTrack
  | RemoteAudioTrack
  | RemoteVideoTrack
  | RemoteDataTrack
  | undefined;

export default function useTrack(publication?: LocalTrackPublication | RemoteTrackPublication): MaybeTrack {
  const [track, setTrack] = useState(publication?.track);

  useEffect(() => {
    // Reset the track when the 'publication' variable changes.
    setTrack(publication?.track);

    if (publication) {
      const removeTrack = (): void => setTrack(null);

      publication.on('subscribed', setTrack);
      publication.on('unsubscribed', removeTrack);

      return () => {
        publication.off('subscribed', setTrack);
        publication.off('unsubscribed', removeTrack);
      };
    }

    return () => {};
  }, [publication]);

  return track || undefined;
}
