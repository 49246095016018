export enum ConsentType {
  CaregiverConsent = 'caregiver-consent',
  PatientConsent = 'patient-consent',
  ConsentToTreatAndTelehealth = 'Consent to Treat and Telehealth',
  ReleaseOfInformation = 'Release of information consent',
  Eula = 'End User License Agreement',
}

export enum ConsentIdentifier {
  ConsentToTreat = 'https://imaginepediatrics.org/consentToTreat',
  EULA = 'https://imaginepediatrics.org/eula',
  ReleaseOfInformation = 'https://imaginepediatrics.org/releaseOfInformation',
}
