import { Drawer, MantineSize } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';

interface ExpandableDrawerProps {
  opened: boolean;
  close: () => void;
  expanded?: boolean;
  size?: MantineSize | 'xxl';
  id?: string;
}

export const ExpandableDrawer = ({
  opened,
  close,
  children,
  expanded,
  size,
  id,
}: React.PropsWithChildren<ExpandableDrawerProps>): JSX.Element => {
  const mediaMatch = useMediaQuery('(max-width: 1408px)');
  const [expandedState, setExpandedState] = useState(false);
  const [calculatedSize, setCalculatedSize] = useState<MantineSize | 'xxl' | number | '100%' | undefined>('xxl');

  useEffect(() => {
    if (mediaMatch || expanded) {
      setExpandedState(true);
    } else {
      setExpandedState(false);
    }

    if (expandedState) {
      setCalculatedSize('100%');
    } else if ('xxl' === size) {
      setCalculatedSize(1080);
    } else {
      setCalculatedSize(size);
    }
  }, [mediaMatch, expanded, expandedState, size]);

  return (
    <Drawer.Root
      id={id}
      position="right"
      size={calculatedSize}
      transitionProps={{ transition: 'slide-left', duration: 400, timingFunction: 'ease' }}
      opened={opened}
      onClose={close}
    >
      <Drawer.Overlay />
      {children}
    </Drawer.Root>
  );
};
