import React, { useState } from 'react';
import { Notification } from 'imagine-dsl/models/communications/notification';
import { Text, Box, Group, Badge, ActionIcon, Menu } from '@mantine/core';
import { formatHumanName } from '@medplum/core';
import { HumanName } from '@medplum/fhirtypes';
import { formatDate } from 'date-fns';

import classes from './Row.module.css';
import { useNotificationContext } from './NotificationsProvider';
import { markAsRead, toggleReadStatus } from 'imagine-dsl/services/notificationCommunicationService';
import { notifications } from '@mantine/notifications';
import { logError } from '@/errors';
import { useMedplum } from '@medplum/react';
import { IconDotsVertical } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

interface RowProps {
  notification: Notification;
}

export const Row = ({ notification }: RowProps): JSX.Element => {
  const navigate = useNavigate();
  const medplum = useMedplum();
  const { refetchNotifications, refetchUnreadCount } = useNotificationContext();
  const patientLink = notification.subjectReference || '/notifications';
  const [opened, setOpened] = useState(false);

  const onMarkAsRead = (): void => {
    toggleReadStatus(medplum, notification)
      .then(async () => {
        await refetchNotifications();
        await refetchUnreadCount();
      })
      .catch((e) => {
        notifications.show({ title: 'Error', message: 'Failed to update notification', color: 'status-error' });
        logError(e);
      });
  };

  const onDelete = (): void => {
    medplum
      .deleteResource('Communication', notification.id!)
      .then(async () => {
        await refetchNotifications();
        await refetchUnreadCount();
      })
      .catch((e) => {
        notifications.show({ title: 'Error', message: 'Failed to delete notification', color: 'status-error' });
        logError(e);
      });
  };

  const onRowClick = () => {
    navigate(`/${patientLink}`);
    if (notification.received) {
      return;
    }

    markAsRead(medplum, notification.id!)
      .then(async () => {
        await refetchNotifications();
        await refetchUnreadCount();
      })
      .catch((e) => {
        notifications.show({ title: 'Error', message: 'Failed to update notification', color: 'status-error' });
        logError(e);
      });
  };

  const senderName = notification.sender?.name?.at(0);
  return (
    <Box onClick={onRowClick} className={classes.row} p="lg">
      <Group justify="space-between">
        <Text dangerouslySetInnerHTML={{ __html: notification.title || '' }} />
        <Group>
          <Menu opened={opened} onChange={setOpened}>
            <Menu.Target>
              <ActionIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setOpened(!opened);
                }}
                variant="outline"
                radius="xl"
              >
                <IconDotsVertical />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={(e) => {
                  e.stopPropagation();
                  onMarkAsRead();
                }}
              >
                Mark as {notification.received ? 'unread' : 'read'}
              </Menu.Item>
              <Menu.Item
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
                c="status-error"
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          {!notification.received && <Badge h={8} w={8} circle />}
        </Group>
      </Group>
      {notification.contentString && (
        <Group justify="space-between" wrap="nowrap">
          <Box w="100%" bd="1px solid brand-gray.4" my={8} style={{ borderRadius: '8px' }} p={8}>
            {senderName && <Text fw="bold">{formatHumanName(senderName as HumanName)}</Text>}
            <Text>{notification.contentString}</Text>
          </Box>
        </Group>
      )}
      {notification.sentTime && (
        <Text c="brand-gray.7">{formatDate(notification.sentTime, 'EEEE M/d/yyyy h:mm a')}</Text>
      )}
    </Box>
  );
};
