import { PatientMultiSearchForm } from '@components/patientSearch/MultiSearchForm';
import { Loader, Stack, Box } from '@mantine/core';
import { map } from 'lodash';
import React from 'react';
import { GetPatientQuery, Maybe, Patient, SearchPatientQueryVariables, useSearchPatientQuery } from 'medplum-gql';

import { LinkPatientRows } from './LinkPatientRows';
import { LinkPatientProvider } from './LinkPatientContext';
import { ApolloQueryResult } from '@apollo/client';

interface SearchPatientsModalBodyProps {
  searchParams?: Maybe<SearchPatientQueryVariables>;
  onSearch: (query: SearchPatientQueryVariables) => void;
  patient: Patient;
  refetchPatient: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

export function SearchPatientsModalBody({
  searchParams,
  onSearch,
  patient,
  refetchPatient,
}: SearchPatientsModalBodyProps): JSX.Element {
  const { data, loading } = useSearchPatientQuery({
    skip: !searchParams,
    variables: {
      ...(searchParams || {}),
      tag: 'patient',
    },
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Box px="md" mt="md">
        <PatientMultiSearchForm search={onSearch} searchParams={searchParams} />
      </Box>
      <Stack p="md" gap={0}>
        <LinkPatientProvider refetchPatient={refetchPatient}>
          <LinkPatientRows primaryPatient={patient} linkPatients={map(data?.PatientConnection?.edges, 'resource')} />
        </LinkPatientProvider>
      </Stack>
    </>
  );
}
