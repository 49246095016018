import React from 'react';
import { Box, Group, Title, Tooltip, Button, Text, Stack } from '@mantine/core';
import { IconChecklist, IconPlus } from '@tabler/icons-react';
import { uniqBy } from 'lodash';
import UpsertTaskModal from '@/components/shared/UpsertTaskModal';
import { useDisclosure } from '@mantine/hooks';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { Exact, GetSubTasksQuery, Scalars } from 'medplum-gql';
import { CarePathwayReferralTask } from 'imagine-dsl/models/tasks/carePathwayReferralTask';
import { RenderSubTask } from '@/hooks/useSubTask';
import { QueryResult } from '@apollo/client';

interface SubTaskSectionProps {
  task: CarePathwayReferralTask | BaseTask;
  patientId: string;
  taskTypes: { label: string; value: string }[];
  subTasksQuery: QueryResult<
    GetSubTasksQuery,
    Exact<{
      partOf: Scalars['String']['input'];
    }>
  >;
  subTasks: BaseTask[];
}

const SubTaskSection = ({ task, patientId, taskTypes, subTasksQuery, subTasks }: SubTaskSectionProps) => {
  const [upsertTaskModalOpened, upsertTaskModalHandler] = useDisclosure();

  const isTaskActionable = (task?.status !== 'completed' && task?.status !== 'cancelled') || !task?.isResolved;
  const hasIncompleteSubTask = !subTasks.every((t) => t.status === 'completed' || t.status === 'cancelled');
  const createSubTaskDisabled = subTasksQuery.loading || hasIncompleteSubTask;

  return (
    <Box mt="xl">
      <Group>
        <Group>
          <IconChecklist />
          <Title order={2} size={18}>
            Sub-tasks
          </Title>
        </Group>
        {isTaskActionable && (
          <Tooltip disabled={!hasIncompleteSubTask} label="Incomplete sub-task exists">
            <Button
              variant="outline"
              leftSection={<IconPlus size={14} />}
              size="xs"
              onClick={upsertTaskModalHandler.open}
              disabled={createSubTaskDisabled}
            >
              Create sub-task
            </Button>
          </Tooltip>
        )}
      </Group>
      {subTasks.length === 0 ? (
        <Text c="dimmed" py="md">
          No sub-tasks created for this alert
        </Text>
      ) : (
        <Stack my="md">
          {subTasks.map((subTask) => (
            <SubTaskWrapper key={subTask.id} subTask={subTask} patientId={patientId} />
          ))}
        </Stack>
      )}
      {upsertTaskModalOpened && (
        <UpsertTaskModal
          operation="Create"
          patientId={patientId}
          opened={upsertTaskModalOpened}
          closeModal={async () => {
            await subTasksQuery.refetch();
            upsertTaskModalHandler.close();
          }}
          taskTypes={taskTypes}
          taskOverridesFn={(_t) => {
            return {
              ..._t,
              partOf: uniqBy([...(_t.partOf ?? []), { reference: `Task/${task.id}` }], 'reference'),
            };
          }}
        />
      )}
    </Box>
  );
};

const SubTaskWrapper = ({ subTask, patientId }: { subTask: BaseTask; patientId: string }) => {
  return RenderSubTask(subTask, patientId);
};

export default SubTaskSection;
