import { MedplumClient } from '@medplum/core';
import { System } from 'const-utils';

const genderIdentityDataRetention = async (
  genderIdentityValue: string,
  preferredPronounValue: string,
  patientId: string,
  medplum: MedplumClient,
): Promise<void> => {
  const refreshedPatient = await medplum.readResource('Patient', patientId);
  const filteredExtensions =
    refreshedPatient?.extension?.filter((ext) => {
      return ext.url !== System.GenderIdentity.toString() && ext.url !== System.PreferredPronoun.toString();
    }) ?? [];
  const updatedExtensions = [
    ...filteredExtensions,
    {
      url: System.GenderIdentity,
      valueCode: genderIdentityValue,
    },
  ];

  if (preferredPronounValue) {
    updatedExtensions.push({
      url: System.PreferredPronoun,
      valueCode: preferredPronounValue,
    });
  }
  const updatedPatient = { ...refreshedPatient, extension: updatedExtensions };
  await medplum.updateResource(updatedPatient);
};

export default genderIdentityDataRetention;
