import React from 'react';
import { Attachment } from '@medplum/fhirtypes';
import { Button, Modal, Stack, Group, Text, TextInput, Textarea, Radio } from '@mantine/core';
import { AttachmentDisplay } from '@medplum/react';
import { documentTypeMap } from 'const-utils';

interface AttachmentModalProps {
  pendingAttachment?: Attachment;
  pendingAttachmentType?: string;
  setPendingAttachment: (value: Attachment | undefined) => void;
  setAttachmentDescription: (value: string) => void;
  setAttachmentType: (value: string) => void;
  onAttachDocument: (value: Attachment | undefined) => void;
}

export function AttachmentModal({
  pendingAttachment,
  pendingAttachmentType,
  setPendingAttachment,
  setAttachmentDescription,
  setAttachmentType,
  onAttachDocument,
}: AttachmentModalProps): JSX.Element {
  return (
    <Modal.Root
      size="xl"
      padding="xl"
      radius="xl"
      opened={!!pendingAttachment}
      onClose={() => {
        setPendingAttachment(undefined);
      }}
    >
      <Modal.Overlay />
      <Modal.Content style={{ borderRadius: 20 }}>
        <Modal.Header>
          <Modal.Title c="imagine-green" style={{ fontSize: '20px' }}>
            Attach Document
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Stack>
            <Group>
              <AttachmentDisplay value={pendingAttachment as Attachment} maxWidth={200} />

              <Stack flex="1" h={300}>
                <Text>Name</Text>
                <TextInput
                  mt={-15}
                  width="auto"
                  defaultValue={pendingAttachment?.title}
                  onChange={(event) => {
                    setPendingAttachment({
                      ...pendingAttachment,
                      title: event.currentTarget.value,
                    });
                  }}
                />

                <Text>Description</Text>
                <Textarea
                  mt={-15}
                  onChange={(event) => {
                    setAttachmentDescription(event.currentTarget.value);
                  }}
                />
                <Radio.Group
                  value={pendingAttachmentType}
                  onChange={setAttachmentType}
                  name="attachment-type"
                  label="Attachment Type"
                >
                  <Stack gap={10} mt={10}>
                    {documentTypeMap.map((docType) => (
                      <Radio key={docType.code} value={docType.code} label={docType.display} />
                    ))}
                  </Stack>
                </Radio.Group>
              </Stack>
            </Group>
            <Group justify="flex-end">
              <Button
                variant="outline"
                onClick={() => {
                  setPendingAttachment(undefined);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onAttachDocument(pendingAttachment);
                  setPendingAttachment(undefined);
                }}
              >
                Attach document
              </Button>
            </Group>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}
