import React, { useMemo } from 'react';
import { ComboboxItem, Group, Select } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { HumanName } from '@medplum/fhirtypes';
import { formatHumanName } from '@medplum/core';
import { TaskType } from 'const-utils/codeSystems/ImaginePediatrics';
import { uniqBy, sortBy } from 'lodash';
import { useOutreachTaskAlertContext } from '@/pages/OutreachTask/OutreachTaskAlertProvider';
import { isDefined } from 'imagine-dsl/utils/lists';
import { SearchFilters } from '@/pages/OutreachTask/Page';
import { engagementManagerRoles, useHasRole } from '@/hooks/useHasRole';
import { useMedplum } from '@medplum/react';

interface TasksSearchProps {
  setSearchFilters: React.Dispatch<React.SetStateAction<SearchFilters>>;
  searchFilters: SearchFilters;
}

const taskTypeOptionsMap: Partial<Record<TaskType, string>> = {
  [TaskType.FirstCall]: 'First Call',
  [TaskType.Callback]: 'Callback',
  [TaskType.InformationRequest]: 'Information Request',
  [TaskType.InformationRequestVerification]: 'Information Request Verification',
  [TaskType.CallPartneringProvider]: 'Call Partnering Provider or Agency',
  [TaskType.NetworkEngagement]: 'Network Engagement',
  [TaskType.OutreachFollowUp]: 'Outreach Follow Up',
  [TaskType.ScheduleAppointmentBHTOC]: 'Schedule Appointment BH TOC',
  [TaskType.AppointmentRequest]: 'Appointment Request',
};
const taskTypeOptions: ComboboxItem[] = Object.entries(taskTypeOptionsMap).map(([value, label]) => ({
  value,
  label,
}));

export const TasksSearch = ({ searchFilters, setSearchFilters }: TasksSearchProps): JSX.Element => {
  const medplum = useMedplum();
  const { tasks: tasksData } = useOutreachTaskAlertContext();
  const hasElevatedPrivilege = useHasRole(engagementManagerRoles) || medplum.isProjectAdmin();

  const patientOptions = useMemo(() => {
    const options = tasksData
      ?.map((t) => t?.for)
      .filter(isDefined)
      .map((patient) => ({ value: patient.id!, label: formatHumanName(patient.name?.[0] as HumanName) }));
    return uniqBy(options, 'value');
  }, [tasksData]);

  const engagementPodOptions = useMemo(() => {
    const options = tasksData
      ?.map((t) => t?.pod)
      .filter(isDefined)
      .map((pod) => ({ value: pod.id!, label: pod.name }));
    return uniqBy(options, 'value');
  }, [tasksData]);

  const assignedToOptions = useMemo(() => {
    const options = tasksData
      ?.map((t) => t?.owner)
      .filter(isDefined)
      .map((owner) => ({ value: owner.id!, label: formatHumanName(owner.name?.[0] as HumanName) }));
    const uniqueOptions = uniqBy(options, 'value');

    const hasUnassignedTasks = tasksData?.some((task) => !task?.owner);
    if (hasUnassignedTasks) {
      uniqueOptions.unshift({ value: 'unassigned', label: 'Unassigned' });
    }

    return uniqueOptions;
  }, [tasksData]);

  const relevantTaskTypeOptions = useMemo(() => {
    return sortBy(
      taskTypeOptions.filter((opt) => tasksData?.filter(isDefined)?.some((task) => task.taskType.code === opt.value)),
      'value',
    );
  }, [tasksData]);

  const handleSelectChange = (field: keyof SearchFilters) => (value: string | null) => {
    setSearchFilters((prev) => ({ ...prev, [field]: value || '' }));
  };

  return (
    <Group gap="xs" w="70%">
      <Select
        placeholder="Search by patient name"
        data={patientOptions}
        leftSection={<IconSearch size={16} />}
        flex="3"
        onChange={handleSelectChange('patientId')}
        value={searchFilters.patientId}
        clearable
      />
      <Select
        placeholder="Task"
        data={relevantTaskTypeOptions}
        flex="2"
        onChange={handleSelectChange('taskType')}
        value={searchFilters.taskType}
        clearable
      />
      <Select
        placeholder="Assigned to"
        data={assignedToOptions}
        flex="2"
        onChange={handleSelectChange('assignedTo')}
        value={searchFilters.assignedTo}
        clearable
      />
      <Select
        required
        placeholder="Engagement pod"
        data={engagementPodOptions}
        disabled={!hasElevatedPrivilege}
        value={searchFilters.engagementPod}
        onChange={handleSelectChange('engagementPod')}
      />
    </Group>
  );
};
