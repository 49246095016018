import { imagineClient } from '@/hooks/useImagineApolloClient';
import { logError } from '@/errors';
import { notifications } from '@mantine/notifications';
import { useResetPatientMutation } from 'imagine-gql/client';
import { useCallback } from 'react';

type ResetPatient = () => Promise<void>;

export const useResetPatient = (patientId: string): ResetPatient => {
  const [resetPatientMutation] = useResetPatientMutation({
    client: imagineClient,
    onCompleted: () => {
      notifications.show({ message: 'Patient has been reset', color: 'status-success' });
    },
    onError: (error) => {
      notifications.show({ message: 'Error resetting patient', color: 'status-error' });
      logError(error);
    },
  });

  const reset = useCallback(async () => {
    await resetPatientMutation({
      variables: {
        request: {
          patientId,
          resetMessages: true,
        },
      },
    });
  }, [resetPatientMutation, patientId]);

  return reset;
};
