import { AlertContent } from '@/components/shared/AlertContent';
import { IconMessages } from '@tabler/icons-react';
import React from 'react';

interface TaskAlertNotificationProps {
  id: string;
  message: string;
  path: string;
}

export function CommandCenterAlertNotification({ id, message, path }: TaskAlertNotificationProps): JSX.Element {
  const icon = <IconMessages width="20px" height="20px" />;

  return <AlertContent icon={icon} message={message} id={id} path={path} linkText="View Alert" />;
}
