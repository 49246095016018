import { useGetPatientActivityQuery } from 'medplum-gql';
import {
  ActivityType,
  TaskActivity,
  VideoCall,
  mapFromGraphqlAlerts,
  mapFromGraphqlTasks,
  mapFromGraphqlVideoCall,
  ADTEncounterActivity,
  mapFromGraphqlADT,
} from './types';
import { OutreachAttempt, mapOutreachQueryResult } from 'imagine-dsl/utils/outreach';

type ActivityData = { date: Date | undefined } & (
  | {
      type: ActivityType.Touchpoint;
      data: OutreachAttempt;
    }
  | {
      type: ActivityType.Video;
      data: VideoCall;
    }
  | {
      type: ActivityType.Alert;
      data: TaskActivity;
    }
  | {
      type: ActivityType.ADT;
      data: ADTEncounterActivity;
    }
  | {
      type: ActivityType.Task;
      data: TaskActivity;
    }
);

type UseActivityDataResult = {
  query: ReturnType<typeof useGetPatientActivityQuery>;
  data: ActivityData[];
};

export const mapFromQuery = (data: ReturnType<typeof useGetPatientActivityQuery>['data']) => {
  if (data === undefined) {
    return [];
  }

  const outreachAttempts: ActivityData[] = mapOutreachQueryResult(data).map((data) => {
    return {
      type: ActivityType.Touchpoint,
      date: data.sentTime,
      data,
    };
  });

  const videoCalls: ActivityData[] = mapFromGraphqlVideoCall(data).map((data) => {
    return {
      type: ActivityType.Video,
      date: data?.callTime,
      data,
    };
  });

  const alerts: ActivityData[] = mapFromGraphqlAlerts(data).map((alert) => {
    return {
      type: ActivityType.Alert,
      date: alert?.date,
      data: alert,
    };
  });

  const tasks: ActivityData[] = mapFromGraphqlTasks(data).map((alert) => {
    return {
      type: ActivityType.Task,
      date: alert?.date,
      data: alert,
    };
  });

  const adtEncounters: ActivityData[] = mapFromGraphqlADT(data).map((encounter) => {
    let date: Date | undefined;
    if (encounter.start) {
      date = new Date(encounter.start);
    } else if (encounter.end) {
      date = new Date(encounter.end);
    } else {
      date = undefined;
    }
    return {
      type: ActivityType.ADT,
      date,
      data: encounter,
    };
  });

  return [...outreachAttempts, ...videoCalls, ...alerts, ...adtEncounters, ...tasks].sort((a, b) => {
    if (a.date === undefined) {
      return 1;
    }
    if (b.date === undefined) {
      return -1;
    }
    return b.date.getTime() - a.date.getTime();
  });
};

export const useActivityData = (patientId: string, filter?: ActivityType): UseActivityDataResult => {
  const query = useGetPatientActivityQuery({
    variables: {
      id: patientId,
    },
  });

  return {
    query: query,
    data: mapFromQuery(query.data).filter(
      (item) => filter === undefined || item.type === filter || filter === ActivityType.All,
    ),
  };
};
