import { getErrorMessage, logError } from '@/errors';
import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import { ActionIcon, Group, Textarea, Text, Box, Button, UnstyledButton, ThemeIcon } from '@mantine/core';
import { useForm } from '@mantine/form';
import { AttachmentModal } from './AttachmentModal';
import { notifications } from '@mantine/notifications';
import { createReference } from '@medplum/core';
import { Attachment, Practitioner, Patient, Task, DocumentReference, Reference } from '@medplum/fhirtypes';
import { useMedplum, AttachmentButton, AttachmentDisplay } from '@medplum/react';
import { IconPaperclip, IconSend } from '@tabler/icons-react';
import { CommunicationMedium, DocumentType, System, TaskType } from 'const-utils';
import {
  GetChatTimelineByPatientIdQuery,
  GetIncompleteChatTasksByPatientIdQuery,
  GetDocumentsForPatientDocument,
  useGetActiveEncounterIdsByPatientIdQuery,
} from 'medplum-gql';
import { assignTask } from 'imagine-dsl/services/taskService';
import React, { useState } from 'react';
import { buildCommunication } from 'imagine-dsl/utils/communication';
import { buildTask } from 'imagine-dsl/utils/task';

interface ChatFormData {
  contentString: string;
  attachment: Attachment | undefined;
}

interface ChatInputProps {
  patient: Patient;
  profile: Practitioner;
  task?: Task;
  refetchMessages: () => Promise<ApolloQueryResult<GetChatTimelineByPatientIdQuery>>;
  refreshTasks: () => Promise<ApolloQueryResult<GetIncompleteChatTasksByPatientIdQuery>>;
}

export function ChatInput({ refreshTasks, task, patient, profile, refetchMessages }: ChatInputProps): JSX.Element {
  const medplum = useMedplum();
  const apolloClient = useApolloClient();
  const [pendingAttachment, setPendingAttachment] = useState<Attachment | undefined>();
  const [pendingAttachmentDescription, setPendingAttachmentDescription] = useState('');
  const [pendingAttachmentType, setPendingAttachmentType] = useState<string>(DocumentType.ChatAttachment);
  const [sendingChat, setSendingChat] = useState(false);
  const initialValues: ChatFormData = {
    contentString: '',
    attachment: undefined,
  };
  const form = useForm({
    initialValues,
  });

  const { data: activeEncounterIds } = useGetActiveEncounterIdsByPatientIdQuery({
    variables: {
      patientId: `Patient/${patient.id!}`,
    },
    skip: !patient,
  });

  const encounterId = task?.focus?.resource?.id || activeEncounterIds?.EncounterList?.at(0)?.id;

  const onSendChat = async (formData: ChatFormData): Promise<void> => {
    let docReference;

    if (form.values.attachment) {
      const patientReference = createReference(patient);
      docReference = await medplum.createResource({
        resourceType: 'DocumentReference',
        content: [
          {
            attachment: form.values.attachment,
          },
        ],
        type: {
          coding: [
            {
              system: System.DocumentType,
              code: pendingAttachmentType,
            },
          ],
        },
        status: 'current',
        subject: patientReference,
        description: pendingAttachmentDescription,
      });

      await medplum.createResource(
        buildTask({
          status: 'ready',
          type: TaskType.Document,
          description: 'Document review by caregiver',
          intent: 'order',
          for: patientReference,
          basedOn: [createReference(docReference)],
        }),
      );
    }

    const contentReference: Reference<DocumentReference> | undefined = docReference
      ? createReference(docReference)
      : undefined;

    const communicationObj = buildCommunication({
      medium: CommunicationMedium.Chat,
      contentString: formData.contentString,
      sender: createReference(profile),
      subject: createReference(patient),
      encounter: encounterId
        ? {
            reference: `Encounter/${encounterId}`,
          }
        : undefined,
      contentReference,
    });

    medplum
      .createResource(communicationObj)
      .then(async () => {
        form.reset();
        await refetchMessages();
        await apolloClient.refetchQueries({ include: [GetDocumentsForPatientDocument] }).catch(logError);
      })
      .catch((error) => {
        notifications.show({
          title: 'Error',
          message: error,
          color: 'status-error',
        });
      });
  };

  const onSubmit = (values: ChatFormData): void => {
    setSendingChat(true);
    onSendChat(values)
      .then(() => {
        setSendingChat(false);
      })
      .catch((err) => {
        setSendingChat(false);
        notifications.show({
          title: 'Error sending message',
          message: getErrorMessage(err),
          color: 'status-error',
        });
      });
  };

  const onUploadChange = (value: Attachment | undefined): void => {
    setPendingAttachment(value);
  };

  const onAttachDocument = (value: Attachment | undefined): void => {
    form.setFieldValue('attachment', value);
  };

  const onAssignToMe = (): void => {
    if (!task) {
      return;
    }

    assignTask(medplum, profile.id!, task.id!, (task.for!.resource as Patient).managingOrganization!.resource!.id!)
      .then(async () => {
        await refreshTasks();
      })
      .catch((err) => {
        notifications.show({
          title: 'Error assigning task',
          message: getErrorMessage(err),
          color: 'status-error',
        });
      });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.key === 'Enter' && event.shiftKey) {
      return;
    }

    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (form.values.attachment || form.values.contentString) {
        onSubmit(form.values);
      }
    }
  };

  const sendDisabled = sendingChat || (!form.values.contentString && !form.values.attachment);
  const taskAssigned = task?.owner?.resource?.id !== undefined;
  const chatAllowed = taskAssigned || task?.status === 'completed' || task === undefined;

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Group>
        {!chatAllowed && (
          <Box h={44} bg="imagine-gray.1" bd="1px solid brand-gray.4" flex="1">
            <Group mt={10} ml={8} gap={6}>
              <UnstyledButton onClick={() => onAssignToMe()} td="underline" fw={700} c="imagine-green">
                Assign to me
              </UnstyledButton>
              <Text>to send a message</Text>
            </Group>
          </Box>
        )}
        {chatAllowed && (
          <Textarea
            name="contentString"
            flex={1}
            placeholder="Type your message. Use Shift + Enter to add a new line."
            autosize
            onKeyDown={handleKeyDown}
            {...form.getInputProps('contentString')}
          />
        )}
        <AttachmentButton onUpload={onUploadChange}>
          {(props) => (
            <ActionIcon {...props} disabled={!chatAllowed}>
              <ThemeIcon c="brand-gray.6">
                <IconPaperclip size={20} />
              </ThemeIcon>
            </ActionIcon>
          )}
        </AttachmentButton>
        <ActionIcon disabled={sendDisabled} type="submit">
          <ThemeIcon c="brand-gray.6">
            <IconSend size={20} />
          </ThemeIcon>
        </ActionIcon>
      </Group>
      {form.values.attachment && (
        <>
          <AttachmentDisplay value={form.values.attachment as Attachment} maxWidth={200} />
          <Button
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              form.setFieldValue('attachment', undefined);
            }}
          >
            Remove
          </Button>
        </>
      )}
      {pendingAttachment && (
        <AttachmentModal
          pendingAttachment={pendingAttachment}
          pendingAttachmentType={pendingAttachmentType}
          setPendingAttachment={setPendingAttachment}
          setAttachmentDescription={setPendingAttachmentDescription}
          setAttachmentType={setPendingAttachmentType}
          onAttachDocument={onAttachDocument}
        />
      )}
    </form>
  );
}
