import { Badge } from '@mantine/core';
import React from 'react';

export function UnassignedBadge(): JSX.Element {
  return (
    <Badge bg="brand-blue.5" variant="filled">
      Unassigned
    </Badge>
  );
}
