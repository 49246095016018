import { Organization, Practitioner } from '@medplum/fhirtypes';
import { useMedplumProfile } from '@medplum/react';
import { useGetPractitionerRoleQuery } from 'medplum-gql';
import React, { createContext, useContext, useMemo } from 'react';
import { UserRole, UserRoleDisplay, isUserRole } from 'const-utils/codeSystems/ImaginePediatrics';
import { compact, uniq, uniqBy } from 'lodash';
import { isBefore } from 'date-fns';
import { isDefined } from 'imagine-dsl/utils/lists';

export type UserRoleCoding = {
  code: UserRole;
  display: string;
};

interface UserSessionContext {
  profile: Practitioner;
  markets: Organization[];
  roles: UserRoleCoding[];
  applicationRoles: string[];
}

const UserSessionContext = createContext<UserSessionContext>({
  profile: { resourceType: 'Practitioner' },
  markets: [],
  roles: [],
  applicationRoles: [],
});

export const useUserSession = (): UserSessionContext => {
  return useContext(UserSessionContext);
};

interface UserSessionProviderProps {
  children?: React.ReactNode;
}

/**
 * UserSessionProvider provides the user session context.
 *
 * Currently, the user session context contains a list of markets (or organization references) via
 * the `useUserSession` hook. This list can be used to scope queries to the user's markets, as a soft security
 * measure.
 *
 * @param props - The component props.
 * @returns The child tree wrapped in either an empty context or a context with the user's markets, queried from the Medplum GraphQL API.
 */
export const UserSessionProvider = (props: UserSessionProviderProps): JSX.Element => {
  const { children } = props;
  const profile = useMedplumProfile();
  const { data } = useGetPractitionerRoleQuery({
    variables: {
      id: profile?.id ?? '',
    },
    skip: !profile?.id,
  });

  const markets = useMemo(
    () =>
      uniqBy(
        compact(
          data?.Practitioner?.PractitionerRoleList?.map(
            (role) => (role?.organization?.resource as Organization) || undefined,
          ),
        ),
        'id',
      ),
    [data],
  );

  const roles = useMemo(
    () =>
      (data?.Practitioner?.PractitionerRoleList || [])
        .map((role) => role?.code?.[0]?.coding?.[0]?.code || undefined)
        .filter(isUserRole)
        .map((role) => {
          return {
            code: role,
            display: UserRoleDisplay[role],
          };
        }),
    [data],
  );

  const applicationRoles = useMemo(() => {
    const allActiveRoles =
      data?.Practitioner?.PractitionerRoleList?.filter((role) => {
        const active = role?.active;
        const started = role?.period?.start && isBefore(new Date(role.period.start), new Date());
        const ended = role?.period?.end && isBefore(new Date(role.period.end), new Date());

        return active && started && !ended;
      }) || [];

    return uniq(
      allActiveRoles
        .flatMap((role) => role?.code)
        .flatMap((code) => code?.coding)
        .map((coding) => coding?.code)
        .filter(isDefined),
    );
  }, [data]);

  const value = useMemo(
    () => ({ profile: profile as Practitioner, markets, roles, applicationRoles }),
    [profile, markets, roles, applicationRoles],
  );

  return <UserSessionContext.Provider value={value}>{children}</UserSessionContext.Provider>;
};
