import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Collapse, Group, List, Loader, Stack, Text, Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconChevronDown, IconChevronRight, IconQuestionMark, IconX } from '@tabler/icons-react';
import { GetDevicePermissionsQuery, useGetDevicePermissionsQuery } from 'imagine-gql/client';
import { imagineClient } from '@/hooks/useImagineApolloClient';

type CaregiverDevicesProps = {
  caregiverId: string;
};

export const CaregiverDevices = ({ caregiverId }: CaregiverDevicesProps) => {
  const [expandedIds, setExpandedIds] = useState<{ [key: string]: boolean }>({});
  const anyExpanded = useMemo(() => Object.values(expandedIds).some((v) => v), [expandedIds]);

  const { data, error } = useGetDevicePermissionsQuery({
    variables: {
      caregiverId,
    },
    client: imagineClient,
    pollInterval: anyExpanded ? 10_000 : undefined,
    skip: !caregiverId,
  });

  useEffect(() => {
    if (error) {
      showNotification({ title: 'Error loading devices', message: error.message, color: 'status-error' });
    }
  }, [error]);

  if (!data) {
    return <Loader size="sm" />;
  }

  return data.devicePermissions.length > 0 ? (
    <List spacing="xs" listStyleType="none">
      {data.devicePermissions
        .filter((d) => d.deviceId)
        .map((device) => (
          <List.Item key={device.deviceId!}>
            <Device
              device={device}
              expanded={expandedIds[device.deviceId!]}
              toggleExpanded={() =>
                setExpandedIds((prev) => ({ ...prev, [device.deviceId!]: !prev[device.deviceId!] }))
              }
            />
          </List.Item>
        ))}
    </List>
  ) : (
    <Text c="dimmed" size="xs">
      No devices found
    </Text>
  );
};

type DeviceProps = {
  device: GetDevicePermissionsQuery['devicePermissions'][number];
  expanded: boolean;
  toggleExpanded: () => void;
};

function Device({ device, expanded, toggleExpanded }: DeviceProps) {
  const permissions = useMemo(() => parsePermissions(device.permission ?? undefined), [device]);

  return (
    <Stack gap={5}>
      <Text
        c="imagine-green"
        onClick={toggleExpanded}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        {expanded ? (
          <IconChevronDown size={14} style={{ marginRight: 5 }} />
        ) : (
          <IconChevronRight size={14} style={{ marginRight: 5 }} />
        )}
        {device.manufacturer} {device.modelNumber}
      </Text>
      <Collapse in={expanded}>
        {permissions ? (
          <Group gap="xs">
            {Object.entries(permissions)
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([pType, pResult]) => {
                const permissionResult = pResult as PermissionResult;
                const label =
                  permissionResultLabelMap[permissionResult] ?? `Unknown permission result (${permissionResult})`;
                const icon = permissionResultIconMap[permissionResult] ?? <IconQuestionMark size="14" color="grey" />;

                return (
                  <Tooltip key={pType + pResult} label={label}>
                    <Badge
                      leftSection={icon}
                      variant="transparent"
                      size="xs"
                      color="rgba(0,0,0,.65)"
                      bg="rgba(0,0,0,.03)"
                    >
                      {pType}
                    </Badge>
                  </Tooltip>
                );
              })}
          </Group>
        ) : (
          <Text c="dimmed" size="xs">
            No permissions found
          </Text>
        )}
      </Collapse>
    </Stack>
  );
}

// https://github.com/zoontek/react-native-permissions?tab=readme-ov-file#permissions-statuses
// https://github.com/zoontek/react-native-permissions/blob/05d4071dbcde2e5e9dbe543bcb318d6f0add2d55/src/results.ts#L1-L9
const PERMISSION_RESULTS = Object.freeze({
  UNAVAILABLE: 'unavailable',
  BLOCKED: 'blocked',
  DENIED: 'denied',
  GRANTED: 'granted',
  LIMITED: 'limited',
} as const);

export type PermissionResult = (typeof PERMISSION_RESULTS)[keyof typeof PERMISSION_RESULTS];

const permissionResultLabelMap: Record<PermissionResult, string> = {
  granted: 'The permission is granted',
  limited: 'The permission is granted but with limitations',
  denied: 'The permission has not been requested / is denied but requestable',
  blocked: 'The permission is denied and not requestable anymore',
  unavailable: 'This feature is not available (on this device / in this context)',
};

const permissionResultIconMap: Record<PermissionResult, React.ReactNode> = {
  granted: <IconCheck size="14" color="green" />,
  limited: <IconCheck size="14" color="green" />,
  denied: <IconX size="14" color="red" />,
  blocked: <IconX size="14" color="red" />,
  unavailable: <IconX size="14" color="red" />,
};

const parsePermissions = (permissionsJsonString?: string): Record<string, string> | undefined => {
  if (!permissionsJsonString) {
    return undefined;
  }

  try {
    return JSON.parse(permissionsJsonString) as Record<string, string>;
  } catch (_) {
    return undefined;
  }
};
