import { Box, Button, Collapse, Group, Stack, Text, ThemeIcon } from '@mantine/core';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import { DocumentReference, GetDocumentsForPatientQuery, useGetDocumentsForPatientQuery } from 'medplum-gql';
import React, { useEffect, useState } from 'react';
import { EmptyResource } from './EmptyResource';
import { Loading } from '@medplum/react';
import { Attachment } from '@medplum/fhirtypes';
import { Link } from 'react-router-dom';
import { DocumentTypeBadge } from './DocumentTypeBadge';
import { formatWithYesterdayAndToday } from 'imagine-dsl/utils/dates';

interface OtherDocsProps {
  patientId: string;
}

export const OtherDocs = ({ patientId }: OtherDocsProps): JSX.Element => {
  const [resourcesOpened, setResourcesOpened] = useState(false);
  const { data, loading } = useGetDocumentsForPatientQuery({
    variables: { patientId, type: 'sick-plan,disaster-plan' },
    skip: !patientId,
    pollInterval: 60_000,
  });

  const [docs, setDocs] = useState<GetDocumentsForPatientQuery['DocumentReferenceList']>([]);

  useEffect(() => {
    setDocs((data?.DocumentReferenceList as DocumentReference[]) || []);
  }, [data]);

  return (
    <>
      <Group align="center" justify="space-between">
        <Group onClick={() => setResourcesOpened(!resourcesOpened)}>
          {resourcesOpened ? (
            <ThemeIcon color="imagine-green" variant="transparent" mr={5}>
              <IconChevronDown size={15} />
            </ThemeIcon>
          ) : (
            <ThemeIcon color="imagine-green" variant="transparent" mr={5}>
              <IconChevronRight size={15} />
            </ThemeIcon>
          )}
          <Text style={{ cursor: 'pointer' }} c="imagine-green">
            Other Documents
          </Text>
          {loading && <Loading />}
        </Group>
        <Collapse in={resourcesOpened}>
          {!docs?.length ? (
            <EmptyResource />
          ) : (
            <Box miw={250}>
              {docs.map((doc) => {
                if (!doc) {
                  return null;
                }
                const { title, url } = doc.content![0].attachment as Attachment;
                return (
                  <Box key={doc.id} mb={'sm'}>
                    <Stack gap={'xs'}>
                      <DocumentTypeBadge docType={doc.type?.coding?.[0].code || 'Unknown'} />
                      <Group>
                        <Link to={url ?? ''} target="_blank">
                          <Button variant="transparent" p={0} h="lg" maw={275}>
                            <Text c={'brand-secondary.8'} truncate="end">
                              {title}
                            </Text>
                          </Button>
                        </Link>
                      </Group>
                      <Group>
                        <Text c="dimmed" size="xs">
                          Uploaded By:
                        </Text>
                        <Text c="dimmed" size="xs">
                          {doc.meta?.author?.display}
                        </Text>
                      </Group>
                      <Group>
                        <Text c="dimmed" size="xs">
                          Date:
                        </Text>
                        <Text c="dimmed" size="xs">
                          {doc?.meta?.lastUpdated
                            ? formatWithYesterdayAndToday(doc?.meta?.lastUpdated, 'M/dd/yyyy', false)
                            : ''}
                        </Text>
                      </Group>
                    </Stack>
                  </Box>
                );
              })}
            </Box>
          )}
        </Collapse>
      </Group>
    </>
  );
};
