import { ValueSet } from '@medplum/fhirtypes';
import { SYSTEM_URL, Concepts, codeSystemConcepts } from '../codeSystems/ImaginePediatrics';
import { System } from '../codeSystems/system';

const code = codeSystemConcepts[Concepts.ContactType].concept;

export const ContactType: ValueSet = {
  resourceType: 'ValueSet',
  url: System.ContactType,
  identifier: [
    {
      system: 'https://imaginepediatrics.org/fhir/ValueSet',
      value: 'contact-type',
    },
  ],
  version: '0.0.1',
  name: 'ContactType',
  title: 'Contact Type',
  status: 'active',
  experimental: false,
  compose: {
    include: [
      {
        system: SYSTEM_URL,
        concept: code!.map((c) => ({ code: c.code, display: c.display })),
      },
    ],
  },
};
