import React from 'react';
import { Text, Paper, Stack, Divider } from '@mantine/core';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery, RelatedPerson, Patient } from 'medplum-gql';
import { Surveys } from './Surveys';

import { Consents } from './Consents';
import { OtherDocs } from './OtherDocs';

interface PatientDocumentsProps {
  linkedPatients: RelatedPerson[];
  patient: Patient;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

export const PatientDocuments = ({ patient, linkedPatients, refetch }: PatientDocumentsProps): JSX.Element => {
  return (
    <Paper shadow="xs" radius="lg" style={{ padding: '20px', marginBottom: '20px' }}>
      <Text size="xl" c="imagine-green">
        Documents
      </Text>
      <Stack style={{ marginTop: '10px' }}>
        <Consents patient={patient} linkedPatients={linkedPatients} refetch={refetch} />
        <Divider />
        <Surveys patient={patient} linkedPatients={linkedPatients} />
        <Divider />
        <OtherDocs patientId={patient.id!} />
      </Stack>
    </Paper>
  );
};
