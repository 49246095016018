import { MedplumClient, isEmpty } from '@medplum/core';
import { Bundle, Consent } from '@medplum/fhirtypes';
import { Maybe, Patient } from 'medplum-gql';
import { compact, map } from 'lodash';

interface ConsentsResponse {
  isSigned: boolean;
  signedAt: Maybe<Date>;
}

export const checkConsents = async (medplum: MedplumClient, patient: Patient): Promise<ConsentsResponse> => {
  const consentBundleForPatient: Bundle<Consent> = await medplum.search(
    'Consent',
    {
      patient: `Patient/${patient.id}`,
    },
    { cache: 'no-cache' },
  );

  if (isEmpty(consentBundleForPatient.entry)) {
    return { isSigned: false, signedAt: null };
  }

  const consentsForPatient: Consent[] = compact(map(consentBundleForPatient.entry, 'resource'));
  const pendingConsents = consentsForPatient.filter((consent) => consent.status === 'draft');
  if (!isEmpty(pendingConsents)) {
    return { isSigned: false, signedAt: null };
  }

  const signedConsents = consentsForPatient.filter((consent) => consent.status === 'active');
  if (!isEmpty(signedConsents)) {
    const consentDateTime = signedConsents[0].dateTime;
    const signedAt = consentDateTime ? new Date(consentDateTime) : new Date();

    return { isSigned: true, signedAt };
  }

  return { isSigned: false, signedAt: null };
};

export const validateConsents = async (
  consentsCheckedAt: Date | null,
  consentsSignedAt: Date | null,
): Promise<void> => {
  if (!consentsCheckedAt) {
    throw new Error('Checking consents is required');
  }

  if (!consentsSignedAt) {
    throw new Error('Caregiver has not signed consents');
  }
};
