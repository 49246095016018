import React, { useMemo, useState } from 'react';
import { UnassignedBadge } from '@/components/shared/UnassignedBadge';
import { ActionIcon, Group, Menu, Text } from '@mantine/core';
import { Practitioner } from '@medplum/fhirtypes';
import { IconPencil } from '@tabler/icons-react';
import { Maybe, SearchPractitionersQueryVariables } from 'medplum-gql';
import { useMedplum } from '@medplum/react';

import classes from '../TaskDrawer.module.css';
import { notifications } from '@mantine/notifications';
import { useDisclosure } from '@mantine/hooks';
import { AssignToTeamMemberModal } from '../../shared/AssignToTeamMemberModal';
import { MedplumClient } from '@medplum/core';
import { assignTask } from 'imagine-dsl/services/taskService';
import { getErrorMessage, logError } from '@/errors';
import { useTaskCountContext } from '@/pages/Task/TaskCountProvider';

interface AssigneeSectionProps {
  assigneeId?: Maybe<string>;
  assigneeName?: Maybe<string>;
  profile: Practitioner;
  taskId: string;
  taskMarket: string;
  disabled: boolean;
  refreshTasks: () => Promise<unknown>;
}

const AssignedToLabel = (): JSX.Element => {
  return (
    <Text size="xs" c="brand-gray.7">
      Assigned to:
    </Text>
  );
};

const UnassignedSection = (props: EditAssigneeIconProps): JSX.Element => {
  return (
    <Group>
      <UnassignedBadge />
      <EditAssigneeIcon {...props} />
    </Group>
  );
};

interface EditAssigneeIconProps {
  taskId: string;
  profileId: string;
  assigned: boolean;
  taskMarket: string;
  disabled: boolean;
  refreshTasks: () => Promise<unknown>;
}

const onAssignTask = async (
  medplum: MedplumClient,
  practitionerId: string,
  taskId: string,
  taskMarket: string,
  refreshTasks: () => Promise<unknown>,
): Promise<void> => {
  return assignTask(medplum, practitionerId, taskId, taskMarket).then(async () => {
    await refreshTasks();
  });
};

const onAssignTaskError = (err: unknown): void => {
  logError(err);
  notifications.show({
    title: 'Error assigning task',
    message: getErrorMessage(err),
    color: 'status-error',
  });
};

const EditAssigneeIcon = ({
  refreshTasks,
  taskId,
  profileId,
  assigned,
  taskMarket,
  disabled,
}: EditAssigneeIconProps): JSX.Element => {
  const medplum = useMedplum();
  const [searchParams, setSearchParams] = useState<Maybe<SearchPractitionersQueryVariables>>();
  const [opened, { close, open }] = useDisclosure();
  const [loading, setLoading] = useState(false);
  const taskCounts = useTaskCountContext();

  const refetch = async () => {
    await Promise.all([refreshTasks(), taskCounts.refetch()]);
  };

  const onAssignToMe = (): void => {
    setLoading(true);
    onAssignTask(medplum, profileId, taskId, taskMarket, refetch)
      .catch(onAssignTaskError)
      .finally(() => setLoading(false));
  };

  const onAssignToTeamMember = (practitionerId: string): void => {
    setLoading(true);
    onAssignTask(medplum, practitionerId, taskId, taskMarket, refetch)
      .then(() => {
        close();
      })
      .catch(onAssignTaskError)
      .finally(() => setLoading(false));
  };

  const onUnassign = (): void => {
    setLoading(true);
    medplum
      .patchResource('Task', taskId, [
        {
          op: 'remove',
          path: '/owner',
        },
        {
          op: 'replace',
          path: '/status',
          value: 'ready',
        },
      ])
      .then(async () => {
        await refetch();
      })
      .catch((err) => {
        notifications.show({
          title: 'Error unassigning task',
          message: getErrorMessage(err),
          color: 'status-error',
        });
      })
      .finally(() => setLoading(false));
  };

  const onSearch = (name: Maybe<string>): void => {
    if (!name) {
      setSearchParams({});
    } else {
      setSearchParams({ name });
    }
  };

  const closeModal = (): void => {
    setSearchParams(null);
    close();
  };

  return (
    <>
      <AssignToTeamMemberModal
        searchParams={searchParams}
        search={onSearch}
        assign={onAssignToTeamMember}
        opened={opened}
        onClose={closeModal}
      />
      <Menu disabled={disabled}>
        <Menu.Target>
          <ActionIcon disabled={disabled} loading={loading} variant="outline" className={classes.editAssigneePencil}>
            <IconPencil size={16} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={() => onAssignToMe()}>Assign to me</Menu.Item>
          <Menu.Item onClick={() => open()}>Assign to team member</Menu.Item>
          <Menu.Item disabled={!assigned} onClick={() => onUnassign()}>
            Unassign
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};

export function AssigneeSection({
  refreshTasks,
  taskId,
  assigneeId,
  assigneeName,
  profile,
  taskMarket,
  disabled,
}: AssigneeSectionProps): JSX.Element {
  const isAssigneeLoggedIn = useMemo(() => {
    return profile.id === assigneeId;
  }, [profile, assigneeId]);

  if (!assigneeId) {
    return (
      <>
        <AssignedToLabel />
        <UnassignedSection
          taskMarket={taskMarket}
          assigned={false}
          refreshTasks={refreshTasks}
          profileId={profile.id!}
          disabled={disabled}
          taskId={taskId}
        />
      </>
    );
  }

  return (
    <>
      <AssignedToLabel />
      <Group>
        <Text>{assigneeName}</Text>
        {isAssigneeLoggedIn && <Text fw="bold"> (Me)</Text>}
        <EditAssigneeIcon
          taskMarket={taskMarket}
          assigned={true}
          refreshTasks={refreshTasks}
          disabled={disabled}
          taskId={taskId}
          profileId={profile.id!}
        />
      </Group>
    </>
  );
}
