import { ClinicalImpression } from '@medplum/fhirtypes';
import { ClinicalImpressionInfoFragment, Maybe, useGetPatientClinicalImpressionsQuery } from 'medplum-gql';
import { clusterFromClinicalImpressions, getCarePathway } from 'imagine-dsl/utils/clinicalImpression';
import { CarePathway } from 'const-utils/codeSystems/ImaginePediatrics';
import { useMemo } from 'react';

//TODO: Can we remove the patientId and only pass in clinical impressions? Can we add the list of clinical impressions to all task queries?
export const useGetCarePathway = ({
  patientId,
  clinicalImpressions,
}: {
  patientId?: string;
  clinicalImpressions?: Maybe<Maybe<ClinicalImpressionInfoFragment>[]> | undefined;
}): { carePathway: CarePathway | undefined; cluster: string | undefined } => {
  const { data: patient } = useGetPatientClinicalImpressionsQuery({
    variables: {
      id: patientId!,
    },
    skip: !patientId && clinicalImpressions !== undefined,
  });

  const clinicalImpressionList = (clinicalImpressions ||
    patient?.Patient?.ClinicalImpressionList) as ClinicalImpression[];
  const carePathway = getCarePathway(clinicalImpressionList);
  const cluster = clusterFromClinicalImpressions(clinicalImpressionList);

  return useMemo(() => ({ carePathway, cluster }), [carePathway, cluster]);
};
