import React, { useState } from 'react';
import { Button, Modal, TextInput, Select, Group, Radio, Divider, ActionIcon } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { PhoneStatus } from 'const-utils';
import { notifications } from '@mantine/notifications';
import { cleanPhoneNumber } from 'imagine-dsl/utils/strings';
import { logError } from '@/errors';

export interface PhoneNumber {
  number: string;
  status: string;
  isPrimary: boolean;
}

interface EditPhoneNumbersModalProps {
  opened: boolean;
  onClose: () => void;
  onSave: (data: PhoneNumber[]) => void;
  initialPhoneNumbers: PhoneNumber[];
}

export function EditPhoneNumbersModal({
  opened,
  onClose,
  onSave,
  initialPhoneNumbers,
}: EditPhoneNumbersModalProps): JSX.Element {
  const [invalidPhoneStatusCodes] = PhoneStatus.compose?.include?.map((c) => c.concept?.map((d) => d.display)) ?? [];
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      phoneNumbers: initialPhoneNumbers,
    },
    validateInputOnBlur: true,
    transformValues: (values) => ({
      phoneNumbers: values.phoneNumbers.map((phoneNumber) => {
        return {
          ...phoneNumber,
          number: cleanPhoneNumber(phoneNumber.number),
        };
      }),
    }),
    validate: (values) => {
      const errors: Record<string, string> = {};

      values.phoneNumbers.forEach((phoneNumber, index) => {
        if (!phoneNumber.number) {
          errors[`phoneNumbers.${index}.number`] = 'Phone number is required';
        } else {
          const cleanedNumber = cleanPhoneNumber(phoneNumber.number);
          if (cleanedNumber.length !== 10) {
            errors[`phoneNumbers.${index}.number`] = 'Phone number must be 10 digits';
          }
        }
      });

      return errors;
    },
  });

  const addPhoneNumber = (): void => {
    const newPhoneNumber: PhoneNumber = { number: '', status: '', isPrimary: false };
    form.insertListItem('phoneNumbers', newPhoneNumber);
  };

  const handlePrimaryChange = (index: number): void => {
    // Set all phone numbers' isPrimary to false
    const updatedPhoneNumbers = form.values.phoneNumbers.map((phoneNumber, i) => ({
      ...phoneNumber,
      isPrimary: i === index,
    }));
    form.setFieldValue('phoneNumbers', updatedPhoneNumbers);
  };

  const handleSubmit = async (values: { phoneNumbers: PhoneNumber[] }): Promise<void> => {
    setLoading(true);
    try {
      await onSave(values.phoneNumbers);
    } catch (error) {
      logError(error);
      notifications.show({
        title: 'Error',
        message: 'An error occurred while saving the phone numbers. Please try again.',
        color: 'status-error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Edit phone number(s)" size="lg" centered c="imagine-green">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        {form.values.phoneNumbers.map((phoneNumber, index) => (
          <React.Fragment key={index}>
            <Group justify="space-between" align="center" gap="xs">
              <TextInput
                required
                label="Phone number"
                placeholder="1234567890"
                value={form.values.phoneNumbers[index].number}
                {...form.getInputProps(`phoneNumbers.${index}.number`, { withError: true })}
                style={{ flex: 2 }}
              />
              <Select
                label="Phone status"
                placeholder="Please select"
                value={form.values.phoneNumbers[index].status}
                data={invalidPhoneStatusCodes as string[]}
                {...form.getInputProps(`phoneNumbers.${index}.status`)}
                style={{ flex: 2 }}
              />
              <Radio
                label="Primary"
                pt="lg"
                checked={form.values.phoneNumbers[index].isPrimary}
                onChange={() => handlePrimaryChange(index)}
                name="primaryPhoneNumber"
                value={String(index)}
                style={{ flex: 1 }}
              />
              {form.values.phoneNumbers[index].number === '' && (
                <ActionIcon onClick={() => form.removeListItem('phoneNumbers', index)} mt="md">
                  <IconTrash size={20} color="red" cursor="pointer" />
                </ActionIcon>
              )}
            </Group>
            <Divider my="xs" />
          </React.Fragment>
        ))}
        <Group mt="md">
          <Button variant="outline" onClick={addPhoneNumber} leftSection={<IconPlus size={16} />} radius="md">
            Add another phone number
          </Button>
        </Group>
        <Group mt="md" justify="flex-end">
          <Button variant="outline" onClick={onClose} radius="md">
            Cancel
          </Button>
          <Button
            type="submit"
            radius="md"
            disabled={
              !form.isDirty() ||
              form.values.phoneNumbers.some((phoneNumber) => !phoneNumber?.number || phoneNumber.number.length < 10)
            }
            loading={loading}
          >
            Save changes
          </Button>
        </Group>
      </form>
    </Modal>
  );
}
