import React, { useState } from 'react';
import { Notification } from 'imagine-dsl/models/communications/notification';
import { ActionIcon, Badge, Box, Group, Menu, Text } from '@mantine/core';
import { formatHumanName } from '@medplum/core';
import { HumanName } from '@medplum/fhirtypes';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { IconDotsVertical } from '@tabler/icons-react';
import { useNotificationContext } from '@/pages/Notifications/NotificationsProvider';
import { useMedplum } from '@medplum/react';
import { logError } from '@/errors';
import { notifications } from '@mantine/notifications';
import { markAsRead, toggleReadStatus } from 'imagine-dsl/services/notificationCommunicationService';

interface MenuItemProps {
  notification: Notification;
}

export const MenuItem = ({ notification }: MenuItemProps): JSX.Element => {
  const senderName = notification.sender?.name?.[0];
  const patientLink = notification.subjectReference || '/notifications';
  const navigate = useNavigate();
  const medplum = useMedplum();
  const [opened, setOpened] = useState(false);
  const { refetchNotifications, refetchUnreadCount } = useNotificationContext();

  const onMarkAsRead = (): void => {
    toggleReadStatus(medplum, notification)
      .then(async () => {
        await refetchNotifications();
        await refetchUnreadCount();
      })
      .catch((e) => {
        notifications.show({ title: 'Error', message: 'Failed to update notification', color: 'status-error' });
        logError(e);
      });
  };

  const onDelete = (): void => {
    medplum
      .deleteResource('Communication', notification.id!)
      .then(async () => {
        await refetchNotifications();
        await refetchUnreadCount();
      })
      .catch((e) => {
        notifications.show({ title: 'Error', message: 'Failed to delete notification', color: 'status-error' });
        logError(e);
      });
  };

  const onMenuItemClick = () => {
    navigate(patientLink);
    if (notification.received) {
      return;
    }

    markAsRead(medplum, notification.id!)
      .then(async () => {
        await refetchNotifications();
        await refetchUnreadCount();
      })
      .catch((e) => {
        notifications.show({ title: 'Error', message: 'Failed to update notification', color: 'status-error' });
        logError(e);
      });
  };

  return (
    <Menu.Item onClick={onMenuItemClick} p={12}>
      <Group justify="space-between">
        <Text w="75%" dangerouslySetInnerHTML={{ __html: notification.title || '' }} />
        <Group>
          <Menu opened={opened} onChange={setOpened}>
            <Menu.Target>
              <ActionIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setOpened(!opened);
                }}
                variant="outline"
                radius="xl"
              >
                <IconDotsVertical />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={(e) => {
                  e.stopPropagation();
                  onMarkAsRead();
                }}
              >
                Mark as {notification.received ? 'unread' : 'read'}
              </Menu.Item>
              <Menu.Item
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
                c="status-error"
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          {!notification.received && <Badge h={8} w={8} circle />}
        </Group>
      </Group>
      {notification.contentString && (
        <Group justify="space-between" wrap="nowrap">
          <Box mt={4} p={8} w="100%" bd="1px solid brand-gray.4" style={{ borderRadius: '8px' }}>
            {senderName && <Text fw="bold">{formatHumanName(senderName as HumanName)}</Text>}
            <Text>{notification.contentString}</Text>
          </Box>
        </Group>
      )}
      {notification.sentTime && (
        <Text mt={8} c="brand-gray.7">
          {format(notification.sentTime, 'EEEE M/d/yyyy h:mm aa')}
        </Text>
      )}
    </Menu.Item>
  );
};
