import React, { useEffect, useMemo, useState } from 'react';
import { ActionIcon, Container, Group, Title, Modal, Text, Stack, Button } from '@mantine/core';
import Markdown from 'react-markdown';
import { Loading, useMedplum } from '@medplum/react';
import { useQuery } from '@tanstack/react-query';
import { Buffer } from 'buffer';
import { IconDeviceFloppy, IconEdit, IconX } from '@tabler/icons-react';
import { logError } from '@/errors';
import { showNotification } from '@mantine/notifications';
import remarkGfm from 'remark-gfm';
import MarkdownEditor from '@uiw/react-markdown-editor';
import { useBlocker } from 'react-router-dom';

const decode = (data: string): string => Buffer.from(data, 'base64').toString('utf-8');
const encode = (data: string): string => Buffer.from(data, 'utf-8').toString('base64');

export function ReleaseNotesPage(): JSX.Element {
  const medplum = useMedplum();
  const releaseNotes = useQuery(['release-notes'], () => medplum.searchOne('Basic', { code: 'release-notes' }));
  const decodedReleaseNote = useMemo(() => decode(releaseNotes.data?.text?.div ?? ''), [releaseNotes.data?.text?.div]);
  const [isEditing, setIsEditing] = useState(false);
  const [confirmingCancel, setConfirmingCancel] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const canEdit = useMemo(() => {
    return (
      medplum.isProjectAdmin() ||
      // https://www.medplum.com/docs/sdk/core.satisfiedaccesspolicy would be nice to use here instead of digging through
      // access policy implementation details, but it requires indexing the search-parameter.json bundle which is a multi-MB file (~1.52 MB compressed)
      // and we probably shouldn't pull into the client.
      // https://github.com/medplum/medplum/blob/58b17a1f0e495a64af764d7198644558ded98ec0/packages/core/src/types.ts#L141-L221
      // https://github.com/medplum/medplum/blob/58b17a1f0e495a64af764d7198644558ded98ec0/packages/core/src/access.test.ts#L55
      // https://github.com/medplum/medplum/blob/58b17a1f0e495a64af764d7198644558ded98ec0/packages/definitions/dist/fhir/r4/search-parameters.json#L1
      medplum?.getAccessPolicy()?.resource?.some((r) => r.resourceType === 'Basic' && !r.readonly)
    );
  }, [medplum]);

  const [releaseNotesDraft, setReleaseNotesDraft] = useState('');
  useEffect(() => {
    setReleaseNotesDraft(decodedReleaseNote);
  }, [decodedReleaseNote]);

  const save = async () => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);
    medplum
      .updateResource({
        ...releaseNotes.data!,
        text: {
          status: 'additional',
          div: encode(releaseNotesDraft),
        },
      })
      .then(() => {
        showNotification({
          title: 'Release notes saved',
          message: 'Release notes saved successfully',
          color: 'status-success',
        });
        setIsEditing(false);

        releaseNotes.refetch().catch((e) => logError(e));
      })
      .catch((err) => {
        logError(err);
        showNotification({
          title: 'Error saving release notes',
          message: err.message,
          color: 'status-error',
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const blocker = useBlocker(isEditing && releaseNotesDraft !== decodedReleaseNote);

  return (
    <>
      <Container size="xl" style={{ width: '100%', marginTop: '20px' }}>
        <Group>
          <Title>Release Notes</Title>
          {canEdit && !isEditing && (
            <ActionIcon
              variant="subtle"
              onClick={() => {
                setIsEditing(true);
              }}
            >
              <IconEdit />
            </ActionIcon>
          )}
          {isEditing && (
            <>
              <ActionIcon
                disabled={isSaving}
                variant="subtle"
                onClick={() => {
                  if (releaseNotesDraft !== decodedReleaseNote) {
                    setConfirmingCancel(true);
                    return;
                  }
                  setIsEditing(false);
                }}
              >
                <IconX />
              </ActionIcon>
              <ActionIcon disabled={!releaseNotesDraft} loading={isSaving} variant="subtle" onClick={save}>
                <IconDeviceFloppy />
              </ActionIcon>
            </>
          )}
        </Group>
        {releaseNotes.isLoading && <Loading />}
        {releaseNotes.data &&
          (isEditing ? (
            <MarkdownEditor
              renderPreview={() => (
                <Markdown className="word-wrap" remarkPlugins={[remarkGfm]}>
                  {releaseNotesDraft}
                </Markdown>
              )}
              value={releaseNotesDraft}
              onChange={(data) => setReleaseNotesDraft(data)}
            />
          ) : (
            <Markdown className="word-wrap" remarkPlugins={[remarkGfm]}>
              {decodedReleaseNote}
            </Markdown>
          ))}
      </Container>
      <Modal
        centered
        opened={confirmingCancel || blocker.state === 'blocked'}
        onClose={() => {
          blocker.reset?.();
          setConfirmingCancel(false);
        }}
        title={
          <Text size="sm" fw={700} c="imagine-green">
            Are you sure you want to {confirmingCancel ? 'cancel' : 'exit'}?
          </Text>
        }
      >
        <Text>Any unsaved changes will be lost. You may want to 'Save' instead to record your changes.</Text>
        <Stack align="center" mt="md">
          <Button
            variant="filled"
            onClick={() => {
              blocker.reset?.();
              setConfirmingCancel(false);
            }}
          >
            Continue editing
          </Button>
          <Button
            variant="transparent"
            onClick={() => {
              if (blocker.state === 'blocked') {
                blocker.proceed?.();
              } else {
                setReleaseNotesDraft(decodedReleaseNote);
                setConfirmingCancel(false);
                setIsEditing(false);
              }
            }}
          >
            {confirmingCancel ? 'Cancel' : 'Exit'}
          </Button>
        </Stack>
      </Modal>
    </>
  );
}
