import { logError } from '@/errors';
import { useMedplum, useMedplumProfile } from '@medplum/react';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { PatientListService, PatientListsType, buildPatientListService } from 'imagine-dsl';
import { useDebouncedState } from '@mantine/hooks';
import { isEqual } from 'lodash';
import { notifications } from '@mantine/notifications';

interface PatientListContext {
  recent: UsePatientListType;
  pinned: UsePatientListType & WithLoading;
}

const PatientListContextValue = createContext<PatientListContext | undefined>(undefined);

export const PatientListProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const recent = useLocalPatientList({ listName: PatientListsType.recent });
  const pinned = useRemotePatientList({ listName: PatientListsType.pinned });

  const contextValue = {
    recent,
    pinned,
  };

  const Provider = PatientListContextValue.Provider;

  return <Provider value={contextValue}> {children} </Provider>;
};

const usePatientListContext = (): PatientListContext => {
  const context = React.useContext(PatientListContextValue);

  if (!context) {
    throw new Error('Must be used within a PatientListProvider');
  }

  return context;
};

const MAX_LIST_SIZE_DEFAULT = 20;

export type UsePatientListType = {
  patientIds: string[];
  add: (patientId: string) => void;
  remove: (patientId: string) => void;
  move: (from: number, to: number) => void;
  isFull: boolean;
};

type WithLoading = {
  loading: boolean;
};

const getPatientList = (key: string): string[] => {
  const recentPatients = localStorage.getItem(key);

  if (recentPatients) {
    return JSON.parse(recentPatients);
  }

  return [];
};

function arraymove<T>(arr: T[], fromIndex: number, toIndex: number): T[] {
  const copy = [...arr];
  const element = copy[fromIndex];
  copy.splice(fromIndex, 1);
  copy.splice(toIndex, 0, element);
  return copy;
}

function arrayadd<T>(arr: T[], element: T, max: number): T[] {
  if (arr.at(0) === element) {
    return arr;
  } else if (arr.includes(element)) {
    arr.splice(arr.indexOf(element), 1);
  }

  if (arr.length === max) {
    arr.pop();
  }

  arr.unshift(element);
  return arr;
}

export interface UsePatientListOptions {
  listName: PatientListsType;
  maxPatients?: number;
}

const useLocalPatientList = (options: UsePatientListOptions): UsePatientListType => {
  const [patients, setPatients] = useState<string[]>(getPatientList(options.listName));
  const size = options.maxPatients || MAX_LIST_SIZE_DEFAULT;

  useEffect(() => {
    localStorage.setItem(options.listName, JSON.stringify(patients));
  }, [patients, options.listName]);

  const addPatient = useCallback(
    (patientId: string): void => {
      if (patients.at(0) === patientId) {
        return;
      }
      const update = arrayadd([...patients], patientId, size);
      setPatients([...update]);
    },
    [patients, size],
  );

  const removePatient = useCallback(
    (patientId: string): void => {
      if (!patients.includes(patientId)) {
        return;
      }
      setPatients([...patients.filter((id) => id !== patientId)]);
    },
    [patients],
  );

  const movePatient = useCallback(
    (fromIndex: number, toIndex: number): void => {
      const newPatients = arraymove(patients, fromIndex, toIndex);
      setPatients([...newPatients]);
    },
    [patients],
  );

  const isFull = useMemo(() => patients.length >= size, [patients, size]);

  return {
    patientIds: patients,
    add: addPatient,
    remove: removePatient,
    move: movePatient,
    isFull,
  };
};

const usePatientListService = (): PatientListService => {
  const medplum = useMedplum();

  return useMemo(() => buildPatientListService(medplum), [medplum]);
};

/**
 * A hook that manages a remote patient list optimistically. This is
 * accomplished by updating a local representation of the list and
 * syncing it with the server in the background with a debounce.
 *
 * @param options - UsePatientListOptions
 * @returns - UsePatientListType
 */
const useRemotePatientList = (options: UsePatientListOptions): UsePatientListType & WithLoading => {
  const patientLists = usePatientListService();
  const profile = useMedplumProfile();
  const [patients, setPatients] = useState<string[]>([]);
  const [updatedPatients, setUpdatedPatients] = useDebouncedState<string[] | undefined>(undefined, 800);
  const [lastUpdated, setLastUpdated] = useState<string[] | undefined>(undefined);
  const size = useMemo(() => options.maxPatients || MAX_LIST_SIZE_DEFAULT, [options.maxPatients]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (profile?.id) {
      setLoading(true);
      patientLists
        .get(profile.id, options.listName)
        .then((result) => {
          setPatients([...result]);
          setLastUpdated([...result]);
        })
        .catch((err) => {
          logError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [profile, options.listName, patientLists]);

  const updateRemoteList = useCallback(
    (patients: string[]) => {
      if (!profile?.id) {
        return Promise.resolve();
      }

      return patientLists.updateList(profile.id, options.listName, patients);
    },
    [profile, patientLists, options.listName],
  );

  useEffect(() => {
    if (isEqual(lastUpdated, updatedPatients)) {
      return;
    }
    if (updatedPatients) {
      const update = [...updatedPatients];
      updateRemoteList(update)
        .then(() => {
          setLastUpdated(update);
        })
        .catch((e) => {
          logError(e);
          notifications.show({
            title: 'Error updating patient list.',
            message: 'Too many updates. Please try again later.',
            color: 'status-error',
          });
          if (lastUpdated !== undefined) {
            setPatients([...lastUpdated]);
            setUpdatedPatients([...lastUpdated]);
          }
        });
    }
  }, [lastUpdated, updatedPatients, updateRemoteList, setUpdatedPatients, setPatients]);

  const add = useCallback(
    (patientId: string): void => {
      if (patients.at(0) === patientId) {
        return;
      }
      const update = arrayadd([...patients], patientId, size);
      setPatients([...update]);
      setUpdatedPatients([...update]);
    },
    [patients, size, setUpdatedPatients],
  );

  const remove = useCallback(
    (patientId: string): void => {
      if (!patients.includes(patientId)) {
        return;
      }
      const list = patients.filter((id) => id !== patientId);
      setPatients([...list]);
      setUpdatedPatients([...list]);
    },
    [patients, setUpdatedPatients],
  );

  const move = useCallback(
    (fromIndex: number, toIndex: number): void => {
      const newPatients = arraymove([...patients], fromIndex, toIndex);
      setPatients([...newPatients]);
      setUpdatedPatients([...newPatients]);
    },
    [patients, setUpdatedPatients],
  );

  const isFull = useMemo(() => patients.length >= size, [patients, size]);

  return {
    patientIds: patients,
    add,
    remove,
    move,
    isFull,
    loading,
  };
};

export const useRecentPatients = (): UsePatientListType => {
  const { recent } = usePatientListContext();
  return recent;
};

export const usePinnedPatients = (): UsePatientListType & WithLoading => {
  const { pinned } = usePatientListContext();
  return pinned;
};
