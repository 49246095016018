import { Badge, MantineSize } from '@mantine/core';
import {
  GAD7ResponseCategory,
  PHQ9ResponseCategory,
  QuestionnaireResponseCategory,
} from 'const-utils/codeSystems/ImaginePediatrics';
import React, { useMemo } from 'react';

const getResponseCategoryBadgeColor = (responseValue: string): string => {
  switch (responseValue) {
    case QuestionnaireResponseCategory.Positive.toString():
    case QuestionnaireResponseCategory.SuicideRisk.toString():
    case GAD7ResponseCategory.Severe.toString():
    case PHQ9ResponseCategory.Severe.toString():
      return 'red';
    case QuestionnaireResponseCategory.Negative.toString():
    case PHQ9ResponseCategory.ModeratelySevere.toString():
    case GAD7ResponseCategory.Mild.toString():
    case GAD7ResponseCategory.Moderate.toString():
    case GAD7ResponseCategory.LowRisk.toString():
    case PHQ9ResponseCategory.Mild.toString():
    case PHQ9ResponseCategory.NonOrMinimal.toString():
    case PHQ9ResponseCategory.Moderate.toString():
      return 'green';
    default:
      return 'rgba(179, 179, 179, 1)';
  }
};

interface ResponseCategoryBadgeProps {
  responseValue: string;
  size?: MantineSize;
}

export function ResponseCategoryBadge({ responseValue, size }: ResponseCategoryBadgeProps): JSX.Element {
  const badgeColor = useMemo(() => getResponseCategoryBadgeColor(responseValue), [responseValue]);

  return (
    <Badge size={size} color={badgeColor}>
      {responseValue}
    </Badge>
  );
}
