import { logError } from '@/errors';
import { Patient } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { useCallback, useMemo } from 'react';

type FetchSignature = (url: RequestInfo, options?: RequestInit) => Promise<Response>;
export type Client = { fetch: FetchSignature };

export const useApiClient = (): Client => {
  const medplum = useMedplum();

  const apiFetch: FetchSignature = useCallback(
    async (url: RequestInfo, options?: RequestInit): Promise<Response> => {
      const response = await fetch(url, {
        ...options,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${medplum.getAccessToken()}`,
          ...options?.headers,
        },
      });

      if (response.status === 401) {
        throw new Error('Unauthorized');
      }

      return response;
    },
    [medplum],
  );

  return useMemo(() => ({ fetch: apiFetch }), [apiFetch]);
};

export const resolveJSON = async <T>(response: Response): Promise<T> => {
  if (!response.ok) {
    throw await createError(response);
  }

  return response.json() as Promise<T>;
};

export const createError = async (response: Response, baseMessage?: string): Promise<Error> => {
  const message = baseMessage ? baseMessage : `unexpected response status ${response.status}`;
  try {
    const { error } = await response.json();
    return new Error(`${message}: ${error}`);
  } catch (e) {
    logError(e);
    return new Error(message);
  }
};

export const searchCaregiversWithProjectMembership = async (
  apiClient: Client,
  profileQuery: string,
): Promise<Patient[]> => {
  const res = await apiClient.fetch(`/api/enrollment/caregiversWithProjectMemberships?profileQuery=${profileQuery}`);
  if (!res.ok) {
    throw new Error(`Failed to fetch maybe existing contacts ${res.status}`);
  }

  const data = (await res.json()) as { patients: Patient[] };

  return data.patients;
};
