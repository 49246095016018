import { Center, Group, List, Table, Text, ThemeIcon } from '@mantine/core';
import { ClinicalImpression } from '@medplum/fhirtypes';
import { IconInfoCircle } from '@tabler/icons-react';
import { qualifyingDiagnosesFromClinicalImpressions } from 'imagine-dsl/utils/clinicalImpression';
import React from 'react';

export const Diagnoses = ({ impressions }: { impressions: ClinicalImpression[] }) => {
  const diagnoses = qualifyingDiagnosesFromClinicalImpressions(impressions);

  return (
    <>
      <Table mt={'lg'}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>ICD-10 code</Table.Th>
            <Table.Th>Description</Table.Th>
            <Table.Th>Most recent month</Table.Th>
            <Table.Th>First month</Table.Th>
            <Table.Th># of months</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {diagnoses.map((diagnosis) => {
            return (
              <Table.Tr key={diagnosis.code}>
                <Table.Td>{diagnosis.code}</Table.Td>
                <Table.Td>{diagnosis.description}</Table.Td>
                <Table.Td>{diagnosis.yearMonthClaimMax}</Table.Td>
                <Table.Td>{diagnosis.yearMonthClaimMin}</Table.Td>
                <Table.Td>{diagnosis.monthsWithCode}</Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
        {diagnoses.length ? (
          <Table.Tfoot>
            <Table.Tr>
              <Table.Td colSpan={5}>
                <List mt={'md'} fz={'xs'}>
                  <List.Item>Most recent month: Most recent month of diagnosis</List.Item>
                  <List.Item>
                    First month: First month where diagnosis appears (during 2 year lookback period)
                  </List.Item>
                  <List.Item>
                    # of months: Number of months where diagnosis appears (during 2 year lookback period)
                  </List.Item>
                </List>
              </Table.Td>
            </Table.Tr>
          </Table.Tfoot>
        ) : null}
      </Table>
      {diagnoses.length === 0 && (
        <Center mt="lg">
          <Group>
            <ThemeIcon c="status-info">
              <IconInfoCircle size={18} />
            </ThemeIcon>
            <Text>There are no claims data for this patient during the 2 year lookback period</Text>
          </Group>
        </Center>
      )}
    </>
  );
};
