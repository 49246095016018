import { MedplumClient, PatchOperation, createReference, parseReference } from '@medplum/core';
import { Bundle, Extension, Patient, QuestionnaireResponse, Reference, RelatedPerson, Task } from '@medplum/fhirtypes';
import { differenceInCalendarYears } from 'date-fns';
import { Maybe } from 'medplum-gql';
import { compact, uniqBy } from 'lodash';
import { isPrimaryCaregiver } from '../utils/patient';
import {
  PreferredLanguage,
  QuestionnaireResponseCategory,
  QuestionnaireType,
  TaskType,
} from 'const-utils/codeSystems/ImaginePediatrics';
import { System, HL7System } from 'const-utils';
import { buildQuestionnaireTask, CareHubQuestionnaire } from '../utils/questionnaire';
import {
  calculateScore,
  CareHubQuestionnaireResponse,
  categorizeBHSurvey,
  scoreableResponseItems,
} from '../utils/questionnaireResponse';
import { Logger } from '../utils/logging';
import { buildTask } from '../utils/task';
import { getCaregiversForPatient } from './patientService';

class QuestionnaireError extends Error {}

/**
 *
 * @param medplum - the medplum client
 * @param patient - patient the questionnaire is for
 * @param logger - logger
 * @returns a task to complete the questionnaire
 */
export const assignScreenerQuestionnaire = async (
  medplum: MedplumClient,
  patient: Patient,
  logger: Logger = console,
): Promise<Maybe<Task>> => {
  const caregivers = await getCaregiversForPatient(medplum, patient.id!);
  const primaryCaregiver = caregivers.find((caregiver) => isPrimaryCaregiver(patient, caregiver));

  const questionnaires = await medplum.search('Questionnaire', `_tag=${QuestionnaireType.Screener}&status=active`);

  if (!questionnaires.entry || questionnaires.entry.length === 0) {
    throw new QuestionnaireError('Active Screener Questionnaire not found');
  }

  if (caregivers.length === 0) {
    throw new QuestionnaireError('No caregivers found to assign screener to');
  }

  const questionnaireIds = compact(questionnaires.entry.map((entry) => entry.resource?.id).filter((id) => id));
  const { currentResponsesTasks, responses } = await upToDateQuestionnaireResponses(medplum, {
    questionnaireIds,
    caregivers,
  });

  if (currentResponsesTasks.length > 0) {
    const incompleteCurrentTask = currentResponsesTasks.find((task) => task.status !== 'completed');
    if (incompleteCurrentTask) {
      logger.info('Questionnaire task in progress');
      return null;
    }

    logger.info(`Questionnaire task completed by caregiver - creating a screener review task for enrolled patient.`);
    const reviewTask: Task = buildTask({
      status: 'requested',
      description: 'Review screener questionnaire response',
      type: TaskType.ScreenerReview,
      for: createReference(patient),
      focus: responses?.[0] ? createReference(responses[0]) : undefined,
      basedOn: [createReference(currentResponsesTasks[0])],
      authoredOn: new Date().toISOString(),
    });
    await medplum.createResourceIfNoneExist(
      reviewTask,
      `subject=Patient/${patient.id}&_tag=${TaskType.ScreenerReview}`,
    );
    return null;
  }

  const assignee = primaryCaregiver || caregivers[0];
  const caregiverLanguageCommunicationCode = assignee.communication?.find(
    (c) => c.language.coding?.find((coding) => coding.system === System.PreferredLanguage),
  )?.language.coding?.[0].code;

  const questionnaireResources = questionnaires.entry.filter((entry) => entry.resource).map((entry) => entry.resource);
  const careHubQuestionnaires = questionnaireResources.map((resource) => new CareHubQuestionnaire(resource!));

  let careHubQuestionnaire: CareHubQuestionnaire | undefined;
  if (
    !careHubQuestionnaires.map((questionnaire) => questionnaire.language).includes(caregiverLanguageCommunicationCode)
  ) {
    careHubQuestionnaire = careHubQuestionnaires.find(
      (questionnaire) => questionnaire.language === PreferredLanguage.En.toString(),
    );
  } else {
    careHubQuestionnaire = careHubQuestionnaires.find((questionnaire) => {
      const language = questionnaire.language;
      if (!caregiverLanguageCommunicationCode) {
        return language === PreferredLanguage.En.toString();
      }
      return language === caregiverLanguageCommunicationCode;
    });
  }

  if (!careHubQuestionnaire) {
    throw new QuestionnaireError('Questionnaire not found');
  }

  const taskObj = buildQuestionnaireTask({
    patientReference: createReference(patient),
    caregiverReference: createReference(primaryCaregiver || caregivers[0]),
    questionnaireReference: createReference(careHubQuestionnaire.questionnaire),
    taskType: TaskType.Screener,
  });

  return medplum.createResource(taskObj);
};

/**
 *
 * @param medplum - the medplum client
 * @param questionnaireResponse - the questionnaire response to score
 * @returns an updated QuestionnaireResponse
 *
 * This function gets the scoreable questions from the questionnaire
 * and the scoreable responses from the questionnaire response.
 *
 * It then calculates the score and category of the questionnaire response
 * by finding the answer values for each response and summing them up.
 *
 * It then adds the score and category as extensions to the questionnaire response.
 * A response is positive if the score is greater than 0, otherwise it is negative.
 */
export const scoreQuestionnaireResponse = async (
  medplum: MedplumClient,
  questionnaireResponse: QuestionnaireResponse,
): Promise<QuestionnaireResponse> => {
  const questionnaireId = questionnaireResponse.questionnaire?.split('/')[1];
  if (!questionnaireId) {
    throw new QuestionnaireError('QuestionnaireResponse missing questionnaire');
  }
  const questionnaire = await medplum.readResource('Questionnaire', questionnaireId);
  const careHubQuestionnaire = new CareHubQuestionnaire(questionnaire);
  if (!careHubQuestionnaire.id) {
    throw new QuestionnaireError('Questionnaire not found');
  }

  const scoreableQuestions = careHubQuestionnaire.scoreableItems;

  const scoreableResponses = scoreableResponseItems(questionnaireResponse, scoreableQuestions);

  const { score, positiveForSuicidality } = calculateScore({ scoreableResponses, scoreableQuestions });
  let category: string;
  if (careHubQuestionnaire.type === QuestionnaireType.BHSurvey) {
    category = categorizeBHSurvey(careHubQuestionnaire.BHtype!, score);
  } else if (score > 0) {
    category = QuestionnaireResponseCategory.Positive;
  } else {
    category = QuestionnaireResponseCategory.Negative;
  }

  const extensions: Extension[] = [
    {
      url: System.QuestionnaireResponseScore,
      valueInteger: score,
    },
    {
      url: System.QuestionnaireResponseCategory,
      valueString: category,
    },
  ];

  if (positiveForSuicidality) {
    extensions.push({
      url: System.QuestionnaireResponseCategory,
      valueString: QuestionnaireResponseCategory.SuicideRisk,
    });
  }

  const extensionPatches: PatchOperation[] = extensions.map((ext) => ({
    op: 'add',
    path: '/extension/-',
    value: ext,
  }));

  return medplum.patchResource('QuestionnaireResponse', questionnaireResponse.id!, extensionPatches);
};

/**
 * @typedef CreateReviewTaskParams
 * @property {Patient} caregiver - the caregiver who authored the response
 * @property {QuestionnaireResponse} questionnaireResponse - the questionnaire response to create review tasks for
 */
interface CreateReviewTaskParams {
  caregiver: Patient;
  questionnaireResponse: QuestionnaireResponse;
}

/**
 *
 * @param medplum - the medplum client
 * @param args - caregiver who authored the response, the questionnaire response to create review tasks for
 * @returns a bundle of tasks to review the questionnaire response
 *
 */
export const createReviewTasks = async (
  medplum: MedplumClient,
  args: CreateReviewTaskParams,
): Promise<Bundle<Task>> => {
  const { caregiver, questionnaireResponse } = args;

  const response = new CareHubQuestionnaireResponse(questionnaireResponse);
  const responseType = response.type;

  if (!questionnaireResponse?.id || !responseType || !response.reviewTaskTypeTaskType) {
    throw new QuestionnaireError('QuestionnaireResponse not found');
  }

  const patients = await getPatientsForReviewTaskSubject(medplum, { caregiver, response: questionnaireResponse });

  const surveyTasks = await medplum.searchResources('Task', {
    _tag: tagSearchString(response),
    owner: `Patient/${caregiver.id}`,
    focus: questionnaireResponse.questionnaire,
    _sort: '-_lastUpdated',
    _count: 1,
  });
  if (!surveyTasks.length) {
    throw new QuestionnaireError('No survey task found for caregiver');
  }

  const bundle: Bundle<Task> = {
    resourceType: 'Bundle',
    type: 'batch',
    entry: [],
  };

  patients.forEach((patient) => {
    const taskObj = buildTask({
      status: 'requested',
      description: 'Review survey response',
      type: response.reviewTaskTypeTaskType!,
      for: patient,
      focus: createReference(questionnaireResponse),
      basedOn: [createReference(surveyTasks[0])],
      authoredOn: new Date().toISOString(),
    });

    if (response.reviewTaskTypeTaskType === TaskType.ReviewBHSurvey) {
      const bhType = response.bhSurveyType;
      if (bhType) {
        taskObj.meta?.tag?.push({ system: System.BHSurveyType, code: bhType });
      }
    }

    bundle.entry?.push({
      request: {
        method: 'POST',
        url: 'Task',
        ifNoneExist: `focus=${taskObj.focus?.reference}&based-on=${taskObj.basedOn?.[0].reference}&subject=${patient.reference}`,
      },
      resource: {
        ...taskObj,
      },
    });
  });

  const result = await medplum.executeBatch(bundle);
  return result as Bundle<Task>;
};

export const createSuicideRiskReviewTask = async (
  medplum: MedplumClient,
  args: CreateReviewTaskParams,
): Promise<Task> => {
  const { caregiver, questionnaireResponse } = args;

  const response = new CareHubQuestionnaireResponse(questionnaireResponse);

  const surveyTasks = await medplum.search('Task', {
    owner: `Patient/${caregiver.id}`,
    _sort: '-_lastUpdated',
    _count: 1,
    _tag: tagSearchString(response),
  });

  if (!surveyTasks.entry?.[0]?.resource) {
    throw new QuestionnaireError('No survey task found for caregiver');
  }

  const surveyTask = surveyTasks.entry?.[0]?.resource;

  if (!questionnaireResponse.subject) {
    throw new QuestionnaireError('No subject found for questionnaire response');
  }

  const [resourceType, resourceId] = parseReference(questionnaireResponse.subject);

  const taskSearch = `requester=${resourceType}/${resourceId}&status:not=completed,cancelled&_tag=${TaskType.Chat},${TaskType.SuicideRisk}`;

  const existingTask = await medplum.searchOne('Task', taskSearch);

  const suicideRiskTaskData: Task = buildTask({
    status: 'requested',
    description: 'Suicide Risk Alert',
    type: TaskType.SuicideRisk,
    for: questionnaireResponse.subject,
    basedOn: [createReference(surveyTask)],
    authoredOn: new Date().toISOString(),
    priority: 'urgent',
    requester: questionnaireResponse.subject as Reference<Patient>,
  });

  const suicideRiskTask = await medplum.createResource(suicideRiskTaskData);

  if (existingTask) {
    const existingExtension =
      existingTask.statusReason?.extension?.filter((ext) => ext.url !== HL7System.SupersedingTask.toString()) || [];

    await medplum.updateResource({
      ...existingTask,
      status: 'cancelled',
      statusReason: {
        coding: [
          {
            system: HL7System.StatusReason,
            code: 'superseded',
            display: 'Superseded',
          },
        ],
        extension: [
          ...(existingExtension ?? []),
          {
            url: HL7System.SupersedingTask,
            valueReference: {
              reference: `Task/${suicideRiskTask.id}`,
            },
          },
        ],
      },
      executionPeriod: {
        start: existingTask.executionPeriod?.start ?? existingTask.authoredOn,
        end: new Date().toISOString(),
      },
    });
  }

  return suicideRiskTask;
};

interface GetPatientsForReviewTaskSubjectArgs {
  caregiver: Patient;
  response: QuestionnaireResponse;
}

const tagSearchString = (response: CareHubQuestionnaireResponse): string => {
  if (response.type === QuestionnaireType.Screener) {
    return response.caregiverTaskType?.toString() ?? '';
  }

  if (response.type === QuestionnaireType.BHSurvey) {
    return `${System.BHSurveyType}|${response.bhSurveyType}`;
  }

  throw new QuestionnaireError('Invalid questionnaire type');
};

const getPatientsForReviewTaskSubject = async (
  medplum: MedplumClient,
  args: GetPatientsForReviewTaskSubjectArgs,
): Promise<Reference<Patient>[]> => {
  const { caregiver, response } = args;
  const carehubResponse = new CareHubQuestionnaireResponse(response);

  if (carehubResponse.type === QuestionnaireType.Screener) {
    const caregiverRelatedPersonsIds = compact(
      caregiver.link
        ?.map((link) => link.other)
        .filter((rp) => rp.reference?.startsWith('RelatedPerson/'))
        .map((rp) => rp.reference?.split('/')[1]),
    );

    if (caregiverRelatedPersonsIds.length === 0) {
      throw new QuestionnaireError('No patients found for caregiver');
    }

    const relatedPatients = await medplum.search('RelatedPerson', '_id=' + caregiverRelatedPersonsIds.join(','));

    if (!relatedPatients.entry || relatedPatients.entry.length === 0) {
      throw new QuestionnaireError('No patients found for caregiver');
    }

    return relatedPatients.entry.map((entry) => entry.resource as RelatedPerson).map((rp) => rp.patient);
  }

  if (carehubResponse.type === QuestionnaireType.BHSurvey) {
    if (!response.subject) {
      throw new QuestionnaireError('No subject found for BH response');
    }

    return [response.subject as Reference<Patient>];
  }

  throw new QuestionnaireError('Invalid questionnaire type');
};

const upToDateQuestionnaireResponses = async (
  medplum: MedplumClient,
  { questionnaireIds, caregivers }: { questionnaireIds: string[]; caregivers: Patient[] },
): Promise<{ currentResponsesTasks: Task[]; responses: Maybe<QuestionnaireResponse[]> }> => {
  const caregiverReferences = caregivers.map((caregiver) => `Patient/${caregiver.id}`);
  const questionnaireReferences = questionnaireIds.map((id) => `Questionnaire/${id}`);

  const responseTasksForCaregivers = await medplum.search(
    'Task',
    `_sort=-_lastUpdated&status=completed,in-progress,requested&owner=${caregiverReferences.join(
      ',',
    )}&focus=${questionnaireReferences}`,
  );

  const currentResponsesTasks = compact(
    responseTasksForCaregivers.entry
      ?.map((entry) => entry.resource)
      .filter((task) => {
        return (
          ['in-progress', 'requested'].includes(task!.status) ||
          (task!.status === 'completed' && differenceInCalendarYears(new Date(), task!.executionPeriod!.end!) < 1)
        );
      }),
  );

  // If there are completed tasks, get the responses
  const completedTasks = currentResponsesTasks.filter((task) => task.status === 'completed');
  const caregiverReferencesForCompletedTasks = completedTasks.map((task) => task.owner?.reference);
  const questionnaireResponseResults = await medplum.search(
    'QuestionnaireResponse',
    `_sort=-_lastUpdated&author=${caregiverReferencesForCompletedTasks.join(',')}&status=completed&_tag=${
      QuestionnaireType.Screener
    }`,
  );
  const responses = compact(questionnaireResponseResults.entry?.map((entry) => entry.resource));

  return { currentResponsesTasks, responses };
};

interface TranslateResponseArgs {
  toLanguage: PreferredLanguage;
  questionnaireTags: string[];
}

export interface TranslationCodeMapping {
  code: string;
  display: string;
}

export const translateQuestionnaire = async (
  medplum: MedplumClient,
  args: TranslateResponseArgs,
): Promise<TranslationCodeMapping[]> => {
  const { toLanguage, questionnaireTags } = args;

  const tagsSearchString = questionnaireTags.map((tag) => `_tag=${tag}`).join('&');
  const questionnaireResult = await medplum.searchOne('Questionnaire', `${tagsSearchString}&_tag=${toLanguage}`);

  if (!questionnaireResult) {
    throw new QuestionnaireError(
      `Questionnaire with tags: ${tagsSearchString} not found in requested language: ${toLanguage}`,
    );
  }

  const toLanguageQuestionnaire = new CareHubQuestionnaire(questionnaireResult);

  const flattenedQuestionnaireItems = toLanguageQuestionnaire.flattenedItems;
  const toLanguageAnswerOptions = flattenedQuestionnaireItems
    .filter((item) => !['group', 'display'].includes(item.type))
    .map((item) => item.answerOption)
    .flat();

  const allToLanguageItems = flattenedQuestionnaireItems;

  const mappedCodes = allToLanguageItems.reduce<TranslationCodeMapping[]>((acc, item) => {
    const code = item?.linkId;
    if (!code || !item?.text) {
      return acc;
    }

    return acc.concat({ code, display: item.text });
  }, []);

  const mappedAnswerCodes = toLanguageAnswerOptions.reduce<TranslationCodeMapping[]>((acc, option) => {
    if (!option?.valueCoding) {
      return acc;
    }

    const code = option.valueCoding;
    if (!code?.code || !code?.display) {
      return acc;
    }

    return acc.concat({ code: code?.code, display: code?.display });
  }, []);

  return uniqBy(mappedAnswerCodes.concat(mappedCodes), 'code');
};

export const translatedDisplayByCode = (
  translationMappings: TranslationCodeMapping[],
  code: string,
): string | undefined => {
  const translation = translationMappings.find((mapping) => mapping.code === code);
  return translation?.display;
};
