import React from 'react';
import { Text, Flex } from '@mantine/core';

interface FieldProps {
  label: string;
  value: string;
}

export function Field({ label, value }: FieldProps): JSX.Element {
  return (
    <Flex direction="column" mb="md">
      <Text c="dimmed">{label}</Text>
      <Text size="sm">{value}</Text>
    </Flex>
  );
}
