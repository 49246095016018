import { resolveId } from '@medplum/core';
import { BundleEntry, Task, Communication } from '@medplum/fhirtypes';

export interface TimelineItem {
  id?: string;
  resource?: BundleEntry<Task> | Communication;
  relevantTime: string;
  itemType: ItemType;
}

type ItemType = 'Communication' | 'TaskHistory';

export const buildTimelineItems = (
  assignmentHistory: BundleEntry<Task>[],
  messages: Communication[],
): TimelineItem[] => {
  const items = timelineInputs(assignmentHistory, messages);
  items.sort((itemA, itemB) => new Date(itemA.relevantTime).getTime() - new Date(itemB.relevantTime).getTime());

  return items;
};

const timelineInputs = (assignmentHistory: BundleEntry<Task>[], messages: Communication[]): TimelineItem[] => {
  const items = [] as TimelineItem[];
  assignmentHistory.forEach((history) => {
    if (history.resource?.meta?.lastUpdated) {
      items.push({
        id: resolveId(history.resource?.focus),
        resource: history,
        relevantTime: history.resource.meta.lastUpdated,
        itemType: 'TaskHistory',
      });
    }
  });
  messages.forEach((message) => {
    items.push({
      id: resolveId(message.encounter),
      resource: message,
      relevantTime: message.sent!,
      itemType: 'Communication',
    });
  });

  return items;
};
