import {
  Divider,
  Text,
  Stack,
  Group,
  ActionIconGroup,
  ActionIcon,
  UnstyledButton,
  Box,
  ThemeIcon,
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconArrowBackUp, IconPencil, IconTrash } from '@tabler/icons-react';
import { format } from 'date-fns';
import { CareHubNote, CareHubNoteSection } from 'imagine-dsl/utils/note';
import React from 'react';

interface RowProps {
  note: CareHubNote;
  noteIndex: number;
  notesLength: number;
  editNote: (note: CareHubNote) => void;
  deleteNote: (noteId: string) => void;
  stagedForDelete?: boolean;
  undoDelete: (noteId: string) => void;
}

export function Row({
  note,
  noteIndex,
  notesLength,
  editNote,
  deleteNote,
  stagedForDelete,
  undoDelete,
}: RowProps): JSX.Element {
  const [showFullHistory, setShowFullHistory] = React.useState(false);
  const { hovered, ref } = useHover();

  if (!note.sections) {
    return <></>;
  }

  const noteEdited = note.noteHasBeenEdited;

  return (
    <Box key={note.id} ref={ref}>
      {stagedForDelete && (
        <Group>
          <Text fs="italic">Note deleted</Text>
          <UnstyledButton onClick={() => undoDelete(note.id!)}>
            <Group gap={0}>
              <ThemeIcon color="imagine-green" variant="transparent">
                <IconArrowBackUp size={16} />
              </ThemeIcon>
              <Text td="underline" fw="bold" c="imagine-green">
                Undo
              </Text>
            </Group>
          </UnstyledButton>
        </Group>
      )}
      {!stagedForDelete && (
        <Stack gap={0} my={8}>
          {note.latestNoteSection && (
            <React.Fragment key={`${note.id}-0`}>
              <Group mih={32}>
                <Text w="80%">{note.latestNoteSection.noteText}</Text>
                {hovered && (
                  <ActionIconGroup>
                    <ActionIcon color="imagine-green" onClick={() => editNote(note)}>
                      <IconPencil size={16} />
                    </ActionIcon>
                    <ActionIcon color="status-error" onClick={() => deleteNote(note.id!)}>
                      <IconTrash size={16} />
                    </ActionIcon>
                  </ActionIconGroup>
                )}
              </Group>
              <Text size="xs" c="brand-gray.7">
                {noteEdited ? 'Edited' : 'Created'} by {note.latestNoteSection.authorReference?.display}{' '}
                {format(note.latestNoteSection!.editDateTime, 'iii MMMM d, h:mm a')}
                {noteEdited && !showFullHistory && (
                  <>
                    {' ('}
                    <UnstyledButton
                      component="span"
                      td="underline"
                      onClick={() => setShowFullHistory(!showFullHistory)}
                      size="xs"
                    >
                      History
                    </UnstyledButton>
                    {')'}
                  </>
                )}
                {showFullHistory && (
                  <Stack component="span" gap={0}>
                    {note.noteHistory.map((section: CareHubNoteSection, index: number) => (
                      <span key={`history-${note.id}-${index}`}>
                        {index === note.sections.slice(1).length - 1 ? 'Created' : 'Edited'} by{' '}
                        {section.authorReference?.display} {format(section.editDateTime!, 'iii MMMM d, h:mm a')}
                      </span>
                    ))}
                  </Stack>
                )}
              </Text>
              {notesLength - 1 !== noteIndex && <Divider my={8} />}
            </React.Fragment>
          )}
        </Stack>
      )}
    </Box>
  );
}
