import React from 'react';
import { UseFormReturnType } from '@mantine/form';
import LivingSituation from '../patientEditableFields/livingSituation/LivingSituation';
import GenderIdentity from '../patientEditableFields/genderIdentity/GenderIdentity';
import PreferredName from '../patientEditableFields/preferredName/PreferredName';
import LanguagePreference from '../patientEditableFields/languagePreference/LanguagePreference';
import Pcp from '../patientEditableFields/Pcp/Pcp';
import {
  GenderIdentity as GenderIdentityData,
  LivingSituation as LivingSituationData,
  PreferredLanguage,
  PreferredPronoun,
} from 'const-utils';
import { PatientEditModalForm } from '../helpers/EditFormInterfaces';
import { PatientPrimaryInfoFields } from '../helpers/EditFormHandler';
import { PatientAddress } from './address/Address';

interface FieldRendererProps {
  field: PatientPrimaryInfoFields;
  form: UseFormReturnType<PatientEditModalForm>;
  values: PatientEditModalForm;
}

const [livingSituationData] = LivingSituationData.compose?.include?.map((c) => c.concept?.map((d) => d.display)) ?? [];
const [genderIdentityData] = GenderIdentityData.compose?.include?.map((c) => c.concept?.map((d) => d.display)) ?? [];
const [preferredPronounData] = PreferredPronoun?.compose?.include?.map((c) => c.concept?.map((d) => d.display)) ?? [];
const preferredLanguageData =
  PreferredLanguage?.compose?.include?.flatMap(
    (c) => c.concept?.map(({ code, display }) => ({ code, display })) ?? [],
  ) ?? [];

export const FieldRenderer = ({ field, form }: FieldRendererProps): JSX.Element => {
  const fieldRenderer = {
    [PatientPrimaryInfoFields.LIVING_SITUATION]: () => (
      <LivingSituation {...form.getInputProps('livingSituation')} data={livingSituationData as string[]} />
    ),
    [PatientPrimaryInfoFields.GENDER_IDENTITY]: () => (
      <GenderIdentity
        genderData={genderIdentityData as string[]}
        preferredPronounData={preferredPronounData as string[]}
        genderIdentityProps={form.getInputProps('genderIdentity')}
        preferredPronounProps={form.getInputProps('preferredPronoun')}
      />
    ),
    [PatientPrimaryInfoFields.PREFERRED_NAME]: () => <PreferredName {...form.getInputProps('preferredName')} />,
    [PatientPrimaryInfoFields.LANGUAGE_PREFERENCE]: () => (
      <LanguagePreference
        languagePreferenceProps={form.getInputProps('languagePreference')}
        requiresInterpreterProps={form.getInputProps('requiresInterpreter')}
        preferredLanguageData={preferredLanguageData}
      />
    ),
    [PatientPrimaryInfoFields.PCP]: () => <Pcp {...form.getInputProps('pcp')} />,
    [PatientPrimaryInfoFields.ADDRESS]: () => {
      return <PatientAddress form={form} />;
    },
  };

  return fieldRenderer[field]();
};
