import React from 'react';
import { Text, Group } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';

export const SuccessMessage = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <Text size="xs" c="dimmed">
    <Group wrap="nowrap" gap="xs" align="center">
      <IconCheck color="green" size="18" />
      {children}
    </Group>
  </Text>
);
