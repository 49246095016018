import { Patient, Practitioner, Questionnaire, QuestionnaireItem, Reference, Task } from '@medplum/fhirtypes';
import { System, TaskType } from 'const-utils';
import { Maybe } from 'medplum-gql';
import { buildTask } from './task';
import { BHSurveyType, QuestionnaireType } from 'const-utils/codeSystems/ImaginePediatrics';

interface QuestionnaireTaskArgs {
  questionnaireReference: Reference<Questionnaire>;
  patientReference: Reference<Patient>;
  caregiverReference: Reference<Patient>;
  requesterReference?: Reference<Practitioner>;
  taskType: TaskType;
}

export const buildQuestionnaireTask = ({
  questionnaireReference,
  patientReference,
  caregiverReference,
  requesterReference,
  taskType,
}: QuestionnaireTaskArgs): Task => {
  return buildTask({
    status: 'requested',
    description: 'Complete screener questionnaire',
    type: taskType,
    for: patientReference,
    owner: caregiverReference,
    focus: questionnaireReference,
    authoredOn: new Date().toISOString(),
    requester: requesterReference,
  });
};

interface ICareHubQuestionnaire {
  questionnaire: Questionnaire;
}

export class CareHubQuestionnaire implements ICareHubQuestionnaire {
  questionnaire: Questionnaire;

  constructor(questionnaire: Questionnaire) {
    this.questionnaire = questionnaire;
  }

  get id(): Maybe<string> | undefined {
    return this.questionnaire.id || '';
  }

  get scoreableItems(): QuestionnaireItem[] {
    const scoreableQuestions = this.flattenedItems.filter(
      (item) => item.extension?.some((ext) => ext.url === System.QuestionnaireItemMaxTally.toString()),
    );

    return scoreableQuestions;
  }

  get type(): Maybe<QuestionnaireType> | undefined {
    const type = this.questionnaire.meta?.tag?.find((tag) => tag.system === System.QuestionnaireType)?.code;
    if (!type) {
      return undefined;
    }

    return type as QuestionnaireType;
  }

  get BHtype(): Maybe<BHSurveyType> | undefined {
    const type = this.questionnaire.meta?.tag?.find((tag) => tag.system === System.BHSurveyType)?.code;
    if (!type) {
      return undefined;
    }

    return type as BHSurveyType;
  }

  get language(): Maybe<string> | undefined {
    return this.questionnaire.meta?.tag?.find((tag) => tag.system === System.QuestionnaireLanguage)?.code;
  }

  get flattenedItems(): QuestionnaireItem[] {
    const flatItems: QuestionnaireItem[] = [];

    const flattenItems = (items: QuestionnaireItem[] | undefined) => {
      if (!items) {
        return;
      }

      items.forEach((item) => {
        flatItems.push(item);
        if (item.item) {
          flattenItems(item.item);
        }
      });
    };

    flattenItems(this.questionnaire.item);
    return flatItems;
  }
}
