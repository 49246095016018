import { UseFormReturnType } from '@mantine/form';
import { ContactCaregiverFormValues } from '../types';
import { pleaseFixErrorsAboveMessage } from '@/errors';
import { MedplumClient } from '@medplum/core';
import { ContactPoint } from 'medplum-gql';
import { Patient } from '@medplum/fhirtypes';

export const validateContactCaregiver = async (
  contactCaregiverForm: UseFormReturnType<ContactCaregiverFormValues>,
): Promise<void> => {
  contactCaregiverForm.validate();

  if (!contactCaregiverForm.isValid()) {
    throw new Error(pleaseFixErrorsAboveMessage);
  }
};

export const handleAddPatientPhoneNumber = async (
  medplum: MedplumClient,
  patientId: string,
  phoneNumber: string,
): Promise<Patient> => {
  const patientResource = await medplum.readResource('Patient', patientId);
  const telecom = patientResource.telecom || [];

  if (telecom.find((t) => t.value === phoneNumber)) {
    return patientResource;
  }

  const contactPoint: ContactPoint = { system: 'phone', use: 'home', value: phoneNumber };
  const newTelecoms = [contactPoint, ...telecom];

  return medplum.patchResource<'Patient'>('Patient', patientId, [
    {
      op: 'add',
      path: '/telecom',
      value: newTelecoms,
    },
  ]);
};
