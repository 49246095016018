import { useCallback, useEffect, useState } from 'react';
import { LocalVideoTrack, Room, VideoTrackPublication } from 'twilio-video';
import { GaussianBlurBackgroundProcessor, Pipeline } from '@twilio/video-processors';

interface Options {
  room?: Room;
  initialCameraState: boolean;
  initialBlurState: boolean;
}

interface Result {
  isCameraOn: boolean;
  toggleCamera: () => void;
  isBlurOn: boolean;
  toggleBlur: () => void;
}

export default function useCamera({ room, initialCameraState, initialBlurState }: Options): Result {
  const [isCameraOn, setIsCameraOn] = useState(initialCameraState);
  const [isBlurOn, setIsBlurOn] = useState(initialBlurState);
  const [blur] = useState<GaussianBlurBackgroundProcessor>(
    new GaussianBlurBackgroundProcessor({
      assetsPath: '/',
      pipeline: Pipeline.WebGL2,
      debounce: true,
      maskBlurRadius: 5,
      blurFilterRadius: 10,
    }),
  );

  useEffect(() => {
    if (!room) {
      setIsCameraOn(initialCameraState);
    }
  }, [room, initialCameraState]);

  useEffect(() => {
    if (!room) {
      setIsBlurOn(false);
      blur.loadModel().catch(() => {});
    }
  }, [blur, room, initialBlurState]);

  const toggleCamera = useCallback(async () => {
    if (room) {
      if (isCameraOn) {
        room.localParticipant.videoTracks.forEach(async ({ track }) => {
          if (track instanceof LocalVideoTrack) {
            // remove blur processor
            if (isBlurOn) {
              track.removeProcessor(blur);
              await track.restart();
            }
            track.disable();
            track.stop();
          }
        });
        setIsCameraOn(false);
      } else {
        await Promise.all(
          Array.from(room.localParticipant.videoTracks.values()).map(({ track }) => {
            if (track instanceof LocalVideoTrack) {
              track.enable();
              if (isBlurOn) {
                return track.restart().then(() =>
                  track.addProcessor(blur, {
                    inputFrameBufferType: 'video',
                    outputFrameBufferContextType: 'webgl2',
                  }),
                );
              }
              return track.restart();
            }
            return Promise.resolve();
          }),
        );
        setIsCameraOn(true);
      }
    } else {
      setIsCameraOn(initialCameraState);
    }
  }, [room, isCameraOn, isBlurOn, blur, initialCameraState]);

  const toggleBlur = useCallback(async () => {
    if (room) {
      if (isBlurOn) {
        // turn off
        room.localParticipant.videoTracks.forEach((publication: VideoTrackPublication) => {
          (publication?.track as LocalVideoTrack).removeProcessor(blur);
        });

        setIsBlurOn(false);
      } else {
        // turn on
        room.localParticipant.videoTracks.forEach((publication: VideoTrackPublication) => {
          //set blur effect
          (publication?.track as LocalVideoTrack).addProcessor(blur, {
            inputFrameBufferType: 'video',
            outputFrameBufferContextType: 'webgl2',
          });
        });

        setIsBlurOn(true);
      }
    } else {
      setIsBlurOn(initialBlurState);
    }
  }, [room, isBlurOn, blur, initialBlurState]);

  return { isBlurOn, isCameraOn, toggleBlur, toggleCamera };
}
