import React, { useMemo } from 'react';
import { Table, Text, LoadingOverlay, Badge, Group } from '@mantine/core';
import { Patient, Practitioner } from '@medplum/fhirtypes';
import { HumanNameDisplay } from '@medplum/react';
import { SearchFilters } from '@/pages/OutreachTask/Page';
import { TasksDrawer } from '../drawer';
import { formatAge } from 'imagine-dsl/utils/strings';
import { useOutreachTaskAlertContext } from '@/pages/OutreachTask/OutreachTaskAlertProvider';
import { format } from 'date-fns';
import { ClusterBadge } from '../../ClusterBadge';
import { useGetCarePathway } from '@/hooks/useGetCarePathway';
import { IconAlertTriangle, IconPointFilled } from '@tabler/icons-react';
import { PriorityBadge } from '../PriorityBadge';
import { EMPTY_DATA_PLACEHOLDER } from '@/constants';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';

const TaskRow = ({
  task,
  openTaskDrawer,
}: {
  task: BaseTask;
  openTaskDrawer: (task: BaseTask) => void;
}): JSX.Element => {
  const patient = task?.for as Patient;
  const assignedTo = task?.owner as Practitioner;
  const { carePathway, cluster } = useGetCarePathway({ patientId: patient?.id || '' });

  return (
    <Table.Tr onClick={() => openTaskDrawer(task)} key={task?.id} style={{ cursor: 'pointer' }}>
      <Table.Td>
        <Text size="lg">
          <HumanNameDisplay value={patient?.name?.[0]} />
        </Text>
        {patient.birthDate && (
          <Group gap={'2'}>
            <Text c="gray">{patient.managingOrganization?.resource?.name ?? EMPTY_DATA_PLACEHOLDER}</Text>
            <IconPointFilled size={14} color="gray" />
            <Text c="gray">
              {formatAge(patient.birthDate, { emptyText: EMPTY_DATA_PLACEHOLDER, abbreviate: true })}
            </Text>
            <IconPointFilled size={14} color="gray" />
            <ClusterBadge
              carePathway={carePathway}
              cluster={cluster || 'Unknown'}
              iconOnly
              hideSubtext
              condensedDisplay
            />
          </Group>
        )}
      </Table.Td>
      <Table.Td>
        <Text size="lg">{task.outreachTaskType?.display}</Text>
      </Table.Td>
      <Table.Td>{task.priority && <PriorityBadge priority={task.priority} />}</Table.Td>
      <Table.Td>
        {task.dueDate ? format(task.dueDate, 'Pp') : EMPTY_DATA_PLACEHOLDER}
        {task.isOverdue && (
          <Group align="center">
            <IconAlertTriangle color="orange" size={20} />
            <Text fw={'bold'} size="lg">
              Overdue
            </Text>
          </Group>
        )}
      </Table.Td>
      <Table.Td>
        {!assignedTo ? (
          <Badge color="indigo" variant="filled">
            Unassigned
          </Badge>
        ) : (
          <HumanNameDisplay value={assignedTo?.name?.[0]} />
        )}
      </Table.Td>
    </Table.Tr>
  );
};

export const TasksTable = ({
  searchFilters,
  selectedTaskId,
  setSelectedTaskId,
}: {
  searchFilters: SearchFilters;
  selectedTaskId: string | undefined;
  setSelectedTaskId: React.Dispatch<React.SetStateAction<string | undefined>>;
}): JSX.Element => {
  const { loading, error, refetchTasks, tasks } = useOutreachTaskAlertContext();

  const filteredTasks = useMemo(() => {
    return tasks?.filter(
      (task) =>
        (!searchFilters?.patientId || (task?.for as Patient)?.id === searchFilters?.patientId) &&
        (!searchFilters?.assignedTo || (task?.owner as Practitioner)?.id === searchFilters?.assignedTo) &&
        (!searchFilters?.taskType || task?.outreachTaskType?.code === searchFilters?.taskType) &&
        (!searchFilters?.market ||
          (task.for as Patient).managingOrganization?.resource?.id === searchFilters?.market) &&
        (!searchFilters?.engagementPod || task?.pod?.id === searchFilters?.engagementPod),
    );
  }, [tasks, searchFilters]);

  const selectedTask = useMemo(() => tasks?.find((task) => task?.id === selectedTaskId), [selectedTaskId, tasks]);

  const openTaskDrawer = (task: BaseTask): void => {
    setSelectedTaskId(task.id!);
  };

  if (error) {
    return (
      <Text size="xl" fw={700} c="imagine-green" mt="lg" ml="lg">
        Error fetching tasks
      </Text>
    );
  }

  const onCloseHandler = () => {
    setSelectedTaskId(undefined);
  };

  return (
    <>
      <TasksDrawer
        withViewPatientProfile
        task={selectedTask}
        onClose={onCloseHandler}
        opened={!!selectedTask}
        refetchTasks={refetchTasks}
      />
      <Table highlightOnHover horizontalSpacing="sm" verticalSpacing="sm">
        <Table.Thead>
          <Table.Tr>
            <Table.Th miw={250}>Patient</Table.Th>
            <Table.Th>Task</Table.Th>
            <Table.Th>Priority</Table.Th>
            <Table.Th>Due Date</Table.Th>
            <Table.Th>Assigned to</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {loading ? (
            <tr>
              <td colSpan={3}>
                <LoadingOverlay visible zIndex={1000} overlayProps={{ radius: 'sm', blur: 2 }} />
              </td>
            </tr>
          ) : (
            filteredTasks
              ?.filter((task) => !!task)
              .map((task) => <TaskRow key={task?.id} task={task} openTaskDrawer={openTaskDrawer} />)
          )}
          {filteredTasks?.length === 0 && (
            <Table.Tr>
              <Table.Td colSpan={3}>
                <Text size="xl" fw={700} c="imagine-green" mt="lg" ml="lg">
                  No tasks found.
                </Text>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </>
  );
};
