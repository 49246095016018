import React, { useEffect, useMemo, useState } from 'react';
import { ActionIcon, Collapse, Group, Stack, Tooltip } from '@mantine/core';
import { IconArrowsMinimize, IconCircleChevronDown, IconCircleChevronUp } from '@tabler/icons-react';
import { Participant } from 'twilio-video';
import { ParticipantVideo } from './participants/ParticipantVideo';
import DurationDisplay from './DurationDisplay';
import { createImageFromName } from './placeholder/Placeholder';

import classes from './VideoFullScreen.module.css';
import VideoControls from './participants/VideoControls';
import { useVideo } from './VideoProvider';
import { useDisclosure } from '@mantine/hooks';

interface FullScreenOverlayProps {
  children: React.ReactNode;
  opened: boolean;
}

const FullScreenOverlay = ({ children, opened }: FullScreenOverlayProps): JSX.Element => {
  if (!opened) {
    return <></>;
  }
  return <div className={classes.fullscreenOverlay}>{children}</div>;
};

interface SpotlightVideoProps {
  participant: Participant;
}

function cleanIdentity(identity: string): string {
  return identity.split(' session-id:')[0];
}

const SpotlightVideo = ({ participant }: SpotlightVideoProps): JSX.Element => {
  return (
    <div data-testid="spotlight-video" className={classes.spotlightVideo}>
      <div>
        <ParticipantVideo participant={participant}>
          <div className={classes.spotlightVideoPlaceholder}>
            <img
              style={{ borderRadius: 400, maxWidth: '100px', height: '100px' }}
              id="preview"
              src={createImageFromName(300, cleanIdentity(participant.identity))}
              alt="profile-pic"
            />
          </div>
        </ParticipantVideo>
        <div className={classes.spotlightOverlay}>
          <span>{cleanIdentity(participant.identity)}</span>
        </div>
      </div>
    </div>
  );
};

interface GalleryVideoProps {
  remoteParticipants: Participant[];
  localParticipant?: Participant;
  setSpotlightParticipant: (participant: Participant) => void;
  excludeLocal: boolean;
}

const GalleryVideo = ({
  remoteParticipants,
  localParticipant,
  setSpotlightParticipant,
  excludeLocal,
}: GalleryVideoProps): JSX.Element => {
  return (
    <Group wrap="nowrap" data-testid="participant-gallery" justify="center" className={classes.gallery}>
      {remoteParticipants.map((p) => (
        <div
          className={classes.galleryVideo + ' ' + classes.galleryVideoHover}
          onClick={() => {
            setSpotlightParticipant(p);
          }}
        >
          <ParticipantVideo participant={p}>
            <div className={classes.galleryVideoPlaceholder}>
              <img
                style={{ borderRadius: 100 }}
                id="preview"
                src={createImageFromName(300, cleanIdentity(p.identity))}
                alt="profile-pic"
              />
            </div>
          </ParticipantVideo>
          <div className={classes.galleryVideoOverlay}>
            <span>{cleanIdentity(p.identity)}</span>
          </div>
        </div>
      ))}
      {localParticipant && !excludeLocal && (
        <div className={` ${classes.galleryVideo} ${classes.galleryVideoLocal} ${excludeLocal ? classes.hidden : ''}`}>
          <ParticipantVideo participant={localParticipant}>
            <div className={classes.galleryVideoPlaceholder}>
              <img
                style={{ borderRadius: 100 }}
                id="preview"
                src={createImageFromName(300, cleanIdentity(localParticipant.identity))}
                alt="profile-pic"
              />
            </div>
          </ParticipantVideo>
          <div className={classes.galleryVideoOverlay}>
            <span>{cleanIdentity(localParticipant.identity)} (Me)</span>
          </div>
        </div>
      )}
    </Group>
  );
};

interface VideoFullScreenProps {
  opened: boolean;
  close: () => void;
}

const VideoFullScreen = ({ opened, close }: VideoFullScreenProps): JSX.Element => {
  const {
    isBlurOn,
    isCameraOn,
    isMicrophoneOn,
    remoteParticipants,
    localParticipant,
    callStartTime,
    toggleBlur,
    toggleCamera,
    toggleMicrophone,
    endCall,
    currentEncounter,
    mainParticipant,
    setMainParticipant,
  } = useVideo();

  const endAndClose = (): void => {
    close();
    endCall();
  };

  const galleryParticipants = useMemo(() => {
    return remoteParticipants.filter((p) => p !== mainParticipant);
  }, [remoteParticipants, mainParticipant]);

  const [localIsMain, setLocalIsMain] = useState<boolean>(true);
  useEffect(() => {
    setLocalIsMain(localParticipant === mainParticipant);
  }, [localParticipant, mainParticipant]);

  const [galleryOpened, galleryHandlers] = useDisclosure(true);

  return (
    <FullScreenOverlay opened={opened}>
      <Stack justify="space-between" style={{ height: '100%', gap: 'auto' }} align="center" gap="xl">
        <Group style={{ width: '100%', padding: '1rem' }} justify="space-between" align="stretch">
          <span>Video call with {currentEncounter?.subject?.display}</span>
          <ActionIcon variant="transparent" onClick={close}>
            <IconArrowsMinimize color="white" />
          </ActionIcon>
        </Group>
        {mainParticipant && <SpotlightVideo participant={mainParticipant} />}
        <Collapse in={galleryOpened}>
          <GalleryVideo
            remoteParticipants={galleryParticipants}
            localParticipant={localParticipant}
            setSpotlightParticipant={setMainParticipant}
            excludeLocal={localIsMain}
          />
        </Collapse>
        <Group align="center" justify="space-between" bg="imagine-gray.9" p="1rem" pos="sticky" bottom={0} w="100%">
          <div style={{ width: '4em' }}>
            <DurationDisplay start={callStartTime || new Date()} />
          </div>
          <VideoControls
            isBlurOn={isBlurOn}
            isCameraOn={isCameraOn}
            isMicrophoneOn={isMicrophoneOn}
            toggleBlur={toggleBlur}
            toggleCamera={toggleCamera}
            toggleMicrophone={toggleMicrophone}
            endCall={endAndClose}
          />
          <Tooltip label="Collapse/Show the gallery.">
            <ActionIcon variant="transparent" onClick={galleryHandlers.toggle}>
              {galleryOpened ? <IconCircleChevronDown color="white" /> : <IconCircleChevronUp color="white" />}
            </ActionIcon>
          </Tooltip>
        </Group>
      </Stack>
    </FullScreenOverlay>
  );
};

export default VideoFullScreen;
