import React from 'react';
import { Button } from '@mantine/core';
import { FieldRenderer } from '../patientEditableFields/FieldRenderer';
import { PatientPrimaryInfoFields } from '../helpers/EditFormHandler';
import { usePatientProfileEditContext } from './PatientProfileEditProvider';

interface PatientProfileEditModalProps {
  title?: string;
  field: PatientPrimaryInfoFields;
  patientId: string;
  onDataChanged?: () => void;
  closeModal: () => void;
}

const PatientProfileEditModal = ({ field, closeModal }: PatientProfileEditModalProps): JSX.Element => {
  const { loading, form, onSaveHandler, buttonDisabled } = usePatientProfileEditContext();

  return (
    <form onSubmit={onSaveHandler}>
      <FieldRenderer field={field} form={form} values={form.values} />

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outline"
          color="imagine-green"
          radius="md"
          onClick={() => {
            closeModal();
          }}
          style={{ marginRight: '10px' }}
        >
          Cancel
        </Button>
        <Button type="submit" color="imagine-green" radius="md" disabled={buttonDisabled} loading={loading}>
          Save changes
        </Button>
      </div>
    </form>
  );
};

export default PatientProfileEditModal;
