import { MedplumClient } from '@medplum/core';
import { System } from 'const-utils';

const livingSituationDataRetention = async (
  value: string,
  patientId: string,
  medplum: MedplumClient,
): Promise<void> => {
  const refreshedPatient = await medplum.readResource('Patient', patientId);
  const filteredExtensions =
    refreshedPatient?.extension?.filter((ext) => {
      return ext.url !== System.LivingArrangement.toString();
    }) ?? [];
  const updatedExtensions = [
    ...filteredExtensions,
    {
      url: System.LivingArrangement,
      valueCode: value,
    },
  ];
  const updatedPatient = { ...refreshedPatient, extension: updatedExtensions };
  await medplum.updateResource(updatedPatient);
};

export default livingSituationDataRetention;
