import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Container,
  Drawer,
  Grid,
  Group,
  Loader,
  Paper,
  ScrollArea,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core';
import { TaskDrawerContentRenderer } from '..';
import { TaskDrawerHeader } from '../TaskDrawerHeader';
import { Encounter } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { logError } from '@/errors';
import { useGetPatientAdtEncountersQuery, useGetSubTasksQuery } from 'medplum-gql';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { isDefined } from 'imagine-dsl/utils/lists';
import { IconBell } from '@tabler/icons-react';
import { AssigneeSection } from '@/components/chat/footer/AssigneeSection';
import { useUserSession } from '@/components/shared/UserSessionContext';
import { bhTOCTaskResolveReasonOptions, TaskType } from 'const-utils/codeSystems/ImaginePediatrics';
import { ResolveTaskButton } from '../../ResolveTaskButton';
import { System } from 'const-utils';
import SubTaskSection from '../carePathwayReferralReview/SubTaskSection';
import { getName } from 'imagine-dsl/utils/patient';

export const BHTOCDrawerContent: TaskDrawerContentRenderer = ({
  task,
  withViewPatientProfile,
  patientId,
  refetchTasks,
  closeDrawer,
}) => {
  const { profile } = useUserSession();
  const adtEncountersQuery = useADTEncountersForTaskQuery(task, patientId);
  const subTasksQuery = useGetSubTasksQuery({ variables: { partOf: `Task/${task?.id}` } });
  const subTasks = useMemo(() => {
    return (subTasksQuery.data?.TaskList ?? []).filter(isDefined).map((t) => new BaseTask(t));
  }, [subTasksQuery.data]);

  const onCloseDrawerHandler = async () => {
    await refetchTasks().catch((e) => logError(e));
    closeDrawer();
  };

  if (!patientId || !task) {
    return (
      <Drawer.Content>
        <Drawer.Body>
          <Loader />
        </Drawer.Body>
      </Drawer.Content>
    );
  }

  const encounters = (adtEncountersQuery.data?.EncounterList ?? []).filter(isDefined);
  const isTaskActionable = (task?.status !== 'completed' && task?.status !== 'cancelled') || !task?.isResolved;

  const taskTypes = [
    {
      label: 'Schedule Appointment BH TOC',
      value: TaskType.ScheduleAppointmentBHTOC,
    },
  ];

  const assignee = task.owner;

  return (
    <Drawer.Content>
      <Drawer.Header h={3}>
        <Group ml="auto">
          <Drawer.CloseButton />
        </Group>
      </Drawer.Header>
      <Drawer.Body display="flex" h="calc(100% - 60px)" style={{ flexDirection: 'column' }}>
        <TaskDrawerHeader patientId={patientId} withViewPatientProfile={withViewPatientProfile} />
        <ScrollArea>
          {adtEncountersQuery.loading ? (
            <Loader />
          ) : (
            <Stack h="100%">
              <Box p="md" flex="1">
                <Group gap="sm">
                  <IconBell />
                  <Title order={2} size={18}>
                    ADT alerts ({encounters.length})
                  </Title>
                </Group>
                <Paper my="md" withBorder>
                  <Table>
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th>Class</Table.Th>
                        <Table.Th>Admit date</Table.Th>
                        <Table.Th>Discharge date</Table.Th>
                        <Table.Th>Location</Table.Th>
                        <Table.Th>Diagnosis</Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {encounters.map((encounter) => {
                        const location = encounter.location?.at(0)?.location.display;
                        const encounterClass = encounter.class.display;
                        const start = encounter.period?.start;
                        const end = encounter.period?.end;

                        return (
                          <Table.Tr key={encounter.id}>
                            <Table.Td>
                              <Text fw="bold">BH TOC{encounterClass && ` - ${encounterClass}`}</Text>
                            </Table.Td>
                            <Table.Td>
                              <Text fs={start ? undefined : 'italic'} c={start ? undefined : 'dimmed'}>
                                {start ?? 'Missing'}
                              </Text>
                            </Table.Td>
                            <Table.Td>
                              <Text fs={end ? undefined : 'italic'} c={end ? undefined : 'dimmed'}>
                                {end ?? 'Missing'}
                              </Text>
                            </Table.Td>
                            <Table.Td>
                              <Text fs={location ? undefined : 'italic'} c={location ? undefined : 'dimmed'}>
                                {location ?? 'Missing'}
                              </Text>
                            </Table.Td>
                            <Table.Td>
                              {encounter.reasonCode?.map((reason) => (
                                <Text key={`${encounter.id}-${reason.coding?.[0].code}`}>
                                  {reason.coding?.[0].code} - {reason.coding?.[0].display}
                                </Text>
                              ))}
                            </Table.Td>
                          </Table.Tr>
                        );
                      })}
                    </Table.Tbody>
                  </Table>
                </Paper>
                <SubTaskSection
                  task={task}
                  patientId={patientId}
                  taskTypes={taskTypes}
                  subTasksQuery={subTasksQuery}
                  subTasks={subTasks}
                />
              </Box>
            </Stack>
          )}
        </ScrollArea>
        {isTaskActionable && (
          <Container pt={16} pb={24} mx={10}>
            <Grid justify="space-between">
              <Stack style={{ gap: 0 }}>
                <AssigneeSection
                  taskMarket={task.market!}
                  assigneeId={assignee?.id}
                  assigneeName={getName(assignee)}
                  taskId={task.id!}
                  disabled={task.completed}
                  refreshTasks={refetchTasks}
                  profile={profile}
                />
              </Stack>
              <ResolveTaskButton
                taskId={task.id!}
                callback={onCloseDrawerHandler}
                reasons={{
                  system: System.BHTOCTaskResolveReason,
                  options: bhTOCTaskResolveReasonOptions,
                }}
              />
            </Grid>
          </Container>
        )}
      </Drawer.Body>
    </Drawer.Content>
  );
};

const useADTEncountersForTaskQuery = (task: BaseTask | undefined, patientId: string | null | undefined) => {
  const medplum = useMedplum();
  const [focusEncounter, setFocusEncounter] = useState<Encounter | undefined>(undefined);
  const focusEncounterRef = task?.task.focus?.reference;
  const encounterDate = useMemo(() => {
    if (!focusEncounter) {
      return undefined;
    }
    const start = focusEncounter.period?.start;
    const end = focusEncounter.period?.end;
    if (!start && !end) {
      return new Date();
    }

    return new Date(start || end!);
  }, [focusEncounter]);
  const result = useGetPatientAdtEncountersQuery({
    variables: {
      patientRef: `Patient/${patientId}`,
      filter: encounterDate && `date ge ${encounterDate.toISOString()}`,
    },
    skip: !focusEncounter,
  });

  useEffect(() => {
    if (!focusEncounterRef) {
      return;
    }
    const [resourceType, resourceId] = focusEncounterRef.split('/');
    if (!resourceType || !resourceId || resourceType !== 'Encounter') {
      return;
    }

    medplum
      .readResource(resourceType, resourceId)
      .then(setFocusEncounter)
      .catch((e) => logError(e));
  }, [focusEncounterRef, medplum]);

  return result;
};
