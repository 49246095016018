import React from 'react';
import { getConfig } from '@/config';
import { api } from '@/utils/trpc';
import { Button } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import { useMedplum } from '@medplum/react';

const config = getConfig();

interface ResetDemoButtonProps {
  patientId: string;
  onSuccess?: () => void;
}

export function ResetDemoButton(props: ResetDemoButtonProps) {
  const reset = api.demo.reset.useMutation({ onSuccess: props.onSuccess });

  const medplum = useMedplum();

  if (!medplum.isProjectAdmin()) {
    return null;
  }

  if (config.appEnv !== 'stage') {
    return null;
  }

  if (config.demoPatient !== props.patientId) {
    return null;
  }

  return (
    <Button variant="subtle" leftSection={<IconRefresh />} disabled={reset.isLoading} onClick={() => reset.mutate()}>
      Reset for Demo
    </Button>
  );
}
