import React, { PropsWithChildren } from 'react';
import { Anchor, Group } from '@mantine/core';

interface PatientProfileAlertProps extends PropsWithChildren {
  callToAction: string;
  onClick: () => void;
  icon: React.ReactNode;
}

export function PatientProfileAlert({ onClick, icon, children, callToAction }: PatientProfileAlertProps): JSX.Element {
  return (
    <Group
      bg="status-info.0"
      p={10}
      mb={20}
      bd="1px solid status-info.8"
      style={{
        borderRadius: 10,
      }}
    >
      {icon}
      {children}
      <Anchor href="#" underline="always" fw={700} onClick={onClick}>
        {callToAction}
      </Anchor>
    </Group>
  );
}
