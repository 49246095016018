import React from 'react';
import { Patient, useGetPatientQuery } from 'medplum-gql';
import { useParams } from 'react-router-dom';
import { OutreachFlow } from './Flow';
import { Container, Group, Loader, Stack } from '@mantine/core';
import { OutreachProvider } from './Context';
import Footer from './Footer';
import OutreachSidebar from '@/components/outreach/OutreachSidebar';
import { MaybeExistingContactProvider } from '@/components/MaybeExistingContactForm/MaybeExistingContactContext';
import { isValidPatient } from '@/utils/queryTypes';

export const OutreachPage = (): JSX.Element => {
  const { id } = useParams();
  const { data, refetch } = useGetPatientQuery({ errorPolicy: 'all', skip: !id, variables: { id: id || 'skipped' } });

  const patient = data?.Patient;

  if (!isValidPatient(patient)) {
    return <Loader data-testid="loader" />;
  }

  return (
    <MaybeExistingContactProvider>
      <OutreachProvider patient={patient as Patient} refreshPatient={refetch}>
        <Group
          gap={0}
          h="100%"
          w="100%"
          justify="space-between"
          align="start"
          wrap="nowrap"
          style={{ minHeight: '100dvh' }}
        >
          <Stack justify="space-between" style={{ flexGrow: 1, minHeight: '100dvh' }}>
            <Container size="xl" w="100%" h="100%">
              <OutreachFlow />
            </Container>
            <Footer />
          </Stack>
        </Group>

        <Group pos="sticky" mih="100vh" maw={700} miw={500}>
          <OutreachSidebar patient={patient} />
        </Group>
      </OutreachProvider>
    </MaybeExistingContactProvider>
  );
};

export default OutreachPage;
