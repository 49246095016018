import { Button, Stack, Title, Group, Alert, Center } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { Loading, useMedplum, useMedplumProfile } from '@medplum/react';
import React, { useEffect } from 'react';
import { getConfig } from '../config';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { localStorageKeys, sessionStorageKeys } from '@/localAndSessionStorage';
import { setActive } from '@/hooks/useActivityTracking';
import { logError } from '@/errors';
import { showNotification } from '@mantine/notifications';
import { CareHubLogo } from '@/components/CareHubLogo';

export function LandingPage(): JSX.Element {
  const config = getConfig();
  const medplum = useMedplum();
  const profile = useMedplumProfile();
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const code = params.get('code');
  const inactive = !!localStorage.getItem(localStorageKeys.inactiveAlert);

  useEffect(() => {
    if (!code || localStorage.getItem(localStorageKeys.processingCode)) {
      return;
    }
    localStorage.setItem(localStorageKeys.processingCode, '1');

    // Process the code
    // On success, remove the query string parameters
    medplum
      .processCode(code)
      .then(({ id }) => {
        setActive(id);
        navigate(sessionStorage.getItem(sessionStorageKeys.redirectTo()) ?? '/');
      })
      .catch((e) => {
        logError(e);
        showNotification({
          title: 'Sign In Error',
          message:
            'An error was encountered. If this continues, please try clearing your cache & cookies and refreshing.',
          color: 'status-error',
          autoClose: false,
        });
      });
  }, [code, medplum, navigate]);

  const signInClick = async (): Promise<void> => {
    localStorage.removeItem(localStorageKeys.processingCode);
    localStorage.removeItem(localStorageKeys.inactiveAlert);
    const search = new URLSearchParams(window.location.search);
    search.delete('code');

    const redirectTo = search.get('redirectTo');
    if (redirectTo) {
      sessionStorage.setItem(sessionStorageKeys.redirectTo(), decodeURIComponent(redirectTo));
    } else {
      sessionStorage.removeItem(sessionStorageKeys.redirectTo());
    }

    await medplum.signInWithExternalAuth(
      config.externalAuthorizeUrl,
      config.externalClientId,
      `${config.baseUrl}auth/external`,
      {
        projectId: config.projectId,
        clientId: config.clientId,
        redirectUri: config.webAppRedirectUri,
      },
      false, //don't use PKCE - azure sso won't work with PKCE enabled and using medplum
    );
  };

  if (profile) {
    return (
      <Navigate
        to={
          sessionStorage.getItem(sessionStorageKeys.redirectTo()) ??
          (decodeURIComponent(params.get('redirectTo') ?? '') || '/')
        }
      />
    );
  }

  if (medplum.isLoading()) {
    return <Loading />;
  }

  return (
    <Center h="100%">
      <Stack align="center" py="xl" my="xl">
        <Title order={1} fz={40}>
          <Group>
            <CareHubLogo size={50} />
            <Title size="lg" fw="normal">
              Care Hub
            </Title>
          </Group>
        </Title>
        <Button onClick={signInClick} size="lg" radius="xl">
          Sign in with SSO
        </Button>
        {inactive && (
          <Alert variant="light" color="blue" title="Inactivity timeout" mt={20} icon={<IconInfoCircle />}>
            You were automatically logged out due to inactivity
          </Alert>
        )}
      </Stack>
    </Center>
  );
}
