import { Modal, Text } from '@mantine/core';
import React from 'react';

interface AttachmentPreviewModalProps {
  contentType: string | undefined;
  url: string;
  title: string;
  onClose: () => void;
}

const AttachmentPreviewModal = ({ contentType, url, title, onClose }: AttachmentPreviewModalProps): JSX.Element => {
  return (
    <Modal
      title={
        <Text size="sm" fw={700} c="imagine-green">
          {title}
        </Text>
      }
      size="xl"
      onClose={onClose}
      opened
    >
      {contentType?.startsWith('image/') && <img data-testid="attachment-image" width="100%" src={url} alt={title} />}
    </Modal>
  );
};

export default AttachmentPreviewModal;
