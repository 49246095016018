import { imagineClient } from '@/hooks/useImagineApolloClient';
import { logError } from '@/errors';
import { notifications } from '@mantine/notifications';
import { useReactivateCaregiverAccessMutation } from 'imagine-gql/client';
import { useCallback } from 'react';

type Reactivate = () => Promise<void>;

export const useReactivateCaregiver = (caregiverId: string, reason: string): Reactivate => {
  const [reactivateMutation] = useReactivateCaregiverAccessMutation({
    client: imagineClient,
    onCompleted: () => {
      notifications.show({ message: 'Caregiver has been reactivated', color: 'status-success' });
    },
    onError: (error) => {
      notifications.show({ message: 'Error reactivating caregiver', color: 'status-error' });
      logError(error);
    },
  });

  const reactivate = useCallback(async () => {
    await reactivateMutation({
      variables: {
        request: {
          caregiverId,
          reason,
        },
      },
    });
  }, [reactivateMutation, caregiverId, reason]);

  return reactivate;
};
