import React from 'react';
import { Text, ActionIcon, Group } from '@mantine/core';
import { formatAddress } from '@medplum/core';
import { Address } from '@medplum/fhirtypes';
import { IconCopy, IconHome } from '@tabler/icons-react';
import { System } from 'const-utils';
import { GetPatientQuery } from 'medplum-gql';
import { copyToClipboardHandler } from './clipboard';

interface AddressDisplayProps {
  patient: GetPatientQuery['Patient'];
}

export function AddressDisplay({ patient }: AddressDisplayProps): JSX.Element {
  const patientAddress = patient?.address?.find(
    (address) =>
      address.extension?.find((ext) => ext.url === System.AddressType.toString() && ext.valueCode === 'home'),
  );

  const formattedAddress = patientAddress && formatAddress(patientAddress as Address);

  return (
    <Group gap="xs">
      <IconHome size={20} />
      <Text>{formattedAddress ?? '--'}</Text>
      <ActionIcon
        variant="transparent"
        disabled={!formattedAddress}
        onClick={() => copyToClipboardHandler(formattedAddress)}
        color="brand-gray.7"
      >
        <IconCopy size="16" />
      </ActionIcon>
    </Group>
  );
}
