import React, { useState } from 'react';
import {
  IconChevronDown,
  IconPhoneCalling,
  IconVideo,
  IconArrowsShuffle,
  IconMessage,
  IconChecklist,
} from '@tabler/icons-react';
import { Button, Menu } from '@mantine/core';
import { SaveTouchpointModal } from '../outreach/SaveTouchpointModal';
import { useDisclosure } from '@mantine/hooks';
import { engagementRoles, techSupportRoles, useHasRole } from '@/hooks/useHasRole';
import { useMedplum } from '@medplum/react';
import UpsertTaskModal from '../shared/UpsertTaskModal';

interface PatientActionsProps {
  withOutreachButton?: boolean;
  withTouchpointButton?: boolean;
  withVideoCallButton?: boolean;
  withMessagesButton?: boolean;
  outreachHandler?: () => void;
  videoHandler?: () => void;
  messagesHandler?: () => void;
  patientId: string;
  activeCall?: boolean;
}

const PatientActions = ({
  withOutreachButton,
  withTouchpointButton,
  withVideoCallButton,
  withMessagesButton,
  outreachHandler,
  videoHandler,
  messagesHandler,
  patientId,
  activeCall = false,
}: PatientActionsProps): JSX.Element => {
  const medplum = useMedplum();

  const [opened, setOpened] = useState(false);

  const [openTouchpointModal, handleTouchpointModal] = useDisclosure();
  const [openTaskModal, handleTaskModal] = useDisclosure();
  const canCreateTask = useHasRole([...techSupportRoles, ...engagementRoles]) || medplum.isProjectAdmin();
  const shouldDisplayCreateTask = canCreateTask;

  const fromChatDrawer = !withOutreachButton && !withTouchpointButton && !withMessagesButton;

  const closeTouchPointModal = async (): Promise<void> => {
    handleTouchpointModal.close();
  };
  const closeTaskModal = async (): Promise<void> => {
    handleTaskModal.close();
  };

  const handleItemClick = (handler: () => void): void => {
    handler();
    setOpened(false);
  };

  return fromChatDrawer ? (
    <Button
      variant="outline"
      leftSection={<IconVideo size={20} />}
      onClick={() => videoHandler?.()}
      disabled={activeCall}
    >
      Video
    </Button>
  ) : (
    <>
      <Menu
        opened={opened}
        onClose={() => setOpened(false)}
        width={250}
        position="bottom-start"
        withArrow
        shadow="md"
        radius="md"
      >
        <Menu.Target>
          <Button variant="filled" color="imagine-green" onClick={() => setOpened(!opened)}>
            Actions
            <IconChevronDown size={22} color="#fff" />
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {withOutreachButton && (
            <Menu.Item
              leftSection={<IconPhoneCalling size={20} />}
              onClick={() => handleItemClick(() => outreachHandler?.())}
              color="imagine-green"
            >
              Conduct Outreach
            </Menu.Item>
          )}
          {withTouchpointButton && (
            <Menu.Item
              leftSection={<IconArrowsShuffle size={20} />}
              onClick={() => handleItemClick(() => handleTouchpointModal.open())}
              color="imagine-green"
            >
              Add Touchpoint
            </Menu.Item>
          )}

          {withMessagesButton && (
            <Menu.Item
              leftSection={<IconMessage size={20} />}
              onClick={() => handleItemClick(() => messagesHandler?.())}
              color="imagine-green"
            >
              Messages
            </Menu.Item>
          )}
          {withVideoCallButton && (
            <Menu.Item
              leftSection={<IconVideo size={20} />}
              onClick={() => videoHandler?.()}
              color="imagine-green"
              disabled={activeCall}
            >
              Video Call
            </Menu.Item>
          )}
          {shouldDisplayCreateTask && (
            <Menu.Item
              leftSection={<IconChecklist size={20} />}
              onClick={() => handleTaskModal.open()}
              color="imagine-green"
            >
              Create Task
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
      {openTouchpointModal && (
        <SaveTouchpointModal
          patientId={patientId}
          submitLabel="Add Touchpoint"
          opened={openTouchpointModal}
          closeModal={closeTouchPointModal}
        />
      )}
      {openTaskModal && (
        <UpsertTaskModal patientId={patientId} opened={openTaskModal} closeModal={closeTaskModal} operation="Create" />
      )}
    </>
  );
};

export default PatientActions;
