export enum TaskType {
  Document = 'document',
  Chat = 'chat',
  Screener = 'screener',
  BHSurvey = 'bh-survey',
  ScreenerReview = 'screener-review',
  CarePathwayReferralReview = 'care-pathway-referral-review',
  ReviewBHSurvey = 'review-bh-survey',
  SuicideRisk = 'suicide-alert',
  AppointmentRequest = 'appointment-request',
  Callback = 'callback',
  CallPartneringProvider = 'call-partnering-provider',
  FirstCall = 'first-call',
  InformationRequest = 'information-request',
  InformationRequestVerification = 'information-request-verification',
  NetworkEngagement = 'network-engagement',
  OutreachFollowUp = 'outreach-follow-up',
  ScheduleAppointmentBHTOC = 'schedule-appointment-bh-toc',
  BHTOC = 'bh-toc',
  ScheduleVisitsForCarePathwayChange = 'schedule-visits-for-care-pathway-change',
  CancelAllLongitudinalAppointments = 'cancel-all-longitudinal-appointments',
}

export const taskTypeMap: Record<
  TaskType,
  { display: string; definition?: string; allowManualCreation?: boolean; isOutreach?: boolean }
> = {
  [TaskType.Document]: { display: 'Document' },
  [TaskType.Chat]: { display: 'Incoming Chat', definition: 'Review incoming chat messages' },
  [TaskType.Screener]: { display: 'Screener' },
  [TaskType.BHSurvey]: { display: 'BH Survey' },
  [TaskType.ScreenerReview]: { display: 'Review PRAPARE Survey', definition: 'Review responses to a PRAPARE survey' },
  [TaskType.CarePathwayReferralReview]: {
    display: 'Care Pathway Referral Review',
    definition: 'Review Care Pathway Referral',
  },
  [TaskType.ReviewBHSurvey]: { display: 'Review BH Survey', definition: 'Review responses to a BH survey' },
  [TaskType.SuicideRisk]: {
    display: 'Suicide Risk Alert',
    definition: 'BH Survey results have determined a suicide-risk',
  },
  [TaskType.AppointmentRequest]: {
    display: 'Appointment Request',
    definition: 'A request to verify appointment availability, outside normal hours, with the care/market teams',
    allowManualCreation: true,
    isOutreach: true,
  },
  [TaskType.Callback]: {
    display: 'Callback',
    definition: 'A request to call a patient or caregiver',
    allowManualCreation: true,
    isOutreach: true,
  },
  [TaskType.CallPartneringProvider]: {
    display: 'Call Partnering Provider or Agency',
    definition: 'A request to call a partnering provider or agency',
    allowManualCreation: true,
    isOutreach: true,
  },
  [TaskType.FirstCall]: {
    display: 'First Call',
    definition: 'An initial call to a patient or caregiver',
    allowManualCreation: true,
    isOutreach: true,
  },
  [TaskType.InformationRequest]: {
    display: 'Information Request',
    definition: 'A request to send information to a patient or caregiver about Imagine Pediatrics',
    allowManualCreation: true,
    isOutreach: true,
  },
  [TaskType.InformationRequestVerification]: {
    display: 'Information Request Verification',
    definition: 'A request to follow-up with patient or caregiver, to ensure they received the information we sent',
    allowManualCreation: true,
    isOutreach: true,
  },
  [TaskType.NetworkEngagement]: {
    display: 'Network Engagement',
    definition: 'A request for Network Engagement to follow-up with a patients provider, or visit at a facility',
    allowManualCreation: true,
    isOutreach: true,
  },
  [TaskType.OutreachFollowUp]: {
    display: 'Outreach Follow-Up',
    definition: 'A request to have Care Delivery Team follow-up with a patient or caregiver for outreach',
    allowManualCreation: true,
    isOutreach: true,
  },
  [TaskType.ScheduleAppointmentBHTOC]: {
    display: 'Schedule Appointment BH TOC',
    definition: 'Schedule appointment for patient or caregiver for BH TOC',
    isOutreach: true,
  },
  [TaskType.BHTOC]: { display: 'BH TOC' },
  [TaskType.ScheduleVisitsForCarePathwayChange]: {
    display: 'Schedule visit(s) for care pathway change',
  },
  [TaskType.CancelAllLongitudinalAppointments]: {
    display: 'Cancel all future longitudinal appointments',
    definition: 'Cancel all future longitudinal appointments',
  },
};

export const outreachTaskTypes: TaskType[] = Object.entries(taskTypeMap)
  .filter(([_, { isOutreach }]) => isOutreach)
  .map(([key]) => key as TaskType);

export const manuallyCreatableTaskTypes: TaskType[] = Object.entries(taskTypeMap)
  .filter(([_, { allowManualCreation }]) => allowManualCreation)
  .map(([key]) => key as TaskType);

export const manuallyCreatableTaskTypeOptions = manuallyCreatableTaskTypes.map((value) => ({
  value,
  label: taskTypeMap[value].display,
}));

export enum TaskPriority {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export enum BHTOCTaskResolveReason {
  TOCVisitCompletedImaginePediatrics = 'toc-visit-completed-imagine-pediatrics',
  TOCVisitCompletedExternalProvider = 'toc-visit-completed-external-provider',
  PatientDisenrolled = 'patient-disenrolled',
  PatientDeclinedVisit = 'patient-declined-visit',
  UnableToReach = 'unable-to-reach',
  Past30DayWindow = 'past-30-day-window',
  NotApplicable = 'not-applicable',
  DuplicateADTAlert = 'duplicate-adt-alert',
}

export const bhTOCTaskResolveReasons: Record<BHTOCTaskResolveReason, string> = {
  [BHTOCTaskResolveReason.TOCVisitCompletedImaginePediatrics]: 'TOC visit completed by Imagine Pediatrics',
  [BHTOCTaskResolveReason.TOCVisitCompletedExternalProvider]: 'TOC visit completed by external provider',
  [BHTOCTaskResolveReason.PatientDisenrolled]: 'Patient disenrolled',
  [BHTOCTaskResolveReason.PatientDeclinedVisit]: 'Patient declined visit',
  [BHTOCTaskResolveReason.UnableToReach]: 'Unable to reach',
  [BHTOCTaskResolveReason.Past30DayWindow]: 'Past 30-day window',
  [BHTOCTaskResolveReason.NotApplicable]: 'Not applicable',
  [BHTOCTaskResolveReason.DuplicateADTAlert]: 'Duplicate ADT alert',
};

export const bhTOCTaskResolveReasonOptions = Object.entries(bhTOCTaskResolveReasons).map(([value, label]) => ({
  value,
  label,
}));
