import { ClinicalImpression, Communication } from '@medplum/fhirtypes';
import { Patient, Encounter, Maybe, TaskInfoFragment, TaskEncounterFragment } from 'medplum-gql';
import { getSentByImaginePediatrics } from 'imagine-dsl/utils/communication';
import { timeSince } from 'imagine-dsl/utils/dates';
import { clusterFromClinicalImpressions } from 'imagine-dsl/utils/clinicalImpression';
import { System, TaskType } from 'const-utils';

const unassigned = (taskA: TaskInfoFragment, taskB: TaskInfoFragment): number => {
  if (!taskA || !taskB || (!taskA.owner && !taskB.owner) || (taskA.owner && taskB.owner)) {
    return 0;
  }

  if (!taskA.owner && taskB.owner) {
    return -1;
  } else {
    return 1;
  }
};

const urgent = (taskA: TaskInfoFragment, taskB: TaskInfoFragment): number => {
  if (taskA.priority === taskB.priority || (!taskA && !taskB)) {
    return 0;
  } else if (!taskA.priority) {
    return 1;
  } else if (!taskB.priority) {
    return -1;
  }

  if (taskA.priority === 'urgent' && taskB.priority !== 'urgent') {
    return -1;
  } else {
    return 1;
  }
};

const cluster = (taskA: TaskInfoFragment, taskB: TaskInfoFragment): number => {
  const clusterA = clusterFromClinicalImpressions(
    (taskA?.for?.resource as Patient)?.ClinicalImpressionList as ClinicalImpression[],
  );
  const clusterB = clusterFromClinicalImpressions(
    (taskB?.for?.resource as Patient)?.ClinicalImpressionList as ClinicalImpression[],
  );

  if (clusterA === clusterB || (!clusterA && !clusterB)) {
    return 0;
  } else if (!clusterA) {
    return 1;
  } else if (!clusterB) {
    return -1;
  }

  // TODO: Sort clusters based on actual criteria, not just alphabetically
  return clusterA!.localeCompare(clusterB!) * -1;
};

const respondedToByPractitioner = (taskA: TaskInfoFragment, taskB: TaskInfoFragment): number => {
  const communicationA = (taskA?.focus?.resource as Encounter)?.CommunicationList?.[0];
  const communicationB = (taskB?.focus?.resource as Encounter)?.CommunicationList?.[0];
  const sentByPractitionerA = getSentByImaginePediatrics(communicationA as Maybe<Communication>);
  const sentByPractitionerB = getSentByImaginePediatrics(communicationB as Maybe<Communication>);

  if (sentByPractitionerA === sentByPractitionerB) {
    return 0;
  }

  if (sentByPractitionerA && !sentByPractitionerB) {
    return 1;
  } else {
    return -1;
  }
};

const focusIsEncounter = (task: TaskInfoFragment) => task?.focus?.resource?.__typename === 'Encounter';

const waitTime = (taskA: TaskInfoFragment, taskB: TaskInfoFragment): number => {
  if (!focusIsEncounter(taskA) && !focusIsEncounter(taskB)) {
    return 0;
  } else if (!focusIsEncounter(taskA)) {
    return 1;
  } else if (!focusIsEncounter(taskB)) {
    return -1;
  }

  const sentA = (taskA?.focus?.resource as TaskEncounterFragment)?.CommunicationList?.[0]?.sent ?? taskA.authoredOn;
  const sentB = (taskB?.focus?.resource as TaskEncounterFragment)?.CommunicationList?.[0]?.sent ?? taskB.authoredOn;

  if (!sentA && !sentB) {
    return 0;
  } else if (!sentA) {
    return 1;
  } else if (!sentB) {
    return -1;
  }

  const waitTimeA = Math.abs(timeSince(sentA));
  const waitTimeB = Math.abs(timeSince(sentB));

  return waitTimeB - waitTimeA;
};

const taskType = (taskA: TaskInfoFragment, taskB: TaskInfoFragment): number => {
  const isTaskASuicideAlert = taskA.meta?.tag?.find(
    (tag) => tag.system === System.TaskType && tag.code === TaskType.SuicideRisk,
  );
  const isTaskBSuicideAlert = taskB.meta?.tag?.find(
    (tag) => tag.system === System.TaskType && tag.code === TaskType.SuicideRisk,
  );

  if (isTaskASuicideAlert && isTaskBSuicideAlert) {
    return 0;
  } else if (isTaskASuicideAlert) {
    return 1;
  } else if (isTaskBSuicideAlert) {
    return -1;
  }

  return 0;
};

const sortPriorityCluster = [taskType, waitTime, cluster, urgent, unassigned, respondedToByPractitioner];

/**
 *
 * @param tasks - list of tasks to be sorted
 * sorts the task list sorted by unassigned, urgent, cluster, waitTime
 */
export const sort = (tasks: TaskInfoFragment[]): void => {
  if (!tasks) {
    return;
  }

  const sortPriority = sortPriorityCluster;

  sortPriority.forEach((sortFn) => {
    tasks.sort((taskA, taskB) => sortFn(taskA, taskB));
  });
};
