import { useEffect, useState, useCallback } from 'react';
import { Participant, Room } from 'twilio-video';

interface Options {
  room?: Room;
  onNewParticipantConnected?: (participant: Participant) => void;
  onParticipantDisconnected?: (participant: Participant) => void;
}

interface Result {
  localParticipant?: Participant;
  remoteParticipants: Participant[];
}

export default function useParticipant({
  room,
  onNewParticipantConnected,
  onParticipantDisconnected,
}: Options): Result {
  const [participants, setParticipants] = useState<Participant[]>([]);
  const localParticipant = room?.localParticipant;

  const handleNewConnected = useCallback(
    (participant: Participant): void => {
      setParticipants((p) => [...p, participant]);
      onNewParticipantConnected?.(participant);
    },
    [onNewParticipantConnected],
  );

  const handleParticipantDisconnected = useCallback(
    (participant: Participant): void => {
      setParticipants((current) => current.filter((p) => p !== participant));
      onParticipantDisconnected?.(participant);
    },
    [onParticipantDisconnected],
  );

  useEffect(() => {
    if (room) {
      setParticipants(Array.from(room.participants.values()));
    } else {
      setParticipants([]);
    }
  }, [room]);

  useEffect(() => {
    if (room) {
      room.on('participantConnected', handleNewConnected);
      room.on('participantDisconnected', handleParticipantDisconnected);

      return () => {
        room.off('participantConnected', handleNewConnected);
        room.off('participantDisconnected', handleParticipantDisconnected);
      };
    }
    return () => {};
  }, [onNewParticipantConnected, onParticipantDisconnected, handleNewConnected, handleParticipantDisconnected, room]);

  return { localParticipant, remoteParticipants: participants };
}
