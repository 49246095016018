import { TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import { Maybe, SearchPatientQueryVariables } from 'medplum-gql';
import { cleanPhoneNumber } from 'imagine-dsl/utils/strings';
import { format } from 'date-fns';

interface MultiSearchProps {
  search: (query: SearchPatientQueryVariables) => void;
  searchParams?: Maybe<SearchPatientQueryVariables>;
}

export function PatientMultiSearchForm({ search, searchParams }: MultiSearchProps): JSX.Element {
  const form = useForm({
    initialValues: {
      query: (searchParams?.name || searchParams?.birthdate || searchParams?.phone) ?? '',
    },
  });

  const onSearch = (formData: { query: string }): void => {
    const { query } = formData;

    if (/\d{10}$/.test(cleanPhoneNumber(query))) {
      search({
        phone: cleanPhoneNumber(query),
      });
    } else if (/\d+\/\d+\/\d+$/.test(query)) {
      search({
        birthdate: format(query, 'yyyy-MM-dd'),
      });
    } else {
      search({
        name: query,
      });
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSearch)}>
      <TextInput
        name="query"
        placeholder="Search by patient name, phone number, or date of birth"
        {...form.getInputProps('query')}
      />
    </form>
  );
}
