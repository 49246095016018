import { UseFormReturnType } from '@mantine/form';
import { ScheduleOnboardingVisitFormValues } from '../types';
import { pleaseFixErrorsAboveMessage } from '@/errors';

export const validateScheduleOnboardingVisitForm = async (
  scheduleOnboardingVisitForm: UseFormReturnType<ScheduleOnboardingVisitFormValues>,
): Promise<void> => {
  scheduleOnboardingVisitForm.validate();

  if (!scheduleOnboardingVisitForm.isValid()) {
    throw new Error(pleaseFixErrorsAboveMessage);
  }
};
