import { HealthPlanIdentifier } from 'const-utils/codeSystems/ImaginePediatrics';
import { CoverageInfoFragment, Maybe } from 'medplum-gql';

export const acuteCareOnly = (coverageList: Maybe<Maybe<CoverageInfoFragment>[]> | undefined): boolean => {
  if (!coverageList) {
    return false;
  }

  const activeCoverage = coverageList?.find((cov) => cov?.status === 'active');

  if (activeCoverage?.payor?.[0].resource?.__typename !== 'Organization') {
    return false;
  }

  return activeCoverage?.payor?.[0].resource.identifier?.[0].value === HealthPlanIdentifier.HSCSN;
};
