import { getConfig } from '@/config';
import { Title } from '@mantine/core';
import { SignInForm } from '@medplum/react';
import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const { enableAutomation } = getConfig();

interface SignInPageProps {
  projectId?: string;
}
/**
 * this page is only displayed based on environment configuration in lower level environments
 * to be used to bypass SSO and utilize the medplum authentication method
 */

export function SignInPage(props: SignInPageProps): JSX.Element {
  const navigate = useNavigate();

  if (!enableAutomation) {
    return <Navigate to="/signin" />;
  }

  return (
    <SignInForm onSuccess={() => navigate('/')} projectId={props.projectId}>
      <Title>Sign in to Care Hub</Title>
    </SignInForm>
  );
}
