import { Group, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { formatHumanName } from '@medplum/core';
import { HumanName, Organization, Practitioner } from '@medplum/fhirtypes';
import { CareTeam, GetIncompleteChatTasksQuery, Maybe, Patient } from 'medplum-gql';
import { pediatricRnFromPatient } from 'imagine-dsl/utils/patient';
import React, { useEffect, useMemo } from 'react';
import { FilterValues } from '../utils/filter';
import { map } from 'lodash';

interface FiltersProps {
  onFilter: (values: FilterValues) => void;
  tasks: GetIncompleteChatTasksQuery['TaskList'];
  defaultMarketId?: Maybe<string>;
  userMarkets: Organization[];
}

interface Option {
  value: string;
  label: string;
}

export function Filters({ onFilter, tasks, defaultMarketId, userMarkets }: FiltersProps): JSX.Element {
  const form = useForm<FilterValues>({
    initialValues: {
      assigneeId: '',
      patientId: '',
      category: '',
      primaryRnId: '',
      marketId: defaultMarketId || '',
    },
  });

  useEffect(() => {
    if (defaultMarketId) {
      form.setFieldValue('marketId', defaultMarketId);
    }
  }, [defaultMarketId, form]);

  const patientSelectOptions = useMemo((): Option[] => {
    return (
      tasks?.reduce<Option[]>((options, t, _index) => {
        const patientForTask = t?.for?.resource as Patient;

        if (!patientForTask?.id) {
          return options;
        }

        if (options?.map((option) => option.value).includes(patientForTask.id!)) {
          return options;
        }

        return [
          ...options,
          { value: patientForTask.id, label: formatHumanName(patientForTask.name?.[0] as HumanName) },
        ];
      }, []) || []
    );
  }, [tasks]);

  const primaryRnOptions = useMemo((): Option[] => {
    return (
      tasks?.reduce<Option[]>((options, t, _index) => {
        const patient = t?.for?.resource as Patient;
        if (!patient) {
          return options;
        }
        const rn = pediatricRnFromPatient(patient.CareTeamList as CareTeam[]);
        if (!rn) {
          return options;
        }

        if (options?.map((option) => option.value).includes(rn.id!)) {
          return options;
        }

        return [...options, { value: rn.id!, label: formatHumanName(rn.name?.[0] as HumanName) }];
      }, []) || []
    );
  }, [tasks]);

  const marketOptions = useMemo((): Option[] => {
    if (defaultMarketId && userMarkets) {
      const userMarket = userMarkets[0];
      return [{ value: userMarket.id!, label: userMarket.name! }];
    }
    const marketsFromTasks =
      tasks?.reduce<Option[]>((options, t, _index) => {
        const patient = t?.for?.resource as Patient;
        if (!patient) {
          return options;
        }
        const taskMarket = patient.managingOrganization?.resource as Organization;
        if (!taskMarket) {
          return options;
        }

        if (options?.map((option) => option.value).includes(taskMarket.id!)) {
          return options;
        }

        return [...options, { value: taskMarket.id!, label: taskMarket.name! }];
      }, []) || [];

    return marketsFromTasks.filter((option) => map(userMarkets, 'id').includes(option.value));
  }, [tasks, userMarkets, defaultMarketId]);

  const assignedToOptions = useMemo((): Option[] => {
    const assigneeOptions = tasks?.reduce<Option[]>(
      (options, t, _index) => {
        const assignee = t?.owner?.resource as Practitioner;
        if (!assignee) {
          return options;
        }

        if (options?.map((option) => option.value).includes(assignee.id!)) {
          return options;
        }

        return [...options, { value: assignee.id!, label: formatHumanName(assignee.name?.[0] as HumanName) }];
      },
      [{ value: 'unassigned', label: 'Unassigned' }],
    );

    return assigneeOptions || [];
  }, [tasks]);

  return (
    <>
      <Group mb={24} wrap="nowrap">
        <Select
          data={patientSelectOptions}
          name="patientId"
          placeholder="Patient name"
          searchable
          clearable
          onChange={(value) => {
            form.setFieldValue('patientId', value);
            onFilter({ ...form.values, patientId: value });
          }}
        />

        <Select
          data={marketOptions}
          name="marketId"
          placeholder="Market"
          defaultValue={defaultMarketId}
          disabled={!!defaultMarketId}
          searchable
          clearable
          onChange={(value) => {
            form.setFieldValue('marketId', value);
            onFilter({ ...form.values, marketId: value });
          }}
        />

        <Select
          w={180}
          data={primaryRnOptions}
          name="primaryRnId"
          placeholder="Primary RN"
          searchable
          clearable
          onChange={(value) => {
            form.setFieldValue('primaryRnId', value);
            onFilter({ ...form.values, primaryRnId: value });
          }}
        />

        <Select
          data={[
            { value: 'urgent', label: 'Urgent' },
            { value: 'non-urgent', label: 'Non-urgent' },
            { value: 'suicide-risk', label: 'Suicide Risk' },
          ]}
          w={150}
          name="category"
          placeholder="Category"
          searchable
          clearable
          onChange={(value) => {
            form.setFieldValue('category', value);
            onFilter({ ...form.values, category: value });
          }}
        />

        <Select
          w={180}
          data={assignedToOptions}
          name="assigneeId"
          placeholder="Assigned to"
          searchable
          clearable
          onChange={(value) => {
            form.setFieldValue('assigneeId', value);
            onFilter({ ...form.values, assigneeId: value });
          }}
        />
      </Group>
    </>
  );
}
