import React from 'react';
import { NativeSelect } from '@mantine/core';

const style = {
  marginBottom: '20px',
  flex: 1,
  marginRight: '8px',
};

export interface SelectInputProps {
  data: string[];
  label: string;
  value?: string;
  onChange?: (value: string) => void;
  withAsterisk?: boolean;
  error?: string;
}

export const SelectInput = ({ data, label, value, onChange, withAsterisk, error }: SelectInputProps): JSX.Element => (
  <NativeSelect
    data={[{ value: '', label: `Please select ${label}`, disabled: true }, ...data]}
    value={value}
    onChange={(e) => onChange && onChange(e.currentTarget.value)}
    label={label}
    data-autofocus
    style={style}
    withAsterisk={withAsterisk}
    error={error}
  />
);
