import { CodeSystem } from '@medplum/fhirtypes';
import { codeSystemConcepts } from './Concepts';

export const SYSTEM_URL = 'https://imaginepediatrics.org/fhir/CodeSystem';

export const ImaginePediatricsCodeSystem: Readonly<CodeSystem> = {
  resourceType: 'CodeSystem',
  url: SYSTEM_URL,
  identifier: [
    {
      system: 'https://imaginepediatrics.org/fhir/CodeSystem',
      value: 'imaginepediatrics',
    },
  ],
  version: '0.0.1',
  name: 'ImaginePediatrics',
  title: 'Imagine Pediatrics Code System',
  status: 'active',
  experimental: false,
  caseSensitive: true,
  hierarchyMeaning: 'is-a',
  content: 'complete',
  concept: Object.entries(codeSystemConcepts).map(([_, concept]) => concept),
};
