import { ClinicalImpression } from '@medplum/fhirtypes';
import { HL7System, System } from 'const-utils';
import { CarePathway } from 'const-utils/codeSystems/ImaginePediatrics';
import { ClinicalImpressionInfoFragment, Maybe } from 'medplum-gql';

export const clusterFromClinicalImpressions = (
  clinicalImpressionList?: Maybe<ClinicalImpression[] | ClinicalImpressionInfoFragment[]>,
): string | undefined => {
  return clinicalImpressionList
    ?.filter((impression) => impression?.code?.coding?.some((coding) => coding?.code === 'cluster'))
    ?.map((impression) => impression?.finding?.[0].itemCodeableConcept?.coding?.[0].code)
    .join(', ');
};

export const qualifyingDiagnosesFromClinicalImpressions = (
  clinicalImpressionList: Maybe<ClinicalImpression[]>,
): QualifyingDiagnosis[] => {
  return (
    clinicalImpressionList
      ?.filter((impression) => impression?.code?.coding?.some((coding) => coding?.code === 'cluster'))
      .map(
        (clusterItem) =>
          clusterItem?.finding?.filter(
            (finding) =>
              finding?.itemCodeableConcept?.coding?.[0].system === HL7System.ICD10 ||
              finding?.itemCodeableConcept?.coding?.[0].system === HL7System.HCPCS,
          ),
      )
      .flat()
      .map((dx) => {
        const claimValues = dx?.itemCodeableConcept?.text?.split('|');
        return {
          code: dx?.itemCodeableConcept?.coding?.[0].code || '',
          description: dx?.itemCodeableConcept?.coding?.[0].display || '',
          yearMonthClaimMax: claimValues?.[0] || '',
          yearMonthClaimMin: claimValues?.[1] || '',
          monthsWithCode: claimValues?.[2] || '',
        };
      })
      .sort((a, b) => {
        if (a.yearMonthClaimMax < b.yearMonthClaimMax) {
          return 1;
        }
        if (a.yearMonthClaimMax > b.yearMonthClaimMax) {
          return -1;
        }
        return 0;
      }) || []
  );
};

export const getCarePathway = (
  clinicalImpression?: (ClinicalImpression | ClinicalImpressionInfoFragment)[],
): CarePathway | undefined => {
  const clinicalImpressionOfTypeCluster = clinicalImpression?.find(
    (impression) => impression.identifier?.[0]?.system === System.Cluster,
  );

  const carePathwayFromClinicalImpression =
    clinicalImpressionOfTypeCluster?.finding
      ?.find((cp) => cp.itemCodeableConcept?.coding?.find((code) => code.system === System.Cluster))
      ?.itemCodeableConcept?.coding?.find((code) => code.system === System.Cluster)?.code ?? undefined;

  const carePathwayFromOverride = clinicalImpressionOfTypeCluster?.extension?.find(
    (ext) => ext.url === System.CarePathwayReferralOverride.toString(),
  )?.valueString as CarePathway | undefined;

  return carePathwayFromOverride
    ? carePathwayFromOverride
    : (carePathwayFromClinicalImpression?.split(':')?.[0] as CarePathway | undefined);
};

interface QualifyingDiagnosis {
  code: string;
  description: string;
  yearMonthClaimMax: string;
  yearMonthClaimMin: string;
  monthsWithCode: string;
}
