import { Coding } from '@medplum/fhirtypes';
import { OutreachModality } from 'const-utils/codeSystems/ImaginePediatrics';

export enum FlowStep {
  ContactCaregiver = 'contactCaregiver',
  ContactInfo = 'contactInfo',
  Enroll = 'enroll',
}

export enum EnrollStep {
  AppDownload = 'appDownload',
  Credentials = 'credentials',
  Consents = 'consents',
  TestMessage = 'testMessage',
  TestVideoCall = 'testVideoCall',
  ScheduleOnboarding = 'scheduleOnboarding',
}

export const flowSteps = [FlowStep.ContactCaregiver, FlowStep.ContactInfo, FlowStep.Enroll];

export const enrollSteps = [
  EnrollStep.AppDownload,
  EnrollStep.Credentials,
  EnrollStep.Consents,
  EnrollStep.TestMessage,
  EnrollStep.TestVideoCall,
  EnrollStep.ScheduleOnboarding,
];

export interface ContactCaregiverFormValues {
  contactFirstName: string;
  contactLastName: string;
  phoneNumber: string;
  patientId: string;
  date: Date;
  timezone: string;
  modality?: OutreachModality;
}

export interface LogCallFormValues {
  notes: string;
  outcome?: Coding;
  callbackDate?: string;
  callbackTimeStart?: string;
  callbackTimeEnd?: string;
}

export interface AppDownloadFormValues {
  consent: boolean;
  phoneNumber: string;
}

export interface SendCredentialsFormValues {
  phoneNumber: string;
}

export interface ScheduleOnboardingVisitFormValues {
  visitScheduled: 'yes' | 'no' | null;
  notes: string;
}
