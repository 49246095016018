// TODO: cleanup once merge-task-views is deployed

import React, { useEffect, useMemo, useState } from 'react';
import { Paper } from '@mantine/core';
import { TasksSearch } from '@/components/tasks/outreach/TasksSearch';
import { TasksTable } from '@/components/tasks/outreach/TasksTable';
import { Navigate, useSearchParams } from 'react-router-dom';
import { pickBy } from 'lodash';
import { useUserSession } from '@/components/shared/UserSessionContext';
import { outreachTaskTypes } from 'const-utils/codeSystems/ImaginePediatrics';
import { PageSkeleton } from '@/design-system/PageSkeleton';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';

export interface SearchFilters {
  patientId: string;
  taskType: string;
  assignedTo: string;
  engagementPod?: string;
  market: string;
}

export const OutreachTaskPage = (): JSX.Element => {
  const flags = useFeatureFlags();
  const {
    markets,
    profile: { id },
  } = useUserSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTaskId, setSelectedTaskId] = useState<string | undefined>(searchParams.get('task') ?? undefined);
  const defaultMarketId = useMemo(() => {
    if (markets.length === 1) {
      return markets[0].id;
    } else {
      return '';
    }
  }, [markets]);
  const [searchFilters, setSearchFilters] = useState<SearchFilters>({
    patientId: searchParams.get('patientId') || '',
    taskType: searchParams.get('taskType') || '',
    assignedTo: searchParams.get('assignedTo') || id || '',
    engagementPod: searchParams.get('engagementPod') || '',
    market: defaultMarketId || searchParams.get('market') || '',
  });

  useEffect(() => {
    if (!searchFilters.market && defaultMarketId) {
      setSearchFilters((params) => {
        return {
          ...params,
          market: defaultMarketId,
        };
      });
    }

    setSearchParams((params) => {
      return {
        ...params,
        ...pickBy(
          {
            ...searchFilters,
            task: selectedTaskId,
          },
          (value) => value,
        ),
      };
    });
  }, [setSearchParams, searchFilters, selectedTaskId, defaultMarketId, searchParams]);

  if (flags.MergeTaskViews) {
    // TODO: remove FUH flag check once FUH is deployed
    // move this navigate to the top-level router once FUH is deployed
    return <Navigate to={`/Task?taskType=${outreachTaskTypes.join(',')}`} replace />;
  }
  return (
    <PageSkeleton withPaper={false}>
      <TasksSearch searchFilters={searchFilters} setSearchFilters={setSearchFilters} />
      <Paper radius="lg" shadow="xs" p="xl" mt="xl" mb="xl">
        <TasksTable
          searchFilters={searchFilters}
          selectedTaskId={selectedTaskId}
          setSelectedTaskId={setSelectedTaskId}
        />
      </Paper>
    </PageSkeleton>
  );
};
