export const enum CommunicationCategory {
  Outreach = 'outreach',
  Test = 'test',
  Welcome = 'welcome',
  Notification = 'notification',
}

export const enum CommunicationMedium {
  Video = 'video',
  Chat = 'chat',
  Telephone = 'telephone',
  InPerson = 'in-person',
  Text = 'text',
  Notification = 'notification',
}

export const communicationMediumMap: Record<CommunicationMedium, string> = {
  [CommunicationMedium.Video]: 'Video call',
  [CommunicationMedium.Chat]: 'In-app chat message',
  [CommunicationMedium.Telephone]: 'Telephone call',
  [CommunicationMedium.InPerson]: 'In-Person interaction',
  [CommunicationMedium.Text]: 'SMS/MMS text message',
  [CommunicationMedium.Notification]: 'Notification',
};
