import {
  Maybe,
  Patient,
  SearchPatientByAddressQuery,
  SearchPatientByAddressQueryVariables,
  useSearchPatientByAddressQuery,
} from 'medplum-gql';
import { Patient as FHIRPatient } from '@medplum/fhirtypes';
import { caregiversFromPatient, relatedPersonsFromCaregiver } from 'imagine-dsl/utils/patient';
import { formatAddress } from 'imagine-dsl/utils/strings';
import { flatten, isEmpty, map } from 'lodash';
import { useMemo } from 'react';
import { isDefined } from 'imagine-dsl/utils/lists';

export const useRecommendedLinks = (
  patient: Patient,
  getAddress?: () => Maybe<SearchPatientByAddressQueryVariables>,
) => {
  const searchParams = useMemo(() => {
    const params = getAddress ? getAddress() : getAddressSearch(patient);
    if (!params) {
      return params;
    }

    return {
      ...params,
      // commas act as an OR in the search which increases the scope of the search returning irrelevant results
      address: params.address?.replaceAll(',', ''),
    };
  }, [patient, getAddress]);
  const { data, ...rest } = useSearchPatientByAddressQuery({
    skip: !searchParams,
    variables: { ...(searchParams || {}), tag: 'patient' },
  });

  const recommendedLinks = useMemo(() => {
    const candidates = searchPatientByAddressToPatients(data, patient);

    return candidates && getUnlinkedRecommendations(patient, candidates);
  }, [patient, data]);

  return {
    data: recommendedLinks,
    ...rest,
  };
};

const getAddressSearch = (patient: Patient): Maybe<SearchPatientByAddressQueryVariables> => {
  if (!patient.address || isEmpty(patient?.address)) {
    return null;
  }

  const address = patient.address[0];

  return {
    address: formatAddress(address),
    addressCity: address.city,
    addressPostalCode: address.postalCode,
    addressState: address.state,
  };
};

const searchPatientByAddressToPatients = (
  result: SearchPatientByAddressQuery | undefined,
  patient: Patient,
): Patient[] | undefined => {
  if (!result) {
    return undefined;
  }

  return (result?.PatientConnection?.edges || [])
    ?.map((edge) => edge?.resource)
    ?.filter(isDefined)
    .filter((link) => link?.id !== patient.id) as Patient[];
};

export const getUnlinkedRecommendations = (patient: Patient, candidates: Patient[]): Patient[] | undefined => {
  const primaryPatientCaregivers = caregiversFromPatient(patient);

  const linkedPatientIds = map(
    flatten(
      primaryPatientCaregivers.map((caregiver) =>
        relatedPersonsFromCaregiver(caregiver as FHIRPatient, patient.id!).map((rp) => rp.patient?.resource),
      ),
    ),
    'id',
  );

  return candidates.filter((p) => !linkedPatientIds.includes(p.id));
};
