import { logError } from '@/errors';
import { ApolloQueryResult } from '@apollo/client';
import { notifications } from '@mantine/notifications';
import { formatHumanName } from '@medplum/core';
import { HumanName, Questionnaire, QuestionnaireItem, QuestionnaireResponse, Task } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { System } from 'const-utils';
import {
  PreferredLanguage,
  QuestionnaireResponseCategory,
  QuestionnaireType,
  TaskType,
} from 'const-utils/codeSystems/ImaginePediatrics';
import {
  GetIncompleteTasksByPatientIdQuery,
  Maybe,
  useGetIncompleteTasksByPatientIdQuery,
  useGetPatientQuery,
} from 'medplum-gql';
import { TranslationCodeMapping, translateQuestionnaire } from 'imagine-dsl/services/questionnaireService';
import React, { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { SurveyReviewTask } from 'imagine-dsl/models/tasks/surveyReviewTask';

interface QuestionnaireResponseProviderProps {
  patientId: string;
  surveyTask: SurveyReviewTask;
}
interface QuestionnaireResponseContext {
  items: QuestionnaireItem[];
  translatedItemMapping: TranslationCodeMapping[];
  questionnaireResponse?: Maybe<QuestionnaireResponse>;
  questionnaire?: Maybe<Questionnaire>;
  responseCategory: string;
  patientPreferredLanguage: string;
  patientTask?: Task;
  patientName: string;
  refetchPatientTasks?: () => Promise<ApolloQueryResult<GetIncompleteTasksByPatientIdQuery>>;
  questionnaireResponseLoading: boolean;
  surveyTask: SurveyReviewTask;
}

const QuestionnaireResponseContextValue = createContext<QuestionnaireResponseContext | undefined>(undefined);

export const useQuestionnaireResponseContext = (): QuestionnaireResponseContext => {
  const value = useContext(QuestionnaireResponseContextValue);
  if (!value) {
    throw new Error('No QuestionnaireResponseContextValue');
  }
  return value;
};

export function QuestionnaireResponseProvider(
  props: PropsWithChildren<QuestionnaireResponseProviderProps>,
): JSX.Element {
  const { children, patientId, surveyTask } = props;
  const medplum = useMedplum();
  const [translatedItemMapping, setTranslatedItemMapping] = useState<TranslationCodeMapping[]>([]);
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | null>(null);
  const [questionnaireResponse, setQuestionnaireResponse] = useState<QuestionnaireResponse | null>(null);
  const [questionnaireResponseLoading, setQuestionnaireResponseLoading] = useState(false);

  const { data: patientData } = useGetPatientQuery({
    variables: { id: patientId },
  });

  const patientPreferredLanguage = patientData?.Patient?.communication?.[0]?.language?.coding?.[0]?.code || 'en';

  useEffect(() => {
    const questionnaireTags = surveyTask.isBHSurveyTask
      ? [QuestionnaireType.BHSurvey, surveyTask.bhSurveyType!]
      : [QuestionnaireType.Screener];
    translateQuestionnaire(medplum, {
      toLanguage: PreferredLanguage.En,
      questionnaireTags,
    })
      .then((translatedItemMapping) => {
        setTranslatedItemMapping(translatedItemMapping);
      })
      .catch((error) => {
        logError('Error translating questionnaire', error);
      });
  }, [medplum, patientPreferredLanguage, surveyTask.bhSurveyType, surveyTask.isBHSurveyTask]);

  useEffect(() => {
    (async () => {
      const questionnaireTags = surveyTask.isBHSurveyTask
        ? [QuestionnaireType.BHSurvey, surveyTask.bhSurveyType!]
        : [QuestionnaireType.Screener];
      const questionnaireTagString = questionnaireTags.map((tag) => `_tag=${tag}`).join('&');
      const questionnaireResult = await medplum.searchOne(
        'Questionnaire',
        `${questionnaireTagString}&status=active&_tag=${System.QuestionnaireLanguage}|en`,
      );

      const questionnaireResponseId = surveyTask?.focus?.id;

      setQuestionnaireResponseLoading(true);
      const questionnaireResponseResult =
        questionnaireResponseId && (await medplum.readResource('QuestionnaireResponse', questionnaireResponseId));

      return { questionnaireResult, questionnaireResponseResult };
    })()
      .then(({ questionnaireResult, questionnaireResponseResult }) => {
        setQuestionnaireResponse(questionnaireResponseResult as QuestionnaireResponse);
        setQuestionnaire(questionnaireResult as Questionnaire);
        setQuestionnaireResponseLoading(false);
      })
      .catch((error) => {
        notifications.show({ color: 'status-error', title: 'Error fetching questionnaire', message: error.message });
        setQuestionnaireResponseLoading(false);
      });
  }, [
    patientId,
    medplum,
    patientPreferredLanguage,
    surveyTask.isBHSurveyTask,
    surveyTask.bhSurveyType,
    surveyTask?.focus?.id,
  ]);

  const patientTaskTags = [TaskType.Screener, TaskType.BHSurvey];

  const { data: patientTaskData, refetch: refetchPatientTasks } = useGetIncompleteTasksByPatientIdQuery({
    variables: { patientId: `Patient/${patientId}`, tag: patientTaskTags.join(',') },
  });

  const patientTask = patientTaskData?.TaskList?.at(0) as Task | undefined;
  const patientName = patientData?.Patient?.name?.[0]
    ? formatHumanName(patientData?.Patient?.name?.[0] as HumanName)
    : '';

  const responseCategory = useMemo(() => {
    if (!questionnaireResponse?.extension) {
      return 'pending score';
    }

    const responseCategories = questionnaireResponse.extension.filter(
      (ext) => ext.url === System.QuestionnaireResponseCategory.toString(),
    );

    if (responseCategories.some((ext) => ext.valueString === QuestionnaireResponseCategory.SuicideRisk)) {
      return QuestionnaireResponseCategory.SuicideRisk;
    }

    return responseCategories[0]?.valueString || 'pending score';
  }, [questionnaireResponse]);

  const value = {
    translatedItemMapping,
    items: questionnaire?.item ?? [],
    questionnaire,
    questionnaireResponse,
    responseCategory,
    patientPreferredLanguage,
    patientTask,
    patientName,
    refetchPatientTasks,
    questionnaireResponseLoading,
    surveyTask,
  };

  return (
    <QuestionnaireResponseContextValue.Provider value={value}>{children}</QuestionnaireResponseContextValue.Provider>
  );
}
