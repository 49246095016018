import { ActionIcon, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Patient } from '@medplum/fhirtypes';
import { IconCirclePlusFilled } from '@tabler/icons-react';
import { isEnrolled, isOnboarded } from 'imagine-dsl/utils/patient';
import React from 'react';
import { SendSurveyModal } from '../SendSurveyModal';
import { SurveyTask } from 'imagine-dsl/models/tasks/surveyTask';

interface SendSurveyButtonProps {
  caregivers: Patient[];
  patient: Patient;
  refetchTask: () => Promise<void>;
  surveyTasks: SurveyTask[];
}

export const SendSurveyButton = ({
  caregivers,
  patient,
  refetchTask,
  surveyTasks,
}: SendSurveyButtonProps): JSX.Element => {
  const [surveyModalOpened, { open: openSurveyModal, close: closeSurveyModal }] = useDisclosure();
  const disabled = caregivers.length === 0 || (!isEnrolled(patient) && !isOnboarded(patient));

  return (
    <>
      <SendSurveyModal
        patient={patient}
        caregivers={caregivers}
        surveyTasks={surveyTasks}
        opened={surveyModalOpened}
        onClose={closeSurveyModal}
        refetchTask={refetchTask}
      />
      <Tooltip
        label={disabled ? 'Patient needs to be enrolled and have caregivers to receive a survey.' : 'Send Survey'}
        position="left"
        withArrow
      >
        <ActionIcon onClick={() => openSurveyModal()} disabled={disabled}>
          <IconCirclePlusFilled size={24} />
        </ActionIcon>
      </Tooltip>
    </>
  );
};
