import { MedplumClient } from '@medplum/core';
import { System } from 'const-utils';
import { AddressType } from 'const-utils/codeSystems/ImaginePediatrics';
import { Address } from '@/components/shared/ContactReviewForm';

const addressDataRetention = async (address: Address, patientId: string, medplum: MedplumClient): Promise<void> => {
  const refreshedPatient = await medplum.readResource('Patient', patientId);
  const existingNonHomeAddress =
    refreshedPatient?.address?.filter((addr) => !addr.extension?.some((ext) => ext.valueCode === AddressType.Home)) ??
    [];

  const updatedHomeAddress = {
    extension: [
      {
        url: System.AddressType,
        valueCode: AddressType.Home,
      },
    ],
    line: address.line2 ? [address.line1, address.line2] : [address.line1],
    city: address.city,
    state: address.state,
    postalCode: address.zip,
  };

  const updatedAddresses = [...existingNonHomeAddress, updatedHomeAddress];

  const updatedPatient = { ...refreshedPatient, address: updatedAddresses };
  await medplum.updateResource(updatedPatient);
};

export default addressDataRetention;
