import { ValueSet } from '@medplum/fhirtypes';
import { SYSTEM_URL, Concepts, codeSystemConcepts } from '../codeSystems/ImaginePediatrics';
import { System } from '../codeSystems/system';

const preferredLanguageCodes = codeSystemConcepts[Concepts.PreferredLanguage].concept;

export const PreferredLanguage: ValueSet = {
  resourceType: 'ValueSet',
  url: System.PreferredLanguage,
  identifier: [
    {
      system: 'https://imaginepediatrics.org/fhir/ValueSet',
      value: 'preferred-language',
    },
  ],
  version: '0.0.1',
  name: 'PreferredLanguage',
  title: 'Preferred Language',
  status: 'active',
  experimental: false,
  compose: {
    include: [
      {
        system: SYSTEM_URL,
        concept: preferredLanguageCodes!.map((c) => ({ code: c.code, display: c.display })),
      },
    ],
  },
};
