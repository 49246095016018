import React, { useMemo } from 'react';
import { Stack, Container, Group, Button, Grid, Menu, Text, ThemeIcon } from '@mantine/core';
import { Patient, Practitioner, Task } from '@medplum/fhirtypes';
import { GetIncompleteChatTasksByPatientIdQuery, Maybe } from 'medplum-gql';
import { AssigneeSection } from './AssigneeSection';
import { IconCheck, IconExclamationCircleFilled } from '@tabler/icons-react';
import { ApolloQueryResult } from '@apollo/client';
import { ResolveAlertModal } from './ResolveAlertModal';
import { useDisclosure } from '@mantine/hooks';
import { MedplumClient } from '@medplum/core';
import { notifications } from '@mantine/notifications';
import { useMedplum } from '@medplum/react';
import { isSuicideAlertTask } from 'imagine-dsl/services/taskService';
import { useImagineApolloClient } from '@/hooks/useImagineApolloClient';
import { GetChatTimelineForPatientDocument, GetChatTimelineForPatientQuery } from 'imagine-gql/client';
import { getName } from 'imagine-dsl/utils/patient';

interface FooterProps {
  assignee: Maybe<Practitioner>;
  profile: Practitioner;
  task: Task;
  refreshTasks: () => Promise<ApolloQueryResult<GetIncompleteChatTasksByPatientIdQuery>>;
}

const onUpdatePriority = (
  medplum: MedplumClient,
  taskId: string,
  priority: 'urgent' | 'routine',
  refreshTasks: () => Promise<ApolloQueryResult<GetIncompleteChatTasksByPatientIdQuery>> | Promise<void>,
): void => {
  medplum
    .patchResource('Task', taskId, [
      {
        op: 'add',
        path: '/priority',
        value: priority,
      },
    ])
    .then(async () => {
      await refreshTasks();
      const message = priority === 'urgent' ? 'Alert marked as urgent' : 'Alert marked as not urgent';
      notifications.show({
        message,
        color: 'status-success',
      });
    })
    .catch((err) => {
      notifications.show({
        title: 'Error marking alert as urgent',
        message: err,
        color: 'status-error',
      });
    });
};

export function Footer({ assignee, refreshTasks, profile, task }: FooterProps): JSX.Element {
  const medplum = useMedplum();
  const [resolveModalOpened, { close: closeResolveModal, open: openResolveModal }] = useDisclosure();
  const priority = task.priority;
  const isSuicideAlert = isSuicideAlertTask(task);

  const taskMarket = useMemo(() => {
    return (task.for?.resource as Patient).managingOrganization?.resource?.id;
  }, [task]);

  return (
    <>
      <ResolveAlertModal
        refreshTasks={refreshTasks}
        taskId={task.id!}
        opened={resolveModalOpened}
        taskStatus={task.status}
        onClose={closeResolveModal}
        encounterId={task.focus?.resource?.id as string}
      />
      <Container pt={16} pb={24} mx={10}>
        <Grid justify="space-between">
          <Stack gap={0}>
            <AssigneeSection
              taskMarket={taskMarket!}
              assigneeId={assignee?.id}
              assigneeName={getName(assignee)}
              taskId={task.id!}
              disabled={task.status === 'completed'}
              refreshTasks={refreshTasks}
              profile={profile}
            />
          </Stack>
          <Group>
            <Button onClick={() => openResolveModal()} variant="outline" leftSection={<IconCheck />}>
              {task.status === 'completed' ? 'Reopen' : 'Resolve'}
            </Button>
            <Menu>
              <Menu.Target>
                <Button variant="outline" leftSection={<IconExclamationCircleFilled />}>
                  Mark as...
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  disabled={priority === 'urgent'}
                  onClick={() => onUpdatePriority(medplum, task.id!, 'urgent', refreshTasks)}
                >
                  <Group gap={0}>
                    <ThemeIcon c="status-error">
                      <IconExclamationCircleFilled />
                    </ThemeIcon>
                    <Text>Urgent</Text>
                  </Group>
                </Menu.Item>
                <Menu.Item
                  disabled={priority === 'routine' || isSuicideAlert}
                  onClick={() => onUpdatePriority(medplum, task.id!, 'routine', refreshTasks)}
                >
                  Not urgent
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Grid>
      </Container>
    </>
  );
}

interface NewFooterProps {
  timelineItem: GetChatTimelineForPatientQuery['chatTimeline']['timelineItems'][0];
  profile: Practitioner;
}

export const NewFooter = ({ timelineItem, profile }: NewFooterProps): JSX.Element => {
  const medplum = useMedplum();
  const [resolveModalOpened, { close: closeResolveModal, open: openResolveModal }] = useDisclosure();
  const apolloClient = useImagineApolloClient();
  const refreshTasks = async (): Promise<void> => {
    await apolloClient.refetchQueries({ include: [GetChatTimelineForPatientDocument] });
  };

  return (
    <>
      <ResolveAlertModal
        refreshTasks={refreshTasks}
        taskId={timelineItem.task.id!}
        opened={resolveModalOpened}
        taskStatus={timelineItem.task.status}
        onClose={closeResolveModal}
        encounterId={timelineItem.task.focusId}
      />
      <Container pt={16} pb={24} mx={10}>
        <Grid justify="space-between">
          <Stack gap={0}>
            <AssigneeSection
              taskMarket={timelineItem.market}
              assigneeId={timelineItem.task.assignee?.id}
              assigneeName={timelineItem.task.assignee?.name}
              taskId={timelineItem.task.id!}
              disabled={!timelineItem.task.open}
              refreshTasks={refreshTasks}
              profile={profile}
            />
          </Stack>
          <Group>
            <Button onClick={() => openResolveModal()} variant="outline" leftSection={<IconCheck />}>
              {!timelineItem.task.open ? 'Reopen' : 'Resolve'}
            </Button>
            <Menu>
              <Menu.Target>
                <Button variant="outline" leftSection={<IconExclamationCircleFilled />}>
                  Mark as...
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  disabled={timelineItem.task.priority.toString().toLowerCase() === 'urgent'}
                  onClick={() => onUpdatePriority(medplum, timelineItem.task.id!, 'urgent', refreshTasks)}
                >
                  <Group gap={0}>
                    <ThemeIcon c="status-error">
                      <IconExclamationCircleFilled />
                    </ThemeIcon>
                    <Text>Urgent</Text>
                  </Group>
                </Menu.Item>
                <Menu.Item
                  disabled={
                    timelineItem.task.priority.toString().toLowerCase() === 'routine' ||
                    timelineItem.task.isSuicideAlert
                  }
                  onClick={() => onUpdatePriority(medplum, timelineItem.task.id!, 'routine', refreshTasks)}
                >
                  Not urgent
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Grid>
      </Container>
    </>
  );
};
