import { notifications } from '@mantine/notifications';
import { copyToClipboard } from 'imagine-dsl/utils/strings';

export const copyToClipboardHandler = async (text: string | undefined): Promise<void> => {
  if (!text) {
    return;
  }

  await copyToClipboard(text);
  notifications.show({
    message: 'Copied to clipboard',
    color: 'status-success',
  });
};
