import * as Sentry from '@sentry/react';
import { getConfig } from './config';

const config = getConfig();

export const genericErrorMessage = 'Something went wrong';
export const pleaseFixErrorsAboveMessage = 'Please fix the errors above.';

export const getErrorMessage = (err: unknown, fallback: string = genericErrorMessage): string =>
  err instanceof Error ? err.message : fallback;

// The type is not exported from @sentry/react or @sentry/core
type SentryExceptionHint = Parameters<typeof Sentry.captureException>[1];

export const logError = (err: unknown, hint?: SentryExceptionHint): void => {
  if (config.appEnv === 'local') {
    // eslint-disable-next-line no-console
    console.error(err, hint);
  } else {
    Sentry.captureException(err, hint);
  }
};
