import React from 'react';
import { Alert, Box, Group, Radio, RadioGroup, Select, Stack, Text } from '@mantine/core';
import { formatAddress } from '@medplum/core';
import { Coding, Patient } from '@medplum/fhirtypes';
import { CodingInput } from '@medplum/react';
import { IconAlertCircle } from '@tabler/icons-react';
import { HL7ValueSet, ContactType, extractValues } from 'const-utils';
import { getName } from 'imagine-dsl/utils/patient';
import { formatPhoneString } from 'imagine-dsl/utils/strings';
import { uniqBy } from 'lodash';
import { useMaybeExistingContacts } from './MaybeExistingContactContext';

const ContactTypeCodes = extractValues(ContactType);

export type ExistingContact = {
  patient: Patient;
  relationship: Coding;
  contactType?: Coding;
};

export const MaybeExistingContactForm = (): React.ReactNode => {
  const { setSelected, selected, setRelationship, relationship, setContactType, contactType, candidates, key } =
    useMaybeExistingContacts();

  if (!candidates?.length) {
    return null;
  }

  const title = candidates.length > 1 ? 'Existing contacts' : 'Existing contact';
  const message =
    candidates.length > 1
      ? 'Multiple existing contacts with matching phone number(s). Please review and select the appropriate option.'
      : 'A contact with matching phone number(s) already exists. Please review and select the appropriate option.';

  return (
    <Alert icon={<IconAlertCircle />} variant="outline" color="orange" my="lg" title={title}>
      <Text mb="md">{message}</Text>
      <Stack>
        <RadioGroup
          key={key}
          variant="vertical"
          onChange={(id) => setSelected(candidates.find((c) => c.id === id) ?? null)}
          defaultValue={selected === null ? 'none' : selected?.id}
        >
          {candidates.map((maybeExistingContact) => {
            const label = (
              <Box style={{ fontSize: '1.5rem' }}>
                <Text fw="bold">{getName(maybeExistingContact)}</Text>
                {uniqBy(maybeExistingContact.telecom ?? [], (t) => formatPhoneString(t.value)).map((telecom, i) => (
                  <Text key={i}>
                    {formatPhoneString(telecom.value)}
                    {telecom.use && (
                      <Text span c="dimmed">
                        {' '}
                        {telecom.use}
                      </Text>
                    )}
                  </Text>
                ))}
                {maybeExistingContact.address?.[0] && (
                  <Text c="dimmed">{formatAddress(maybeExistingContact.address?.[0])}</Text>
                )}
              </Box>
            );

            return <Radio key={maybeExistingContact.id} mb="sm" label={label} value={maybeExistingContact.id} />;
          })}
          <Radio key="none" mb="sm" value="none" label="None apply - continue with creating new contact" />
        </RadioGroup>
      </Stack>
      {selected && (
        <Group mt="lg">
          <Select
            clearable
            label="Contact type"
            placeholder="Please select"
            data={ContactTypeCodes.map((d) => d.display) as string[]}
            value={contactType?.display}
            onChange={(value) => setContactType(ContactTypeCodes.find((c) => c.display === value))}
          />
          <CodingInput
            name="relationship"
            path="relationship"
            required
            label="Relationship to patient"
            binding={HL7ValueSet.RelatedPersonRelationshipType}
            defaultValue={relationship}
            onChange={setRelationship}
            withHelpText={false}
          />
        </Group>
      )}
    </Alert>
  );
};
