import React from 'react';
import { Box, Group, Stack, Table, Text, ThemeIcon } from '@mantine/core';
import { IconArrowsShuffle, IconPointFilled } from '@tabler/icons-react';
import { OutreachAttempt } from 'imagine-dsl/utils/outreach';
import { formatPhoneString, ianaToTimezone } from 'imagine-dsl/utils/strings';
import { EditTouchpointButton } from '@/components/outreach/EditTouchpointButton';
import { sanitizeHtmlFromString } from '@/utils/htmlUtils';
import { outreachModalityMap } from 'const-utils/codeSystems/ImaginePediatrics';
import { capitalize } from 'lodash';
import { formatWithYesterdayAndToday } from 'imagine-dsl/utils/dates';
import { format } from 'date-fns';

type Element = {
  label: string;
  value: string;
};

const TouchpointElements = ({ elements }: { elements: Element[] }): JSX.Element => {
  return (
    <Group gap={4} maw="75%">
      {elements.map((element, idx) => (
        <React.Fragment key={element.label}>
          <TextWithLabel label={element.label} text={element.value} />
          {idx !== elements.length - 1 && <IconPointFilled size={14} color="imagine-gray" />}
        </React.Fragment>
      ))}
    </Group>
  );
};

const TextWithLabel = ({ label, text }: { label: string; text: string }): JSX.Element => {
  return (
    <Group gap={4}>
      <Text size="xs" fw={400} c="imagine-gray">
        {label}:
      </Text>
      <Text size="xs" fw={700} c="imagine-gray">
        {text}
      </Text>
    </Group>
  );
};

interface TouchpointCardProps {
  touchpoint: OutreachAttempt;
  patientId: string;
  hideEditButton?: boolean;
}
type MaybeElement = {
  label: string;
  value?: string;
};
export const TouchpointActivityItem = (props: TouchpointCardProps): JSX.Element => {
  const elements: Element[] = [
    {
      label: 'Modality',
      value:
        props.touchpoint.modality && outreachModalityMap[props.touchpoint.modality]
          ? outreachModalityMap[props.touchpoint.modality].display
          : capitalize(props.touchpoint.modality), // TODO: cleanup after cut-over to new outreach modalities and dispositions
    },
    { label: 'Phone number', value: formatPhoneString(props.touchpoint.phoneNumber) },
    { label: 'Contact name', value: props.touchpoint.contactName },
    { label: 'Outcome', value: props.touchpoint.outcome },
    {
      label: 'Callback time',
      value: props.touchpoint.callbackDateTimeStart
        ? `${format(props.touchpoint.callbackDateTimeStart, 'MM/dd/yyyy hh:mm aaa')} ${ianaToTimezone(
            props.touchpoint.callbackTimezone || '',
          )}`
        : undefined,
    },
  ].filter((element: MaybeElement): element is Element => !!element.value);

  return (
    <Table.Tr key={props.touchpoint.id} style={{ verticalAlign: 'top' }}>
      <Table.Td>
        <Stack justify="flex-start" mt="25px">
          <ThemeIcon bd="1px solid touchpoint-icon.5" c="touchpoint-icon.5" pos="relative" p={2} radius="100%">
            <IconArrowsShuffle size={16} />
          </ThemeIcon>
        </Stack>
      </Table.Td>
      <Table.Td>
        <Text size="xs" c="brand-gray.7">
          {props.touchpoint.sentTime
            ? formatWithYesterdayAndToday(props.touchpoint.sentTime, 'EEE, MMMM do, yyyy hh:mm aaa', true)
            : 'Unknown'}
        </Text>
        <Group justify="space-between">
          <Group gap={8} my={10} fz="sm" maw="90%">
            <Text fw="bold">Touchpoint</Text>
            <Text>created by</Text>
            <Text fw="bold">{props.touchpoint.senderName}</Text>
            <Text fw={400} fz="xs" c="imagine-gray">
              ({props.touchpoint.senderRole})
            </Text>
          </Group>

          {!props.hideEditButton && (
            <EditTouchpointButton
              basedOn={props.touchpoint.basedOn}
              patientId={props.patientId}
              outreachAttempt={props.touchpoint}
            />
          )}
        </Group>
        <TouchpointElements elements={elements} />
        {props.touchpoint.note && (
          <Box mt={10} mr={35} bg="imagine-gray.0" p="12px" style={{ borderRadius: '4px' }}>
            <Text size="14px">{sanitizeHtmlFromString(props.touchpoint.note)}</Text>
          </Box>
        )}
      </Table.Td>
    </Table.Tr>
  );
};
