import { Composition, CompositionSection, Practitioner, Reference } from '@medplum/fhirtypes';

interface ICareHubNote {
  note: Composition;
}

interface ICareHubNoteSection {
  section: CompositionSection;
}

export class CareHubNote implements ICareHubNote {
  note: Composition;

  constructor(note: Composition) {
    this.note = note;
  }

  noteTextFromSectionIndex(index: number): string {
    const section = this.sections.at(index);
    if (!section?.noteText) {
      return '';
    }

    return section.noteText;
  }

  get sections(): CareHubNoteSection[] {
    return this.note.section?.map((section) => new CareHubNoteSection(section)) || [];
  }

  get id(): string | undefined {
    return this.note.id;
  }

  noteAuthorReferenceFromSectionIndex(index: number): Reference<Practitioner> | undefined {
    const section = this.sections.at(index);

    if (!section?.authorReference) {
      return undefined;
    }
    return section.authorReference;
  }

  get noteHasBeenEdited(): boolean {
    return this.sections.length > 1;
  }

  get latestNoteSection(): CareHubNoteSection | undefined {
    return this.sections.at(0);
  }

  get noteHistory(): CareHubNoteSection[] {
    if (this.sections.length === 1) {
      return [];
    }

    return this.sections.slice(1);
  }
}

export class CareHubNoteSection implements ICareHubNoteSection {
  section: CompositionSection;

  constructor(section: CompositionSection) {
    this.section = section;
  }

  get noteText(): string {
    return this.section?.text?.div || '';
  }

  get authorReference(): Reference<Practitioner> | undefined {
    return this.section?.author?.at(0) as Reference<Practitioner> | undefined;
  }

  get editDateTime(): string {
    return this.section?.extension?.at(0)?.valueDateTime || '';
  }
}
