import { MedplumClient } from '@medplum/core';

const preferredNameDataRetention = async (value: string, patientId: string, medplum: MedplumClient): Promise<void> => {
  const refreshedPatient = await medplum.readResource('Patient', patientId);
  const filteredName = refreshedPatient?.name?.filter((nameEntry) => nameEntry.use !== 'usual') ?? [];

  await medplum.updateResource({
    ...refreshedPatient,
    name: [
      ...filteredName,
      {
        given: [value],
        family: refreshedPatient?.name?.[0].family, //retains legal last name
        use: 'usual',
      },
    ],
  });
};

export default preferredNameDataRetention;
