import { CodeableConcept, Coding, RelatedPerson } from '@medplum/fhirtypes';
import { RelatedPerson as GraphqlRelatedPerson, CodeableConcept as GraphqlCodeableConcept } from 'medplum-gql';
import { HL7ValueSet } from 'const-utils';

export const getRelatedPersonRelationship = (
  relatedPerson: RelatedPerson | GraphqlRelatedPerson,
): Coding | undefined => {
  return relatedPerson?.relationship
    ?.find(
      (relation: CodeableConcept | GraphqlCodeableConcept) =>
        relation?.coding?.[0]?.system === HL7ValueSet.RelatedPersonRelationshipType,
    )
    ?.coding?.at(0) as Coding;
};
