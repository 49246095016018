import { Text, Button, Stack, Modal, Box, Title } from '@mantine/core';
import { IconDeviceMobile, IconVideo } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import { useOutreach } from '../Context';
import { InAppPreview } from '../InAppPreview';
import { SuccessMessage } from '../SuccessMessage';
import { useVideo } from '@/components/video/VideoProvider';
import { formatHumanName } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import appImage from '@assets/images/in-app-previews/test-video.png';
import { HumanName } from '@medplum/fhirtypes';
import { useDisclosure } from '@mantine/hooks';
import { CaregiverDevices } from '@/components/devices/CaregiverDevices';
import { getName } from 'imagine-dsl/utils/patient';
import { logError } from '@/errors';

export const TestVideoCallStep = (): JSX.Element => {
  const { wrapHandler, error, loading, setVideoCallTestedAt, videoCallTestedAt, patient, caregiver } = useOutreach();
  const video = useVideo();
  const medplum = useMedplum();
  const [devicesInfoOpened, { open: openDevicesInfo, close: closeDevicesInfo }] = useDisclosure();

  useEffect(() => {
    if (videoCallTestedAt) {
      return;
    }

    if (!patient.id) {
      return;
    }

    const existingEncounter = medplum.searchOne('Encounter', {
      patient: patient.id,
      _tag: 'test',
    });

    existingEncounter
      .then((encounter) => {
        if (encounter) {
          setVideoCallTestedAt(new Date());
        }
      })
      .catch((err) => {
        logError(err);
      });
  }, [medplum, patient, setVideoCallTestedAt, videoCallTestedAt]);

  const handleTestVideoCall = async (): Promise<void> =>
    wrapHandler(async () => {
      const humanName = patient.name?.[0] && formatHumanName(patient.name?.[0] as HumanName);
      const encounter = await video.startTestCall({
        reference: `Patient/${patient.id}`,
        display: humanName,
      });

      if (encounter) {
        setVideoCallTestedAt(new Date());
      } else {
        throw new Error('Test video call failed');
      }
    });

  return (
    <InAppPreview imageUrl={appImage}>
      <Stack gap="md">
        <Button
          loading={loading}
          disabled={!!videoCallTestedAt}
          leftSection={<IconVideo />}
          onClick={handleTestVideoCall}
          variant="outline"
        >
          Test video call
        </Button>
        <Stack gap="xs">
          {error && <Text c="red">{error}</Text>}
          {videoCallTestedAt && (
            <SuccessMessage>
              Test video call occurred on: <strong>{videoCallTestedAt.toLocaleString()}</strong>
            </SuccessMessage>
          )}
        </Stack>
        {caregiver?.id && (
          <Stack>
            <Text>
              To troubleshoot,{' '}
              <Button
                p="0"
                variant="transparent"
                leftSection={<IconDeviceMobile size="16" />}
                onClick={openDevicesInfo}
              >
                <Text fw="bold" td="underline">
                  Check caregiver's device settings
                </Text>
              </Button>
            </Text>
            <Modal
              title={<Title>Device Settings</Title>}
              size="xl"
              opened={devicesInfoOpened}
              onClose={closeDevicesInfo}
            >
              <Box>
                <Title order={2} size="md">
                  {getName(caregiver)}
                </Title>
                <Box p="xs">
                  <CaregiverDevices caregiverId={caregiver.id} />
                </Box>
              </Box>
            </Modal>
          </Stack>
        )}
      </Stack>
    </InAppPreview>
  );
};
