import { Group, Tooltip, Badge } from '@mantine/core';
import React from 'react';

interface NotificationBubbleProps {
  unassignedCount: number;
  myCount: number;
}

export function NotificationBubble({ unassignedCount, myCount }: NotificationBubbleProps): JSX.Element {
  return (
    <Group gap={4}>
      <Tooltip fw="normal" label="Unassigned tasks">
        <Badge bg="brand-blue.7" variant="filled" size="xs">
          {unassignedCount}
        </Badge>
      </Tooltip>
      <Tooltip fw="normal" label="Tasks assigned to me">
        <Badge bg="brand-yellow.3" c="black" variant="filled" size="xs">
          {myCount}
        </Badge>
      </Tooltip>
    </Group>
  );
}
