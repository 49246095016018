import { Loader, Text } from '@mantine/core';
import { QuestionnaireResponse } from '@medplum/fhirtypes';
import { Maybe } from 'medplum-gql';
import React, { PropsWithChildren } from 'react';

interface BodyProps {
  loading: boolean;
  questionnaireResponse: Maybe<QuestionnaireResponse> | undefined;
  expanded?: boolean;
}

export function Body(props: PropsWithChildren<BodyProps>): JSX.Element {
  const { loading, questionnaireResponse, children, expanded } = props;

  if (loading) {
    return <Loader style={{ alignSelf: 'center' }} mt={16} />;
  }

  if (!questionnaireResponse) {
    return (
      <>
        {!expanded && (
          <Text ta="center" size="xl" fw={700} c="imagine-green">
            Questionnaire Response not found
          </Text>
        )}
        {expanded && (
          <Text ta="center" size="xl" fw={700} c="imagine-green">
            Questionnaire Response not found
          </Text>
        )}
      </>
    );
  }

  return <>{children}</>;
}
