import React, { useEffect, useRef } from 'react';
import { VideoTrack } from 'twilio-video';

interface VideoTrackProps {
  track?: VideoTrack;
}

export default function VideoElement({ track }: VideoTrackProps): JSX.Element {
  const ref = useRef<HTMLVideoElement>(null!);

  useEffect(() => {
    if (track) {
      const el = ref.current;
      track.attach(el);

      return () => {
        track.detach();
        el.srcObject = null;
      };
    }

    return () => {};
  }, [track]);

  return <video style={{ height: '100%', maxWidth: '100%' }} ref={ref} />;
}
