import { ActionIcon, Button, Flex, Group, Modal, Tabs, Text } from '@mantine/core';
import React from 'react';
import { ClinicalImpression } from '@medplum/fhirtypes';
import { ClusterBadge } from '@/components/ClusterBadge';
import { clusterFromClinicalImpressions } from 'imagine-dsl/utils/clinicalImpression';
import { IconEditCircle } from '@tabler/icons-react';
import { UseQueryResult } from '@tanstack/react-query';
import { Diagnoses } from '../patientCarePathway/Diagnoses';
import { CarePathwayHistory } from '../patientCarePathway/CarePathwayHistory';

interface carePathwayDetailsProps {
  isModalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  impressions: ClinicalImpression[];
  setCarePathwayEditModalOpen: () => void;
  hasOpenCarePathwayReferralReviewTask?: UseQueryResult<boolean>;
  carePathway?: string;
  patientId: string;
}

const CarePathwayInfoModal = ({
  isModalOpen,
  impressions,
  setModalOpen,
  setCarePathwayEditModalOpen,
  hasOpenCarePathwayReferralReviewTask,
  carePathway,
  patientId,
}: carePathwayDetailsProps): JSX.Element => {
  return (
    <Modal
      title={
        <Text size="xl" fw={500} c="imagine-green">
          Care pathway
        </Text>
      }
      opened={isModalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      size="xxl"
      radius="lg"
      centered
    >
      <Text fz="xs" mb="sm">
        Cluster is determined by certain qualifying diagnoses in claims data from the last 24 months.
      </Text>
      <Group gap="xs">
        <ClusterBadge
          cluster={clusterFromClinicalImpressions(impressions) || 'Unknown'}
          carePathway={carePathway}
          isOpenCarePathwayReferralReview={hasOpenCarePathwayReferralReviewTask?.data}
        />
        <ActionIcon
          onClick={() => {
            setModalOpen(false);
            setCarePathwayEditModalOpen();
          }}
          disabled={hasOpenCarePathwayReferralReviewTask?.data}
        >
          <IconEditCircle size={20} />
        </ActionIcon>
      </Group>
      <Tabs defaultValue="diagnoses" mt="md">
        <Tabs.List>
          <Tabs.Tab value="diagnoses">Diagnoses</Tabs.Tab>
          <Tabs.Tab value="carePathwayHistory">Care pathway history</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="diagnoses">
          <Diagnoses impressions={impressions} />
        </Tabs.Panel>

        <Tabs.Panel value="carePathwayHistory">
          <CarePathwayHistory patientId={patientId} />
        </Tabs.Panel>
      </Tabs>
      <Flex mt="xl" justify={'flex-end'}>
        <Button onClick={() => setModalOpen(false)}>Close</Button>
      </Flex>
    </Modal>
  );
};

export default CarePathwayInfoModal;
