import React from 'react';
import { Text, Group } from '@mantine/core';
import { caregiversFromPatient, isPrimaryCaregiver } from 'imagine-dsl/utils/patient';
import { Patient as GraphqlPatient, GetPatientQuery } from 'medplum-gql';
import { HL7System } from 'const-utils';
import { IconClockHour4 } from '@tabler/icons-react';
import { ianaToTimezone } from 'imagine-dsl/utils/strings';

interface TimeZoneDisplayProps {
  patient: GetPatientQuery['Patient'];
}

export const TimeZoneDisplay = ({ patient }: TimeZoneDisplayProps): JSX.Element => {
  const caregivers = caregiversFromPatient(patient as GraphqlPatient);

  const primaryCaregiver = caregivers.find((caregiver) => isPrimaryCaregiver(patient as GraphqlPatient, caregiver));

  const caregiver = primaryCaregiver || caregivers[0];

  const timeZone = caregiver?.extension?.find((ext) => ext.url === HL7System.TimeZone.toString())?.valueString;
  const mappedTimeZone = ianaToTimezone(timeZone || '');

  return (
    <Group gap="xs">
      <IconClockHour4 size={20} />
      <Text>{mappedTimeZone ? mappedTimeZone : 'Unknown time zone'}</Text>
    </Group>
  );
};
