import { ProfileResource } from '@medplum/core';
import { Annotation, Coding, Communication, Patient, Reference } from '@medplum/fhirtypes';
import {
  CommunicationCategory,
  CommunicationMedium,
  CommunicationNoteType,
  Extensions,
  SYSTEM_URL,
  System,
} from 'const-utils';
import { OutreachModality } from 'const-utils/codeSystems/ImaginePediatrics';

interface BuildOutreachAttemptCommunicationOptions {
  patientRef: Reference<Patient>;
  practitionerRef: Reference<ProfileResource>;
  outcome: Coding;
  sent: Date;
  modality: OutreachModality;
  phoneNumber: string;
  contactName?: string;
  callbackTime?: string;
  callbackStart?: Date;
  callbackEnd?: Date;
  callbackTimezone?: string;
  notes?: string;
  identifier?: string;
  basedOn?: Reference[];
}

export const buildOutreachAttemptCommunication = (
  options: BuildOutreachAttemptCommunicationOptions,
): Communication => ({
  identifier: options.identifier ? [{ system: System.OutreachAttempt, value: options.identifier }] : undefined,
  basedOn: options.basedOn,
  resourceType: 'Communication',
  status: 'completed',
  statusReason: { coding: [options.outcome] },
  subject: options.patientRef,
  sender: options.practitionerRef,
  sent: options.sent.toISOString(),
  category: [
    {
      coding: [
        {
          system: SYSTEM_URL,
          code: CommunicationCategory.Outreach,
        },
      ],
    },
  ],
  medium: [
    {
      coding: [
        {
          system: SYSTEM_URL,
          code: CommunicationMedium.Telephone,
        },
      ],
    },
  ],
  note: [
    {
      extension: [
        {
          url: Extensions.CommunicationNoteType,
          valueCode: CommunicationNoteType.OutreachModality,
        },
      ],
      authorReference: options.practitionerRef,
      text: options.modality,
    },
    options.contactName && {
      extension: [
        {
          url: Extensions.CommunicationNoteType,
          valueCode: CommunicationNoteType.ContactName,
        },
      ],
      authorReference: options.practitionerRef,
      text: options.contactName,
    },
    options.phoneNumber && {
      extension: [
        {
          url: Extensions.CommunicationNoteType,
          valueCode: CommunicationNoteType.PhoneNumber,
        },
      ],
      authorReference: options.practitionerRef,
      text: options.phoneNumber,
    },
    (options.callbackTime && {
      extension: [
        {
          url: Extensions.CommunicationNoteType,
          valueCode: CommunicationNoteType.CallbackTime,
        },
      ],
      authorReference: options.practitionerRef,
      text: options.callbackTime,
    }) ||
      null,
    (options.callbackStart && {
      extension: [
        {
          url: Extensions.CommunicationNoteType,
          valueCode: CommunicationNoteType.CallbackStart,
        },
      ],
      authorReference: options.practitionerRef,
      text: options.callbackStart.toISOString(),
    }) ||
      null,
    (options.callbackEnd && {
      extension: [
        {
          url: Extensions.CommunicationNoteType,
          valueCode: CommunicationNoteType.CallbackEnd,
        },
      ],
      authorReference: options.practitionerRef,
      text: options.callbackEnd.toISOString(),
    }) ||
      null,
    (options.callbackStart && {
      extension: [
        {
          url: Extensions.CommunicationNoteType,
          valueCode: CommunicationNoteType.CallbackTimezone,
        },
      ],
      authorReference: options.practitionerRef,
      text: options.callbackTimezone,
    }) ||
      null,
    (options.notes && {
      extension: [
        {
          url: Extensions.CommunicationNoteType,
          valueCode: CommunicationNoteType.Notes,
        },
      ],
      authorReference: options.practitionerRef,
      text: options.notes,
    }) ||
      null,
  ].filter(Boolean) as Annotation[],
});
