import React, { PropsWithChildren, useState } from 'react';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { UserSessionProvider } from './components/shared/UserSessionContext';
import { MedplumProvider, useMedplum } from '@medplum/react';
import { MantineProvider, MantineThemeOverride } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { DatesProvider, DatesProviderSettings } from '@mantine/dates';
import { MedplumClient } from '@medplum/core';
import { useNavigate } from 'react-router-dom';
import VideoProvider from './components/video/VideoProvider';
import { PusherProvider } from './components/PusherProvider';
import Pusher from 'pusher-js/types/src/core/pusher';
import { CommandCenterAlertAndCountProvider } from './pages/CommandCenter/CommandCenterAlertProvider';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import { PatientListProvider } from './hooks/usePatientList';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { api } from './utils/trpc';
import { httpLink } from '@trpc/client';
import { ProfilePreferencesProvider } from './context/ProfilePreferencesContext';
import { OutreachTaskAlertProvider } from './pages/OutreachTask/OutreachTaskAlertProvider';
import { NotificationsProvider } from './pages/Notifications/NotificationsProvider';
import { TaskCountProvider } from './pages/Task/TaskCountProvider';
import { TaskAlertProvider } from './pages/Task/TaskAlertProvider';

interface ProvidersProps {
  client: ApolloClient<NormalizedCacheObject>;
  theme: MantineThemeOverride;
  medplum: MedplumClient;
  pusher: Pusher;
  split: SplitIO.IBrowserSettings;
}

export const AuthenticatedProviders = ({ children }: PropsWithChildren): JSX.Element => {
  return (
    <UserSessionProvider>
      <CommandCenterAlertAndCountProvider>
        <TaskCountProvider>
          <OutreachTaskAlertProvider>
            <NotificationsProvider>
              <ProfilePreferencesProvider>
                <TaskAlertProvider>
                  <VideoProvider>{children}</VideoProvider>
                </TaskAlertProvider>
              </ProfilePreferencesProvider>
            </NotificationsProvider>
          </OutreachTaskAlertProvider>
        </TaskCountProvider>
      </CommandCenterAlertAndCountProvider>
    </UserSessionProvider>
  );
};

const TRPCProvider = ({ children }: { children: React.ReactNode }) => {
  const medplum = useMedplum();

  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    api.createClient({
      links: [
        httpLink({
          url: '/api/rpc',
          async headers() {
            return {
              Authorization: `Bearer ${medplum.getAccessToken()}`,
            };
          },
        }),
      ],
    }),
  );

  return (
    <api.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </api.Provider>
  );
};

const dateProviderSettings: DatesProviderSettings = {
  consistentWeeks: true,
};

const Providers = ({
  children,
  client,
  theme,
  medplum,
  pusher,
  split,
}: PropsWithChildren<ProvidersProps>): JSX.Element => {
  const navigate = useNavigate();

  return (
    <SplitFactoryProvider config={split}>
      <ApolloProvider client={client}>
        <MedplumProvider medplum={medplum} navigate={navigate}>
          <MantineProvider theme={theme}>
            <ModalsProvider>
              <DatesProvider settings={dateProviderSettings}>
                <TRPCProvider>
                  <PatientListProvider>
                    <PusherProvider pusher={pusher}>{children}</PusherProvider>
                  </PatientListProvider>
                </TRPCProvider>
              </DatesProvider>
            </ModalsProvider>
          </MantineProvider>
        </MedplumProvider>
      </ApolloProvider>
    </SplitFactoryProvider>
  );
};

export default Providers;
