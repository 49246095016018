import React from 'react';
import { IconChecklist } from '@tabler/icons-react';
import { PatientProfileAlert } from './PatientProfileAlert';
import { Group, Text, ThemeIcon } from '@mantine/core';
import { toUpper } from 'lodash';

interface PrapareScreenerAlertProps {
  onClick: () => void;
  name: string;
  responseCategories: string[];
  title: string;
}
export function SurveyReviewAlert({
  onClick,
  name,
  responseCategories,
  title,
}: PrapareScreenerAlertProps): JSX.Element {
  return (
    <PatientProfileAlert
      onClick={onClick}
      icon={
        <ThemeIcon>
          <IconChecklist size={20} />
        </ThemeIcon>
      }
      callToAction="View Task"
    >
      <Group gap={4}>
        <Text fw={700}>{name}</Text>
        <Text>
          has an open task: {title}, {responseCategories.map(toUpper).join(', ')}
        </Text>
      </Group>
    </PatientProfileAlert>
  );
}
