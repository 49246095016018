import { Box, Group, Radio, Stack, Text, Textarea } from '@mantine/core';
import React from 'react';
import { useOutreach } from '../Context';
import { TimeZoneDisplay } from '@/components/shared/patient/TimeZoneDisplay';

export const ScheduleOnboardingStep = (): JSX.Element => {
  const { patient, scheduleOnboardingVisitForm } = useOutreach();

  return (
    <form id="schedule-onboarding-visit-form">
      <Stack gap="md">
        <Box style={{ padding: 'var(--mantine-spacing-sm) var(--mantine-spacing-md)' }} bg="imagine-green.0">
          <Group>
            <Text>Caregivers Timezone</Text>
            <TimeZoneDisplay patient={patient} />
          </Group>
        </Box>
        <Radio.Group
          label="Onboarding visit scheduled?"
          required
          {...scheduleOnboardingVisitForm.getInputProps('visitScheduled')}
        >
          <Group mt="xs">
            <Radio label="Yes" value="yes" />
            <Radio label="No" value="no" />
          </Group>
        </Radio.Group>
        <Textarea label="Notes" {...scheduleOnboardingVisitForm.getInputProps('notes')} />
      </Stack>
    </form>
  );
};
