import React, { useEffect, useRef } from 'react';
import { AudioTrack } from 'twilio-video';

interface AudioTrackProps {
  track?: AudioTrack;
}

export default function AudioElement({ track }: AudioTrackProps): JSX.Element {
  const ref = useRef<HTMLAudioElement>(null!);

  useEffect(() => {
    if (track) {
      const el = ref.current;
      track.attach(el as HTMLAudioElement);
      return () => {
        track.detach();
      };
    }

    return () => {};
  }, [track]);

  return <audio ref={ref} />;
}
