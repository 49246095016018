import { useForm, UseFormReturnType } from '@mantine/form';
import { GetPatientQuery } from 'medplum-gql';
import { Patient } from '@medplum/fhirtypes';
import { AppDownloadFormValues } from '../types';
import { getPatientCaregiver, hasSMSConsent } from '../util';
import { System } from 'const-utils';

interface UseAppDownloadFormInput {
  patient: GetPatientQuery['Patient'];
}

export const useAppDownloadForm = ({ patient }: UseAppDownloadFormInput): UseFormReturnType<AppDownloadFormValues> => {
  const caregiver = getPatientCaregiver(patient);

  const consent = caregiver ? hasSMSConsent(caregiver as Patient) : false;

  const phoneNumbers = caregiver?.telecom?.filter((t) => t.system === 'phone') || [];

  const defaultPhone =
    phoneNumbers.find((pn) => pn?.extension?.find((e) => e.url === System.PrimaryPhone.toString())?.valueBoolean) ||
    phoneNumbers[0];

  return useForm<AppDownloadFormValues>({
    initialValues: {
      phoneNumber: defaultPhone?.value || '',
      consent,
    },
    validate: {
      phoneNumber: (value, values) => (values.consent && !value ? 'Phone number is required' : null),
    },
  });
};
