import { sort } from '@/pages/CommandCenter/utils/sort';
import { Organization, Patient } from '@medplum/fhirtypes';
import {
  GetIncompleteChatTasksDocument,
  GetIncompleteChatTaskCountDocument,
  GetIncompleteChatTasksQuery,
  GetIncompleteSuicideAlertTasksDocument,
  Maybe,
  Practitioner,
  useGetIncompleteChatTaskCountQuery,
  useGetIncompleteChatTasksQuery,
  useGetIncompleteSuicideAlertTasksQuery,
} from 'medplum-gql';
import { compact, map } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NotificationData, notifications } from '@mantine/notifications';
import { useGlobalChannelSubscription, usePractitionerChannelSubscriptions } from '@/components/PusherProvider';
import { NewAssignedTask, NewTaskActivity, NewUnassigenedTask } from 'imagine-dsl/services/notificationService';
import { useInterval } from '@mantine/hooks';
import { useUserSession } from '@/components/shared/UserSessionContext';
import { logError } from '@/errors';
import { UserRole } from 'const-utils/codeSystems/ImaginePediatrics';
import { CommandCenterAlertNotification } from './CommandCenterAlertNotification';
import { useApolloClient } from '@apollo/client';

const SILENCED_ROLES = [UserRole.Outreach, UserRole.OutreachSupervisor];

interface CommandCenterAlertContext {
  tasks: GetIncompleteChatTasksQuery['TaskList'];
  incompleteTasks: GetIncompleteChatTasksQuery['TaskList'];
  loading: boolean;
  defaultMarketId?: Maybe<string>;
  userMarkets: Organization[];
}

const CommandCenterAlertContextValue = createContext<CommandCenterAlertContext | undefined>(undefined);

interface CommandCenterAlertProviderProps {
  children: React.ReactNode;
  pollInterval?: number;
}

const notificationData = ({
  id,
  message,
  color,
  patientId,
}: {
  id: string;
  message: string;
  color: string;
  patientId: string;
}): NotificationData => {
  const alertContent = <CommandCenterAlertNotification path={`/?patient=${patientId}`} message={message} id={id} />;
  return {
    id,
    message: alertContent,
    color,
    autoClose: 60000,
  };
};

export const useCommandCenterAlertContext = (): CommandCenterAlertContext => {
  const context = useContext(CommandCenterAlertContextValue);
  if (!context) {
    throw new Error('useCommandCenterAlertContext must be used within a CommandCenterAlertProvider');
  }
  return context;
};

export function CommandCenterAlertProvider({
  children,
  pollInterval = 30000,
}: CommandCenterAlertProviderProps): JSX.Element {
  const { markets } = useUserSession();
  const defaultMarketId = useMemo(() => {
    return markets?.length === 1 ? markets[0].id! : null;
  }, [markets]);

  const { data, refetch, loading } = useGetIncompleteChatTasksQuery({ pollInterval: 60000 });
  const { data: suicideAlertTaskData, loading: suicideAlertLoading } = useGetIncompleteSuicideAlertTasksQuery({
    pollInterval: 60000,
  });

  const tasks = useMemo(() => data?.TaskList || [], [data]);
  const suicideAlertTasks = useMemo(() => suicideAlertTaskData?.TaskList || [], [suicideAlertTaskData]);

  const incompleteTasks = useMemo(() => {
    const incomplete = compact(
      tasks?.filter(
        (task) =>
          task?.status !== 'completed' &&
          task?.status !== 'cancelled' &&
          map(markets, 'id').includes((task?.for?.resource as Patient)?.managingOrganization?.resource?.id),
      ),
    );

    const suicideAlertIncomplete = compact(
      suicideAlertTasks?.filter(
        (task) =>
          task?.status !== 'completed' &&
          task?.status !== 'cancelled' &&
          map(markets, 'id').includes((task?.for?.resource as Patient)?.managingOrganization?.resource?.id),
      ),
    );

    const tasksToSort = suicideAlertIncomplete.concat(incomplete);
    sort(tasksToSort);

    return tasksToSort;
  }, [tasks, markets, suicideAlertTasks]);

  useInterval(() => {
    refetch().catch((e) => logError(e));
  }, pollInterval);

  return (
    <CommandCenterAlertContextValue.Provider
      value={{
        userMarkets: markets,
        defaultMarketId,
        loading: loading || suicideAlertLoading,
        tasks,
        incompleteTasks,
      }}
    >
      {children}
    </CommandCenterAlertContextValue.Provider>
  );
}

interface CommandCenterAlertAndCountContext {
  tasksAssignedToMeCount: number;
  tasksUnassignedCount: number;
}

const CommandCenterAlertAndCountContextValue = createContext<CommandCenterAlertAndCountContext | undefined>(undefined);

interface CommandCenterAlertAndCountProviderProps {
  children: React.ReactNode;
  pollInterval?: number;
}

export const useCommandCenterAlertAndCountContext = (): CommandCenterAlertAndCountContext => {
  const context = useContext(CommandCenterAlertAndCountContextValue);
  if (!context) {
    throw new Error('useCommandCenterAlertAndCountContext must be used within a CommandCenterAlertAndCountProvider');
  }
  return context;
};

export function CommandCenterAlertAndCountProvider({ children }: CommandCenterAlertAndCountProviderProps): JSX.Element {
  const { markets, roles, profile } = useUserSession();
  const apolloClient = useApolloClient();
  const myMarketIds = markets.map((market) => market.id!);
  const { data } = useGetIncompleteChatTaskCountQuery();
  const [tasksAssignedToMeCount, setTasksAssignedToMeCount] = useState<number>(0);
  const [tasksUnassignedCount, setTasksUnassignedCount] = useState<number>(0);

  const refetch = () =>
    apolloClient.refetchQueries({
      include: [
        GetIncompleteSuicideAlertTasksDocument,
        GetIncompleteChatTasksDocument,
        GetIncompleteChatTaskCountDocument,
      ],
    });

  const shouldNotify = useCallback(
    (marketId: string): boolean => {
      if (roles.length > 0 && roles.every((role) => SILENCED_ROLES.includes(role.code))) {
        return false;
      }

      return markets.some((market) => market.id === marketId);
    },
    [markets, roles],
  );

  useGlobalChannelSubscription('new-unassigned-task', ({ taskId, marketId, patientId }: NewUnassigenedTask) => {
    if (!shouldNotify(marketId)) {
      return;
    }

    const id = `new-unassigned-task-${taskId}`;
    notifications.show(
      notificationData({
        id,
        message: 'A new alert has come into the Command Center',
        color: 'status-info',
        patientId,
      }),
    );
    refetch().catch((e) => logError(e));
  });

  usePractitionerChannelSubscriptions([
    {
      eventType: 'new-assigned-task',
      callback: ({ taskId, marketId, patientId }: NewAssignedTask) => {
        if (!shouldNotify(marketId)) {
          return;
        }

        const id = `new-assigned-task-${taskId}`;
        notifications.show(
          notificationData({ id, message: 'You have been assigned a new alert', color: 'status-warn', patientId }),
        );
        refetch().catch((e) => logError(e));
      },
    },
    {
      eventType: 'new-task-activity',
      callback: ({ taskId, marketId, patientId }: NewTaskActivity) => {
        if (!shouldNotify(marketId)) {
          return;
        }

        const id = `new-task-activity-${taskId}`;
        notifications.show({
          ...notificationData({
            id,
            message: 'You received a reply on an alert that is assigned to you.',
            color: 'status-info',
            patientId,
          }),
        });
        refetch().catch((e) => logError(e));
      },
    },
  ]);

  useEffect(() => {
    //get the count of tasks assigned to me
    const myCount =
      data?.TaskList?.filter((task) => (task?.owner?.resource as Practitioner)?.id === profile.id).length ?? 0;
    setTasksAssignedToMeCount(myCount);
    //get the count of tasks unassigned but in markets I have access to
    const unassignedCount =
      data?.TaskList?.filter(
        (task) =>
          task?.owner === null &&
          myMarketIds.includes((task?.for?.resource as Patient)?.managingOrganization?.resource?.id ?? ''),
      ).length ?? 0;
    setTasksUnassignedCount(unassignedCount);
  }, [data, profile.id, myMarketIds]);

  return (
    <CommandCenterAlertAndCountContextValue.Provider
      value={{
        tasksAssignedToMeCount,
        tasksUnassignedCount,
      }}
    >
      {children}
    </CommandCenterAlertAndCountContextValue.Provider>
  );
}
