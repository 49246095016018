import React, { useCallback, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Button, ButtonProps, ComboboxItem, Group, Modal, Select, Text } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { resolveTask } from 'imagine-dsl/services/taskService';
import { useMedplum } from '@medplum/react';
import { useApolloClient } from '@apollo/client';
import { GetPatientPanelDocument, GetSubTasksDocument } from 'medplum-gql';
import { CodeableConcept } from '@medplum/fhirtypes';

interface ResolveTaskButtonProps extends Omit<ButtonProps, 'onClick'> {
  taskId: string;
  callback?: () => void;
  reasons?: {
    system: string;
    options: ComboboxItem[];
  };
}

const useResolveTask = (
  taskId: string,
  callback?: () => void,
): [(reason?: CodeableConcept) => Promise<void>, boolean] => {
  const [loading, setLoading] = useState(false);
  const medplum = useMedplum();
  const apolloClient = useApolloClient();

  const resolve = useCallback(
    async (reason?: CodeableConcept): Promise<void> => {
      setLoading(true);
      try {
        await resolveTask(medplum, taskId, undefined, reason);
        await apolloClient.refetchQueries({ include: [GetPatientPanelDocument, GetSubTasksDocument] });
      } finally {
        setLoading(false);
      }

      if (callback) {
        callback();
      }
    },
    [callback, medplum, taskId, apolloClient],
  );

  return [resolve, loading];
};

export const ResolveTaskButton = ({
  taskId,
  callback,
  reasons,
  ...buttonProps
}: ResolveTaskButtonProps): JSX.Element => {
  const [open, handle] = useDisclosure();
  const handleClose = () => {
    callback?.();
    handle.close();
  };

  const [resolve, loading] = useResolveTask(taskId, handleClose);
  const [reasonCode, setReasonCode] = useState<string>();

  const hasReasonCodes = reasons && reasons.options.length > 0;
  const disabledSubmit = hasReasonCodes && !reasonCode;

  return (
    <>
      <Button variant="outline" leftSection={<IconCheck size={16} />} {...buttonProps} onClick={handle.open}>
        Resolve
      </Button>
      <Modal opened={open} onClose={handle.close} title="Resolve task" c="imagine-green" fz="xs" size="lg">
        <Text>Are you sure you want to resolve this task?</Text>
        {hasReasonCodes && (
          <Select
            label="Reason"
            mt="md"
            required
            placeholder="Select reason"
            data={reasons.options}
            onChange={(c) => setReasonCode(c || undefined)}
            value={reasonCode ?? ''}
          />
        )}
        <Group justify="flex-end" mt="lg">
          <Button disabled={loading} onClick={handle.close} variant="outline">
            Cancel
          </Button>
          <Button
            disabled={disabledSubmit}
            onClick={() =>
              resolve(
                reasonCode
                  ? {
                      coding: [{ system: reasons?.system, code: reasonCode }],
                    }
                  : undefined,
              )
            }
            loading={loading}
          >
            Resolve
          </Button>
        </Group>
      </Modal>
    </>
  );
};
