import { CommunicationMedium } from './Communications';
import { MarketIdentifier } from './Organizations';

export const enum EngagementTeamPod {
  PodA = 'engagement-pod-a',
  PodB = 'engagement-pod-b',
  PodC = 'engagement-pod-c',
  PodD = 'engagement-pod-d',
  PodE = 'engagement-pod-e',
  PodF = 'engagement-pod-f',
}
export enum EngagementPodAssignmentFallbackReason {
  MissingPostalCode = 'missing-postal-code',
  UnmappedPostalCode = 'unmapped-postal-code',
  MissingCareTeamForPod = 'missing-care-team-for-pod', // this shouldn't happen and would be a signal of a misconfiguration but adding to address the code-flow branch
}

export const engagementPods: Record<EngagementTeamPod, { display: string; market: MarketIdentifier }> = {
  [EngagementTeamPod.PodA]: {
    display: 'Engagement Pod A (TX - Valley)',
    market: MarketIdentifier.Texas,
  },
  [EngagementTeamPod.PodB]: {
    display: 'Engagement Pod B (TX - Dallas)',
    market: MarketIdentifier.Texas,
  },
  [EngagementTeamPod.PodC]: {
    display: 'Engagement Pod C (TX - Houston Med Center)',
    market: MarketIdentifier.Texas,
  },
  [EngagementTeamPod.PodD]: {
    display: 'Engagement Pod D (TX - Houston/San Antonio)',
    market: MarketIdentifier.Texas,
  },
  [EngagementTeamPod.PodE]: {
    display: 'Engagement Pod E (TX - West Texas)',
    market: MarketIdentifier.Texas,
  },
  [EngagementTeamPod.PodF]: {
    display: 'Engagement Pod F (FL - Ocala)',
    market: MarketIdentifier.Florida,
  },
};

export const marketIdentifierToEngagementPods: Record<MarketIdentifier, EngagementTeamPod[]> = Object.entries(
  engagementPods,
).reduce(
  (acc, [pod, { market }]) => {
    if (!acc[market]) {
      acc[market] = [];
    }
    acc[market].push(pod as EngagementTeamPod);
    return acc;
  },
  // using the type parameter throws an error as the default value does not match the type
  // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
  {} as Record<MarketIdentifier, EngagementTeamPod[]>,
);

export enum OutreachStatus {
  NotYetOutreached = 'not-yet-outreached',
  InProgress = 'in-progress',
  HardToReach = 'hard-to-reach',
  NotInterested = 'not-interested',
  Enrolled = 'enrolled',
}

export const outreachStatusMap: Record<OutreachStatus, { label: string; showWarning: boolean }> = {
  [OutreachStatus.NotYetOutreached]: {
    label: 'Not Yet Outreached',
    showWarning: false,
  },
  [OutreachStatus.InProgress]: {
    label: 'In Progress',
    showWarning: false,
  },
  [OutreachStatus.HardToReach]: {
    label: 'Hard to Reach',
    showWarning: false,
  },
  [OutreachStatus.NotInterested]: {
    label: 'Not Interested at this Time',
    showWarning: true,
  },
  [OutreachStatus.Enrolled]: {
    label: 'Enrolled',
    showWarning: false,
  },
};

export const outreachStatusOptions = Object.entries(outreachStatusMap)
  .map(([key, { label }]) => ({
    value: key,
    label,
  }))
  .sort((a, b) => a?.label?.localeCompare(b?.label ?? '') ?? 0);

export const enum CommunicationNoteType {
  CallRoute = 'call-route', // TODO: cleanup after cut-over to new outreach modalities and dispositions
  OutreachModality = 'outreach-modality',
  ContactName = 'contact-name',
  PhoneNumber = 'phone-number',
  CallbackTime = 'callback-time',
  CallbackStart = 'callback-start',
  CallbackEnd = 'callback-end',
  CallbackTimezone = 'callback-timezone',
  Notes = 'notes',
}

// TODO: cleanup after cut-over to new outreach modalities and dispositions
export const enum CallRoute {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
  DoorKnock = 'door-knock',
}

export enum OutreachDisposition {
  EnrollmentCompletedOnboardingVisitScheduled = 'enrollment-completed-onboarding-visit-scheduled',
  EnrollmentCompletedOnboardingVisitNotScheduled = 'enrollment-completed-onboarding-visit-not-scheduled',
  ReEngagedOnboardingVisitScheduled = 're-engaged-onboarding-visit-scheduled',
  CallbackRequested = 'callback-requested',
  NotHomeOrAvailable = 'not-home-or-available',
  WantsPCPConsult = 'want-pcp-consult',
  MobileAppOrLoginIssues = 'mobile-app-or-login-issues',
  HungUp = 'hung-up',
  DoorShut = 'door-shut',
  DoNotCall = 'do-not-call',
  CallQualityIssue = 'call-quality-issue',
  LanguageAssist = 'language-assist',
  LeftVoicemail = 'left-voicemail',
  InvalidNumber = 'invalid-number',
  NotAnswered = 'not-answered',
  DoorNotAccessible = 'door-not-accessible',
  WrongNumber = 'wrong-number',
  WrongAddress = 'wrong-address',
  Deceased = 'deceased',
  OutOfState = 'out-of-state',
  Over18 = 'over-18',
  Facility = 'facility',
  PatientEnrolledEscalationVisitScheduled = 'patient-enrolled-escalation-visit-scheduled',
  DeferredNotAGoodFit = 'deferred-not-a-good-fit',
  DeferredNeedsTakenCareOf = 'deferred-needs-taken-care-of',
  DeferredMoreInfoNeeded = 'deferred-more-info-needed',
  DeferredConfirmInsurance = 'deferred-confirm-insurance',
  DeferredPrefersInPerson = 'deferred-prefers-in-person',
  DeferredLackOfAppointmentAvailability = 'deferred-lack-of-appointment-availability',
  DeferredOther = 'deferred-other',
  UnableToEnrollDeceased = 'unable-to-enroll-deceased',
  UnableToEnrollOutOfCoverageArea = 'unable-to-enroll-out-of-coverage-area',
  UnableToEnrollIneligible = 'unable-to-enroll-ineligible',
  UnableToSpeakWithCaregiver = 'unable-to-speak-with-caregiver',
  VisitScheduledBHTOC = 'visit-scheduled-bh-toc',
  TextSent = 'text-sent',
  Other = 'other',
}

export const outreachDispositionMap: Record<OutreachDisposition, { display: string; definition?: string }> = {
  [OutreachDisposition.EnrollmentCompletedOnboardingVisitScheduled]: {
    display: 'Patient enrolled, onboarding visit or chart review scheduled',
    definition:
      'Patient enrolled, onboarding visit or chart review scheduled: The touchpoint led to the completion of the enrollment flow. This value is applied automatically when the enrollment flow is completed, and an onboarding visit has been scheduled. No follow-up needed.',
  },
  [OutreachDisposition.EnrollmentCompletedOnboardingVisitNotScheduled]: {
    display: 'Patient enrolled, onboarding visit not scheduled',
    definition:
      'The touchpoint led to the completion of the enrollment flow. This value is applied automatically when the enrollment flow is completed.',
  },
  [OutreachDisposition.ReEngagedOnboardingVisitScheduled]: {
    display: 'Patient re-engaged, onboarding visit scheduled',
    definition:
      'The recipient was re-engaged after unsuccessful onboarding appointment to schedule another onboarding visit.',
  },
  [OutreachDisposition.CallbackRequested]: {
    display: 'Callback requested',
    definition:
      'The recipient requested a callback at a specific date or time when it’d be better to connect. The call was either answered by someone who is not a primary contact or caregiver, or just connected at an inconvenient time.',
  },
  [OutreachDisposition.NotHomeOrAvailable]: {
    display: 'Not home or available',
    definition: 'Spoke to someone, caregiver not home or available.',
  },
  [OutreachDisposition.WantsPCPConsult]: {
    display: 'Wants to consult PCP',
    definition: 'Spoke to someone, caregiver not home or available.',
  },
  [OutreachDisposition.MobileAppOrLoginIssues]: {
    display: 'Attempted to enroll, mobile app or tech issues',
  },
  [OutreachDisposition.HungUp]: {
    display: 'Hung up',
    definition: 'The recipient hung up or disconnected the call.',
  },
  [OutreachDisposition.DoorShut]: {
    display: 'Door shut/not interested in speaking',
    definition: 'Door shut/not interested in speaking.',
  },
  [OutreachDisposition.DeferredNotAGoodFit]: {
    display: "Deferred, doesn't feel services are a good fit for child's needs",
  },
  [OutreachDisposition.DeferredNeedsTakenCareOf]: {
    display: "Deferred, child's needs are being taken care of with existing care team",
  },
  [OutreachDisposition.DeferredMoreInfoNeeded]: {
    display: 'Deferred, would like more information on Imagine',
  },
  [OutreachDisposition.DeferredConfirmInsurance]: {
    display: 'Deferred, would like to confirm partnership with insurance plan',
  },
  [OutreachDisposition.DeferredPrefersInPerson]: {
    display: 'Deferred, prefers in person services',
  },
  [OutreachDisposition.DeferredLackOfAppointmentAvailability]: {
    display: 'Deferred, lack of appointment availability',
  },
  [OutreachDisposition.DeferredOther]: {
    display: 'Deferred, other',
  },
  [OutreachDisposition.DoNotCall]: {
    display: 'Do not call',
    definition:
      'The recipient requested to be removed from the calling list or expressed a desire not to be contacted.',
  },
  [OutreachDisposition.CallQualityIssue]: {
    display: 'Unable to complete call',
    definition: 'Unable to complete call - translation challenge or call quality issue.',
  },
  [OutreachDisposition.LanguageAssist]: {
    display: 'Language assist',
    definition: 'Communication was challenging due to a language barrier. Denote language preference in notes.',
  },
  [OutreachDisposition.LeftVoicemail]: {
    display: 'Left voicemail',
    definition: 'The team left a voicemail message for the recipient, but there was no live conversation.',
  },
  [OutreachDisposition.InvalidNumber]: {
    display: 'Invalid number',
    definition: 'The phone number has been disconnected or is no longer in service.',
  },
  [OutreachDisposition.NotAnswered]: {
    display: 'Not answered',
    definition:
      'The recipient did not answer the call, a busy signal was reached, voicemail was full and no voicemail was left, or unable to reach in person.',
  },
  [OutreachDisposition.DoorNotAccessible]: {
    display: 'Door not accessible',
    definition: 'Door not accessible (ie: gated entry, loose animals).',
  },
  [OutreachDisposition.WrongNumber]: {
    display: 'Wrong number',
    definition: 'The phone number reached was incorrect, or not associated with the intended recipient.',
  },
  [OutreachDisposition.WrongAddress]: {
    display: 'Wrong address',
    definition: 'The address was incorrect, or not associated with the intended recipient.',
  },
  [OutreachDisposition.Deceased]: {
    display: 'Deceased',
    definition: 'The recipient is deceased.',
  },
  [OutreachDisposition.OutOfState]: {
    display: 'Out of state',
    definition: 'The recipient is out of state.',
  },
  [OutreachDisposition.Over18]: {
    display: 'Over 18',
    definition: 'The recipient is over 18.',
  },
  [OutreachDisposition.Facility]: {
    display: 'Facility',
    definition: 'Spoke to caregiver, child in a facility.',
  },
  [OutreachDisposition.PatientEnrolledEscalationVisitScheduled]: {
    display: 'Patient enrolled, escalation visit scheduled',
    definition: 'Patient enrolled, escalation visit scheduled',
  },
  [OutreachDisposition.UnableToEnrollDeceased]: {
    display: 'Unable to enroll, deceased',
  },
  [OutreachDisposition.UnableToEnrollOutOfCoverageArea]: {
    display: 'Unable to enroll, out of coverage area',
  },
  [OutreachDisposition.UnableToEnrollIneligible]: {
    display: 'Unable to enroll, ineligible',
  },
  [OutreachDisposition.UnableToSpeakWithCaregiver]: {
    display: 'Unable to speak with caregiver',
  },
  [OutreachDisposition.VisitScheduledBHTOC]: {
    display: 'Visit scheduled BH TOC',
  },
  [OutreachDisposition.TextSent]: {
    display: 'Text sent',
  },
  [OutreachDisposition.Other]: {
    display: 'Other',
  },
};

export const outreachDispositionOptions = Object.entries(outreachDispositionMap)
  .map(([value, { display: label }]) => ({
    value,
    label,
  }))
  .sort((a, b) => a?.label?.localeCompare(b?.label ?? '') ?? 0);

export const enum OutreachModality {
  PhoneOutboundStandard = 'phone-outbound-standard',
  PhoneOutboundEmbedded = 'phone-outbound-embedded',
  PhoneInbound = 'phone-inbound',
  InPersonDoorKnock = 'in-person-door-knock',
  InPersonMeetGreet = 'in-person-meet-greet',
  InPersonFacilityRounding = 'in-person-facility-rounding',
  TextMessage = 'text-message',
}

const deferredDispositions = [
  OutreachDisposition.DeferredNotAGoodFit,
  OutreachDisposition.DeferredNeedsTakenCareOf,
  OutreachDisposition.DeferredMoreInfoNeeded,
  OutreachDisposition.DeferredConfirmInsurance,
  OutreachDisposition.DeferredPrefersInPerson,
  OutreachDisposition.DeferredLackOfAppointmentAvailability,
  OutreachDisposition.DeferredOther,
];
const phoneCallDispositions = [
  OutreachDisposition.CallbackRequested,
  OutreachDisposition.PatientEnrolledEscalationVisitScheduled,
  OutreachDisposition.EnrollmentCompletedOnboardingVisitScheduled,
  OutreachDisposition.EnrollmentCompletedOnboardingVisitNotScheduled,
  OutreachDisposition.NotAnswered,
  OutreachDisposition.LeftVoicemail,
  OutreachDisposition.WrongNumber,
  OutreachDisposition.InvalidNumber,
  OutreachDisposition.HungUp,
  OutreachDisposition.NotHomeOrAvailable,
  OutreachDisposition.DoNotCall,
  OutreachDisposition.Facility,
  OutreachDisposition.WantsPCPConsult,
  OutreachDisposition.MobileAppOrLoginIssues,
  OutreachDisposition.CallQualityIssue,
  OutreachDisposition.ReEngagedOnboardingVisitScheduled,
  OutreachDisposition.UnableToEnrollDeceased,
  OutreachDisposition.UnableToEnrollOutOfCoverageArea,
  OutreachDisposition.UnableToEnrollIneligible,
  OutreachDisposition.Other,
  OutreachDisposition.VisitScheduledBHTOC,
  ...deferredDispositions,
];

export const outreachModalityMap: Record<
  OutreachModality,
  { display: string; dispositions: OutreachDisposition[]; medium: CommunicationMedium }
> = {
  [OutreachModality.PhoneOutboundStandard]: {
    display: 'Phone Call (Outbound Standard Outreach)',
    dispositions: phoneCallDispositions,
    medium: CommunicationMedium.Telephone,
  },
  [OutreachModality.PhoneOutboundEmbedded]: {
    display: 'Phone Call (Outbound Embedded Outreach)',
    dispositions: phoneCallDispositions,
    medium: CommunicationMedium.Telephone,
  },
  [OutreachModality.PhoneInbound]: {
    display: 'Phone Call (Inbound)',
    dispositions: phoneCallDispositions,
    medium: CommunicationMedium.Telephone,
  },
  [OutreachModality.InPersonDoorKnock]: {
    display: 'In-Person: Door Knock',
    dispositions: [
      OutreachDisposition.NotAnswered,
      OutreachDisposition.DoorNotAccessible,
      OutreachDisposition.DoorShut,
      OutreachDisposition.NotHomeOrAvailable,
      OutreachDisposition.PatientEnrolledEscalationVisitScheduled,
      OutreachDisposition.EnrollmentCompletedOnboardingVisitScheduled,
      OutreachDisposition.EnrollmentCompletedOnboardingVisitNotScheduled,
      OutreachDisposition.WrongAddress,
      OutreachDisposition.Other,
      ...deferredDispositions,
    ],
    medium: CommunicationMedium.InPerson,
  },
  [OutreachModality.InPersonMeetGreet]: {
    display: 'In-Person: Meet & Greet',
    dispositions: [
      OutreachDisposition.NotAnswered,
      OutreachDisposition.DoorNotAccessible,
      OutreachDisposition.DoorShut,
      OutreachDisposition.NotHomeOrAvailable,
      OutreachDisposition.PatientEnrolledEscalationVisitScheduled,
      OutreachDisposition.EnrollmentCompletedOnboardingVisitScheduled,
      OutreachDisposition.EnrollmentCompletedOnboardingVisitNotScheduled,
      OutreachDisposition.WrongAddress,
      OutreachDisposition.Other,
      ...deferredDispositions,
    ],
    medium: CommunicationMedium.InPerson,
  },
  [OutreachModality.InPersonFacilityRounding]: {
    display: 'In-Person: Facility Rounding',
    dispositions: [
      OutreachDisposition.UnableToSpeakWithCaregiver,
      OutreachDisposition.MobileAppOrLoginIssues,
      OutreachDisposition.PatientEnrolledEscalationVisitScheduled,
      OutreachDisposition.EnrollmentCompletedOnboardingVisitScheduled,
      OutreachDisposition.EnrollmentCompletedOnboardingVisitNotScheduled,
      ...deferredDispositions,
    ],
    medium: CommunicationMedium.InPerson,
  },
  [OutreachModality.TextMessage]: {
    display: 'Text Message',
    dispositions: [OutreachDisposition.TextSent],
    medium: CommunicationMedium.Text,
  },
};

export const outreachModalityOptions = Object.entries(outreachModalityMap)
  .map(([value, { display: label }]) => ({
    value,
    label,
  }))
  .sort((a, b) => a?.label?.localeCompare(b?.label ?? '') ?? 0);

// TODO: cleanup after cut-over to new outreach modalities and dispositions
export const enum CallDisposition {
  EnrollmentCompletedOnboardingVisitScheduled = 'enrollment-completed-onboarding-visit-scheduled',
  EnrollmentCompletedOnboardingVisitNotScheduled = 'enrollment-completed-onboarding-visit-not-scheduled',
  ReEngagedOnboardingVisitScheduled = 're-engaged-onboarding-visit-scheduled',
  CallbackRequested = 'callback-requested',
  NeedsMoreTime = 'needs-more-time',
  MobileAppOrLoginIssues = 'mobile-app-or-login-issues',
  HungUp = 'hung-up',
  Deferred = 'deferred',
  DoNotCall = 'do-not-call',
  CallQualityIssue = 'call-quality-issue',
  LanguageAssist = 'language-assist',
  LeftVoicemail = 'left-voicemail',
  InvalidNumber = 'invalid-number',
  NotAnswered = 'not-answered',
  WrongNumber = 'wrong-number',
  Deceased = 'deceased',
  OutOfState = 'out-of-state',
  Over18 = 'over-18',
  Facility = 'facility',
  PatientEnrolledEscalationVisitScheduled = 'patient-enrolled-escalation-visit-scheduled',
  DoorKnockUnanswered = 'door-knock-unanswered',
  Other = 'other',
}

// TODO: cleanup after cut-over to new outreach modalities and dispositions
export const callDispositionMap: Record<CallDisposition, { display: string; definition?: string }> = {
  [CallDisposition.EnrollmentCompletedOnboardingVisitScheduled]: {
    display: 'Patient enrolled, onboarding visit scheduled',
    definition:
      'The touchpoint led to the completion of the enrollment flow. This value is applied automatically when the enrollment flow is completed.',
  },
  [CallDisposition.EnrollmentCompletedOnboardingVisitNotScheduled]: {
    display: 'Patient enrolled, onboarding visit not scheduled',
    definition:
      'The touchpoint led to the completion of the enrollment flow. This value is applied automatically when the enrollment flow is completed.',
  },
  [CallDisposition.ReEngagedOnboardingVisitScheduled]: {
    display: 'Re-engaged, onboarding visit scheduled',
    definition:
      'The recipient was re-engaged after unsuccessful onboarding appointment to schedule another onboarding visit.',
  },
  [CallDisposition.CallbackRequested]: {
    display: 'Callback requested',
    definition:
      'The recipient requested a callback at a specific date or time when it’d be better to connect. The call was either answered by someone who is not a primary contact or caregiver, or just connected at an inconvenient time.',
  },
  [CallDisposition.NeedsMoreTime]: {
    display: 'Needs more time or information',
    definition: 'The recipient requested more time to consider and/or research.',
  },
  [CallDisposition.MobileAppOrLoginIssues]: {
    display: 'Mobile app or login issues',
    definition:
      'Encountered technical challenges when enrolling related to our Imagine mobile app that requires follow-up.',
  },
  [CallDisposition.HungUp]: {
    display: 'Hung up',
    definition: 'The recipient hung up or disconnected the call.',
  },
  [CallDisposition.Deferred]: {
    display: 'Deferred',
    definition: 'The recipient is not interested at this time, already has all the information they need.',
  },
  [CallDisposition.DoNotCall]: {
    display: 'Do not call',
    definition:
      'The recipient requested to be removed from the calling list or expressed a desire not to be contacted.',
  },
  [CallDisposition.CallQualityIssue]: {
    display: 'Unable to complete call',
    definition: 'Unable to complete call - translation challenge or call quality issue.',
  },
  [CallDisposition.LanguageAssist]: {
    display: 'Language assist',
    definition: 'Communication was challenging due to a language barrier. Denote language preference in notes.',
  },
  [CallDisposition.LeftVoicemail]: {
    display: 'Left voicemail',
    definition: 'The team left a voicemail message for the recipient, but there was no live conversation.',
  },
  [CallDisposition.InvalidNumber]: {
    display: 'Invalid number',
    definition: 'The phone number has been disconnected or is no longer in service.',
  },
  [CallDisposition.NotAnswered]: {
    display: 'Not answered',
    definition:
      'The recipient did not answer the call, a busy signal was reached, or voicemail was full and no voicemail was left.',
  },
  [CallDisposition.WrongNumber]: {
    display: 'Wrong number',
    definition: 'The phone number reached was incorrect, or not associated with the intended recipient.',
  },
  [CallDisposition.Deceased]: {
    display: 'Deceased',
    definition: 'The recipient is deceased.',
  },
  [CallDisposition.OutOfState]: {
    display: 'Out of state',
    definition: 'The recipient is out of state.',
  },
  [CallDisposition.Over18]: {
    display: 'Over 18',
    definition: 'The recipient is over 18.',
  },
  [CallDisposition.Facility]: {
    display: 'Facility',
    definition: 'The recipient is in a facility.',
  },
  [CallDisposition.PatientEnrolledEscalationVisitScheduled]: {
    display: 'Patient enrolled, escalation visit scheduled',
    definition: 'Patient enrolled, escalation visit scheduled',
  },
  [CallDisposition.DoorKnockUnanswered]: {
    display: 'Door knock unanswered',
  },
  [CallDisposition.Other]: {
    display: 'Other',
  },
};

export const callDispositionOptions = Object.entries(callDispositionMap)
  .map(([value, { display: label }]) => ({
    value,
    label,
  }))
  .sort((a, b) => a?.label?.localeCompare(b?.label ?? '') ?? 0);

export const callRouteToModalityMap: Record<CallRoute, OutreachModality> = {
  [CallRoute.Incoming]: OutreachModality.PhoneInbound,
  [CallRoute.Outgoing]: OutreachModality.PhoneOutboundStandard,
  [CallRoute.DoorKnock]: OutreachModality.InPersonDoorKnock,
};
export const getOutreachModality = (input: CallRoute | OutreachModality): OutreachModality => {
  return callRouteToModalityMap[input as CallRoute] ?? (input as OutreachModality);
};

export const callDispositionToOutreachDispositionMap: Record<CallDisposition, OutreachDisposition> = {
  [CallDisposition.EnrollmentCompletedOnboardingVisitScheduled]:
    OutreachDisposition.EnrollmentCompletedOnboardingVisitScheduled,
  [CallDisposition.EnrollmentCompletedOnboardingVisitNotScheduled]:
    OutreachDisposition.EnrollmentCompletedOnboardingVisitNotScheduled,
  [CallDisposition.ReEngagedOnboardingVisitScheduled]: OutreachDisposition.ReEngagedOnboardingVisitScheduled,
  [CallDisposition.CallbackRequested]: OutreachDisposition.CallbackRequested,
  [CallDisposition.NeedsMoreTime]: OutreachDisposition.DeferredMoreInfoNeeded,
  [CallDisposition.MobileAppOrLoginIssues]: OutreachDisposition.MobileAppOrLoginIssues,
  [CallDisposition.HungUp]: OutreachDisposition.HungUp,
  [CallDisposition.Deferred]: OutreachDisposition.DeferredOther,
  [CallDisposition.DoNotCall]: OutreachDisposition.DoNotCall,
  [CallDisposition.CallQualityIssue]: OutreachDisposition.CallQualityIssue,
  [CallDisposition.LanguageAssist]: OutreachDisposition.LanguageAssist,
  [CallDisposition.LeftVoicemail]: OutreachDisposition.LeftVoicemail,
  [CallDisposition.InvalidNumber]: OutreachDisposition.InvalidNumber,
  [CallDisposition.NotAnswered]: OutreachDisposition.NotAnswered,
  [CallDisposition.WrongNumber]: OutreachDisposition.WrongNumber,
  [CallDisposition.Deceased]: OutreachDisposition.Deceased,
  [CallDisposition.OutOfState]: OutreachDisposition.OutOfState,
  [CallDisposition.Over18]: OutreachDisposition.Over18,
  [CallDisposition.Facility]: OutreachDisposition.Facility,
  [CallDisposition.PatientEnrolledEscalationVisitScheduled]:
    OutreachDisposition.PatientEnrolledEscalationVisitScheduled,
  [CallDisposition.DoorKnockUnanswered]: OutreachDisposition.NotAnswered,
  [CallDisposition.Other]: OutreachDisposition.Other,
};
export const getOutreachDisposition = (
  input: CallDisposition | OutreachDisposition,
  modality: OutreachModality,
): OutreachDisposition | undefined => {
  const disposition =
    callDispositionToOutreachDispositionMap[input as CallDisposition] ?? (input as OutreachDisposition);
  return outreachModalityMap[modality]?.dispositions.includes(disposition) ? disposition : undefined;
};
