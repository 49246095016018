import { MedplumClient, createReference } from '@medplum/core';
import { Attachment, Consent, DocumentReference, Patient, Reference } from '@medplum/fhirtypes';
import { isDefined } from '../utils/lists';
import { HL7System, LOINCSystem, LOINCSystemCodes, System } from 'const-utils';
import { getName } from '../utils/patient';
import { PDFDocument, PDFFont, PDFPage, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { ConsentIdentifier, ConsentType, DocumentReferenceTag } from 'const-utils/codeSystems/ImaginePediatrics';
import { P, match } from 'ts-pattern';
import { format } from 'date-fns';

export const mapLegacyConsentToConsentIdentifier = (legacyConsent: Consent): ConsentIdentifier | null => {
  const identifier = legacyConsent.identifier?.at(0)?.value;

  return match(identifier)
    .with(P.string.includes('TreatAndTelehealthConsents.pdf'), () => ConsentIdentifier.ConsentToTreat)
    .with(P.string.includes('ULA.pdf'), () => ConsentIdentifier.EULA)
    .with(P.string.includes('RoiConsent.pdf'), () => ConsentIdentifier.ReleaseOfInformation)
    .otherwise(() => null);
};

export const generatePatientConsents = async (medplum: MedplumClient, patientId: string): Promise<Consent[]> => {
  return generateConsents(medplum, patientId, ConsentType.PatientConsent);
};

export const generateCaregiverConsents = async (medplum: MedplumClient, caregiverId: string): Promise<Consent[]> => {
  return generateConsents(medplum, caregiverId, ConsentType.CaregiverConsent);
};

export const generateConsents = async (medplum: MedplumClient, id: string, type: ConsentType): Promise<Consent[]> => {
  const documents = await medplum.search('DocumentReference', {
    status: 'current',
    category: type,
  });

  const patientRef = `Patient/${id}`;

  const consentsBundle = await medplum.search('Consent', {
    patient: patientRef,
  });

  const existingConsents =
    consentsBundle.entry
      ?.map((e) => e.resource as Consent)
      .filter((consent) => consent.status === 'draft' || consent.status === 'active') || [];

  const createConsent = async (doc: DocumentReference): Promise<Consent> => {
    const sourceRef = `DocumentReference/${doc.id}`;

    const existing = existingConsents.find((c) => {
      if (c.sourceReference?.reference === sourceRef) {
        return true;
      }

      const identifier = mapLegacyConsentToConsentIdentifier(c);

      if (identifier) {
        return identifier === doc.masterIdentifier?.system;
      }

      return false;
    });

    if (existing) {
      return existing;
    }

    const newConsent: Consent = {
      resourceType: 'Consent',
      status: 'draft',
      patient: { reference: 'Patient/' + id },
      sourceReference: createReference(doc),
      scope: {
        coding: [
          {
            system: HL7System.ConsentScope,
            code: 'treatment',
            display: 'Treatment',
          },
        ],
      },
      // This is a workaround for a medplum issue.
      policyRule: {
        coding: [
          {
            code: 'None',
            display: 'None',
          },
        ],
      },
      category: [
        {
          coding: [
            {
              system: LOINCSystem,
              code: LOINCSystemCodes.PatientConsent,
              display: 'Patient Consent',
            },
          ],
        },
      ],
    };

    return medplum.createResource(newConsent);
  };

  const creations =
    documents?.entry
      ?.map((e) => e.resource)
      ?.filter(isDefined)
      ?.map((e) => createConsent(e)) || [];

  return Promise.all(creations);
};

type GetSignedConsentDocumentOptions = {
  cursiveFontBytes?: ArrayBuffer;
};

/**
 * getSignedConsentDocument 'signs' a Consent by embedding the associated patient and signer/performer names into the static document reference
 * associated with the Consent
 *
 * This is expected to be used with post-Verity signed Consents as it is dependant on a Consent.performer which does not exist on legacy consents migrated from Verity
 *
 * @param medplum - medplum client
 * @param consent - Consent resource
 * @param options - options for signing
 * @returns Promise<Uint8Array>
 */
export const getSignedConsentDocument = async (
  medplum: MedplumClient,
  consent: Consent,
  options: GetSignedConsentDocumentOptions = {},
): Promise<Uint8Array> => {
  const patientRef = consent?.patient;
  if (!patientRef) {
    throw new Error('missing patient');
  }

  const documentReferenceRef = consent.sourceReference;
  if (!documentReferenceRef) {
    throw new Error('missing document reference');
  }

  const isManualUpload = consent.meta?.tag?.some((tag) => tag.system === System.ManualConsentUpload.toString());
  if (isManualUpload) {
    return getManuallySignedConsent(documentReferenceRef as Reference<DocumentReference>, medplum);
  }

  const performerRef = consent.performer?.at(0);
  if (!performerRef) {
    throw new Error('missing performer reference');
  }

  const consentedAt = consent.dateTime;
  if (!consentedAt) {
    throw new Error('missing consent date time');
  }

  const [patient, documentReference, performer] = await Promise.all([
    medplum.readReference(patientRef),
    medplum.readReference(documentReferenceRef as Reference<DocumentReference>),
    medplum.readReference(performerRef as Reference<Patient>),
  ]);

  const isBrandedConsent = documentReference.meta?.tag?.some(
    (tag) => tag.system === System.DocumentBranding.toString() && tag.code === 'branded',
  );

  const patientBirthDate = patient.birthDate;
  if (!patientBirthDate) {
    throw new Error('missing patient birth date');
  }

  const patientName = getName(patient, { use: 'official' });
  if (!patientName) {
    throw new Error('unable to produce "official" name for patient');
  }
  const performerName = getName(performer, { use: 'official' });
  if (!performerName) {
    throw new Error('unable to produce "official" name for performer');
  }

  const arrayBuffer = await getDocumentReferenceContent(documentReference);

  const pdfDoc = await PDFDocument.load(arrayBuffer);

  let cursiveFont: PDFFont | undefined = undefined;
  if (options.cursiveFontBytes) {
    pdfDoc.registerFontkit(fontkit);
    cursiveFont = await pdfDoc.embedFont(options.cursiveFontBytes);
  }

  const pages = pdfDoc.getPages();
  const firstPage = pages.at(0);
  if (!firstPage) {
    throw new Error('no pages found in pdf document');
  }

  const xOffset = isBrandedConsent ? 50 : 70;
  const patientHeaderOffset = isBrandedConsent ? 150 : xOffset;

  //TODO: in future allow placing the patient name in the header of the document of the translated document as well
  //also eventual desire is to allow for serving up caregivers language preference and then only displaying English version with sig in care hub?
  const firstPages: PDFPage[] = [firstPage];
  firstPages.forEach((page) =>
    addPatientNameToConsentDocument(page, patientName, patientBirthDate, patientHeaderOffset),
  );
  const lastPages: PDFPage[] = !isBrandedConsent
    ? [pages.at(-1) as PDFPage]
    : [pages.at(pages.length / 2 - 1) as PDFPage, pages.at(-1) as PDFPage];
  lastPages.forEach((page) =>
    addSignatureToConsentDocument(
      page,
      xOffset,
      isBrandedConsent ? 200 : 100,
      performerName,
      consentedAt,
      cursiveFont!,
    ),
  );

  const lastPage = pages.at(-1)!;
  const yBaseline = isBrandedConsent ? 200 : 100;
  lastPage.drawText('Patient/Parent/Guardian signature:', {
    x: xOffset,
    y: yBaseline,
    size: 14,
  });

  return pdfDoc.save();
};

const addPatientNameToConsentDocument = (
  page: PDFPage,
  patientName: string,
  patientBirthDate: string,
  xOffset: number,
) => {
  const { height: firstPageHeight } = page.getSize();
  page.drawText(`Patient: ${patientName} - ${format(patientBirthDate, 'MM/dd/yyyy')}`, {
    x: xOffset,
    y: firstPageHeight - 50,
    color: rgb(0, 0, 0),
    size: 14,
  });
};

const addSignatureToConsentDocument = async (
  page: PDFPage,
  xOffset: number,
  yBaseline: number,
  performerName: string,
  consentedAt: string,
  cursiveFont: PDFFont,
) => {
  page.drawText('Patient/Parent/Guardian signature:', {
    x: xOffset,
    y: yBaseline,
    size: 14,
  });
  page.drawText(performerName, {
    x: xOffset,
    y: yBaseline - 25,
    size: 14,
    font: cursiveFont,
  });
  page.drawText(`Patient/Parent/Guardian printed name: ${performerName}`, {
    x: xOffset,
    y: yBaseline - 50,
    size: 14,
  });
  page.drawText(`Accepted by ${performerName} on ${format(consentedAt, 'MM/dd/yyyy')}`, {
    x: xOffset,
    y: yBaseline - 70,
    size: 14,
  });
};

const getDocumentReferenceContent = async (documentReference: DocumentReference): Promise<ArrayBuffer> => {
  const documentReferenceContentUrl = documentReference.content?.at(0)?.attachment?.url;
  if (!documentReferenceContentUrl) {
    throw new Error('missing document reference content url');
  }

  const res = await fetch(documentReferenceContentUrl);
  return res.arrayBuffer();
};

const getManuallySignedConsent = async (
  documentReferenceRef: Reference<DocumentReference>,
  medplum: MedplumClient,
): Promise<Uint8Array> => {
  const documentReference = await medplum.readReference(documentReferenceRef as Reference<DocumentReference>);
  const arrayBuffer = await getDocumentReferenceContent(documentReference);
  const pdfDoc = await PDFDocument.load(arrayBuffer);
  return pdfDoc.save();
};

const manualConsentTag = {
  system: System.ManualConsentUpload,
  code: DocumentReferenceTag.ManualConsentUpload,
  display: DocumentReferenceTag.ManualConsentUpload,
};
/**
 * This method will upload a pdf to draft consent to treat and release of information for the given patient
 * How do we want to handle if no draft is found? Do we create the consent? Verify if one is currently "final"? Then override it?
 *
 * @param medplum - medplum client
 * @param patientReference - reference to the patient
 * @param attachment - the consent attachment
 */
export const uploadPatientConsent = async (
  medplum: MedplumClient,
  patientReference: Reference<Patient>,
  attachment: Attachment,
): Promise<void> => {
  const consentToTreatRef = await medplum.searchOne('DocumentReference', {
    status: 'current',
    category: ConsentType.PatientConsent,
    type: ConsentType.ConsentToTreatAndTelehealth,
  });

  const roiRef = await medplum.searchOne('DocumentReference', {
    status: 'current',
    category: ConsentType.PatientConsent,
    type: ConsentType.ReleaseOfInformation,
  });
  const consentToTreat = await medplum.searchOne(
    'Consent',
    `patient=${patientReference.reference}&source-reference=DocumentReference/${consentToTreatRef?.id}&status=draft`,
  );
  const roi = await medplum.searchOne(
    'Consent',
    `patient=${patientReference.reference}&source-reference=DocumentReference/${roiRef?.id}&status=draft`,
  );
  if (!consentToTreat && !roi) {
    throw new Error('Patient does not have a consent in draft, document not uploaded');
  }

  if (consentToTreat) {
    //TODO: Determine if we still upload the document even if no consent draft exist
    const consentDocumentUpload = await medplum.createResource({
      resourceType: 'DocumentReference',
      masterIdentifier: {
        system: ConsentIdentifier.ConsentToTreat,
        value: 'manual',
      },
      content: [
        {
          attachment,
        },
      ],
      docStatus: 'final',
      status: 'superseded', //using this status instead of current to help differentiate between manual, the real status that matters is the "consent itself"
      type: {
        coding: [
          {
            code: ConsentType.ConsentToTreatAndTelehealth,
            display: ConsentType.ConsentToTreatAndTelehealth,
          },
        ],
      },
      category: [
        {
          coding: [
            {
              code: ConsentType.PatientConsent,
              display: ConsentType.PatientConsent,
            },
          ],
        },
      ],
      subject: patientReference,
      date: new Date().toISOString(),
      meta: {
        tag: [manualConsentTag],
      },
    });
    const tags = [...(consentToTreat.meta?.tag || []), manualConsentTag];
    await medplum.updateResource({
      ...consentToTreat,
      sourceReference: createReference(consentDocumentUpload),
      meta: { ...consentToTreat.meta, tag: tags },
      dateTime: new Date().toISOString(),
      status: 'active',
    });
  }
  if (roi) {
    //dupe of attachment with type, or reset password will generate the ROI to sign in app
    const roiDocumentUpload = await medplum.createResource({
      resourceType: 'DocumentReference',
      masterIdentifier: {
        system: ConsentIdentifier.ReleaseOfInformation,
        value: 'manual',
      },
      content: [
        {
          attachment,
        },
      ],
      docStatus: 'final',
      status: 'superseded', //using this status instead of current to help differentiate between manual, the real status that matters is the "consent itself"
      type: {
        coding: [
          {
            code: ConsentType.ReleaseOfInformation,
            display: ConsentType.ReleaseOfInformation,
          },
        ],
      },
      category: [
        {
          coding: [
            {
              code: ConsentType.PatientConsent,
              display: ConsentType.PatientConsent,
            },
          ],
        },
      ],
      subject: patientReference,
      date: new Date().toISOString(),
      meta: {
        tag: [manualConsentTag],
      },
    });
    const tags = [...(roi.meta?.tag || []), manualConsentTag];
    await medplum.updateResource({
      ...roi,
      sourceReference: createReference(roiDocumentUpload),
      meta: { ...roi.meta, tag: tags },
      dateTime: new Date().toISOString(),
      status: 'active',
    });
  }
};

/**
 * This method will upload a pdf to draft consent to treat and release of information for the given Caregiver
 * How do we want to handle if no draft is found? Do we create the consent? Verify if one is currently "final"? Then override it?
 *
 * @param medplum - medplum client
 * @param caregiverReference - reference to the patient
 * @param attachment - the consent attachment
 */
export const uploadCaregiverConsent = async (
  medplum: MedplumClient,
  caregiverReference: Reference<Patient>,
  attachment: Attachment,
): Promise<void> => {
  const eulaRef = await medplum.searchOne('DocumentReference', {
    status: 'current',
    category: ConsentType.CaregiverConsent,
  });

  const eula = await medplum.searchOne(
    'Consent',
    `patient=${caregiverReference.reference}&source-reference=DocumentReference/${eulaRef?.id}&status=draft`,
  );

  if (eula) {
    //TODO: Determine if we still upload the document even if no consent draft exist
    const consentDocumentUpload = await medplum.createResource({
      resourceType: 'DocumentReference',
      masterIdentifier: {
        system: ConsentIdentifier.EULA,
        value: 'manual',
      },
      content: [
        {
          attachment,
        },
      ],
      docStatus: 'final',
      status: 'superseded', //using this status instead of current to help differentiate between manual, the real status that matters is the "consent itself"
      type: {
        coding: [
          {
            code: ConsentType.Eula,
            display: ConsentType.Eula,
          },
        ],
      },
      category: [
        {
          coding: [
            {
              code: ConsentType.CaregiverConsent,
              display: ConsentType.CaregiverConsent,
            },
          ],
        },
      ],
      subject: caregiverReference,
      date: new Date().toISOString(),
      meta: {
        tag: [manualConsentTag],
      },
    });

    const tags = [...(eula.meta?.tag || []), manualConsentTag];
    await medplum.updateResource({
      ...eula,
      sourceReference: createReference(consentDocumentUpload),
      meta: { ...eula.meta, tag: tags },
      dateTime: new Date().toISOString(),
      status: 'active',
    });
  } else {
    throw new Error('Caregiver does not have a consent in draft, document not uploaded');
  }
};
