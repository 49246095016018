import React, { useMemo } from 'react';
import { isValidPatient } from '@/utils/queryTypes';
import { useGetPatientQuery } from 'medplum-gql';
import { CompactPatientHeader } from '@/components/shared/patient/PatientHeader';
import { Loader } from '@mantine/core';

interface TaskDrawerHeaderProps {
  patientId?: string;
  withViewPatientProfile?: boolean;
}

export const TaskDrawerHeader = ({ patientId, withViewPatientProfile }: TaskDrawerHeaderProps) => {
  const { data } = useGetPatientQuery({
    variables: {
      id: patientId!,
    },
    skip: !patientId,
  });

  const patient = useMemo(() => data?.Patient, [data]);

  if (!isValidPatient(patient)) {
    return <Loader />;
  }

  return <CompactPatientHeader patientId={patient.id} withViewPatientProfile={withViewPatientProfile} />;
};
