import { Text, ThemeIcon } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconAlertTriangleFilled } from '@tabler/icons-react';
import { System } from 'const-utils';
import { getName } from 'imagine-dsl/utils/patient';
import { HumanName, Maybe, TagsFragment } from 'medplum-gql';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface Patient {
  id?: Maybe<string>;
  meta?: Maybe<TagsFragment>;
  name?: Maybe<HumanName[]>;
}

export const useHandleConductOutreach = (patient?: Maybe<Patient>) => {
  const navigate = useNavigate();
  return useCallback(() => {
    if (!patient?.id) {
      return;
    }

    const isEligible =
      patient.meta?.tag?.find((tag) => tag?.system === System.Attribution.toString())?.code === 'attributed';

    if (isEligible) {
      navigate(`/Outreach/${patient.id}`);
    } else {
      modals.openConfirmModal({
        title: (
          <>
            <ThemeIcon color="status-warn" mb={10} variant="transparent">
              <IconAlertTriangleFilled />
            </ThemeIcon>
            <Text fz="lg" fw={500} c="imagine-green">
              {getName(patient, { use: 'official' })} is
              <span style={{ fontWeight: '900' }}> not attributed</span> to Imagine Pediatrics
            </Text>
          </>
        ),
        children: <Text fz="xs">Are you sure you still want to conduct outreach for this patient?</Text>,
        centered: true,
        radius: 'lg',
        labels: { confirm: 'Yes, conduct outreach', cancel: 'Cancel' },
        onConfirm: () => navigate(`/Outreach/${patient.id}`),
      });
    }
  }, [navigate, patient]);
};
