import { Group, Text, Badge } from '@mantine/core';
import { IconFileFilled, IconPointFilled } from '@tabler/icons-react';
import { format } from 'date-fns';
import { SurveyTask } from 'imagine-dsl/models/tasks/surveyTask';
import React from 'react';

interface ResourceProps {
  surveyTask: SurveyTask;
  setSelectedSurvey: (survey: SurveyTask) => void;
  title: string;
  communicationSentDateTime: string;
  communicationRead: boolean;
}

export const SurveyItem = ({
  surveyTask,
  setSelectedSurvey,
  title,
  communicationSentDateTime,
  communicationRead,
}: ResourceProps): JSX.Element => {
  const formattedCommSentTime = communicationSentDateTime ? format(communicationSentDateTime, 'M/d/yyyy h:mm a') : '';
  const formattedCompletedTime = surveyTask?.executionPeriod?.end
    ? format(surveyTask.executionPeriod.end, 'M/d/yyyy h:mm a')
    : '';

  return (
    <>
      <Group
        gap="xs"
        onClick={
          surveyTask?.completed
            ? (e) => {
                e.stopPropagation();
                setSelectedSurvey(surveyTask);
              }
            : undefined
        }
        style={{ cursor: surveyTask?.completed ? 'pointer' : 'default' }}
      >
        <IconFileFilled size={20} />
        <Text>{title}</Text>
      </Group>
      <Badge color={surveyTask?.completed ? 'green' : 'red'} ml="xl" size="sm">
        {!surveyTask?.completed ? 'Not complete' : 'Complete'}
      </Badge>
      <Group gap="xs" mt="xs" ml="xl" c="dimmed" mb={6}>
        {formattedCommSentTime && (
          <>
            <Text span size="xs">
              Sent {formattedCommSentTime}
            </Text>
            <IconPointFilled size={14} />
            <Text span size="xs">
              {communicationRead ? 'Read' : 'Unread'}
            </Text>
          </>
        )}

        {!communicationSentDateTime && (
          <Text span size="xs">
            Survey was sent, but a communication not sent to caregiver
          </Text>
        )}

        {formattedCompletedTime && (
          <>
            <Text span size="xs">
              Completed {formattedCompletedTime}
            </Text>
          </>
        )}
      </Group>
    </>
  );
};
