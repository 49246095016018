import { UseFormReturnType } from '@mantine/form';
import { PatientEditModalForm } from './EditFormInterfaces';

export const enum PatientPrimaryInfoFields {
  LIVING_SITUATION = 'Living situation',
  GENDER_IDENTITY = 'Gender identity',
  PREFERRED_NAME = 'Preferred name',
  LANGUAGE_PREFERENCE = 'Language preference',
  PCP = 'PCP',
  ADDRESS = 'Address',
}

const fieldMapping: { [key in PatientPrimaryInfoFields]: string[] | undefined } = {
  [PatientPrimaryInfoFields.LIVING_SITUATION]: ['livingSituation'],
  [PatientPrimaryInfoFields.GENDER_IDENTITY]: ['genderIdentity', 'preferredPronoun'],
  [PatientPrimaryInfoFields.PREFERRED_NAME]: ['preferredName'],
  [PatientPrimaryInfoFields.LANGUAGE_PREFERENCE]: ['languagePreference', 'requiresInterpreter'],
  [PatientPrimaryInfoFields.PCP]: ['pcp'],
  [PatientPrimaryInfoFields.ADDRESS]: [
    'address.line1',
    'address.line2',
    'address.city',
    'address.state',
    'address.zip',
  ],
};

export const getFormFieldNames = (field: string): string[] | undefined => {
  return fieldMapping[field as keyof typeof fieldMapping];
};

export const editFormHandler = async (
  form: UseFormReturnType<PatientEditModalForm>,
  field: PatientPrimaryInfoFields,
  dataRetentionHandlers: { [key in PatientPrimaryInfoFields]: () => Promise<void> },
): Promise<boolean> => {
  const handler = dataRetentionHandlers[field];

  let isValid = true;
  const formFields = getFormFieldNames(field);

  if (formFields) {
    for (const formField of formFields) {
      form.validateField(formField);
      if (!form.isValid(formField)) {
        isValid = false;
        break;
      }
    }
  }
  if (isValid) {
    await handler();
  }

  return isValid;
};
