import { HumanName } from '@medplum/fhirtypes';
import { HumanNameDisplay } from '@medplum/react';
import { caregiversFromPatient, isPrimaryCaregiver } from 'imagine-dsl/utils/patient';
import { isEmpty } from 'lodash';
import React from 'react';
import { PatientWithCaregivers, PatientWithExtension } from 'imagine-dsl/utils/types/patient';

export function CaregiverColumn({ patient }: { patient: PatientWithCaregivers & PatientWithExtension }): JSX.Element {
  const caregivers = caregiversFromPatient(patient);

  if (isEmpty(caregivers)) {
    return <div>--</div>;
  }

  const primaryCaregiver = caregivers?.find((c) => !!isPrimaryCaregiver(patient, c));

  if (primaryCaregiver) {
    return (
      <>
        <HumanNameDisplay value={primaryCaregiver.name?.[0] as HumanName} />
      </>
    );
  }

  return (
    <>
      <HumanNameDisplay value={caregivers?.[0]?.name?.[0] as HumanName} />
    </>
  );
}
