import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedState, useDocumentTitle } from '@mantine/hooks';
import { Button, Flex, Group, Loader, Paper, Stack, Table, Text, TextInput } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { useMedplum, useMedplumProfile } from '@medplum/react';
import { isDefined } from 'imagine-dsl/utils/lists';
import { formatHumanName } from '@medplum/core';
import { Bundle, ClinicalImpression, HumanName } from '@medplum/fhirtypes';
import { IconChevronRight, IconSearch } from '@tabler/icons-react';
import { ClusterBadge } from '@/components/ClusterBadge';
import { notifications } from '@mantine/notifications';
import { getErrorMessage, logError } from '@/errors';
import { useForm } from '@mantine/form';
import { formatAgeAndDateOfBirth } from 'imagine-dsl/utils/strings';
import { clusterFromClinicalImpressions, getCarePathway } from 'imagine-dsl/utils/clinicalImpression';
import { PatientPinButton } from '@/components/PatientPinButton';
import { uniqBy } from 'lodash';
import { PageSkeleton } from '@/design-system/PageSkeleton';
import { Pagination } from '@/design-system/Pagination';
import { usePagination } from '@/design-system/hooks/usePagination';
import { is21OrOlder } from 'imagine-dsl/utils/patient';
import { Over21Badge } from '@/components/Over21Badge';

interface ResultsProps {
  data: Bundle | undefined;
}

interface ResultItem {
  id: string;
  name?: string;
  birthdate?: string;
  cluster?: string;
  patientImpressions: ClinicalImpression[];
}

function getResultItems(data: Bundle): ResultItem[] {
  const impressions: ClinicalImpression[] =
    data.entry
      ?.filter((entry) => entry.resource?.resourceType === 'ClinicalImpression')
      .map((entry) => entry.resource as ClinicalImpression)
      .filter(isDefined) || [];

  return (
    data.entry
      ?.map((entry) => {
        const patient = entry.resource;
        if (patient?.resourceType === 'Patient') {
          const name = formatHumanName(patient.name?.[0] as HumanName);
          if (!patient.id || !name) {
            return undefined;
          }

          const patientImpressions = impressions.filter(
            (impression) => impression.subject?.reference === `Patient/${patient.id}`,
          );

          return {
            id: patient.id || undefined,
            name,
            birthdate: patient.birthDate || undefined,
            cluster: clusterFromClinicalImpressions(patientImpressions),
            patientImpressions: patientImpressions,
          } as ResultItem;
        }
        return undefined;
      })
      .filter(isDefined) || []
  );
}

function Results({ data }: ResultsProps): JSX.Element {
  const items = useMemo(() => {
    const mappedData = (data && getResultItems(data)) || [];
    return uniqBy(mappedData, 'id');
  }, [data]);

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th w="40px" />
          <Table.Th>Patient</Table.Th>
          <Table.Th>Care Pathway</Table.Th>
          <Table.Th w="100px">Actions</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {items.map((item) => {
          const carePathway = getCarePathway(item?.patientImpressions as ClinicalImpression[]);
          return (
            <Table.Tr key={item.id}>
              <Table.Td>{item.id && <PatientPinButton patientId={item.id} />}</Table.Td>
              <Table.Td>
                <Stack gap={0}>
                  <Text>{item.name}</Text>
                  <Text size="xs" c="gray">
                    {formatAgeAndDateOfBirth(item.birthdate)}
                    {is21OrOlder(item.birthdate) && <Over21Badge />}
                  </Text>
                </Stack>
              </Table.Td>
              <Table.Td>
                <ClusterBadge cluster={item.cluster || 'Unknown'} carePathway={carePathway} hideSubtext />
              </Table.Td>
              <Table.Td>
                <Button
                  component="a"
                  size="xs"
                  variant="outline"
                  color="imagine-green"
                  href={`/patient/${item.id}`}
                  rightSection={<IconChevronRight size={15} />}
                >
                  View Profile
                </Button>
              </Table.Td>
            </Table.Tr>
          );
        })}
      </Table.Tbody>
    </Table>
  );
}

const PER_PAGE = 20;

export function MyPatientsPage(): JSX.Element {
  useDocumentTitle('My Patients - Care Hub');

  const medplum = useMedplum();
  const profile = useMedplumProfile();
  const [response, setResponse] = useState<Bundle | undefined>(undefined);
  const [nameFilter, setNameFilter] = useDebouncedState<string | undefined>(undefined, 200);
  const [loading, setLoading] = useState(false);
  const { changePage, currentPage } = usePagination({});

  const loadResults = useCallback(
    (practitionerId: string, options?: RequestInit) => {
      const extra = nameFilter
        ? {
            name: nameFilter,
          }
        : {};
      setLoading(true);
      medplum
        .search(
          'Patient',
          {
            '_has:CareTeam:subject:participant': `Practitioner/${practitionerId}`,
            _revinclude: 'ClinicalImpression:subject',
            _count: PER_PAGE,
            _offset: (currentPage - 1) * PER_PAGE,
            _total: 'accurate',
            _sort: 'name',
            ...extra,
          },
          options,
        )
        .then((response) => {
          setResponse(response);
          setLoading(false);
        })
        .catch((reason) => {
          if (reason?.name === 'AbortError') {
            return;
          }
          logError(reason);
          notifications.show({
            title: 'Error when loading My Patients',
            message: getErrorMessage(reason),
            color: 'status-error',
          });
          setLoading(false);
        });
    },
    [currentPage, medplum, nameFilter],
  );

  useEffect(() => {
    if (!profile?.id) {
      return () => {};
    }
    const controller = new AbortController();
    const signal = controller.signal;

    loadResults(profile.id, { signal, cache: 'no-cache' });

    return () => {
      controller.abort();
    };
  }, [loadResults, profile?.id]);

  const searchForm = useForm({
    initialValues: {
      name: '',
    },
    onValuesChange: (e) => {
      setNameFilter(e.name);
      changePage(1);
    },
  });

  return (
    <PageSkeleton withPaper={false}>
      <Group mb={30} w={300} grow>
        <TextInput
          leftSection={<IconSearch size={18} />}
          placeholder="Search by patient name"
          {...searchForm.getInputProps('name')}
        />
      </Group>
      <Paper radius="lg" shadow="xs" p="xl" style={{ marginBottom: '20px' }}>
        <Outlet />
        {loading ? (
          <Loader />
        ) : (
          <>
            <Results data={response} />
            {response?.entry?.length !== 0 ? (
              <Pagination perPage={PER_PAGE} count={response?.total || 0} />
            ) : (
              <Flex align="center" justify="center" style={{ height: '200px' }}>
                <Text c="gray">No patients found</Text>
              </Flex>
            )}
          </>
        )}
      </Paper>
    </PageSkeleton>
  );
}
