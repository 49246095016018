import React, { useMemo, useRef, useState } from 'react';
import { useGetBasicPatientQuery } from 'medplum-gql';
import {
  ActionIcon,
  Anchor,
  Avatar,
  Box,
  Group,
  LoadingOverlay,
  Skeleton,
  Stack,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { Extension, HumanName, Organization, Patient, Reference } from '@medplum/fhirtypes';
import { IconMapPin } from '@tabler/icons-react';
import classes from './PatientHeader.module.css';
import { useVideo } from '../../../video/VideoProvider';
import { getPatientExtension, getName } from 'imagine-dsl/utils/patient';
import MedplumLogo from '@assets/logo/medplum.svg';
import AttachmentPreviewModal from '../../../shared/AttachmentPreviewModal';
import { PatientPinButton } from '../../../PatientPinButton';
import { HeaderLinks } from '../HeaderLinks';
import PatientActions from '@/components/patientProfile/PatientActions';
import { HeaderDetails } from './HeaderDetails';
import { useWindowScroll } from '@mantine/hooks';
import { useVerticalNav } from '@/components/nav/VerticalNavContext';
import { useHandleConductOutreach } from './hooks';
import { System } from 'const-utils';

export interface CompletePatientHeaderProps {
  patientId: string;
  withOutreachButton?: boolean;
  withVideoCallButton?: boolean;
  openChatDrawer?: () => void;
}

export const CompletePatientHeader = ({
  patientId,
  withOutreachButton = false,
  withVideoCallButton = false,
  openChatDrawer,
}: CompletePatientHeaderProps): JSX.Element => {
  const [previewing, setPreviewing] = useState(false);
  const { startCall, activeCall, isStartingCall } = useVideo();
  const medplum = useMedplum();
  const theme = useMantineTheme();
  const headerRef = useRef<HTMLDivElement>(null);
  const [scroll] = useWindowScroll();
  const isSticky = scroll.y > (headerRef.current?.offsetTop ?? 0);
  const { width: navWidth } = useVerticalNav();
  const patientResult = useGetBasicPatientQuery({ variables: { id: patientId } });
  const patient = useMemo(() => patientResult.data?.Patient, [patientResult.data]);

  const conductOutreach = useHandleConductOutreach(patient);

  if (!patient?.id) {
    return <div>No patient found</div>;
  }

  const managingOrganization = patient.managingOrganization?.resource as Organization | undefined;
  const preferredPronoun = getPatientExtension(patient.extension as Extension[], System.PreferredPronoun);
  const legalName = getName(patient, { use: 'official' }) || 'Unknown';
  const preferredName = getName(patient, { use: 'usual' }) || undefined;
  const hasPreferredName = preferredName && preferredName !== legalName;

  const hasPhoto = !!patient.photo?.length;

  const startVideoCall = async (): Promise<void> => {
    const reference: Reference<Patient> = {
      reference: `Patient/${patient.id}`,
      display: preferredName || legalName,
    };
    await startCall(reference);
  };

  return (
    <>
      {/* NOTE: this skeleton holds the place/height of the header and must remain outside the box */}
      <Skeleton h={isSticky ? 120 : 0} />
      <Box
        ref={headerRef}
        style={
          isSticky
            ? {
                zIndex: 100,
                backgroundColor: 'white',
                position: 'fixed',
                top: 0,
                left: navWidth + 10,
                width: `calc(100% - ${navWidth + 20}px)`,
                padding: theme.spacing.md,
                borderRadius: theme.radius.lg,
                boxShadow: theme.shadows.sm,
              }
            : undefined
        }
      >
        <Group>
          <Stack className={classes.headerStack} gap="xs">
            <Group justify="space-between" className={classes.headerGroup}>
              <Group style={{ flexGrow: 1 }}>
                {previewing && (
                  <AttachmentPreviewModal
                    contentType={patient.photo?.[0].contentType || ''}
                    url={patient.photo?.[0].url || ''}
                    title={(patient.name?.[0] as HumanName).text || 'Patient Photo'}
                    onClose={() => setPreviewing(false)}
                  />
                )}
                {hasPhoto && (
                  <Anchor onClick={() => setPreviewing(!!patient.photo?.length)}>
                    <Avatar src={patient.photo?.[0].url} alt="Patient photo" size={'md'} color="imagine-green" />
                  </Anchor>
                )}

                {medplum.isProjectAdmin() && (
                  <Tooltip label="View in Medplum">
                    <ActionIcon
                      onClick={() => {
                        window.open(`https://app.medplum.com/Patient/${patient.id}`, '_blank');
                      }}
                    >
                      <img style={{ height: '1.5em' }} src={MedplumLogo} alt="medplum logo" />
                    </ActionIcon>
                  </Tooltip>
                )}

                <span className={classes.patientName}>{preferredName || legalName}</span>
                {preferredPronoun && <span className={classes.patientPronouns}>({preferredPronoun})</span>}
                <span>
                  <IconMapPin size={14} />
                  {'  '}
                  {managingOrganization?.name}
                </span>
              </Group>
              <PatientPinButton patientId={patient.id} />
              <LoadingOverlay visible={isStartingCall} />
              <PatientActions
                withOutreachButton={withOutreachButton}
                withTouchpointButton
                withMessagesButton={!!openChatDrawer}
                withVideoCallButton={withVideoCallButton}
                patientId={patient.id}
                outreachHandler={conductOutreach}
                messagesHandler={() => openChatDrawer?.()}
                videoHandler={startVideoCall}
                activeCall={activeCall}
              />
            </Group>
            {hasPreferredName && (
              <Group ml={hasPhoto ? 55 : 0}>
                <span className={classes.legalNameLabel}>Legal Name: </span>
                <span className={classes.patientLegalName}>{getName(patient, { use: 'official' })}</span>
              </Group>
            )}
            <HeaderDetails patient={patient} withProgramStepper hidePatientNotes />
            <HeaderLinks
              patient={patient as Patient}
              linkOptions={{
                hidePatientNotes: true,
                withViewAthenaProfile: true,
              }}
            />
          </Stack>
        </Group>
      </Box>
    </>
  );
};
