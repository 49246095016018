import { FormValidateInput } from '@mantine/form/lib/types';
import { PatientEditModalForm } from './EditFormInterfaces';

export const FormValidationRules: FormValidateInput<PatientEditModalForm> = {
  preferredName: (value: string) => (value ? null : 'Preferred name is required'),
  genderIdentity: (value: string) => (value ? null : 'Gender identity is required'),
  preferredPronoun: (value: string, values: PatientEditModalForm) => {
    if (values.genderIdentity === 'does not wish to disclose') {
      return null;
    }
    return value ? null : 'Preferred pronoun is required';
  },
  languagePreference: (value: string) => (value ? null : 'Language preference is required'),
  requiresInterpreter: (value: string) => (value ? null : 'Requires interpreter is required'),
  livingSituation: (value: string) => (value ? null : 'Living situation is required'),
  pcp: (value: string) => (value ? null : 'PCP is required'),
  address: {
    line1: (value: string) => (value ? null : 'Address line 1 is required'),
    city: (value: string) => (value ? null : 'City is required'),
    state: (value: string) => (value ? null : 'State is required'),
    zip: (value: string) => (value ? null : 'Zip code is required'),
  },
};
