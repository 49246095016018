import { LocalTrackPublication, RemoteTrackPublication } from 'twilio-video';
import useTrack from '../participants/useTrack';
import useTrackEnabled from '../participants/useTrackEnabled';
import React from 'react';
import VideoElement from '../participants/VideoElement';

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  children?: React.ReactNode;
}

export default function Publication({ publication, children }: PublicationProps): JSX.Element | null {
  const track = useTrack(publication);
  const enabled = useTrackEnabled(publication);

  if (!track) {
    return null;
  }

  // Even though we only have one case here, let's keep this switch() in case
  // we even need to add a 'data' case for rendering DataTracks.
  switch (track.kind) {
    case 'video':
      return <>{enabled ? <VideoElement track={track} /> : children}</>;
    // All participant audio tracks are rendered in ParticipantAudioTracks.tsx
    default:
      return null;
  }
}
