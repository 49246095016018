import { useForm, UseFormReturnType } from '@mantine/form';
import { GetPatientQuery } from 'medplum-gql';
import { SendCredentialsFormValues } from '../types';

interface UseSendCredentialsFormInput {
  patient: GetPatientQuery['Patient'];
}

export const useSendCredentialsForm = ({
  patient,
}: UseSendCredentialsFormInput): UseFormReturnType<SendCredentialsFormValues> => {
  const phoneNumbers = (patient?.telecom || [])
    .filter((telecom) => telecom.system === 'phone')
    .filter((telecom) => telecom.value)
    .map((telecom) => telecom.value as string);

  return useForm<SendCredentialsFormValues>({
    initialValues: {
      phoneNumber: phoneNumbers[0] || '',
    },
    validate: {
      phoneNumber: (value) => (value ? null : 'Phone number is required'),
    },
  });
};
