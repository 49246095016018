import { useGetPatientPanelQuery } from 'medplum-gql';
import { useHealthPlan } from '../shared/patient/PatientHeader/hooks';
import { useDisclosure } from '@mantine/hooks';
import { ActionIcon, Button, Group, Loader, Popover, Stack, Table, Text } from '@mantine/core';
import { IconSquareCheckFilled, IconSquare, IconPointFilled, IconPlus } from '@tabler/icons-react';
import { System } from 'const-utils';
import {
  attributionDisplay,
  programStatusDisplay,
  outreachStatusMap,
  outreachDispositionMap,
  Attribution,
  ProgramStatus,
  OutreachDisposition,
  OutreachStatus,
} from 'const-utils/codeSystems/ImaginePediatrics';
import { format } from 'date-fns';
import { isDefined } from 'imagine-dsl/utils/lists';
import { formatAge } from 'imagine-dsl/utils/strings';
import React from 'react';
import { Link } from 'react-router-dom';
import { AcuteCareBadge } from '../AcuteCareBadge';
import { ClusterBadge } from '../ClusterBadge';
import UpsertTaskModal from '../shared/UpsertTaskModal';
import { engagementManagerRoles, useHasRole } from '@/hooks/useHasRole';
import { useMedplum } from '@medplum/react';
import { GetOutreachPanelQuery } from 'imagine-gql/client';
import { acuteCareOnly } from 'imagine-dsl/utils/healthplan';

export function PatientRow({
  selected,
  data: {
    id: patientId,
    name,
    lastOutreach,
    outreachCount,
    carePathway,
    attribution,
    outreachStatus,
    programStatus,
    disposition,
  },
  horizontalScrollShadow,
  onToggleSelect,
}: {
  selected: boolean;
  data: GetOutreachPanelQuery['outreachPanels']['patients'][number];
  horizontalScrollShadow: { left: boolean; right: boolean };
  onToggleSelect: (patientId: string) => void;
}): JSX.Element {
  const medplum = useMedplum();
  const hasElevatedPrivilege = useHasRole(engagementManagerRoles) || medplum.isProjectAdmin();
  const result = useGetPatientPanelQuery({ variables: { id: patientId! } });
  const patient = result.data?.Patient;

  const healthPlan = useHealthPlan(patient?.CoverageList?.filter(isDefined));
  const isAcuteCareOnly = acuteCareOnly(patient?.CoverageList);
  const [taskModalOpened, handleTaskModal] = useDisclosure();
  const [outreachTaskPopoverOpened, handleOutreachTaskPopover] = useDisclosure();

  const closeTaskModal = async (): Promise<void> => {
    handleTaskModal.close();
  };

  if (result.loading) {
    return (
      <Table.Tr w="100%">
        <Loader size="xs" />
      </Table.Tr>
    );
  }

  const openOutreachTasks = result.data?.Patient?.TaskList ?? [];
  const age = formatAge(patient?.birthDate);
  const abbreviatedAge = age.replace('years old', 'y.o.');
  return (
    <Table.Tr>
      {hasElevatedPrivilege && (
        <Table.Td className={`table-sticky-column-left`} style={{ position: 'sticky', left: 0, zIndex: 1 }} bg="white">
          <ActionIcon
            onClick={() => {
              onToggleSelect(patientId);
            }}
          >
            {selected ? <IconSquareCheckFilled /> : <IconSquare color="gray" />}
          </ActionIcon>
        </Table.Td>
      )}
      <Table.Td
        className={`table-sticky-column-left${horizontalScrollShadow.left ? '-shadow' : ''}`}
        style={{ position: 'sticky', left: hasElevatedPrivilege ? 40 : 0, zIndex: 1 }}
        bg="white"
      >
        <Text>{name}</Text>
        <Group gap={1}>
          <Text size="xs" c={'dimmed'}>
            {patient?.address?.[0].state}
          </Text>
          <IconPointFilled size={14} color="gray" />
          <Text size="xs" c={'dimmed'}>
            {abbreviatedAge}
          </Text>
          <IconPointFilled size={14} color="gray" />
          {isAcuteCareOnly ? (
            <AcuteCareBadge />
          ) : (
            <ClusterBadge
              cluster={!carePathway ? 'Unknown' : ''}
              carePathway={carePathway ?? undefined}
              iconOnly
              hideSubtext
              condensedDisplay
            />
          )}
        </Group>
      </Table.Td>
      <Table.Td>{attribution && attributionDisplay[attribution as Attribution]}</Table.Td>
      <Table.Td>{(programStatus && programStatusDisplay[programStatus as ProgramStatus]) ?? 'Unknown'}</Table.Td>
      <Table.Td>{(outreachStatus && outreachStatusMap[outreachStatus as OutreachStatus]?.label) ?? 'Unknown'}</Table.Td>
      <Table.Td>
        {(disposition && outreachDispositionMap[disposition as OutreachDisposition]?.display) ?? 'Unknown'}
      </Table.Td>
      <Table.Td>{healthPlan?.name}</Table.Td>
      <Table.Td>
        {(openOutreachTasks.length ?? 0) > 0 ? (
          <Popover opened={outreachTaskPopoverOpened} shadow="md">
            <Popover.Target>
              <Text onMouseEnter={handleOutreachTaskPopover.open} onMouseLeave={handleOutreachTaskPopover.close}>
                {openOutreachTasks.length}
              </Text>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack gap={2}>
                {openOutreachTasks.filter(isDefined).map((task) => (
                  <Text key={task.id}>{task.code?.coding?.find((c) => c.system === System.TaskType)?.display}</Text>
                ))}
              </Stack>
            </Popover.Dropdown>
          </Popover>
        ) : (
          0
        )}
      </Table.Td>
      <Table.Td>{outreachCount}</Table.Td>
      <Table.Td>{lastOutreach && format(lastOutreach, 'M/d/yyyy h:mm a')}</Table.Td>
      <Table.Td
        className={`table-sticky-column-right${horizontalScrollShadow.right ? '-shadow' : ''}`}
        style={{ position: 'sticky', right: 0, zIndex: 1 }}
        bg="white"
      >
        <Group gap="xs">
          <Button
            to={`/Patient/${patientId}`}
            component={Link}
            variant="outline"
            size="compact-sm"
            style={{ fontSize: 10 }}
          >
            Profile
          </Button>
          <Button
            onClick={() => handleTaskModal.open()}
            variant="outline"
            leftSection={<IconPlus size="12" />}
            style={{ fontSize: 10 }}
            size="compact-sm"
          >
            Task
          </Button>
        </Group>
        {taskModalOpened && (
          <UpsertTaskModal
            patientId={patientId}
            opened={taskModalOpened}
            closeModal={closeTaskModal}
            operation="Create"
          />
        )}
      </Table.Td>
    </Table.Tr>
  );
}
