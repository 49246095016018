import React from 'react';
import { SelectInput, SelectInputProps } from '../../helpers/SelectInput';

interface GenderIdentityFieldProps {
  genderIdentityProps: Omit<SelectInputProps, 'label' | 'data'>;
  preferredPronounProps: Omit<SelectInputProps, 'label' | 'data'>;
  genderData: string[];
  preferredPronounData: string[];
}

const GenderIdentity = ({
  genderIdentityProps,
  preferredPronounProps,
  genderData,
  preferredPronounData,
}: GenderIdentityFieldProps): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <SelectInput {...genderIdentityProps} data={genderData} label="Gender identity" />
      <SelectInput {...preferredPronounProps} data={preferredPronounData} label="Preferred Pronouns" />
    </div>
  );
};

export default GenderIdentity;
