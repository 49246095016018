import { useEffect, useState } from 'react';
import { Participant, Room } from 'twilio-video';

interface Options {
  room?: Room;
}

interface Result {
  dominantSpeaker?: Participant;
}

export default function useDominantSpeaker({ room }: Options): Result {
  const [dominantSpeaker, setDominantSpeaker] = useState<Participant>();

  useEffect(() => {
    if (room) {
      const handleDominantSpeakerChanged = (participant: Participant): void => {
        setDominantSpeaker(participant);
      };

      room.on('dominantSpeakerChanged', handleDominantSpeakerChanged);

      return () => {
        room.off('dominantSpeakerChanged', handleDominantSpeakerChanged);
      };
    }

    setDominantSpeaker(undefined);

    return () => {};
  }, [room]);

  return { dominantSpeaker };
}
