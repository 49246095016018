import { MedplumClient } from '@medplum/core';
import { Bundle, ContactPoint, Device, Patient, Reference, Resource } from '@medplum/fhirtypes';
import { System } from 'const-utils';
import { cleanPhoneNumber } from './strings';
import { isDefined } from './lists';

export const createDevice = async (
  medplumClient: MedplumClient,
  {
    brand,
    id,
    model,
    osName,
    osVersion,
    permissions,
  }: {
    brand: string;
    id: string;
    model: string;
    osName: string;
    osVersion: string;
    permissions?: {
      camera: string;
      mediaLibrary?: string; //videos on android and not used for iOS
      microphone: string;
      notifications: string;
      photoLibrary: string;
    };
  },
  patientReference: Reference<Patient>,
  username: string,
  token?: string,
): Promise<Device> => {
  const extensions = [];

  if (token) {
    extensions.push({
      url: System.DevicePushToken,
      valueString: token,
    });
  }
  if (permissions) {
    extensions.push({
      url: System.DevicePermissions,
      valueString: JSON.stringify(permissions),
    });
  }

  const deviceResource: Device = {
    resourceType: 'Device',
    status: 'active',
    manufacturer: brand,
    modelNumber: model,
    patient: patientReference,
    version: [
      {
        type: {
          text: 'OS Name',
        },
        value: osName,
      },
      {
        type: {
          text: 'OS Version',
        },
        value: osVersion,
      },
    ],
    identifier: [
      {
        system: System.Device,
        value: `${id}:${username}`,
      },
    ],
    extension: extensions.length > 0 ? extensions : undefined,
  };
  return medplumClient.createResource(deviceResource);
};

interface DeviceExtensionUpdater {
  updateExtension: (key: string, value: string) => DeviceExtensionUpdater;
  build: () => Device;
}
export const deviceExtensionUpdater = (resource: Device): DeviceExtensionUpdater => {
  const build = (): Device => {
    return resource;
  };

  const updateExtension = (key: string, value: string): DeviceExtensionUpdater => {
    const newExtension = { url: key, valueString: value };
    if (!resource.extension) {
      resource.extension = [newExtension];
    } else {
      const updatedExtensions = resource.extension?.filter((extension) => extension.url !== key);

      updatedExtensions.push(newExtension);
      resource.extension = updatedExtensions;
    }
    return { updateExtension, build };
  };

  return { updateExtension, build };
};

export const phoneNumbersEqual = (a: string, b: string): boolean => {
  const aDigits = cleanPhoneNumber(a);
  const bDigits = cleanPhoneNumber(b);
  return aDigits === bDigits;
};

export const isValidPhoneNumber = (caregiver: Patient, number: string): boolean =>
  !!caregiver?.telecom?.some(
    (telecom: ContactPoint) => telecom.system === 'phone' && phoneNumbersEqual(telecom.value!, number),
  );

export const hasSMSConsent = (caregiver: Patient): boolean => {
  const hasConsent = !!caregiver.extension?.some(
    (extension) => extension.url === System.CaregiverSMSConsent.toString() && extension.valueBoolean,
  );

  return hasConsent;
};

export const unpackBundle = <T extends Resource>(bundle: Bundle<T>): T[] => {
  return bundle.entry?.map((entry) => entry.resource).filter(isDefined) || [];
};
