import React from 'react';
import { Attachment } from '@medplum/fhirtypes';
import { Button, Modal, Stack, Group, Text, TextInput, ThemeIcon } from '@mantine/core';
import { AttachmentDisplay } from '@medplum/react';
import { IconCloudUpload } from '@tabler/icons-react';

interface AttachmentModalProps {
  modalTitle: string;
  pendingAttachment?: Attachment;
  setPendingAttachment: (value: Attachment | undefined) => void;
  onAttachDocument: (value: Attachment | undefined) => void;
}

export function ManualConsentUploadModal({
  modalTitle,
  pendingAttachment,
  setPendingAttachment,
  onAttachDocument,
}: AttachmentModalProps): JSX.Element {
  return (
    <Modal.Root
      size="xl"
      padding="xl"
      radius="xl"
      opened={!!pendingAttachment}
      onClose={() => {
        setPendingAttachment(undefined);
      }}
    >
      <Modal.Overlay />
      <Modal.Content style={{ borderRadius: 20 }}>
        <Modal.Header>
          <Modal.Title c="imagine-green" style={{ fontSize: '20px' }}>
            {modalTitle}
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Stack>
            <Group>
              <AttachmentDisplay value={pendingAttachment as Attachment} maxWidth={200} />

              <Stack flex="1" h={300}>
                <Text>Name</Text>
                <TextInput
                  mt={-15}
                  width="auto"
                  defaultValue={pendingAttachment?.title}
                  onChange={(event) => {
                    setPendingAttachment({
                      ...pendingAttachment,
                      title: event.currentTarget.value,
                    });
                  }}
                />
              </Stack>
            </Group>
            <Group justify="flex-end">
              <Button
                variant="outline"
                onClick={() => {
                  setPendingAttachment(undefined);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onAttachDocument(pendingAttachment);
                  setPendingAttachment(undefined);
                }}
                rightSection={
                  <ThemeIcon variant="transparent">
                    <IconCloudUpload size={20} />
                  </ThemeIcon>
                }
              >
                Upload document
              </Button>
            </Group>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}
