import { useState } from 'react';
import { Resource, Task } from '@medplum/fhirtypes';
import { useMedplum, useMedplumProfile } from '@medplum/react';
import { combineDateAndTimeLocalTimezone } from 'imagine-dsl/utils/dates';
import { TaskFormValues } from './UpsertTaskModal';
import { TaskPriority, extractValues } from 'const-utils';
import { upsertTask } from 'imagine-dsl/services/taskService';
import { notifications } from '@mantine/notifications';
import { getErrorMessage, logError } from '@/errors';
import { useApolloClient } from '@apollo/client';
import { GetTasksDocument, GetPatientPanelDocument } from 'medplum-gql';
import { useTaskCountContext } from '@/pages/Task/TaskCountProvider';

type CallbackWithLoader = [(overridesFn?: (t: Task) => Task) => Promise<Resource | undefined>, boolean];

export const TaskPriorityCodes = extractValues(TaskPriority);

export const useSaveTask = (patientId: string, formValues: TaskFormValues): CallbackWithLoader => {
  const medplum = useMedplum();
  const profile = useMedplumProfile();
  const apolloClient = useApolloClient();
  const taskCounts = useTaskCountContext();

  const [loading, setLoading] = useState(false);

  const saveTask = async (overridesFn?: (t: Task) => Task): Promise<Resource | undefined> => {
    if (!profile?.id || !formValues.dueDate) {
      return undefined;
    }

    setLoading(true);

    try {
      const { taskType, assignedTo, priority, notes, id, requestor, createdDate } = formValues;
      const dueDate = combineDateAndTimeLocalTimezone(formValues.dueDate, formValues.dueTime);

      const result = await upsertTask(
        {
          medplum,
          taskType,
          businessStatus: priority,
          forPatientId: patientId,
          assignToPractitionerId: assignedTo,
          requestedByPractitionerId: requestor ?? profile.id,
          dueDate: dueDate ?? '',
          notes,
          id,
          createdDate,
        },
        overridesFn,
      );

      await Promise.all([
        apolloClient.refetchQueries({ include: [GetTasksDocument, GetPatientPanelDocument] }),
        taskCounts.refetch(),
      ]);

      return result;
    } catch (err) {
      notifications.show({
        title: 'Error creating task',
        message: getErrorMessage(err),
        color: 'status-error',
      });
      logError(err);
    } finally {
      setLoading(false);
    }

    return undefined;
  };

  return [saveTask, loading];
};
